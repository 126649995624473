import { transactionTypeFrom } from '@data/constants';
import { get, post, put } from './baseApi';

const getUsersList = async (token) => {
  return get('/admin/user/status', token);
};

const createUser = async (token, user) => {
  return post('/admin/user', user, token);
};

const unlockPin = async (token, params) => {
  return post('/admin/user/access/unlock-pin', params, token);
};

const unlockVerbal = async (params, token) => {
  return post('/admin/user/access/unlock-user-verbally', params, token);
};

const getUserListEnhance = async (
  token,
  page = 0,
  size = 25,
  searchBy,
  userStatus,
  searchText,
  companyId,
) => {
  let url = `/admin/user?page=${page}&size=${size}`;
  if (searchBy !== '' && searchText !== '') {
    url = url + `&${searchBy}=${searchText}`;
  }
  if (userStatus !== '') {
    url = url + `&user_status=${userStatus}`;
  }
  if (companyId > 0) {
    url = url + `&company_id=${companyId}`;
  }
  return get(url, token);
};

const getUserAccessCredential = async (token, id) => {
  return get(`/admin/user/access/${id}/credential`, token);
};

const getCompanyList = async (token) => {
  return get('/admin/company-group', token);
};

const getLocationGroup = async (token, companyId, page = 1, size = 20) => {
  return get(
    `/admin/location-group?company_id=${companyId}&page=${page}&size=${size}`,
    token,
  );
};

const putLocationGroup = async (params, token) => {
  return put('/admin/location-group', params, token);
};

const postLocationGroup = async (params, token) => {
  return post('/admin/location-group', params, token);
};

const sendUserUnlockPin = async (token, params) => {
  return post('/admin/user/access/unlock-user', params, token);
};

const getUserTypeList = async (token) => {
  return get('/admin/user-types', token);
};

const getCountryCodeList = async (token) => {
  return get('/admin/countries', token);
};

const postUserData = async (token, params) => {
  return post('/admin/user', params, token);
};

const getUserById = async (token, userId, inReview = false) => {
  const path = inReview ? 'user-review' : 'user';
  return get(`/admin/${path}/${userId}`, token);
};

const patchUserData = async (id, params, token, inReview = false) => {
  const path = inReview ? 'user-review' : 'user';
  return put(`/admin/${path}/${id}`, params, token);
};

const getTransactionById = async (
  userId,
  token,
  includeOtherSpends,
  typeData,
  limit = 10,
  offset = 0,
) => {
  let baseUrl = `/admin/transaction/${userId}/list?limit=${limit}&offset=${offset}`;

  if (typeData === transactionTypeFrom.LOCATION) {
    baseUrl = `/admin/transaction-by-location/${userId}/list?limit=${limit}&offset=${offset}`;
  }

  if (includeOtherSpends) {
    baseUrl = `${baseUrl}&include_other_spends=true`;
  }

  return get(baseUrl, token);
};

const getUserNotification = async (token) => {
  return get('/admin/counter-activities', token);
};

const getAllSecurityQuestions = async (token) => {
  return get('/admin/security-questions', token);
};

const getStateByCountryId = async (countryId, token) => {
  return get(`/admin/state?country_id=${countryId}`, token);
};

const getUserLocationDelegationList = async (
  companyId,
  keyword,
  userTypeId,
  isDefaultLocation,
  locationId,
  token,
) => {
  if (isDefaultLocation) {
    return get(
      `/admin/user-location/delegation?company_id=${companyId}&keyword=${keyword}&user_type_id=${userTypeId}&is_default_location=${isDefaultLocation}&default_location_id=${locationId}`,
      token,
    );
  } else {
    return get(
      `/admin/user-location/delegation?company_id=${companyId}&keyword=${keyword}&user_type_id=${userTypeId}`,
      token,
    );
  }
};

const getTimeZoneList = async (token) => {
  return get('/admin/timezones', token);
};

const verifyAdminPassword = async (token, password) => {
  return post('/admin/verify-admin', { password }, token);
};

const getUserLocation = async (token, params) => {
  return get(`/admin/user-location?${new URLSearchParams(params).toString()}`, token);
};

const resendSubscriptionToken = async (token, id) => {
  return post('/admin/user/subscription-token/resend', { user_id: id }, token);
};

export {
  getUsersList,
  createUser,
  unlockPin,
  unlockVerbal,
  getUserListEnhance,
  getCompanyList,
  getUserTypeList,
  getCountryCodeList,
  postUserData,
  getUserById,
  patchUserData,
  getUserAccessCredential,
  sendUserUnlockPin,
  getTransactionById,
  getUserNotification,
  getAllSecurityQuestions,
  getStateByCountryId,
  getLocationGroup,
  putLocationGroup,
  postLocationGroup,
  getUserLocationDelegationList,
  getTimeZoneList,
  verifyAdminPassword,
  getUserLocation,
  resendSubscriptionToken,
};
