import React from 'react';
import _ from 'lodash';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BaseComponent from '@containers/BaseComponent';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CustomizedInputText from '@components/customInputText';
import CustomizedSnackBar from '@components/customizedSnackBar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@components/dialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@assets/icons/CardHero.Delete.png';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { userTypes, userRoles, userOperationTag } from '@data/constants';
import * as userActions from '@redux/user/userActions';
import { customization } from '@customization/default';
import {
  formUserSecondBlockStyle,
  secondBlockCheckboxStyle,
  dialogContentStyle,
  dialogActionStyle,
} from './style';

const CustomCheckbox = withStyles(secondBlockCheckboxStyle)((props) => (
  <Checkbox color="default" {...props} />
));
const DialogContent = withStyles(dialogContentStyle)(MuiDialogContent);
const DialogActions = withStyles(dialogActionStyle)(MuiDialogActions);

class FormUserSecondBlock extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      userSearchResult: [],
      searchText: '',
      userLocations: [],
      userLocationsChanged: false,
      secondBlockCheck: {
        inheritDefaultLocation: false,
        overallAdministrator: false,
        displayOnlyDefault: false,
      },
      searchResultDialog: false,
      showConfirmationDialog: false,
      tempOverallAdministratorCheck: false,
      overallAdminEdited: false,
      defaultLocationEdited: false,
      rowToDelete: {},
      dialogMessage: '',
      dialogHandleFunction: null,
      selectedDefaultLocation: {},
      showSnackBar: false,
      snackBarSeverity: '',
      snackBarMessage: '',
      editFirstLoad: true,
      tempUserLocations: [],
      tempRowsPerPage: 0,
      tempTotalRecords: 0,
      tempTotalPages: 0,
      maximumList: false,
    };
  }

  componentDidMount() {
    this.setSecondBlockDefaultCheck();
  }

  componentDidUpdate(prevProps) {
    const { selectedUserType, defaultLocation, operationTag, userData } = this.props;
    const { editFirstLoad } = this.state;
    if (operationTag === userOperationTag.EDIT_USER) {
      if (editFirstLoad) {
        // populate user/location delegation based on DB
        this.setState({
          userLocations: userData.delegation,
          userLocationsChanged: false,
          editFirstLoad: false,
          tempUserLocations: userData.delegation,
        });
      } else {
        if (prevProps.selectedUserType.id !== selectedUserType.id) {
          // remove user/location delegation when user type was changed
          this.setState(
            {
              userLocationsChanged: false,
            },
            async () => {
              this.keepOrRemoveDelegationList(selectedUserType);
              this.setSecondBlockDefaultCheck(true);
              this.currentInheritDefaultLocationCheck();
            },
          );
        }
      }
    } else {
      if (prevProps.selectedUserType.id !== selectedUserType.id) {
        this.setState(
          {
            userLocationsChanged: false,
          },
          async () => {
            this.keepOrRemoveDelegationList(selectedUserType);
            this.setSecondBlockDefaultCheck(true);
            this.currentInheritDefaultLocationCheck();
          },
        );
      }
    }

    if (defaultLocation?.id && prevProps.defaultLocation !== defaultLocation) {
      this.setState(
        {
          selectedDefaultLocation: defaultLocation,
        },
        () => {
          const { secondBlockCheck } = this.state;
          if (secondBlockCheck.inheritDefaultLocation) {
            this.insertSelectedDefaultLocation();
          }
        },
      );
    }
  }

  currentInheritDefaultLocationCheck = () => {
    if (this.state.secondBlockCheck.inheritDefaultLocation) {
      const event = {
        target: {
          checked: false,
        },
      };
      event.target.checked = this.state.inheritDefaultLocation;
      this.onCheckedHandle(event, 'inheritDefaultLocation');
    }
  };

  keepOrRemoveDelegationList = (selectedUserType) => {
    const selectedRole = selectedUserType.user_type_access_rights?.user_role || '';
    if (selectedRole !== userRoles.DELEGATE) {
      this.setState({
        userLocations: [],
      });
    } else {
      this.setState({
        userLocations: this.state.tempUserLocations,
      });
    }
  };

  findExistDefaultLocation = () => {
    const { userLocations, selectedDefaultLocation } = this.state;
    if (selectedDefaultLocation.id) {
      return userLocations.findIndex(
        (element) =>
          element.default === selectedDefaultLocation.default ||
          selectedDefaultLocation.id === element.id,
      );
    } else {
      return -1;
    }
  };

  insertSelectedDefaultLocation = async () => {
    const { userLocations, selectedDefaultLocation } = this.state;
    this.onCheckUserLocationDelegation();

    if (!this.state.maximumList) {
      // delete existing default first
      const defaultLocationIndex = userLocations.findIndex(
        (element) => element.default === selectedDefaultLocation.default,
      );
      if (defaultLocationIndex > -1) {
        userLocations.splice(defaultLocationIndex, 1);
      }

      // check on the table if the selected default location already exist
      const index = this.findExistDefaultLocation();
      if (index > -1) {
        if (userLocations[index].id === selectedDefaultLocation.id) {
          // found, code and description is same, set default to true
          userLocations[index].default = true;
          this.setState({ userLocations });
        }
      } else {
        if (selectedDefaultLocation.id) {
          const defaultLocObj = {};
          defaultLocObj.id = selectedDefaultLocation.id;
          defaultLocObj.type = selectedDefaultLocation.type;
          defaultLocObj.code = selectedDefaultLocation.code;
          defaultLocObj.description = selectedDefaultLocation.description;
          defaultLocObj.active = false;
          defaultLocObj.default = true;
          userLocations.push(defaultLocObj);

          this.setState({
            userLocations,
            userLocationsChanged: true,
            tempUserLocations: userLocations,
          });
        }
      }
    }
  };

  setSecondBlockDefaultCheck = (userTypeChanged = false) => {
    const { secondBlockCheck, overallAdminEdited, defaultLocationEdited } = this.state;
    const { selectedUserType, operationTag, userData } = this.props;
    if (
      selectedUserType.user_type_access_rights?.user_role.toUpperCase() ===
        userTypes.DELEGATE &&
      selectedUserType.user_type_transaction_settings?.display_inherit_default_location
    ) {
      secondBlockCheck.inheritDefaultLocation = true;
      secondBlockCheck.overallAdministrator = false;
    } else if (
      selectedUserType.user_type_access_rights?.user_role.toUpperCase() ===
      userTypes.ADMINISTRATOR
    ) {
      secondBlockCheck.inheritDefaultLocation = false;
      secondBlockCheck.overallAdministrator = true;
    } else {
      // if user type is OBSERVER or NONE or DELEGATE(inherit default location is false)
      secondBlockCheck.inheritDefaultLocation = false;
      secondBlockCheck.overallAdministrator = false;
    }

    if (operationTag === userOperationTag.EDIT_USER && !userTypeChanged) {
      const currentOverallAdministrator =
        userData.is_overall_administrator === null
          ? false
          : userData.is_overall_administrator;

      const currentInheritDefaultLocation =
        userData.user_type_transaction_settings?.display_inherit_default_location ===
        true;

      if (
        secondBlockCheck.overallAdministrator !== currentOverallAdministrator &&
        !overallAdminEdited
      ) {
        secondBlockCheck.overallAdministrator = currentOverallAdministrator;
      }

      if (
        secondBlockCheck.inheritDefaultLocation !== currentInheritDefaultLocation &&
        !defaultLocationEdited
      ) {
        secondBlockCheck.inheritDefaultLocation = currentInheritDefaultLocation;
      }
      this.setState({ secondBlockCheck });
    } else {
      this.setState({ secondBlockCheck });
    }
  };

  submitSearch = () => {
    const { searchText } = this.state;
    if (searchText === '') {
      this.setState({ searchResultDialog: false });
    } else {
      this.searchUserLocation();
    }
  };

  searchUserLocation = () => {
    const { searchText, secondBlockCheck } = this.state;
    let { userSearchResult } = this.state;
    const { selectedUserType, selectedCompanyId, defaultLocation } = this.props;
    this.props.userAction
      .getUserLocationDelegation(
        selectedCompanyId,
        searchText,
        selectedUserType.id,
        secondBlockCheck.displayOnlyDefault,
        defaultLocation?.id ? defaultLocation.id : '',
        this.props.token,
      )
      .then(() => {
        if (this.props.errorResponse.statusCode) {
          this.setState({
            showSnackBar: true,
            snackBarSeverity: 'error',
            snackBarMessage: `Get user/location list error: ${this.props.errorResponse.statusCode}`,
            notFound: true,
          });
        } else {
          userSearchResult = [];
          if (this.props.userLocationDelegationList.length > 0) {
            this.props.userLocationDelegationList.forEach((element) => {
              const userLocationObj = {
                id: element.id,
                code: element.code,
                default: false,
                description: element.description,
                is_active: false,
                type: element.type,
              };
              userSearchResult.push(userLocationObj);
            });
          }
          this.setState({
            searchResultDialog: true,
            userSearchResult,
          });
        }
      });
  };

  emptyTableUserLocations = () => {
    this.setState({
      userLocations: [],
    });
  };

  handleChangeOverallAdministrator = () => {
    this.setState(
      {
        showConfirmationDialog: false,
      },
      () => {
        // set check state and empty table when user using overall administrator
        const { secondBlockCheck, tempOverallAdministratorCheck } = this.state;
        secondBlockCheck.overallAdministrator = tempOverallAdministratorCheck;
        this.setState(
          {
            secondBlockCheck,
          },
          () => {
            this.emptyTableUserLocations();
          },
        );
      },
    );
  };

  onOverallAdministratorCheck = async (event) => {
    const { userLocations } = this.state;
    // save the overall administrator check event to state
    if (event.target.checked && userLocations.length > 0) {
      this.setState(
        {
          overallAdminEdited: true,
          tempOverallAdministratorCheck: event.target.checked,
          dialogMessage: customization.formUserSecondBlock.useOverallAdministrator,
          dialogHandleFunction: this.handleChangeOverallAdministrator,
        },
        () => {
          this.setState({
            showConfirmationDialog: true,
          });
        },
      );
    } else {
      // trigger the checkbox handle when there is no data on table
      this.onCheckedHandle(event, 'overallAdministrator');
    }
  };

  onCheckedHandle = async (event, key) => {
    const { secondBlockCheck, selectedDefaultLocation, userLocations } = this.state;
    secondBlockCheck[key] = event.target.checked;

    this.setState(
      {
        secondBlockCheck,
      },
      () => {
        if (key === 'inheritDefaultLocation') {
          this.setState({ defaultLocationEdited: true });
          if (!event.target.checked) {
            const index = this.findExistDefaultLocation();
            if (index > -1) {
              userLocations.splice(index, 1);
              this.setState({
                userLocations,
              });
            }
          } else {
            if (selectedDefaultLocation.id) {
              this.insertSelectedDefaultLocation();
            }
          }
        }
        if (key === 'displayOnlyDefault') {
          this.searchUserLocation();
        }
      },
    );
  };

  onCheckedTableHandle = (event, row) => {
    const { userLocations } = this.state;
    const itemIndex = userLocations.findIndex((element) => element.id === row.id);
    userLocations[itemIndex].active = event.target.checked;
    this.setState({
      userLocations,
      userLocationsChanged: true,
    });
  };

  onCheckedResultTableHandle = (event, index) => {
    const { userSearchResult } = this.state;
    userSearchResult[index].selected = event.target.checked;
  };

  handleSearchInput = (val) => {
    this.setState({ searchText: val.target.value }, () => {
      if (this.state.searchText === '') {
        this.setState({ searchResultDialog: false });
      }
    });
  };

  keyPress = (e) => {
    if (e.keyCode === 13) {
      this.submitSearch();
    }
  };

  onCheckUserLocationDelegation() {
    const { userLocations } = this.state;
    let { maximumList } = this.state;
    const maxLength = 15;
    if (userLocations.length < maxLength) {
      maximumList = false;
    } else {
      maximumList = true;
    }
    this.setState({
      maximumList,
    });
  }

  onAddUserLocation = async () => {
    const { userSearchResult, userLocations } = this.state;
    this.onCheckUserLocationDelegation();

    if (!this.state.maximumList) {
      const previousLength = userLocations.length;
      userSearchResult.forEach((element) => {
        const resultObj = {};
        const itemExist = userLocations.findIndex((record) => record.id === element.id);
        if (element.selected && itemExist < 0) {
          resultObj.id = element.id;
          resultObj.type = element.type;
          resultObj.code = element.code;
          resultObj.description = element.description;
          resultObj.active = false;
          if (userLocations.length < 15) {
            userLocations.push(resultObj);
          }
        }
      });
      this.setState({
        userLocations,
        searchResultDialog: false,
        tempUserLocations: userLocations,
      });
      if (previousLength < userLocations.length) {
        this.setState({
          userLocationsChanged: true,
        });
      }
    }
  };

  onDeleteItem = () => {
    const { userLocations, rowToDelete } = this.state;
    const itemIndex = userLocations.findIndex((element) => element.id === rowToDelete.id);
    userLocations.splice(itemIndex, 1);
    this.setState({
      userLocations,
      showConfirmationDialog: false,
      userLocationsChanged: true,
    });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  renderActionButton = (row, index) => {
    const { classes } = this.props;

    const actionBtn = (
      <IconButton
        size="small"
        className={classes.actionButtonStyle}
        onClick={() => {
          this.openDeleteConfirmationDialog(row);
        }}
      >
        <img
          src={DeleteIcon}
          alt="delete_icon"
          loading="lazy"
          className={classes.deleteIconStyle}
        />
      </IconButton>
    );

    return actionBtn;
  };

  handleCloseConfirmationDialog = () => {
    this.setState({
      showConfirmationDialog: false,
      dialogMessage: customization.formUserSecondBlock.deleteConfirmation,
      dialogHandleFunction: this.onDeleteItem,
    });
  };

  handleCloseEmptyTableDialog = () => {
    this.setState({
      showConfirmationDialog: false,
    });
  };

  openDeleteConfirmationDialog = (row) => {
    this.setState({
      showConfirmationDialog: true,
      dialogMessage: customization.formUserSecondBlock.deleteConfirmation,
      dialogHandleFunction: this.onDeleteItem,
      rowToDelete: row,
    });
  };

  renderConfirmationDialog = () => {
    const { classes } = this.props;
    const { dialogMessage, dialogHandleFunction } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        onClose={this.handleCloseConfirmationDialog}
        open={this.state.showConfirmationDialog}
        classes={{
          paper: classes.paperConfirmationMessageStyle,
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={this.handleCloseConfirmationDialog}
        ></DialogTitle>
        <DialogContent>
          <Box p={2}>
            <div className={classes.confirmationMsgContainerStyle}>
              <Typography>{dialogMessage}</Typography>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6} className={classes.buttonContainer}>
              <Button
                autoFocus
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.confirmProceedStyle}
                onClick={dialogHandleFunction}
              >
                {customization.formUserSecondBlock.confirmBtn}
              </Button>
            </Grid>
            <Grid item xs={12} md={12} lg={6} className={classes.buttonContainer}>
              <Button
                className={classes.closeButton}
                onClick={this.handleCloseConfirmationDialog}
                variant="outlined"
              >
                {customization.formUserSecondBlock.cancelBtn}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  createTableHeader = () => {
    const { order, orderBy } = this.state;
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell
            align="left"
            classes={{ root: classes.mediumTitleWidthCell }}
            sortDirection={orderBy === 'type' ? order : false}
          >
            <TableSortLabel
              active={orderBy === 'type'}
              direction={orderBy === 'type' ? order : 'asc'}
              onClick={(e) => this.handleRequestSort(e, 'type')}
            >
              <Typography>
                <b> {customization.formUserSecondBlock.typeHeader}</b>
              </Typography>
              {orderBy === 'type' ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
          <TableCell
            align="left"
            classes={{ root: classes.largeTitleWidthCell }}
            sortDirection={orderBy === 'mobileNumber' ? order : false}
          >
            <TableSortLabel
              active={orderBy === 'mobileNumber'}
              direction={orderBy === 'mobileNumber' ? order : 'asc'}
              onClick={(e) => this.handleRequestSort(e, 'mobileNumber')}
            >
              <Typography>
                <b> {customization.formUserSecondBlock.mobileNumberHeader}</b>
              </Typography>
              {orderBy === 'mobileNumber' ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
          <TableCell
            align="left"
            classes={{ root: classes.largeTitleWidthCell }}
            sortDirection={orderBy === 'location' ? order : false}
          >
            <TableSortLabel
              active={orderBy === 'location'}
              direction={orderBy === 'location' ? order : 'asc'}
              onClick={(e) => this.handleRequestSort(e, 'location')}
            >
              <Typography>
                <b> {customization.formUserSecondBlock.locationHeader}</b>
              </Typography>
              {orderBy === 'location' ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
          <TableCell align="center" classes={{ root: classes.extraSmallTitleWidthCell }}>
            <Typography>
              <b> {customization.formUserSecondBlock.activeHeader}</b>
            </Typography>
          </TableCell>
          <TableCell align="center" classes={{ root: classes.extraSmallTitleWidthCell }}>
            <Typography>
              <b> {customization.formUserSecondBlock.actionHeader}</b>
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  renderTable = () => {
    const { userLocations } = this.state;
    const { classes } = this.props;

    // currently this is using user data as a mockup, this will be changed later once API is available
    return (
      <TableContainer classes={{ root: classes.tableContainerRootStyle }}>
        <Table className={classes.table} aria-label="custom pagination table">
          {this.createTableHeader()}
          <TableBody>
            {userLocations.length > 0 &&
              userLocations.map((row, index) => (
                <React.Fragment key={`userLocations-${row.id}-${index}`}>
                  <TableRow elevation={0} classes={{ root: classes.paperCellStyle }}>
                    <TableCell className={classes.mediumCellStyle}>{row.type}</TableCell>
                    <TableCell
                      className={classes.largeCellStyle}
                      key={index}
                      align="left"
                    >
                      {row.code}
                    </TableCell>
                    <TableCell className={classes.largeCellStyle}>
                      {row.description}
                    </TableCell>
                    <TableCell className={classes.extraSmallWidthCell} align="center">
                      <CustomCheckbox
                        id={`show-${index}`}
                        name={`show-${index}`}
                        checked={row.active}
                        onChange={(e) => this.onCheckedTableHandle(e, row)}
                      />
                    </TableCell>
                    <TableCell className={classes.btnActionCellStyle} align="center">
                      {row.type && !row.default && this.renderActionButton(row, index)}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={7} className={classes.emptyTableCellStyle} />
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  createResultTableHeader = () => {
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell align="left" classes={{ root: classes.mediumTitleWidthCell }}>
            <Typography>
              <b> {customization.formUserSecondBlock.typeHeader}</b>
            </Typography>
          </TableCell>
          <TableCell align="left" classes={{ root: classes.largeTitleWidthCell }}>
            <Typography>
              <b> {customization.formUserSecondBlock.mobileNumberHeader}</b>
            </Typography>
          </TableCell>
          <TableCell align="left" classes={{ root: classes.largeTitleWidthCell }}>
            <Typography>
              <b> {customization.formUserSecondBlock.locationHeader}</b>
            </Typography>
          </TableCell>
          <TableCell classes={{ root: classes.extraSmallTitleWidthCell }} align="center">
            <Typography>
              <b> {customization.formUserSecondBlock.selectHeader}</b>
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  renderSearchResult = () => {
    const { userSearchResult } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Box p={1}>{this.renderOnlyDisplayDefault()}</Box>
        {userSearchResult.length > 0 ? (
          <>
            <TableContainer classes={{ root: classes.tableContainerRootStyle }}>
              <Table className={classes.table} aria-label="custom pagination table">
                {this.createResultTableHeader()}
                <TableBody>
                  {userSearchResult.length > 0 &&
                    userSearchResult.map((row, index) => (
                      <React.Fragment key={`search-${row.id}-${index}`}>
                        <TableRow
                          elevation={0}
                          classes={{
                            root: classes.paperCellStyle,
                          }}
                        >
                          <TableCell className={classes.mediumCellStyle}>
                            {row.type}
                          </TableCell>
                          <TableCell className={classes.largeCellStyle} align="left">
                            {row.code}
                          </TableCell>
                          <TableCell className={classes.largeCellStyle}>
                            {row.description}
                          </TableCell>
                          <TableCell
                            className={classes.extraSmallWidthCell}
                            align="center"
                          >
                            <CustomCheckbox
                              id={`search ${index}`}
                              name={`search ${index}`}
                              checked={row.selected}
                              onChange={(e) => this.onCheckedResultTableHandle(e, index)}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            colSpan={4}
                            className={classes.emptyTableCellStyle}
                          />
                        </TableRow>
                      </React.Fragment>
                    ))}

                  <TableRow key="search-submit">
                    <TableCell
                      colSpan={4}
                      align="right"
                      classes={{
                        root: classes.addSelectedStyle,
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={this.onAddUserLocation}
                      >
                        {customization.formUserSecondBlock.addSelectedBtn}
                      </Button>

                      {this.state.maximumList && (
                        <div>
                          <span className={classes.maximumMessage}>
                            {customization.formUserSecondBlock.maximumExceeded}
                          </span>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <>
            <br></br>
            <div className={classes.emptyResultContainer}>
              <span>{customization.formUserSecondBlock.emptySearch}</span>
            </div>
          </>
        )}
      </>
    );
  };

  renderOnlyDisplayDefault = () => {
    const { secondBlockCheck, selectedDefaultLocation } = this.state;
    const { classes } = this.props;
    if (selectedDefaultLocation.id) {
      return (
        <FormControlLabel
          control={
            <CustomCheckbox
              id="displayOnlyDefault"
              name="displayOnlyDefault"
              checked={secondBlockCheck.displayOnlyDefault}
              onChange={(e) => this.onCheckedHandle(e, 'displayOnlyDefault')}
            />
          }
          classes={{ label: classes.formControlLabel }}
          label={customization.formUserSecondBlock.displayOnlyDefault}
        />
      );
    } else {
      return null;
    }
  };

  renderOvrlAdmCheckBox = () => {
    const { secondBlockCheck } = this.state;
    const { classes } = this.props;
    return (
      <FormControlLabel
        control={
          <CustomCheckbox
            id="overallAdministrator"
            name="overallAdministrator"
            checked={secondBlockCheck.overallAdministrator}
            onChange={(e) => this.onOverallAdministratorCheck(e)}
          />
        }
        classes={{ label: classes.formControlLabel }}
        label={customization.formUserSecondBlock.overallAdministratorCheckBox}
      />
    );
  };

  renderInheritLocationCheckBox = () => {
    const { secondBlockCheck } = this.state;
    const { classes } = this.props;
    return (
      <FormControlLabel
        control={
          <CustomCheckbox
            id="inheritDefaultLocation"
            name="inheritDefaultLocation"
            checked={secondBlockCheck.inheritDefaultLocation}
            onChange={(e) => this.onCheckedHandle(e, 'inheritDefaultLocation')}
          />
        }
        classes={{ label: classes.formControlLabel }}
        label={customization.formUserSecondBlock.inheritDefaultLocation}
      />
    );
  };

  checkFormValidation = () => {
    if (this.state.userLocations.length > 0) {
      if (this.state.userLocationsChanged) {
        if (this.props.rootIsFormValid) {
          return false;
        } else {
          return true;
        }
      } else {
        if (this.props.rootIsFormValid) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      if (!this.props.rootIsFormValid) {
        return true;
      } else {
        return false;
      }
    }
  };

  handleCreate = async (flag) => {
    const { submitCreate } = this.props;
    const { userLocations, secondBlockCheck } = this.state;

    await this.props.bindHandleDisableButtonSave();
    await submitCreate(flag, userLocations, secondBlockCheck);
  };

  renderInviteCancelBtn = () => {
    const { classes, bindClose, selectedUserType, cardTypes, operationTag } = this.props;

    return (
      <>
        <Grid item xs={12} md={12} lg={2}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className={classes.inviteButton}
            onClick={
              !selectedUserType.user_type_access_rights?.allow_mobile_access &&
              cardTypes.length > 0
                ? () => this.handleCreate(true)
                : () => this.handleCreate(false)
            }
            disabled={this.checkFormValidation() || this.props.isDisableSaveButton}
          >
            {operationTag === userOperationTag.EDIT_USER
              ? customization.formUserSecondBlock.saveChanges
              : customization.formUserSecondBlock.inviteToJoinBtn}
          </Button>
        </Grid>
        <Grid item xs={12} md={12} lg={1}>
          <Button className={classes.cancelButton} variant="outlined" onClick={bindClose}>
            {customization.formUserSecondBlock.cancelBtn}
          </Button>
        </Grid>
      </>
    );
  };

  delegateModeRender = () => {
    const { searchText, secondBlockCheck, searchResultDialog, userLocations } =
      this.state;
    const { classes, selectedUserType } = this.props;
    return (
      <>
        {selectedUserType.user_type_access_rights?.user_role.toUpperCase() !==
          userTypes.NONE && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} lg={9}>
                <div className={classes.checkboxContainer}>
                  {selectedUserType.user_type_access_rights?.user_role.toUpperCase() ===
                    userTypes.ADMINISTRATOR && !secondBlockCheck.overallAdministrator
                    ? this.renderOvrlAdmCheckBox()
                    : selectedUserType.user_type_access_rights?.user_role.toUpperCase() ===
                        userTypes.DELEGATE &&
                      selectedUserType.user_type_transaction_settings
                        ?.display_inherit_default_location &&
                      this.renderInheritLocationCheckBox()}
                  {this.state.maximumList && (
                    <span className={classes.maximumMessage}>
                      {customization.formUserSecondBlock.maximumExceeded}
                    </span>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <CustomizedInputText
                  name="search"
                  onChange={(e) => this.handleSearchInput(e)}
                  value={searchText}
                  className={classes.searchInputStyle}
                  onKeyDown={(e) => this.keyPress(e)}
                  classes={{
                    input: classes.input,
                  }}
                  inputProps={{
                    placeholder: customization.formUserSecondBlock.searchPlaceHolder,
                  }}
                >
                  <IconButton
                    size="small"
                    className={classes.searchBtnStyle}
                    onClick={this.submitSearch}
                  >
                    <SearchIcon className={classes.searchIconStyle}></SearchIcon>
                  </IconButton>
                </CustomizedInputText>
              </Grid>
              {searchResultDialog && (
                <Grid container>
                  <Grid item xs={12}>
                    <Paper variant="outlined" elevation={2}>
                      <Box p={3}>{this.renderSearchResult()}</Box>
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <br></br>
            {!_.isEmpty(userLocations) ? (
              this.renderTable()
            ) : (
              <div className={classes.divEmptyTableContainer}>
                <Paper className={classes.emptyRecordContainer}>
                  <b className={classes.emptyRecordCaption}>
                    {customization.formUserSecondBlock.emptyLocationsTable}
                  </b>
                </Paper>
              </div>
            )}
          </>
        )}
        <br></br>
        <Grid container spacing={2}>
          <Grid item xs={12} md={9} lg={9}></Grid>
          {this.renderInviteCancelBtn()}
        </Grid>
      </>
    );
  };

  administratorModeRender = () => {
    const { classes } = this.props;
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} sm={12} md={9} lg={7}>
          <Paper className={classes.emptyRecordContainer}>
            <b className={classes.emptyRecordCaption}>
              {customization.formUserSecondBlock.overallAdministratorMessage}
            </b>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2}>
          <div className={classes.containerOveralAdministratorCheckbox}>
            {this.renderOvrlAdmCheckBox()}
          </div>
        </Grid>
        {this.renderInviteCancelBtn()}
      </Grid>
    );
  };

  handleSnackBar = () => {
    this.setState({ showSnackBar: false });
  };

  render() {
    const { classes, selectedUserType } = this.props;
    const { secondBlockCheck, showSnackBar, snackBarSeverity, snackBarMessage } =
      this.state;
    return (
      <div className={classes.divStyle}>
        <CustomizedSnackBar
          showBar={showSnackBar}
          severity={snackBarSeverity}
          message={snackBarMessage}
          handleClose={this.handleSnackBar}
        />

        {this.renderConfirmationDialog()}

        <div className={classes.divTableContainer}>
          {selectedUserType.user_type_access_rights?.user_role.toUpperCase() ===
            userTypes.ADMINISTRATOR && secondBlockCheck.overallAdministrator ? (
            this.administratorModeRender()
          ) : selectedUserType.id ? (
            this.delegateModeRender()
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={9} lg={9}></Grid>
              {this.renderInviteCancelBtn()}
            </Grid>
          )}
        </div>
      </div>
    );
  }
}

FormUserSecondBlock.propTypes = {
  classes: PropTypes.object,
  selectedUserType: PropTypes.object,
  bindClose: PropTypes.func,
  rootIsFormValid: PropTypes.bool,
  defaultLocation: PropTypes.object,
  submitCreate: PropTypes.func,
  selectedCompanyId: PropTypes.number,
  operationTag: PropTypes.string,
  userData: PropTypes.object,
  bindHandleDisableButtonSave: PropTypes.func,
  isDisableSaveButton: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
    userLocationDelegationList: state.user.userLocationDelegationList,
    cardTypes: state.user.cardTypes,
    errorResponse: state.user.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userAction: bindActionCreators(userActions, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withStyles(formUserSecondBlockStyle),
  withConnect,
)(FormUserSecondBlock);
