const toCamelCase = (sentenceCase) => {
  if (sentenceCase.includes('_')) {
    sentenceCase = sentenceCase.replace(/_/g, ' ');
  }
  if (sentenceCase.includes(' ')) {
    let out = '';
    sentenceCase.split(' ').forEach(function (el, idx) {
      const add = el.toLowerCase();
      out += add[0].toUpperCase() + add.slice(1) + ' ';
    });
    return out;
  } else {
    const lowerCaseSentence = sentenceCase.toLowerCase();
    return lowerCaseSentence.replace(/^\w/, (c) => c.toUpperCase());
  }
};

export default toCamelCase;
