import {
  getActivitiesList,
  getActivityDetail,
  approveActivity,
  rejectActivity,
} from '@services/api/activitiesApi';
import activitiesTypes from './activitiesTypes';

export const getAllActivities = (
  token,
  page,
  size,
  searchBy,
  activitiesStatus,
  searchText,
) => {
  return async (dispatch) => {
    dispatch({ type: activitiesTypes.GET_ACTIVITIES_LIST_REQUEST });
    try {
      const response = await getActivitiesList(
        token,
        page,
        size,
        searchBy,
        activitiesStatus,
        searchBy === 'activity_type' ? searchText.replace(/\s/g, '_') : searchText,
      );
      dispatch({
        type: activitiesTypes.GET_ACTIVITIES_LIST_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: activitiesTypes.GET_ACTIVITIES_LIST_ERROR, error });
      return error;
    }
  };
};

export const fetchActivityDetail = (token, id) => {
  return async (dispatch) => {
    dispatch({ type: activitiesTypes.GET_ACTIVITY_DETAIL_REQUEST });
    try {
      const response = await getActivityDetail(token, id);
      dispatch({
        type: activitiesTypes.GET_ACTIVITY_DETAIL_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: activitiesTypes.GET_ACTIVITY_DETAIL_ERROR, error });
      return error;
    }
  };
};

export const approveActivityDetail = (token, params) => {
  return async (dispatch) => {
    dispatch({ type: activitiesTypes.APPROVE_ACTIVITY_REQUEST });
    try {
      const response = await approveActivity(token, params);
      dispatch({
        type: activitiesTypes.APPROVE_ACTIVITY_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: activitiesTypes.APPROVE_ACTIVITY_ERROR, error });
      return error;
    }
  };
};

export const rejectActivityDetail = (token, params) => {
  return async (dispatch) => {
    dispatch({ type: activitiesTypes.REJECT_ACTIVITY_REQUEST });
    try {
      const response = await rejectActivity(token, params);
      dispatch({
        type: activitiesTypes.REJECT_ACTIVITY_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: activitiesTypes.REJECT_ACTIVITY_ERROR, error });
      return error;
    }
  };
};

export const clearActivityState = () => {
  return async (dispatch) => {
    dispatch({ type: activitiesTypes.CLEAR_STATE });
  };
};
