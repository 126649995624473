export const customInputFieldStyle = (theme) => ({
  placeholder: {
    color: '#aaa',
  },
  formControl: {
    width: '100%',
  },
  itemStyle: {
    whiteSpace: 'normal',
  },
});

export const bootStrapInput = (theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 26,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 26,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
});
