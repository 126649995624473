export const dialogConfirmationStyle = (theme) => ({
  warningIcon: {
    color: '#ffc100',
    fontSize: '100px',
  },
  warningMessage: {
    color: 'black',
  },
  warningIconContainerStyle: {
    textAlign: 'center',
    paddingBottom: '30px',
  },
  warningMsgContainerStyle: {
    textAlign: 'center',
  },
  paperWarningMessageStyle: {
    border: '1px solid',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  confirmProceedStyle: {
    color: 'white',
    width: '100%',
    float: 'right',
    margin: theme.spacing(1, 0, 1),
  },
  closeButton: {
    width: '100%',
    margin: theme.spacing(1, 0, 1),
    backgroundColor: '#F7F7F7',
  },
  dialogTitle: {
    '& button': {
      color: 'black',
    },
  },
});

export const dialogActionStyle = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
});
