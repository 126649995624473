import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { customInputFieldStyle, customLabelFieldStyle } from './style';

const CustomInput = withStyles(customInputFieldStyle)(InputBase);
const useStyles = makeStyles(customLabelFieldStyle);

function CustomizedInputText(props) {
  const classes = useStyles();
  const { label, name, children } = props;

  return (
    <FormControl>
      {label && (
        <InputLabel
          shrink
          htmlFor={name}
          classes={{
            root: classes.root,
            focused: classes.focused,
          }}
        >
          {label}
        </InputLabel>
      )}
      <CustomInput {...props} id={name} type="text" />
      {children}
    </FormControl>
  );
}

CustomizedInputText.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node,
};

export default CustomizedInputText;
