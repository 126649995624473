import React from 'react';
import BaseComponent from '@containers/BaseComponent';
import PropTypes from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@components/dialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormTextInput from '@components/formTextInput';
import FormCurrencyInput from '@components/formCurrencyInput';
import FormCheckBox from '@components/formCheckBox';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/Edit';
import EmptyRowsOverlay from '@components/emptyRowsOverlay';
import AddPersonIcon from '@material-ui/icons/PersonAddRounded';
import { DataGrid } from '@mui/x-data-grid';
import { withStyles } from '@material-ui/core/styles';
import * as userTypeActions from '@redux/settings/userTypeActions';
import { customization } from '@customization/default';
import {
  userRoleOptions,
  splitMethodOptions,
  dateFormatOptions,
  apiRequestStatus,
  userRoles,
} from '@data/constants';
import {
  userTypeStyle,
  dialogContentStyle,
  dialogActionStyle,
  userTypeCheckboxStyle,
} from './style';

const DialogContent = withStyles(dialogContentStyle)(MuiDialogContent);
const DialogActions = withStyles(dialogActionStyle)(MuiDialogActions);
const CustomCheckbox = withStyles(userTypeCheckboxStyle)((props) => (
  <Checkbox color="default" {...props} />
));

class UserTypeTab extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      result: null,
      showTypeDialog: false,
      formTitle: '',
      editMode: false,
      initUserTypeForm: null,
      isFormValid: false,
      userTypeForm: {
        id: 1,
        license_fk: 0,
        internalName: {
          validation: {
            required: true,
            escapedString: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        displayName: {
          validation: {
            required: true,
            escapedString: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        lowBalance: {
          validation: {
            required: true,
          },
          value: false,
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        lowBalanceValue: {
          validation: {
            required: true,
          },
          value: '0.00',
          valid: true,
          isValidFormat: true,
          touched: false,
        },
        newTopUps: false,
        unusualTimes: false,
        successfulExpenses: false,
        declinedExpenses: false,
        allowMobileAccess: false,
        allowWebAccess: false,
        hasAccount: false,
        userRole: {
          validation: {
            required: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        canApprove: false,
        sendViaSms: {
          validation: {
            required: false,
          },
          value: false,
          valid: true,
          isValidFormat: true,
          touched: false,
        },
        sendViaEmail: {
          validation: {
            required: false,
          },
          value: false,
          valid: true,
          isValidFormat: true,
          touched: false,
        },
        message: {
          validation: {
            required: false,
            escapedString: true,
          },
          value: '',
          valid: true,
          isValidFormat: true,
          touched: false,
        },
        requiresPhysicalAddress: false,
        requiresDefaultLocation: false,
        requiresTaxDocumentation: false,
        lockTaxAtEndofMonth: false,
        displayNameTransactions: false,
        displayTransactionsCardsLocations: false,
        displayInheritDefaultLocation: false,
        requiresSpendCategory: false,
        defaultExpenseSplitMethod: {
          validation: {
            required: false,
          },
          value: '',
          valid: true,
          isValidFormat: true,
          touched: false,
        },
        dateFormat: {
          validation: {
            required: false,
          },
          value: 'MM/DD/YYYY',
          valid: true,
          isValidFormat: true,
          touched: false,
        },
      },
      requestParam: {},
      editId: null,
    };
  }

  componentDidMount() {
    this.initGetAllUserType();
    this.getNotificationTypes();
  }

  initGetAllUserType = () => {
    const { onErrorHandled } = this.props;
    this.props.userTypeAction.getAllUserType(this.props.token).then(() => {
      if (this.props.error.statusCode) {
        onErrorHandled(`Get user type list error: ${this.props.error.statusCode}`);
      }
    });
    if (!this.state.initUserTypeForm) {
      this.setState({
        initUserTypeForm: JSON.parse(JSON.stringify(this.state.userTypeForm)),
      });
    }
  };

  getNotificationTypes = () => {
    const { onErrorHandled } = this.props;
    this.props.userTypeAction.getNotificationTypeList(this.props.token).then(() => {
      if (this.props.error.statusCode) {
        if (this.props.error.statusCode) {
          onErrorHandled(`Get notification type error: ${this.props.error.statusCode}`);
        }
      }
    });
  };

  componentDidUpdate(prevProps) {
    const { successMessageDialog } = this.props;
    if (
      prevProps.successMessageDialog !== successMessageDialog &&
      !successMessageDialog
    ) {
      this.setState({
        showTypeDialog: false,
      });
    }
  }

  initUserForm = (id) => {
    this.setState(
      {
        userTypeForm: JSON.parse(JSON.stringify(this.state.initUserTypeForm)),
        isFormValid: false,
      },
      () => {
        // eslint-disable-next-line prefer-const
        let { userTypeForm } = this.state;
        userTypeForm.id = id;
        this.setState({ userTypeForm });
      },
    );
  };

  setEditForm = () => {
    let { userTypeForm, formTitle } = this.state;
    const { userType } = this.props;
    formTitle = "Edit '" + this.props.userType.display_name + "' User Type";
    userTypeForm.id = userType.id;
    userTypeForm.license_fk = userType.licensee_fk;
    userTypeForm.internalName.value = userType.internal_name;
    userTypeForm.internalName.valid = true;
    userTypeForm.displayName.value = userType.display_name;
    userTypeForm.displayName.valid = true;
    if (userType.is_admin) {
      userTypeForm.userRole.value = userRoles.ADMIN;
    }
    if (userType.user_type_notification_settings) {
      this.mapEditDefaultNotificationSettings();
    }
    if (userType.user_type_access_rights) {
      this.mapEditAccessRight();
    }
    if (userType.user_type_subscription_settings) {
      this.mapEditSubscriptionMessage();
    }
    if (userType.user_type_transaction_settings) {
      this.mapEditOtherSettings();
    }
    this.setState({
      userTypeForm,
      isFormValid: true,
      editMode: true,
      editId: userType.id,
      formTitle,
    });
  };

  mapEditDefaultNotificationSettings = () => {
    const { userTypeForm } = this.state;
    const { userType } = this.props;
    userType.user_type_notification_settings.forEach((element) => {
      if (element.notification_type_fk === 1) {
        userTypeForm.lowBalance.value = element.is_enabled;
        userTypeForm.lowBalance.valid = true;
      }
      if (element.notification_type_fk === 2) {
        userTypeForm.lowBalanceValue.value = element.default_qualifying_amount || '0.00';
        userTypeForm.lowBalanceValue.valid = true;
      }
      if (element.notification_type_fk === 3) {
        userTypeForm.newTopUps = element.is_enabled;
      }
      if (element.notification_type_fk === 4) {
        userTypeForm.successfulExpenses = element.is_enabled;
      }
      if (element.notification_type_fk === 5) {
        userTypeForm.declinedExpenses = element.is_enabled;
      }
    });
  };

  mapEditAccessRight = () => {
    const { userTypeForm } = this.state;
    const { userType } = this.props;
    userTypeForm.allowMobileAccess = userType.user_type_access_rights.allow_mobile_access;
    if (userTypeForm.allowMobileAccess) {
      userTypeForm.sendViaSms.validation.required = true;
    }
    userTypeForm.allowWebAccess = userType.user_type_access_rights.allow_web_access;
    if (userTypeForm.allowWebAccess) {
      userTypeForm.sendViaEmail.validation.required = true;
    }
    userTypeForm.canApprove = userType.user_type_access_rights.can_approve_activities;
    userTypeForm.userRole.value = userType.user_type_access_rights.user_role;
    if (userTypeForm.userRole.value) {
      userTypeForm.userRole.valid = true;
    }
    userTypeForm.hasAccount = userType.user_type_access_rights.has_account;
  };

  mapEditSubscriptionMessage = () => {
    const { userTypeForm } = this.state;
    const { userType } = this.props;
    userTypeForm.message.value =
      userType.user_type_subscription_settings.subscription_message;
    userTypeForm.message.valid = true;
    userTypeForm.sendViaEmail.value =
      userType.user_type_subscription_settings.subscription_via_email;
    userTypeForm.sendViaEmail.valid = true;
    userTypeForm.sendViaSms.value =
      userType.user_type_subscription_settings.subscription_via_sms;
    userTypeForm.sendViaSms.valid = true;
  };

  mapEditOtherSettings = () => {
    const { userTypeForm } = this.state;
    const { userType } = this.props;
    userTypeForm.requiresPhysicalAddress =
      userType.user_type_transaction_settings.requires_physical_address;
    userTypeForm.requiresTaxDocumentation =
      userType.user_type_transaction_settings.requires_tax_doc;
    userTypeForm.requiresDefaultLocation =
      userType.user_type_transaction_settings.requires_default_location;
    userTypeForm.displayNameTransactions =
      userType.user_type_transaction_settings.display_expense_card_holder;
    userTypeForm.displayInheritDefaultLocation =
      userType.user_type_transaction_settings.display_inherit_default_location;
    userTypeForm.lockTaxAtEndofMonth =
      userType.user_type_transaction_settings.lock_tax_doc_at_eom;
    userTypeForm.displayTransactionsCardsLocations =
      userType.user_type_transaction_settings?.display_transactions_cards_in_locations ||
      false;
    userTypeForm.defaultExpenseSplitMethod.value =
      userType.user_type_transaction_settings.default_split_option;
    userTypeForm.dateFormat.value = userType.user_type_transaction_settings.date_format;
    userTypeForm.requiresSpendCategory =
      userType.user_type_transaction_settings.requires_spend_category;
  };

  initEdit = (id) => {
    const { onErrorHandled } = this.props;
    this.props.userTypeAction.getUserTypeDetail(this.props.token, id).then(() => {
      if (this.props.error.statusCode) {
        onErrorHandled(`Get user type detail error: ${this.props.error.statusCode}`);
      } else {
        this.setEditForm();
      }
    });
  };

  handleCloseDialog = () => {
    this.setState({ showTypeDialog: false });
  };

  handleOpenDialog = async (id) => {
    let { formTitle } = this.state;
    if (id === '') {
      if (this.props.userTypeList.length > 0) {
        const ids = [];
        this.props.userTypeList.map((element) => ids.push(element.id));
        const maxId = Math.max(...ids) + 1;
        await this.initUserForm(maxId);
      } else {
        await this.initUserForm(1);
      }
      formTitle = 'Add New User Type';
      this.setState({ editMode: false });
    } else {
      await this.initUserForm(1);
      await this.initEdit(id);
    }
    this.setState({ showTypeDialog: true, formTitle });
  };

  onChangeInput = (eventValue, inputIdentifier) => {
    const updateState = this.changeInputWithValidation(
      eventValue,
      this.state.userTypeForm,
      inputIdentifier,
    );
    this.setState(
      {
        userTypeForm: updateState.form,
        isFormValid: updateState.valid,
      },
      () => {
        this.userTypeBusinessProcess(eventValue, inputIdentifier);
      },
    );
  };

  userTypeBusinessProcess = (eventValue, inputIdentifier) => {
    // eslint-disable-next-line prefer-const
    let { userTypeForm } = this.state;
    if (inputIdentifier === 'userRole') {
      if (eventValue !== userRoles.ADMIN) {
        userTypeForm.canApprove = false;
        this.setState({
          userTypeForm,
        });
      }
      if (
        eventValue === userRoles.DELEGATE &&
        userTypeForm.defaultExpenseSplitMethod.value === ''
      ) {
        userTypeForm.defaultExpenseSplitMethod.validation.required = true;
        userTypeForm.defaultExpenseSplitMethod.valid = false;
        userTypeForm.defaultExpenseSplitMethod.touched = true;
        this.onChangeInput(
          userTypeForm.defaultExpenseSplitMethod.value,
          'defaultExpenseSplitMethod',
        );
      } else {
        userTypeForm.defaultExpenseSplitMethod.validation.required = false;
        userTypeForm.defaultExpenseSplitMethod.valid = true;
        userTypeForm.defaultExpenseSplitMethod.touched = true;
        this.onChangeInput(
          userTypeForm.defaultExpenseSplitMethod.value,
          'defaultExpenseSplitMethod',
        );
      }
    }
    if (inputIdentifier === 'allowMobileAccess') {
      if (eventValue && !userTypeForm.sendViaSms.value) {
        userTypeForm.sendViaSms.validation.required = true;
        userTypeForm.sendViaSms.touched = true;
        userTypeForm.sendViaSms.valid = false;
      } else if (eventValue && userTypeForm.sendViaSms.value) {
        userTypeForm.sendViaSms.validation.required = true;
        userTypeForm.sendViaSms.touched = true;
        userTypeForm.sendViaSms.valid = true;
      } else {
        userTypeForm.sendViaSms.validation.required = false;
        userTypeForm.sendViaSms.touched = true;
        userTypeForm.sendViaSms.valid = true;
      }
      if (eventValue && userTypeForm.message.value === '') {
        userTypeForm.message.validation.required = true;
        userTypeForm.message.touched = true;
        userTypeForm.message.valid = false;
      } else if (eventValue && userTypeForm.message.value !== '') {
        userTypeForm.message.validation.required = true;
        userTypeForm.message.touched = true;
        userTypeForm.message.valid = true;
      } else {
        userTypeForm.message.validation.required = false;
        userTypeForm.message.touched = true;
        userTypeForm.message.valid = true;
      }
      this.onChangeInput(userTypeForm.sendViaSms.value, 'sendViaSms');
      this.onChangeInput(userTypeForm.message.value, 'message');
    } else if (inputIdentifier === 'allowWebAccess') {
      if (eventValue && !userTypeForm.sendViaEmail.value) {
        userTypeForm.sendViaEmail.validation.required = true;
        userTypeForm.sendViaEmail.touched = true;
        userTypeForm.sendViaEmail.valid = false;
      } else if (eventValue && userTypeForm.sendViaEmail.value) {
        userTypeForm.sendViaEmail.validation.required = true;
        userTypeForm.sendViaEmail.touched = true;
        userTypeForm.sendViaEmail.valid = true;
      } else {
        userTypeForm.sendViaEmail.validation.required = false;
        userTypeForm.sendViaEmail.touched = true;
        userTypeForm.sendViaEmail.valid = true;
      }
      if (eventValue && userTypeForm.message.value === '') {
        userTypeForm.message.validation.required = true;
        userTypeForm.message.touched = true;
        userTypeForm.message.valid = false;
      } else if (eventValue && userTypeForm.message.value !== '') {
        userTypeForm.message.validation.required = true;
        userTypeForm.message.touched = true;
        userTypeForm.message.valid = true;
      } else {
        userTypeForm.message.validation.required = false;
        userTypeForm.message.touched = true;
        userTypeForm.message.valid = true;
      }
      this.onChangeInput(userTypeForm.sendViaEmail.value, 'sendViaEmail');
      this.onChangeInput(userTypeForm.message.value, 'message');
    }
  };

  onCheckedHandle = async (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    // eslint-disable-next-line prefer-const
    let { userTypeForm } = this.state;
    userTypeForm[name] = value;
    await this.setState(
      {
        userTypeForm,
      },
      () => {
        this.userTypeBusinessProcess(value, name);
      },
    );
  };

  onSave = () => {
    const { editMode } = this.state;
    if (!editMode) {
      this.createSubmit();
    } else {
      this.updateSubmit();
    }
  };

  createSubmit = () => {
    const { onSaveHandled, onErrorHandled } = this.props;
    const { userTypeForm } = this.state;
    this.props.userTypeAction.createUserType(this.props.token, userTypeForm).then(() => {
      if (this.props.error.statusCode) {
        onErrorHandled(
          this.props.error.message
            ? this.props.error.statusCode + ':' + this.props.error.message
            : `Get user type list error: ${this.props.error.statusCode}`,
        );
      } else {
        onSaveHandled();
        this.initGetAllUserType();
      }
    });
  };

  updateSubmit = () => {
    const { onSaveHandled, onErrorHandled } = this.props;
    const { userTypeForm, editId } = this.state;
    this.props.userTypeAction
      .updateUserType(this.props.token, userTypeForm, editId)
      .then(() => {
        if (this.props.error.statusCode) {
          onErrorHandled(
            this.props.error.message
              ? this.props.error.statusCode + ':' + this.props.error.message
              : `Get user type list error: ${this.props.error.statusCode}`,
          );
        } else {
          onSaveHandled();
          this.initGetAllUserType();
        }
      });
  };

  userTypeFormRender = () => {
    const { classes } = this.props;
    const { userTypeForm } = this.state;
    const paddingContainer = { paddingTop: '15px' };
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={1}>
              <Grid item xs={2} md={2} lg={2}>
                <div>
                  <b>{customization.userSettingsPage.idLabel}</b>
                </div>
                <FormTextInput
                  field="id"
                  value={userTypeForm.id}
                  isDisabled={true}
                  type="number"
                />
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <div>
                  <b>{customization.userSettingsPage.internalNameLabel}</b>
                </div>
                <FormTextInput
                  field="internalName"
                  touched={userTypeForm.internalName.touched}
                  valid={userTypeForm.internalName.valid}
                  value={userTypeForm.internalName.value}
                  validFormat={userTypeForm.internalName.isValidFormat}
                  onChangeInput={this.onChangeInput}
                  length={100}
                  validationMessage={customization.requiredError}
                  errorMode={customization.userRootForm.invalidString}
                  type="text"
                  boldMode={true}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <div>
                  <b>{customization.userSettingsPage.displayNameLabel}</b>
                </div>
                <FormTextInput
                  field="displayName"
                  touched={userTypeForm.displayName.touched}
                  valid={userTypeForm.displayName.valid}
                  value={userTypeForm.displayName.value}
                  validFormat={userTypeForm.displayName.isValidFormat}
                  onChangeInput={this.onChangeInput}
                  length={100}
                  validationMessage={customization.requiredError}
                  errorMode={customization.userRootForm.invalidString}
                  type="text"
                  boldMode={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={paddingContainer}>
              <Grid item xs={6} md={6} lg={6}>
                <Paper className={classes.paper} variant="outlined">
                  <div className={classes.boxStyle}>
                    <Box p={1}>
                      <span className={classes.boxSectionTitle}>
                        {customization.userSettingsPage.defaultNotificationLabel}
                      </span>
                    </Box>
                  </div>
                  {this.defaultNotificationSettings()}
                </Paper>
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <Paper className={classes.paper} variant="outlined">
                  <div className={classes.boxStyle}>
                    <Box p={1}>
                      <span className={classes.boxSectionTitle}>
                        {' '}
                        {customization.userSettingsPage.rightAccessLabel}
                      </span>
                    </Box>
                  </div>
                  {this.rightAccess()}
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={1} style={paddingContainer}>
              <Grid item xs={6} md={6} lg={6}>
                <Paper className={classes.paper} variant="outlined">
                  <div className={classes.boxStyle}>
                    <Box p={1}>
                      <span className={classes.boxSectionTitle}>
                        {customization.userSettingsPage.subscriptionLabel}
                      </span>
                    </Box>
                  </div>
                  {this.subscriptionMessage()}
                </Paper>
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <Paper className={classes.paper} variant="outlined">
                  <div className={classes.boxStyle}>
                    <Box p={1}>
                      <span className={classes.boxSectionTitle}>
                        {' '}
                        {customization.userSettingsPage.otherSettingsLabel}
                      </span>
                    </Box>
                  </div>
                  {this.otherSettings()}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  defaultNotificationSettings = () => {
    const { classes } = this.props;
    const { userTypeForm } = this.state;
    return (
      <div className={classes.topSectionContainerStyle}>
        <Box p={2}>
          <Box p={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormCheckBox
                  field="lowBalance"
                  label={customization.userSettingsPage.lowBalanceLabel}
                  value={userTypeForm.lowBalance.value}
                  valid={userTypeForm.lowBalance.valid}
                  touched={userTypeForm.lowBalance.touched}
                  onChangeInput={this.onChangeInput}
                  validationMessage={customization.requiredError}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormCurrencyInput
                  field="lowBalanceValue"
                  touched={userTypeForm.lowBalanceValue.touched}
                  valid={userTypeForm.lowBalanceValue.valid}
                  validFormat={userTypeForm.lowBalanceValue.isValidFormat}
                  value={userTypeForm.lowBalanceValue.value}
                  onChangeInput={this.onChangeInput}
                  validationMessage={customization.requiredError}
                  boldMode={true}
                />
              </Grid>
            </Grid>
          </Box>
          <Box p={1}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  id="newTopUps"
                  name="newTopUps"
                  checked={userTypeForm.newTopUps}
                  onChange={(e) => this.onCheckedHandle(e)}
                />
              }
              classes={{ label: classes.formControlLabel }}
              label={customization.userSettingsPage.newTopUpsLabel}
            />
          </Box>
          <Box p={1}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  id="successfulExpenses"
                  name="successfulExpenses"
                  checked={userTypeForm.successfulExpenses}
                  onChange={(e) => this.onCheckedHandle(e)}
                />
              }
              classes={{ label: classes.formControlLabel }}
              label={customization.userSettingsPage.successfulExpensesLabel}
            />
          </Box>
          <Box p={1}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  id="declinedExpenses"
                  name="declinedExpenses"
                  checked={userTypeForm.declinedExpenses}
                  onChange={(e) => this.onCheckedHandle(e)}
                />
              }
              classes={{ label: classes.formControlLabel }}
              label={customization.userSettingsPage.declineExpensesLabel}
            />
          </Box>
        </Box>
        <div>
          <Box p={3}></Box>
        </div>
      </div>
    );
  };

  subscriptionMessage = () => {
    const { classes } = this.props;
    const { userTypeForm } = this.state;
    return (
      <div className={classes.bottomSectionContainerStyle}>
        <Box p={2}>
          <Box p={1}>
            <Grid container spacing={1}>
              <Grid item xs={8} sm={8} md={8}>
                <FormCheckBox
                  field="sendViaSms"
                  label={customization.userSettingsPage.sendViaSmsLabel}
                  value={userTypeForm.sendViaSms.value}
                  valid={userTypeForm.sendViaSms.valid}
                  touched={userTypeForm.sendViaSms.touched}
                  onChangeInput={this.onChangeInput}
                  validationMessage={customization.requiredError}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <FormCheckBox
                  field="sendViaEmail"
                  label={customization.userSettingsPage.sendViaEmailLabel}
                  value={userTypeForm.sendViaEmail.value}
                  valid={userTypeForm.sendViaEmail.valid}
                  touched={userTypeForm.sendViaEmail.touched}
                  onChangeInput={this.onChangeInput}
                  validationMessage={customization.requiredError}
                />
              </Grid>
            </Grid>
          </Box>
          <FormTextInput
            field="message"
            touched={userTypeForm.message.touched}
            valid={userTypeForm.message.valid}
            value={userTypeForm.message.value}
            validFormat={userTypeForm.message.isValidFormat}
            onChangeInput={this.onChangeInput}
            length={500}
            validationMessage={customization.requiredError}
            errorMode={customization.userRootForm.invalidString}
            type="text"
            multiline={true}
            rows={13}
          />
        </Box>
      </div>
    );
  };

  rightAccess = () => {
    const { classes } = this.props;
    const { userTypeForm, editMode } = this.state;
    return (
      <div className={classes.topSectionContainerStyle}>
        <Box p={2}>
          <Box p={1}>
            <div>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    id="allowMobileAccess"
                    name="allowMobileAccess"
                    checked={userTypeForm.allowMobileAccess}
                    onChange={(e) => this.onCheckedHandle(e)}
                    disabled={editMode}
                  />
                }
                classes={{ label: classes.formControlLabel }}
                label={customization.userSettingsPage.allowMobileAccessLabel}
              />
            </div>
          </Box>
          <Box p={1}>
            <div>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    id="allowWebAccess"
                    name="allowWebAccess"
                    checked={userTypeForm.allowWebAccess}
                    onChange={(e) => this.onCheckedHandle(e)}
                    disabled={editMode}
                  />
                }
                classes={{ label: classes.formControlLabel }}
                label={customization.userSettingsPage.allowWebAccessLabel}
              />
            </div>
          </Box>
          <Box p={1}>
            <div>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    id="hasAccount"
                    name="hasAccount"
                    // value={userTypeForm.hasAccount}
                    checked={userTypeForm.hasAccount}
                    onChange={(e) => this.onCheckedHandle(e)}
                    disabled={editMode}
                  />
                }
                classes={{ label: classes.formControlLabel }}
                label={customization.userSettingsPage.hasAccountLabel}
              />
            </div>
          </Box>
          <div>
            <b> {customization.userSettingsPage.userRoleLabel}</b>
          </div>
          <div>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="roleSelect">
                {customization.userSettingsPage.userRoleLabel}
              </InputLabel>
              <Select
                labelId="roleSelect"
                id="userRole"
                value={userTypeForm.userRole.value ?? ''}
                onChange={(e) => {
                  this.onChangeInput(e.target.value, 'userRole');
                }}
                label={customization.userSettingsPage.userRoleLabel}
                color="secondary"
                disabled={editMode}
              >
                {userRoleOptions.map((data, index) => (
                  <MenuItem value={data.value} key={index}>
                    {data.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Box p={1}>
            <div>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    id="canApprove"
                    name="canApprove"
                    onChange={(e) => this.onCheckedHandle(e)}
                    disabled={userTypeForm.userRole.value !== userRoles.ADMIN || editMode}
                    checked={
                      userTypeForm.userRole.value === userRoles.ADMIN
                        ? !!userTypeForm.canApprove
                        : false
                    }
                  />
                }
                classes={{ label: classes.formControlLabel }}
                label={customization.userSettingsPage.canApproveLabel}
              />
            </div>
          </Box>
          <div>
            <Box p={1}></Box>
          </div>
        </Box>
      </div>
    );
  };

  otherSettings = () => {
    const { classes } = this.props;
    const { editMode, userTypeForm } = this.state;
    return (
      <div className={classes.bottomSectionContainerStyle}>
        <Box p={2}>
          <Box p={1}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} md={6}>
                <div className={classes.checkBoxOtherSettingsPadding}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        id="requiresPhysicalAddress"
                        name="requiresPhysicalAddress"
                        checked={userTypeForm.requiresPhysicalAddress}
                        onChange={(e) => this.onCheckedHandle(e)}
                        disabled={editMode}
                      />
                    }
                    classes={{ label: classes.formControlLabel }}
                    label={customization.userSettingsPage.requirePhysicalLabel}
                  />
                </div>
                <div className={classes.checkBoxOtherSettingsPadding}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        id="requiresDefaultLocation"
                        name="requiresDefaultLocation"
                        checked={userTypeForm.requiresDefaultLocation}
                        onChange={(e) => this.onCheckedHandle(e)}
                        disabled={editMode}
                      />
                    }
                    classes={{ label: classes.formControlLabel }}
                    label={customization.userSettingsPage.requireDefaultLabel}
                  />
                </div>
                <div className={classes.checkBoxOtherSettingsPadding}>
                  <div>
                    <b>{customization.userSettingsPage.defaultExpenseLabel}</b>
                  </div>
                  <div>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="splitMethodSelect">
                        {customization.userSettingsPage.splitMethodLabel}
                      </InputLabel>
                      <Select
                        labelId="splitMethodSelect"
                        id="defaultExpenseSplitMethod"
                        value={userTypeForm.defaultExpenseSplitMethod.value ?? ''}
                        onChange={(e) => {
                          this.onChangeInput(e.target.value, 'defaultExpenseSplitMethod');
                        }}
                        label={customization.userSettingsPage.splitMethodLabel}
                        color="secondary"
                        disabled={editMode}
                      >
                        {splitMethodOptions.map((data, index) => (
                          <MenuItem value={data.value} key={index}>
                            {data.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <span
                      style={{
                        display:
                          userTypeForm.defaultExpenseSplitMethod.touched &&
                          !userTypeForm.defaultExpenseSplitMethod.valid
                            ? 'block'
                            : 'none',
                      }}
                      className={classes.errorMessageColor}
                    >
                      {customization.requiredError}
                    </span>
                  </div>
                </div>
                <div className={classes.checkBoxOtherSettingsPadding}>
                  <div>
                    <b>Date Format</b>
                  </div>
                  <div>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="dateFormatSelect">DD/MM/YYYY</InputLabel>
                      <Select
                        labelId="dateFormatSelect"
                        id="dateFormat"
                        value={userTypeForm.dateFormat.value ?? ''}
                        onChange={(e) => {
                          this.onChangeInput(e.target.value, 'dateFormat');
                        }}
                        label="Date Format"
                        color="secondary"
                        disabled={editMode}
                      >
                        {dateFormatOptions.map((data, index) => (
                          <MenuItem value={data.value} key={index}>
                            {data.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <span
                      style={{
                        display:
                          userTypeForm.dateFormat.touched &&
                          !userTypeForm.dateFormat.valid
                            ? 'block'
                            : 'none',
                      }}
                      className={classes.errorMessageColor}
                    >
                      {customization.requiredError}
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <div className={classes.checkBoxOtherSettingsPadding}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        id="requiresTaxDocumentation"
                        name="requiresTaxDocumentation"
                        checked={userTypeForm.requiresTaxDocumentation}
                        onChange={(e) => this.onCheckedHandle(e)}
                        disabled={editMode}
                      />
                    }
                    classes={{ label: classes.formControlLabel }}
                    label={customization.userSettingsPage.requireTaxLabel}
                  />
                </div>
                <div className={classes.checkBoxOtherSettingsPadding}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        id="displayNameTransactions"
                        name="displayNameTransactions"
                        checked={userTypeForm.displayNameTransactions}
                        onChange={(e) => this.onCheckedHandle(e)}
                        disabled={editMode}
                      />
                    }
                    classes={{ label: classes.formControlLabel }}
                    label={customization.userSettingsPage.displayNameTransactionLabel}
                  />
                </div>
                <div className={classes.checkBoxOtherSettingsPadding}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        id="displayTransactionsCardsLocations"
                        name="displayTransactionsCardsLocations"
                        checked={userTypeForm.displayTransactionsCardsLocations}
                        onChange={(e) => this.onCheckedHandle(e)}
                        disabled={editMode}
                      />
                    }
                    classes={{ label: classes.formControlLabel }}
                    label={
                      customization.userSettingsPage
                        .displayTransactionsCardsLocationsLabel
                    }
                  />
                </div>
                <div className={classes.checkBoxOtherSettingsPadding}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        id="displayInheritDefaultLocation"
                        name="displayInheritDefaultLocation"
                        checked={userTypeForm.displayInheritDefaultLocation}
                        onChange={(e) => this.onCheckedHandle(e)}
                        disabled={editMode}
                      />
                    }
                    classes={{ label: classes.formControlLabel }}
                    label={customization.userSettingsPage.displayInheritLabel}
                  />
                </div>
                <div className={classes.checkBoxOtherSettingsPadding}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        id="requiresSpendCategory"
                        name="requiresSpendCategory"
                        checked={userTypeForm.requiresSpendCategory}
                        onChange={(e) => this.onCheckedHandle(e)}
                        disabled={editMode}
                      />
                    }
                    classes={{ label: classes.formControlLabel }}
                    label={customization.userSettingsPage.requireSpendLabel}
                  />
                </div>
                <div className={classes.checkBoxOtherSettingsPadding}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        id="lockTaxAtEndofMonth"
                        name="lockTaxAtEndofMonth"
                        checked={userTypeForm.lockTaxAtEndofMonth}
                        onChange={(e) => this.onCheckedHandle(e)}
                        disabled={editMode}
                      />
                    }
                    classes={{ label: classes.formControlLabel }}
                    label={customization.userSettingsPage.lockTaxAtEndofMonthLabel}
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
    );
  };

  renderDialog = () => {
    const { classes, loading } = this.props;
    const { isFormValid } = this.state;
    return (
      <div>
        <Dialog fullWidth maxWidth="lg" open={this.state.showTypeDialog}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleCloseDialog}>
            <b>{this.state.formTitle}</b>
          </DialogTitle>
          <DialogContent>{this.userTypeFormRender()}</DialogContent>
          <DialogActions>
            <Button
              autoFocus
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={() => {
                this.onSave();
              }}
              disabled={!isFormValid || loading === apiRequestStatus.PENDING}
            >
              {customization.userSettingsPage.saveChangesButtonLabel}
            </Button>
            <Button
              className={classes.closeButton}
              onClick={this.handleCloseDialog}
              variant="outlined"
            >
              {customization.userSettingsPage.closeButtonLabel}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  render() {
    const { classes, userTypeList } = this.props;
    const columns = [
      {
        cellClassName: 'transform-capitalize',
        field: 'id',
        flex: 1,
        minWidth: 218.4,
        sortable: false,
        headerName: 'ID',
      },
      {
        cellClassName: 'transform-capitalize',
        field: 'display_name',
        flex: 1,
        minWidth: 218.4,
        sortable: false,
        headerName: 'Name',
      },
      {
        align: 'center',
        field: 'action',
        headerAlign: 'center',
        headerName: customization.userViews.headers.action,
        sortable: false,
        width: 150,
        renderCell: (params) => {
          return (
            <>
              <Button
                aria-controls="more-options"
                aria-haspopup="true"
                className={`${classes.actionButton} more`}
                disableElevation
                onClick={() => this.handleOpenDialog(params.id)}
                variant="contained"
              >
                <EditIcon htmlColor="#FFFFFF" fontSize="medium" />
              </Button>
            </>
          );
        },
      },
    ];
    return (
      <div className={classes.divContainerStyle}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item xs={12} lg={4} xl={6} className={classes.alignEnd}>
            <Button
              className={classes.buttonAddDialog}
              color="primary"
              disableElevation
              onClick={() => this.handleOpenDialog('')}
              startIcon={<AddPersonIcon />}
              size="large"
              variant="contained"
            >
              {customization.userSettingsPage.addButtonLabel}
            </Button>
          </Grid>
        </Grid>
        <DataGrid
          autoHeight
          disableColumnMenu
          disableExtendRowFullWidth
          disableSelectionOnClick
          className={classes.table}
          columns={columns}
          components={{
            NoRowsOverlay: EmptyRowsOverlay,
          }}
          componentsProps={{
            noRowsOverlay: {
              label: customization.userViews.emptyRows,
            },
          }}
          hideFooter={true}
          maxColumns={9}
          rows={userTypeList}
          rowHeight={80}
        />

        {this.renderDialog()}
      </div>
    );
  }
}

UserTypeTab.propTypes = {
  classes: PropTypes.object,
  onSaveHandled: PropTypes.func,
  successMessageDialog: PropTypes.bool,
  error: PropTypes.object,
  userType: PropTypes.object,
  userTypeList: PropTypes.array,
  notificationTypes: PropTypes.array,
  token: PropTypes.string,
  userTypeAction: PropTypes.object,
  onErrorHandled: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    error: state.userType.error,
    userType: state.userType.userType,
    userTypeList: state.userType.userTypeList,
    notificationTypes: state.userType.notificationTypes,
    loading: state.userType.loading,
    token: state.auth.inMemoryToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userTypeAction: bindActionCreators(userTypeActions, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withStyles(userTypeStyle), withConnect)(UserTypeTab);
