import { transferFunds as transferFundsService } from '@services/api/transferFundsApi';
import transferFundsTypes from './transferFundsTypes';

export const transferFunds = (token, transferType, payload) => {
  return async (dispatch) => {
    dispatch({ type: transferFundsTypes.TRANSFER_FUND_REQUEST });

    try {
      const response = await transferFundsService(token, transferType, payload);
      dispatch({ type: transferFundsTypes.TRANSFER_FUND_SUCCESS });

      return response;
    } catch (error) {
      dispatch({ type: transferFundsTypes.TRANSFER_FUND_ERROR, error });

      return error;
    }
  };
};

export const transferFundsResetErrorState = () => {
  return async (dispatch) => {
    dispatch({ type: transferFundsTypes.RESET_ERROR_STATE });
  };
};

export const transferFundsResetState = () => {
  return async (dispatch) => {
    dispatch({ type: transferFundsTypes.RESET_STATE });
  };
};
