import { get, post, patch } from './baseApi';

const getUserTypeList = async (token) => {
  return get('/admin/user-types', token);
};

const getUserType = async (token, id) => {
  return get(`/admin/user-types/${id}`, token);
};

const getNotificationTypes = async (token) => {
  return get('/admin/notification-types', token);
};

const insertUserType = async (token, params) => {
  const requestBody = mappingParams(params);
  return post('/admin/user-types', requestBody, token);
};

const editUserType = async (token, params, id) => {
  const requestBody = mappingParams(params);
  return patch(`/admin/user-types/${id}`, requestBody, token);
};

const getCardTypeByUserTypeId = async (userTypeId, token) => {
  return get(`/admin/user-types/${userTypeId}/card-types`, token);
};

const mappingParams = (userTypeForm) => {
  const requestBody = {
    user_type_name: userTypeForm.internalName.value,
    display_name: userTypeForm.displayName.value,
    user_type_notification_settings: [
      { notification_type_fk: 1, is_enabled: userTypeForm.lowBalance.value },
      {
        notification_type_fk: 2,
        default_qualifying_amount: userTypeForm.lowBalanceValue.value,
        is_enabled: true,
      },
      { notification_type_fk: 3, is_enabled: userTypeForm.newTopUps },
      { notification_type_fk: 4, is_enabled: userTypeForm.successfulExpenses },
      { notification_type_fk: 5, is_enabled: userTypeForm.declinedExpenses },
    ],
    user_type_access_rights: {
      user_role: userTypeForm.userRole.value,
      allow_web_access: userTypeForm.allowWebAccess,
      allow_mobile_access: userTypeForm.allowMobileAccess,
      can_approve_activities: userTypeForm.canApprove,
      has_account: userTypeForm.hasAccount,
    },
    user_type_subscription_settings: {
      subscription_message: userTypeForm.message.value,
      subscription_via_email: userTypeForm.sendViaEmail.value,
      subscription_via_sms: userTypeForm.sendViaSms.value,
    },
    user_type_transaction_settings: {
      display_expense_card_holder: userTypeForm.displayNameTransactions,
      display_inherit_default_location: userTypeForm.displayInheritDefaultLocation,
      requires_tax_doc: userTypeForm.requiresTaxDocumentation,
      requires_physical_address: userTypeForm.requiresPhysicalAddress,
      requires_default_location: userTypeForm.requiresDefaultLocation,
      default_split_option: userTypeForm.defaultExpenseSplitMethod.value,
      date_format: userTypeForm.dateFormat.value,
      requires_spend_category: userTypeForm.requiresSpendCategory,
      display_transactions_cards_in_locations:
        userTypeForm.displayTransactionsCardsLocations,
      lock_tax_doc_at_eom: userTypeForm.lockTaxAtEndofMonth,
    },
  };
  return requestBody;
};

export {
  getUserTypeList,
  getUserType,
  insertUserType,
  editUserType,
  mappingParams,
  getNotificationTypes,
  getCardTypeByUserTypeId,
};
