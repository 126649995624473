export const customization = {
  requiredMark: '*',
  login: {
    introduction: 'Welcome',
    introductionDescription: 'Please login to continue.',
    emailLabel: 'Email Address',
    passwordLabel: 'Password',
    rememberMeLabel: 'Remember me',
    forgotPasswordLabel: 'Forgot password?',
    loginButtonLabel: 'Log In',
    emailValidationErrorMessage: 'Incorrect email format',
    invalidCredentials: {
      baseErrorMessage:
        'Invalid Email Address or Password. Please check your entered details and try again.',
      remainingAttemptFailed: (attempt) =>
        `You have ${
          attempt === 2 ? 'two more log in attempts' : 'one more log in attempt'
        } before your account is locked.`,
      lockedAccount:
        'Your account has been locked. Please contact a CardHero Administrator to unlock your account.',
    },
    unlockAccountLabel: 'Unlock Account',
    newUserExpireLink:
      "Your set new password link has expired, Please click the 'Set New Password' link again to obtain a fresh link",
    setNewUserLabel: 'Set New Password',
    newUserSuccessMessage:
      'A set new password link has been sent to you via email. Please click the emailed link to set your new password',
    newUserErrorEmailValidation:
      'Please enter a valid email address before clicking the ‘Set New Password’ link',
    completeFormMessage: 'Please complete form below',
    newUserEmailValidationError: 'set new user email validation error',
    passwordErrorMessage: 'Please enter a valid password',
    securityAnswerErrorMessage: 'Please enter a valid Security Answer',
    securityQuestionErrorMessage: 'Please enter a valid Security Question',
    securityQuestionAndAnswerErrorMessage:
      'Please enter a valid password or security answer',
  },
  firstLogin: {
    title: 'Account Setup',
    titleDesc:
      'Please enter a strong password and your security details for account recovery',
    introduction:
      'Welcome to CardHero! Please create your new password and Security question/answer. Your new password must:',
    firstStepsetupPassword: '1. Be at least 8 characters long.',
    secondStepsetupPassword: '2. Contain one letter and number.',
    newPasswordLabel: 'New Password',
    confirmPasswordLabel: 'Confirm Password',
    securityQuestion: 'Security Question',
    securityAnswer: 'Security Answer',
    newPasswordErrorMessage: 'Password must be alphanumeric with minimum 8 characters',
    confirmPasswordErrorMessage: 'Password do not match',
    securityAnswerErrorMessage:
      'Security answer must be alphanumeric with minimum 6 and maximum 50 characters',
    passwordStrength: 'Password Strength',
    weak: 'Weak',
    strong: 'Strong',
  },
  loginMultiFactorAuthenticationPage: {
    introduction: 'Two-step Verification',
    introductionDescription:
      'Please enter the 6-digit verification code sent to your e-mail.',
    authCodeLabel: 'Authentication Code',
    authenticationCodeFailureMessage:
      'Incorrect Authentication Code. Please check your code and try again.',
    authenticationCodeFailureMessageLastAttempt:
      'This is your last attempt before your account is locked.',
    lockedAccount:
      'Your account has been locked. Please contact your CardHero administration team to unlock your account.',
    expiredAuthenticationCode:
      'The code you have entered is no longer valid. A new code has been sent to your email address.',
    resendAuthenticationCodeSuccess: 'The code was resent successfully.',
    resendAuthenticationCodeFailed:
      'Failed to resend authentication code. Please try again later.',
    INVALID_AUTHENTICATION_CODE: 'Invalid authentication code',
  },
  loginUnlockAccountRequestPage: {
    introduction: 'Unlock Account',
    introductionDescription:
      'Please enter 12-digit authentication code sent to your e-mail.',
    unlockCodeLabel: 'Unlock Code',
    unlockSuccessMessage:
      'Your account has been unlocked. Please try to login using your account.',
    attemptLimitMessage:
      'You have exceeded the allowed number of PIN attempts. Please contact a CardHero programme administrator to unlock your account.',
    expiredUnlockCode:
      'The code you have entered is no longer valid or expired. Please contact your CardHero Administrator to obtain a new code.',
    unlockCodeFailureMessage:
      'Your entered Unlock Code is invalid. Please try again, or contact your CardHero Administrator to obtain a new code. Please note codes are only valid for 10 minutes.',
  },
  userSettingsPage: {
    pageTitleLabel: 'Settings',
    idLabel: 'ID',
    internalNameLabel: 'Internal Name',
    displayNameLabel: 'Display Name',
    defaultNotificationLabel: 'Default Notification Settings',
    rightAccessLabel: 'Rights & Access',
    subscriptionLabel: 'Subscription Message',
    otherSettingsLabel: 'Other Settings',
    newTopUpsLabel: "Set 'New Top Ups' by default?",
    unusualTimeLabel: 'Unusual Times (transactions between 2:00am - 6:00am)',
    userRoleLabel: 'User Role',
    lowBalanceLabel: "Set 'Low Balance' by default?",
    lowBalanceAmountLabel: "Default 'Low Balance' amount",
    successfulExpensesLabel: "Set 'Successful Expenses' by default?",
    declineExpensesLabel: 'Alert Declined Expenses (users cannot alter)',
    sendViaSmsLabel: 'Send via SMS',
    sendViaEmailLabel: 'Send via Email',
    allowMobileAccessLabel: 'Allow mobile access?',
    allowWebAccessLabel: 'Allow web access?',
    hasAccountLabel: 'Has Account?',
    canApproveLabel: 'Can approve activities',
    requirePhysicalLabel: 'Requires Physical Address?',
    requireTaxLabel: 'Require Tax Documentation',
    requireDefaultLabel: 'Requires default Location?',
    defaultLowBalance: "Default 'Low Balance' amount",
    displayNameTransactionLabel: 'Display name against transactions',
    defaultExpenseLabel: 'Default expense split method',
    splitMethodLabel: 'Split Method',
    displayTransactionsCardsLocationsLabel:
      'Display transactions/cards belonging to Locations?',
    displayInheritLabel: "Display 'Inherit default location'",
    requireSpendLabel: 'Requires Spend Category',
    lockTaxAtEndofMonthLabel: 'Lock tax document at the end of the month',
    saveButtonLabel: 'Save',
    saveChangesButtonLabel: 'Save Changes',
    dateFormatLabel: 'Date Format',
    closeButtonLabel: 'Close',
    cancelButtonLabel: 'Cancel',
    editButtonLabel: 'Edit',
    addButtonLabel: 'Add',
    userTypeLabel: 'User Types',
    nameLabel: 'Name',
    saveSuccessMessage: 'Change has been submitted for review',
    locationTableTitle: 'Grouping Names',
    locationDialogTitle: 'Grouping Name',
    locationAddDialogTitle: 'Add Group Name',
    locationTableHeaderFirst: 'Internal Name',
    locationTableHeaderSecond: 'Translation',
    locationTableHeaderThird: 'Company',
    cardAccountsTransferBankTableTitle: 'Transfer bank account',
    cardAccountsTransferBankTableHeaderFirst: 'BSB',
    cardAccountsTransferBankTableHeaderSecond: 'Account',
    cardAccountsTransferBankTableHeaderThird: 'Account Name',
    cardAccountTransferBankTitle: 'Add transfer bank account',
    cardAccountsTransferBankAccountNumber: 'Account Number',
    cardAccountsTransferBankSuccess:
      'Your new transfer bank account has been Submitted for review',
    errorBSB: 'BSB must be numeric and a length of 6 digits.',
    errorAccountNumber: 'Account number must be numeric with a maximum of 50 digits.',
    errorAccountName: 'Please enter a valid account name',
    invalidLengthBSB: {
      name: 'stringLength',
      message: 'BSB must be numeric and a length of 6 digits',
    },
    invalidLengthAccountNumber: {
      name: 'stringLength',
      message: 'Account number must be numeric with a maximum of 50 digits.',
    },
    invalidString: {
      name: 'string',
      message: 'Enter a valid string',
    },
    addLocation: 'Add Location',
    addUser: 'Add User',
    addCardsAccount: 'Add Account',
  },
  expiredInvalidPage: {
    mainTitle: 'Sorry',
    firstTitle:
      'The password reset link was invalid or has expired (it`s valid for 5 minutes). It may have already been used',
    secondTitle: 'Please request a',
    secondTitleBold: 'new password reset',
  },
  forgotPassword: {
    introduction: 'Forgot Password',
    buttonSendEmailLink: 'Send E-mail Link',
    introductionDescription:
      'Please enter your e-mail and we will send an email to reset your password.',
    successResponseMessage:
      'A reset password link has been sent to you via email. Please click the emailed link to reset your password',
    errorEmailValidation:
      'Please enter a valid email address before clicking the ‘Forgot password’ link',
    internalServerError:
      'CardHero has encountered a system error. Please try again in a few minutes.',
  },
  newPassword: {
    introduction: 'Create New Password',
    introductionDescription:
      'Please enter your password. It must be different from your previously used passwords.',
    firstStepNewPassword: '1. Be at least 8 characters long.',
    secondStepNewPassword: '2. Contain one letter and number.',
    thirdStepNewPassword: '3. Not be a password you have previously used.',
    labelNewPassword: 'New Password',
    labelConfirmNewPassword: 'Confirm New Password',
    buttonResetPassword: 'Reset Password',
    missMatchError: 'Your entered passwords do not match. Please try again.',
    emptyFieldsError:
      'Please ensure both password fields are populated before continuing.',
    passwordCriteriaError:
      'Your entered password does not conform to the defined rules. Please enter a new password',
    usedPasswordError: 'Password has been used, please create other password',
    expireInvalidLink:
      "Your password reset link has expired, Please click the 'Forgot password' link again to obtain a fresh link.",
    strength: {
      weak: 'Weak',
      strong: 'Strong',
    },
    popup: {
      title: 'Password must have the following',
      point1: 'At least 8 characters',
      point2: 'Contain one letter',
      point3: 'Contain one number',
      point4: 'Contain one special character',
    },
  },
  userRootForm: {
    requiredMark: '*',
    statusLabel: 'Status',
    locationLabel: 'Location',
    companyLabel: 'Company',
    userTypeLabel: 'User Type',
    uniqueDepositLabel: 'Unique/Deposit Reference No',
    legalFirstNameLabel: 'Legal First Name',
    legalMiddleNameLabel: 'Legal Middle Names',
    legalLastNameLabel: 'Legal Last Name',
    emailAddressLabel: 'Email Address',
    countryCodeLabel: 'Country Code',
    defaultTimeZoneLabel: 'Default Time Zone',
    defaultLocationLabel: 'Default Location',
    fullMobileNumberLabel: 'Full Mobile Number',
    defaultLocationPlaceHolder: 'Default Location',
    locationPlaceHolder: 'Location',
    defaultTimeZonePlaceHolder: 'Default Time Zone',
    locationPlaceholder: 'Location',
    saveAndCloseButton: 'Save and Close',
    cancelButton: 'Cancel',
    invalidString: {
      name: 'string',
      message: 'Enter a valid string',
    },
    invalidEmailFormat: {
      name: 'email',
      message: 'Enter a valid email',
    },
    invalidPhoneNumberFormat: {
      name: 'phoneNumber',
      message: 'Enter a valid phone number',
    },
    statusActive: 'Active',
    statusLocked: 'Locked',
    statusInactive: 'Inactive',
    statusPending: 'Pending',
  },
  userAddEditDialog: {
    addTitle: 'New User',
    editTitle: 'Edit User',
    iconTitle: 'User Details & Delegation',
    cardApplicationTitle: 'Card Application ',
    editSubmittedSuccessMessage: 'Your user update has been submitted for review',
    editUpdatedSuccessMessage: 'Your user update has been updated successfully',
    addNewUser: 'Add User',
  },
  formUserSecondBlock: {
    typeHeader: 'Type',
    mobileNumberHeader: 'Code/Mobile Number',
    locationHeader: 'Full Name/Location Description',
    activeHeader: 'Active?',
    actionHeader: 'Action',
    emptyLocationsTable: 'Please search for, and select, a user or location',
    overallAdministratorMessage:
      "'Overall Administrator' have rights to all data and functions",
    overallAdministratorCheckBox: 'Overall Administrator?',
    displayOnlyDefault: 'Only display default Location Users',
    inheritDefaultLocation: 'Inherit default location',
    addSelectedBtn: 'Add Selected',
    inviteToJoinBtn: 'Invite to Join',
    saveChanges: 'Save Changes',
    cancelBtn: 'Cancel',
    searchPlaceHolder: 'Add User/Location',
    selectHeader: 'Select',
    selectOption: '-- SELECT --',
    deleteConfirmation: 'Are you sure you want to delete this item?',
    useOverallAdministrator:
      'All mapped users and locations will be lost if this user becomes an overall administrator. So you want to continue?',
    confirmBtn: 'Yes',
    emptySearch: 'No data found.',
    maximumExceeded: 'Maximum delegation list number is 15',
  },
  viewTransactionModal: {
    modalTitle: 'View Transaction ',
    merchantName: 'Merchant Name',
    date: 'Date',
    amount: 'Amount',
    cardHolder: 'Card Holder',
    receipt: 'Receipt',
    type: 'Type',
    inDispute: 'In Dispute?',
    auditHistory: 'Audit History',
    emptyAuditHistoryList: 'No transaction audit history data has been found',
    moreThanThreeReceiptMessage:
      'No more than three receipts can be stored against a single transaction.',
    dateTime: 'Date/Time',
    changedBy: 'Changed By',
    bySystem: 'By System',
    action: 'Action',
    remove: 'Remove',
    userCardholder: 'User/Cardholder',
    spendCategory: 'Spend Category',
    disputeExpenseButton: 'Dispute Transaction',
    disputeExpenseMessage: 'Are you sure you want to dispute this transaction?',
    deleteReceiptMessage: 'Are you sure you want to delete this receipt?',
    cancelDisputeExpenseButton: 'Cancel Dispute',
    cancelDisputeExpenseMessage: 'Are you sure you want to cancel this dispute?',
    changeSpendCategoryMessage: 'Are you sure you want to change spend category?',
    removeAccountTransactionAllocationMessage: 'Are you sure you want to remove',
    transactionAllocation: 'Transaction Allocation',
    transactionAllocationClient: 'Client:',
    receiptTaxInvoices: 'Receipt / Tax Invoices',
    equalParts: 'Equal Parts',
    percentage: 'Percentage',
    uploadReceipt: 'Upload Receipt',
    addRemoveAccount: 'Add/Remove Accounts',
    addChangeAccount: 'Add/Change Accounts',
    removeSplit: 'Remove Split',
    download: 'Download',
    delete: 'Delete',
    saveChanges: 'Save Changes',
    backToTransaction: 'Back to Transaction',
    changeTransactionAllocation: 'Change Transaction Allocation',
    search: 'Search user by name or number/code',
    onlyDisplayDefaultLocationUsers: 'Only display default Location Users',
    codeMobileNumber: 'Code/Mobile Number',
    fullNameDescription: 'Full Name/Description',
    add: 'Add',
    cancel: 'Cancel',
    removeTransactionSplit: 'Remove Transaction Split',
    selectTheTransactionSingleAccount: "Select the transaction's single account",
    back: 'Back',
    select: 'Select',
    notPresent: '(not present)',
    notPresentOriginal: 'Original: Not Present',
    original: '1. Original: ',
    noDataFound: 'No Data Found',
  },
  formCardApplication: {
    postalAddressLabel: 'Postal Address*',
    addressLine1Label: 'Address Line 1*',
    addressLine2Label: 'Address Line 2',
    suburbtownLabel: 'Suburb/Town*',
    stateLabel: 'State*',
    postCodeLabel: 'Postcode*',
    dateOfBirthLabel: 'Date of Birth*',
    cardTypeLabel: 'Card Type*',
    securityQuestionLabel: 'Security Question*',
    securityAnswerLabel: 'Security Answer*',
    pdsReferenceLabel: 'PDS Acceptance Name / Reference',
    createUserBtn: 'Create User',
    cancelBtn: 'Cancel',
    cardTypePlaceholder: 'Card Type',
    cantFindAddress: "I can't find the address",
    securityQuestionPlaceHolder: 'Security Question',
    securityAnswerPlaceHolder: 'Security Answer',
    pdsReferencePlaceHolder: 'PDS Acceptance Name / Reference',
    dateOfBirthPlaceHolder: 'DD/MM/YYYY',
    postalAddressPlaceHolder: 'Postal Address',
    cardTypePlaceHolder: 'Card Type',
    addressLine1PlaceHolder: 'Address Line 1',
    addressLine2PlaceHolder: 'Address Line 2',
    suburbtownPlaceHolder: 'Suburb/Town',
    statePlaceHolder: 'State',
    postCodePlaceHolder: 'Postcode',
    yesBtn: 'Yes - Proceed',
    cancelConfirmation:
      "Are you sure you want to cancel? Your new user will not be added to CardHero until you complete this form and click the 'Create User' button",
    topMessage:
      "Please populate the below details and click 'Create User' to establish a new user and CardHero card.",
  },
  transactionViewListDialog: {
    title: 'View Transactions',
    ignoreErrorMessage: 'User does not have an account',
  },
  transactionPrefixForAmount: {
    transaction: '-',
    topup: '+',
  },
  successMessageDialog: {
    addUserMessage: 'has been successfully added and sent a subscription link.',
    addUserMessageWithCardType:
      'has been successfully added and sent a subscription SMS/email.',
    EditUserMessage: 'has been successfully updated',
    addUserAdministratorReviewMessage: 'has been sent for administrator review',
    addUserSmsEmailMessage:
      'has been successfully added and sent a subscription SMS/email',
  },
  confirmationMessageDialog: {
    questionMessage:
      'Are you sure you want to deactivate this User? This will also cancel any active cards for this account.',
    yesButton: 'Yes',
    noButton: 'Cancel',
    questionBlockCardConfirmation: 'Are you sure you want to block this card?',
    questionUnblockCardConfirmation: 'Are you sure you want to Unblock this card?',
  },
  actionItems: {
    locationDetails: 'Location Details',
    viewTransactions: 'View Transactions',
    auditHistory: 'Audit History',
    transferFunds: 'Transfer Available Funds',
  },
  confirm: {
    yes: 'Yes',
    no: 'No',
  },
  userViews: {
    emptyRows: {
      header: 'No Users Found',
      body: 'Please change your search criteria and try again',
    },
    filterPlaceholder: 'Filter by',
    secondaryFilterPlaceholder: 'Company',
    headers: {
      userStatus: 'Status',
      cardStatus: 'Card Status',
      availableBalance: 'Available Balance',
      id: 'ID',
      name: 'Name',
      userType: 'Type',
      location: 'Location',
      action: 'Actions',
    },
    menuItems: {
      resendSubscriptionLink: 'Resend subscription link',
      userDetails: 'User Details & Delegation',
      auditHistory: 'Audit History',
      viewTransactions: 'View Transactions',
      viewCards: 'View Linked Cards',
      transferFunds: 'Transfer Funds',
      resendSubscription: 'Resend Subscription',
    },
    searchLabel: 'Search',
    searchPlaceholder: 'Search by',
    filterActive: 'Active',
    filterInactive: 'Inactive',
    filterLocked: 'Locked',
    securityQuestion: 'Security Question:',
    answer: 'Answer:',
    selectedQuestion: 'Selected Question Text',
    answered: 'Answer',
    confirmBtn: 'CONFIRM',
    sendSmsBtn: 'Send SMS/Email',
    issuedVerballyBtn: 'Issued verbally',
    cancelBtn: 'Cancel',
    unlockCaption: 'Unlock Code',
    unlockSuccessMessage: 'has been successfully sent an Unlock Code.',
  },
  locationViews: {
    pageTitleLabel: 'Locations',
    searchPlaceHolder: 'Search by Code or Description',
    noRecordFound:
      'No locations found. Please change your search criteria and try again.',
    balanceHeader: 'Balance',
    codeHeader: 'Code',
    descriptionHeader: 'Description',
    emptyRows: {
      header: 'No Locations Found',
      body: 'Please change your search criteria and try again',
    },
  },
  activitiesView: {
    viewActivityDetails: 'View Activity Details',
    emptyRows: {
      header: 'No Activities Found',
      body: 'Please change your search criteria and try again',
    },
    filterPlaceholder: 'Filter by',
    headers: {
      status: 'Status',
      type: 'Type',
      description: 'Name/Location/Card',
      submittedBy: 'Submitted By',
      submittedDate: 'Submitted Date',
      approvedRejectedDate: 'Approved/Rejected Date',
      action: 'Actions',
    },
    searchLabel: 'Search',
    searchPlaceholder: 'Search by',
    pageTitleLabel: 'Activities/Notifications',
    filterOpen: 'Open',
    filterRejected: 'Rejected',
    filterApproved: 'Approved',
    statusHeader: 'Status',
    typeHeader: 'Type',
    nameHeader: 'Name/Location/Card',
    submittedDateHeader: 'Submitted Date',
    approveRejectDateHeader: 'Approved/Rejected Date',
    noRecordFound:
      'No activities found. Please change your search criteria and try again.',
    notificationMessage: 'An activity has been submitted for your review…',
    menuItems: {
      activityDetailLabel: 'Activity detail',
    },
  },
  activityDetail: {
    pageTitleLabel: 'Review Activity',
    activityIndicator: 'Activity Indicator',
    editUser: 'Edit User',
    cardDetails: 'Card Details',
    locationDetails: 'Location Details',
    status: 'Status:',
    uniqueReferenceNo: 'Unique/Deposit Reference No:',
    middleName: 'Middle Name:',
    countryCode: 'Country Code:',
    defaultLocation: 'Default Location:',
    userType: 'User Type:',
    firstName: 'First Name:',
    lastName: 'Last Name:',
    mobileNumber: 'Mobile Number:',
    cardType: 'Card Type:',
    activityStatus: 'Status',
    activityType: 'Activity Type',
    submittedDate: 'Submitted Date',
    approvedDate: 'Approved Date',
    rejectedDate: 'Rejected Date',
    approveBtn: 'Approve',
    rejectBtn: 'Reject',
    backBtn: 'Back to Activites',
    notApplicable: 'Not Applicable',
    rejectMessage: 'Enter a reason for rejection and click the Confirm button',
    approveConfirmationMessage: 'Are you sure you want to approve this activity?',
    confirmBtn: 'Confirm',
    approveConfirmBtn: 'Yes - Proceed',
    cancelBtn: 'Cancel',
    notPresent: '(not present)',
  },
  addEditLocation: {
    labelCode: 'Code',
    labelDescription: 'Description',
    labelAddLocationTitle: 'Add Location',
    labelEditLocationTitle: 'Edit Location',
    addLocationMessage: 'Your new Location has been Submitted for review',
    editLocationMessage: 'Your Location has been Submitted for review',
    timezoneLabel: 'Time Zone',
  },
  cardView: {
    pageTitle: 'Cards',
    cardStatusLabel: 'Status',
    userStatusLabel: 'User Status',
    cardHolderNameLabel: 'CardHolder Name',
    cardNumberLabel: 'Card Number',
    cardTypeLabel: 'Type',
    plasticActiveLabel: 'Plastic Active',
    filterBlocked: 'Blocked',
    searchPlaceHolder: 'Search by Name, Card Number, or Card Type',
    noRecordFound: 'No cards found. Please change your search criteria and try again.',
    merchantCategoryTitle: 'Merchant Category',
    merchantAvailableTitle: 'Available to [Card Type]',
    merchantForCardTitle: 'Available for Card?',
    merchantCategoryIdTitle: 'Id',
    emptyRows: {
      header: 'No Cards Found',
      body: 'Please change your search criteria and try again',
    },
    merchantEmptyRows: {
      header: 'No Merchant Found',
      body: 'Please change your search criteria and try again',
    },
  },
  cardDetails: {
    cardStatusDisplay: {
      ACTIVE: 'Active',
      BlOCKED: 'Blocked',
      INACTIVE: 'Inactive',
      FRAUD_DETECTED: 'Fraud Detected',
      EXCEEDED_INCORRECT_PIN: 'Exceeded Incorrect PIN',
      UNBLOCK: 'unblock',
      BLOCK: 'block',
    },
    leftPanelMessage: {
      fraud: 'No maintenance activities can be performed for fraud detected cards',
      closed: 'No maintenance activities can be performed for inactive cards',
      exceededPin: 'PIN reset is required',
    },
    successMessage: {
      blockCard: 'Card has been blocked.',
      unblockCard: 'Card has been unblocked.',
      replaceCard: 'Card has been replaced.',
      cancelCard: 'Card has been successfully Cancelled.',
      saveChanges: 'The card detail has been changed',
    },
    title: 'View/Edit Card',
    cardHolderLabel: 'Card Holder:',
    lastTransactionDateLabel: 'Last Transaction Date:',
    lastTransactionMerchantLabel: 'Last Transaction Merchant Name:',
    lastTransactionAmountLabel: 'Last Transaction Amount:',
    blockButtonLabel: 'Block Card',
    unblockButtonLabel: 'Unblock Card',
    replaceButtonLabel: 'Replace Card',
    reportCardStolenButtonLabel: 'Report Card as Lost or Stolen',
    cancelCardButtonLabel: 'Cancel Card',
    saveChangesLabel: 'Save Changes',
    cashWithdrawalLimitFormLabel: 'Cash Withdrawal Limit:',
    transactionLimitFormLabel: 'Transaction Limit:',
    dailyLimitFormLabel: 'Daily Limit:',
    limitMessageNullTitle: 'There are no available limits for this card',
    limitMessageNullBody: 'Limits cannot be set for users with no (accounts)',
    updateAddressTitle: 'Update Address',
    updateAddressForm: {
      address1: 'Address Line 1',
      address2: 'Address Line 2',
      suburb: 'Suburb',
      country: 'Country',
      state: 'State',
      postalCode: 'Postal Code',
      confirmationMessage: 'Replace card',
      confirmationContent:
        'Are you sure you want to update the address and replace this card?',
      yes: 'Yes',
      no: 'No',
    },
  },
  auditHistory: {
    title: 'Audit History',
    noRecord: 'No audit history record has been found',
  },
  auditHistoryDetail: {
    title: 'Audit History Event Detail',
    activity: 'Activity: ',
    activityStatus: 'Activity Status: ',
    date: 'Date: ',
    user: 'User: ',
    userType: 'User Type: ',
    prevUserType: 'Previous User Type: ',
    userNumber: 'User Number: ',
    completedAt: 'Completed At: ',
    completedBy: 'Completed By: ',
    completedFor: 'Completed For: ',
    completedDateTime: 'Completed Date & Time: ',
    approvedAt: 'Approved At: ',
    approvedBy: 'Approved By: ',
    addedBy: 'Added By: ',
    addedAt: 'Added At: ',
    updatedBy: 'Updated By: ',
    updatedAt: 'Updated At: ',
    editedBy: 'Edited By: ',
    editedAt: 'Edited At: ',
    rejectedBy: 'Rejected By: ',
    rejectedAt: 'Rejected At: ',
    locationDetails: 'Location Details: ',
    locationGroup: 'Location Group: ',
    cardHolderDetail: 'Cardholder Details: ',
    comment: 'Comment: ',
    rejectionMessageReason: 'Rejection Message/Reason: ',
    uniqueNumber: 'Unique Number: ',
    prevUniqueNumber: 'Previous Unique Number: ',
    email: 'Email: ',
    prevEmail: 'Previous Email: ',
    mobilePrefix: 'Mobile Prefix: ',
    prevMobilePrefix: 'Previous Mobile Prefix: ',
    emailMobileNumber: 'Email / Phone Number: ',
    mobileNumber: 'Mobile Number: ',
    prevMobileNumber: 'Previous Mobile Number: ',
    defaultLocation: 'Default Location: ',
    prevDefaultLocation: 'Previous Default Location: ',
    firstName: 'First Name: ',
    prevFirstName: 'Previous First Name: ',
    middleName: 'Middle Name: ',
    prevMiddleName: 'Previous Middle Name: ',
    lastName: 'Last Name: ',
    prevLastName: 'Previous Last Name: ',
    code: 'Code: ',
    description: 'Description: ',
    platform: 'Platform: ',
    method: 'Method: ',
    ipAddress: 'IP Address: ',
    deviceNumber: 'Device Number: ',
    trigger: 'Trigger: ',
    userDelegation: 'User Delegation',
    prevUserDelegation: 'Prev User Delegation',
    locationDelegation: 'Location Delegation',
    prevLocationDelegation: 'Prev Location Delegation',
    status: 'Status: ',
    logoutDateTime: 'Logout Date & Time: ',
    oldName: 'Old Name: ',
    newName: 'New Name: ',
    oldAddress: 'Old Address: ',
    newAddress: 'New Address: ',
    oldSettings: 'Old Settings: ',
    newSettings: 'New Settings: ',
    name: 'Name: ',
    value: 'Value: ',
  },
  editMerchantCategoryBlockings: {
    saveChangesButtonLabel: 'Save Changes',
    closeButtonLabel: 'Close',
    title: 'Edit Merchant Category Blockings',
  },
  statusActive: 'Active',
  statusInactive: 'Inactive',
  unexpectedError: 'An unexpected error occurred. Please try log in again.',
  tokenError: 'Request failed: 401 Token Authentication Error',
  loading: 'Loading',
  continue: 'Continue',
  validate: 'Validate',
  verify: 'Verify',
  back: 'Back',
  resendCode: 'Resend Code',
  requiredError: 'Field is required',
  createAccount: 'Create Account',
  collapseMenu: 'Collapse Menu',
  validPasswordError: 'Please enter your password',
  passwordStrength: 'Password Strength',
  validFieldError: (field) => `Please enter a valid ${field}`,
  validSelectError: (field) => `Please select a valid ${field}`,
  insufficientBalanceError: (currency, amount) =>
    `Total is ${currency}${amount} greater than available amount`,
  maximumCharacterError: (length) => `No more than ${length} characters`,
  enterValidStringError: 'Please enter a valid alphanumeric characters',
  enterValidPostalError: 'Please enter a valid postal code',
  fundTransfer: {
    administratorNotesInputLabel: 'Administrator Notes',
    amountInputLabel: 'Amount',
    availableTextLabel: 'Available: ',
    cancelButtonLabel: 'Cancel',
    confirmationDialog: {
      alert:
        'To proceed with this change, please enter your CardHero password in the below field and click the ‛Yes - Proceed’ button',
      invalidPassword: 'The password you have entered is incorrect, please try again.',
      bankAccountNumber: (number) => `Bank Account Number: ${number}`,
      bankAccountName: (name) => `Bank Account Name: ${name}`,
      mobile: (mobile) => `User Mobile Number: ${mobile}`,
      name: (name) => `User Name: ${name}`,
      code: (code) => `Location Code: ${code}`,
      description: (description) => `Location Description: ${description}`,
      success: 'Transfer has been submitted for review.',
      title: (currency, amount, name) =>
        `You are about to transfer ${currency}${amount} from ${name}'s CardHero Account to:`,
    },
    destinationSelectLabel: 'Destination Bank Account',
    destinationInputLabel: {
      placeholder: 'Select Destination Bank',
      bsb: 'BSB',
      account: 'Account',
      accountName: 'Account Name',
    },
    emptyPlaceholder: 'Please search for a User/Location using the above fields',
    notFoundPlaceholder: 'User/Location not found!',
    firstNameLocationCode: 'First Name / Location Code',
    transactionDescriptionInputLabel: 'Transaction Description (displayed to users)',
    transactionDescriptionPlaceholderInputLabel: 'Internal Reference/Description',
    headers: {
      balance: 'Available Balance',
      destination: 'Destination',
      codeMobileNumber: 'Code/Mobile Number',
      descriptionFullName: 'Description/Legal Full Name',
      source: 'Source',
      type: 'Type',
    },
    lastNameLocationDesc: 'Last Name / Location Descr.',
    mobileNumber: 'Mobile Number',
    passwordInputLabel: 'Password',
    proceedButtonLabel: 'Yes - Proceed',
    requiredMark: '*',
    searchButtonLabel: 'Search',
    searchByInputLabel: 'Search By',
    title: 'Transfer Funds',
    transferButtonLabel: 'Transfer',
  },
  match: {
    isMatch: 'match',
  },
  notAvailable: 'N/A',
};
