import {
  getBankAccountList as getBankAccountListService,
  getBankAccount as getBankAccountService,
  createBankAccount as createBankAccountService,
  updateBankAccount as updateBankAccountService,
} from '@services/api/bankApi';

import bankTypes from './bankTypes';

export const getBankAccountList = (token, params) => {
  return async (dispatch) => {
    dispatch({ type: bankTypes.GET_BANK_LIST_REQUEST });

    try {
      const response = await getBankAccountListService(token, params);
      dispatch({ type: bankTypes.GET_BANK_LIST_SUCCESS, payload: response.data });

      return response;
    } catch (error) {
      dispatch({ type: bankTypes.GET_BANK_LIST_ERROR, error });

      return error;
    }
  };
};

export const getBankAccount = (token, id) => {
  return async (dispatch) => {
    dispatch({ type: bankTypes.GET_BANK_ACCOUNT_REQUEST });

    try {
      const response = await getBankAccountService(token, id);
      dispatch({
        type: bankTypes.GET_BANK_ACCOUNT_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({
        type: bankTypes.GET_BANK_ACCOUNT_ERROR,
        error,
      });
      return error;
    }
  };
};

export const createBankAccount = (token, params) => {
  return async (dispatch) => {
    dispatch({ type: bankTypes.CREATE_BANK_ACCOUNT_REQUEST });

    try {
      const response = await createBankAccountService(token, params);
      dispatch({
        type: bankTypes.CREATE_BANK_ACCOUNT_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({
        type: bankTypes.CREATE_BANK_ACCOUNT_ERROR,
        error,
      });
      return error;
    }
  };
};

export const updateBankAccount = (token, params) => {
  return async (dispatch) => {
    dispatch({ type: bankTypes.UPDATE_BANK_ACCOUNT_REQUEST });

    try {
      const response = await updateBankAccountService(token, params);
      dispatch({
        type: bankTypes.UPDATE_BANK_ACCOUNT_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({
        type: bankTypes.UPDATE_BANK_ACCOUNT_ERROR,
        error,
      });
      return error;
    }
  };
};

export const bankResetErrorState = () => {
  return async (dispatch) => {
    dispatch({ type: bankTypes.RESET_ERROR_STATE });
  };
};

export const bankResetState = () => {
  return async (dispatch) => {
    dispatch({ type: bankTypes.RESET_STATE });
  };
};
