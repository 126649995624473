import {
  getUserListEnhance,
  getUserAccessCredential,
  sendUserUnlockPin,
  postUserData as postUserDataService,
  getTransactionById,
  getUserNotification as getUserNotificationService,
  getAllSecurityQuestions,
  getStateByCountryId,
  getCompanyList as getCompanyListService,
  getLocationGroup as getLocationGroupService,
  putLocationGroup as putLocationGroupService,
  postLocationGroup as postLocationGroupService,
  getUserLocationDelegationList,
  getTimeZoneList,
  verifyAdminPassword as verifyAdminPasswordService,
  getUserById,
  getUserLocation as getUserLocationService,
  resendSubscriptionToken as resendSubscriptionTokenService,
} from '@services/api/userApi';
import { getLocationListByCompany } from '@services/api/locationApi';
import { getCardTypeByUserTypeId } from '@services/api/userType';
import userTypes from './userTypes';

export const getUserList = (
  token,
  page,
  size,
  searchBy,
  userStatus,
  searchText,
  companyId,
) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.GET_USER_LIST_REQUEST });
    try {
      const response = await getUserListEnhance(
        token,
        page,
        size,
        searchBy,
        userStatus,
        searchText,
        companyId,
      );
      dispatch({ type: userTypes.GET_USER_LIST_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: userTypes.GET_USER_LIST_ERROR, error });
      return error;
    }
  };
};

export const getLinkedUser = (token, page, size, searchBy, userStatus, searchText) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.GET_LINKED_USER_REQUEST });
    try {
      const response = await getUserListEnhance(
        token,
        page,
        size,
        searchBy,
        userStatus,
        searchText,
      );
      dispatch({ type: userTypes.GET_LINKED_USER_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: userTypes.GET_LINKED_USER_ERROR, error });
      return error;
    }
  };
};

export const getUserCredentialQuestion = (token, id) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.GET_USER_ACCESS_CREDENTIAL_REQUEST });
    try {
      const response = await getUserAccessCredential(token, id);
      dispatch({
        type: userTypes.GET_USER_ACCESS_CREDENTIAL_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: userTypes.GET_USER_ACCESS_CREDENTIAL_ERROR, error });
      return error;
    }
  };
};

export const postUserUnlockPin = (token, params) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.SEND_USER_UNLOCK_PIN_REQUEST });
    try {
      const response = await sendUserUnlockPin(token, params);
      dispatch({ type: userTypes.SEND_USER_UNLOCK_PIN_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: userTypes.SEND_USER_UNLOCK_PIN_ERROR, error });
      return error;
    }
  };
};

export const postUserData = (body, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: userTypes.POST_USER_DATA_REQUEST });
      const response = await postUserDataService(token, body);
      return response;
    } catch (error) {
      dispatch({ type: userTypes.POST_USER_DATA_ERROR, error });
      return error;
    }
  };
};

export const getUserNotification = (token) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.GET_NOTIFICATIONS_REQUEST });
    try {
      const initiateValue = {
        counter: 0,
        canApprove: false,
      };
      const response = await getUserNotificationService(token);
      const USER_CAN_NOT_APPROVE =
        /User has no access right to approve any activity/i.test(response?.data?.message);
      const USER_NO_ACTIVITY = /No Review Activities Found/i.test(
        response?.data?.message,
      );

      if (USER_NO_ACTIVITY) {
        initiateValue.canApprove = true;
      } else if (USER_CAN_NOT_APPROVE) {
        initiateValue.canApprove = false;
      } else {
        initiateValue.counter = response.data.counter;
        initiateValue.canApprove = response.data.canApprove;
      }

      dispatch({ type: userTypes.GET_NOTIFICATIONS_SUCCESS, payload: initiateValue });
      return response;
    } catch (error) {
      dispatch({ type: userTypes.GET_NOTIFICATIONS_ERROR, error });
      return error;
    }
  };
};

export const userDataClearError = () => {
  return async (dispatch) => {
    dispatch({ type: userTypes.CLEAR_ERROR_STATE });
  };
};

export const getTransactionList = (
  id,
  token,
  includeOtherSpends,
  typeData,
  limit,
  offset,
) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.GET_TRANSACTION_LIST_REQUEST });
    try {
      const response = await getTransactionById(
        id,
        token,
        includeOtherSpends,
        typeData,
        limit,
        offset,
      );
      dispatch({
        type: userTypes.GET_TRANSACTION_LIST_SUCCESS,
        count: response.data.count,
        result: response.data.result,
      });
      return response;
    } catch (error) {
      dispatch({ type: userTypes.GET_TRANSACTION_LIST_ERROR, error });
      return error;
    }
  };
};

export const getUserLocationList = (companyId, token) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.GET_LOCATION_LIST_REQUEST });
    try {
      const response = await getLocationListByCompany(companyId, token);
      dispatch({
        type: userTypes.GET_LOCATION_LIST_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: userTypes.GET_LOCATION_LIST_ERROR, error });
      return error;
    }
  };
};

export const getCompanyList = (token) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.GET_COMPANY_LIST_REQUEST });
    try {
      const response = await getCompanyListService(token);
      dispatch({
        type: userTypes.GET_COMPANY_LIST_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: userTypes.GET_COMPANY_LIST_ERROR, error });
      return error;
    }
  };
};

export const getCardTypeByUserType = (userTypeId, token) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.GET_CARDTYPE_BY_USERTYPEID_REQUEST });
    try {
      const response = await getCardTypeByUserTypeId(userTypeId, token);
      dispatch({
        type: userTypes.GET_CARDTYPE_BY_USERTYPEID_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: userTypes.GET_CARDTYPE_BY_USERTYPEID_ERROR, error });
      return error;
    }
  };
};

export const getLocationGroup = (token, companyId, page, size) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.GET_LOCATION_GROUP_REQUEST });
    try {
      const response = await getLocationGroupService(token, companyId, page, size);
      dispatch({
        type: userTypes.GET_LOCATION_GROUP_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: userTypes.GET_LOCATION_GROUP_ERROR, error });
      return error;
    }
  };
};

export const getSecurityQuestionsList = (token) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.GET_SECURITY_QUESTIONS_REQUEST });
    try {
      const response = await getAllSecurityQuestions(token);
      dispatch({
        type: userTypes.GET_SECURITY_QUESTIONS_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: userTypes.GET_SECURITY_QUESTIONS_ERROR, error });
      return error;
    }
  };
};

export const getStateByCountry = (countryCodeId, token) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.GET_STATE_BY_COUNTRY_REQUEST });
    try {
      const response = await getStateByCountryId(countryCodeId, token);
      dispatch({
        type: userTypes.GET_STATE_BY_COUNTRY_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: userTypes.GET_STATE_BY_COUNTRY_ERROR, error });
      return error;
    }
  };
};

export const putLocationGroup = (body, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: userTypes.PUT_LOCATION_GROUP_REQUEST });
      const response = await putLocationGroupService(body, token);
      dispatch({ type: userTypes.PUT_LOCATION_GROUP_SUCCESS });
      return response;
    } catch (error) {
      dispatch({ type: userTypes.PUT_LOCATION_GROUP_ERROR, error });
      return error;
    }
  };
};

export const postLocationGroup = (body, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: userTypes.POST_LOCATION_GROUP_REQUEST });
      const response = await postLocationGroupService(body, token);
      dispatch({ type: userTypes.POST_LOCATION_GROUP_SUCCESS });
      return response;
    } catch (error) {
      dispatch({ type: userTypes.POST_LOCATION_GROUP_ERROR, error });
      return error;
    }
  };
};

export const getUserLocationDelegation = (
  companyId,
  keyword,
  userTypeId,
  isDefaultLocation,
  locationId,
  token,
) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.GET_USER_LOCATION_DELEGATION_REQUEST });
    try {
      const response = await getUserLocationDelegationList(
        companyId,
        keyword,
        userTypeId,
        isDefaultLocation,
        locationId,
        token,
      );
      dispatch({
        type: userTypes.GET_USER_LOCATION_DELEGATION_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: userTypes.GET_USER_LOCATION_DELEGATION_ERROR, error });
      return error;
    }
  };
};

export const getTimeZoneOptions = (token) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.GET_TIME_ZONE_LIST_REQUEST });
    try {
      const response = await getTimeZoneList(token);
      dispatch({
        type: userTypes.GET_TIME_ZONE_LIST_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: userTypes.GET_TIME_ZONE_LIST_ERROR, error });
      return error;
    }
  };
};

export const resetLinkedUserState = () => {
  return async (dispatch) => {
    dispatch({ type: userTypes.CLEAR_LINKED_USER_TRIGGERED });
  };
};

export const resetCredentialQuestion = () => {
  return async (dispatch) => {
    dispatch({ type: userTypes.RESET_USER_ACCESS_CREDENTIAL });
  };
};

export const userClearErrorState = () => {
  return async (dispatch) => {
    dispatch({ type: userTypes.CLEAR_ERROR_STATE });
  };
};

export const verifyAdminPassword = (token, password) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.VERIFY_ADMIN_PASSWORD_REQUEST });

    try {
      const response = await verifyAdminPasswordService(token, password);

      dispatch({
        type: userTypes.VERIFY_ADMIN_PASSWORD_SUCCESS,
      });

      return response;
    } catch (error) {
      dispatch({ type: userTypes.VERIFY_ADMIN_PASSWORD_ERROR, error });

      return error;
    }
  };
};

export const getUserDetails = (token, id) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.GET_USER_DETAILS_REQUEST });

    try {
      const response = await getUserById(token, id);

      dispatch({ type: userTypes.GET_USER_DETAILS_SUCCESS });

      return response;
    } catch (error) {
      dispatch({ type: userTypes.GET_USER_DETAILS_ERROR });

      return error;
    }
  };
};

export const getUserLocations = (token, body) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.GET_USER_LOCATION_REQUEST });

    try {
      const response = await getUserLocationService(token, body);

      dispatch({ type: userTypes.GET_USER_LOCATION_SUCCESS });

      return response;
    } catch (error) {
      dispatch({ type: userTypes.GET_USER_LOCATION_ERROR });

      return error;
    }
  };
};

export const resendSubscriptionToken = (token, id) => {
  return async (dispatch) => {
    dispatch({ type: userTypes.RESEND_SUBSCRIPTION_TOKEN_REQUEST });

    try {
      const response = await resendSubscriptionTokenService(token, id);

      dispatch({ type: userTypes.RESEND_SUBSCRIPTION_TOKEN_SUCCESS });

      return response;
    } catch (error) {
      dispatch({ type: userTypes.RESEND_SUBSCRIPTION_TOKEN_ERROR, error });

      return error;
    }
  };
};
