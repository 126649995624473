import { activitiesStatus } from '@data/constants';
import { get, post } from './baseApi';

const getActivitiesList = async (
  token,
  page = 0,
  size = 25,
  searchBy,
  activityStatus,
  searchText,
  column = activityStatus === activitiesStatus.OPEN
    ? 'submittedDate'
    : 'statusUpdatedDate',
  order = activityStatus === activitiesStatus.OPEN ? 'DESC' : 'ASC',
) => {
  let url = `/admin/activities?page=${page}&limit=${size}&column=${column}&order=${order}`;
  if (searchText !== '') {
    url = url + `&${searchBy}=${searchText}`;
  }
  if (activityStatus !== '') {
    url = url + `&status=${activityStatus}`;
  }
  return get(url, token);
};

const getActivityDetail = async (token, id) => {
  return get(`/admin/activities/${id}`, token);
};

const approveActivity = async (token, params) => {
  return post('/admin/activities/approve', params, token);
};

const rejectActivity = async (token, params) => {
  return post('/admin/activities/reject', params, token);
};

export { getActivitiesList, getActivityDetail, approveActivity, rejectActivity };
