export const loginPageStyle = (theme) => ({
  header: {
    fontWeight: 800,
    color: theme.palette.grey[900],

    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      flex: 0.45,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },

    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '1.75rem !important',
    },

    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '3rem',
    },
  },
  subtitle: {
    marginTop: '0.5em',
    fontSize: '1rem',
    lineHeight: 1.5,

    [theme.breakpoints.down('sm')]: {
      marginTop: '0.15em',
    },

    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '0.875rem !important',
    },

    [theme.breakpoints.between('sm', 'lg')]: {
      marginTop: '0.25em',
      fontSize: '1.25rem',
    },
  },
  focused: {
    color: `${theme.palette.text.secondary} !important`,
  },
  forgotPassword: {
    textAlign: 'center',
    textDecoration: 'none',
    marginTop: '2em',
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 600,

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.up('lg')]: {
      flex: 1,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',

    '& > :not(:last-child)': {
      margin: '0.5em 0',
    },
  },
  helperTextError: {
    color: theme.palette.error.main,
  },
  inputPasswordLabel: {
    fontSize: '1.125rem',
    fontWeight: 600,
    userSelect: 'none',
  },
  inputPasswordRoot: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    userSelect: 'none',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: '1rem',
    width: 'auto',
    padding: '4px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
  rememberMe: {
    '& .MuiTypography-root': {
      fontSize: '1rem',

      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
  },
});
