export const userSettingsPageStyle = (theme) => ({
  '@global': {
    '.MuiDialogTitle-root .MuiIconButton-root': {
      color: 'black !important',
    },
    '.MuiDialogTitle-root .MuiSvgIcon-root': {
      fontSize: '20px',
    },
  },
  divStyle: {
    paddingTop: '20px',
  },
  root: {
    width: '90%',
  },
  flexContainer: { justifyContent: 'flex-end' },
  settingsTabsPaper: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    borderBottom: '0px',
  },
  tableContainer: {
    width: '30%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  boxStyle: {
    borderBottom: '2px solid #F2F2F2',
    textAlign: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '220px',
  },
  successIcon: {
    color: 'green',
    fontSize: '100px',
  },
  successMessage: {
    color: 'green',
  },
  tabTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    minWidth: '22%',
    borderBottom: '2px solid #F2F2F2',
  },
  pageTitle: {
    fontSize: 28,
    paddingLeft: '22px',
    fontWeight: 'bold',
  },
  successIconContainterStyle: {
    textAlign: 'center',
  },
  successMsgContainterStyle: {
    textAlign: 'center',
    paddingTop: '20px',
  },
  gridContainerTabStyle: {
    paddingBottom: '6px !important',
  },
  paperSuccessMessageStyle: {
    border: '2px solid green',
  },
  roundedButton: {
    borderRadius: '8px',
  },
  statusTabs: {
    width: '100%',
    padding: theme.spacing(0, 1, 1),

    '& .MuiTabs-fixed': {
      '& .MuiTabs-flexContainer': {
        borderBottom: ' 4px solid #E0E0E0',

        '& .MuiTab-root': {
          fontWeight: 700,
          color: '#BDBDBD',
          fontSize: '1rem',

          '&.Mui-selected': {
            color: '#EB4E88',
          },
        },
      },

      '& .MuiTabs-indicator': {
        backgroundColor: '#EB4E88',
        height: 4,
      },
    },
  },
  tabInformationPage: {
    position: 'absolute',
    right: 0,
    top: 15,
    color: '#C7C7C7',
  },
});
