import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import { ActivityDetailPageStyle } from './style';

function ChildDelegationMatrix({ classes, matrix, location, definition }) {
  return (
    matrix.value.length > 0 && (
      <>
        <span className={classes.definitionChildDelegationMatrix}>{definition}:</span>
        <div className={classes.tabSpecificContentStyle}>
          <div
            className={[
              classes.gridSpecificContentStyle,
              classes.gridDelegationContentStyle,
            ].join(' ')}
          >
            {/* <div
              className={[
                classes.specificContentStyle,
                classes.borderContentStyle,
                classes.delegationContentStyle,
              ].join(' ')}
            >
              <div
                className={[
                  classes.cellContentPropertyStyle,
                  classes.cellDelegationContentPropertyStyle,
                ].join(' ')}
              >
                <RadioButtonUncheckedRoundedIcon
                  style={{ color: 'red' }}
                  className={classes.cellContentIconStyle}
                ></RadioButtonUncheckedRoundedIcon>
                <span className={classes.cellContentPropertyValueStyle}>Location: </span>
              </div>
              <span className={classes.contentValueStyle}>
                {`${location.fields[0].value} - ${location.fields[1].value}`}
              </span>
            </div> */}
            {matrix.value.map((value, index) => {
              const cellClass = [
                classes.specificContentStyle,
                classes.delegationContentStyle,
                classes.childDelegationContentStyle,
              ];

              if (index !== matrix.value.length - 1) {
                cellClass.push(classes.borderContentStyle);
              }
              return (
                <div className={cellClass.join(' ')} key={index}>
                  <div
                    className={[
                      classes.cellContentPropertyStyle,
                      classes.cellChildDelegationContentPropertyStyle,
                    ].join(' ')}
                  >
                    <RadioButtonUncheckedRoundedIcon
                      style={{ color: 'blue' }}
                      className={classes.cellContentIconStyle}
                    ></RadioButtonUncheckedRoundedIcon>
                    <span className={classes.cellContentPropertyValueStyle}>
                      {value.displayName}:
                    </span>
                  </div>
                  <span className={classes.contentValueStyle}>
                    <b>{value.value}</b>
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </>
    )
  );
}

ChildDelegationMatrix.propTypes = {
  classes: PropTypes.object,
  matrix: PropTypes.object,
  location: PropTypes.object,
  definition: PropTypes.string,
};

export default compose(withStyles(ActivityDetailPageStyle))(ChildDelegationMatrix);
