export const editUserDialogStyle = (theme) => ({
  iconMenuBtnStyle: {
    color: 'black',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#FF7141',
    },
    selected: {},
    width: '100%',
  },
  iconContainerStyle: {
    width: '100%',
  },
  editIconStyle: {
    paddingLeft: '20px',
  },
  editTitleStyle: {
    paddingTop: '10px',
  },
});
