import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tabs,
  Tab,
  Chip,
  useMediaQuery,
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import {
  activitiesStatus,
  activitiesType,
  activitiesSearchType,
  activitiesStatusFilter,
} from '@data/constants';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CustomizedSnackbar from '@components/customizedSnackBar';
import DashboardLayout from '@components/layout/dashboardLayout';
import DataGridPagination from '@components/dataGridPagination';
import EmptyRowsOverlay from '@components/emptyRowsOverlay';
import LayoutRoute from '@containers/layout/layoutRoute';
import ProtectedLayout from '@containers/layout/protectedLayout';
import { customization } from '@customization/default';
import ActivityDetailPage from '@containers/ActivityDetailPage';
import * as activitiesActions from '@redux/activities/activitiesActions';
import truncateWithEllipses from '@utils/truncateWithEllipses';
import { useStyles } from './style';

function ActivitiesViewPage(props) {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const activityStatus = [
    {
      status: [
        activitiesStatus.APPROVED,
        activitiesStatus.COMPLETED,
        activitiesStatus.FINAL,
      ],
      icon: <Chip label="Approved" className={classes.chipApproved} />,
    },
    {
      status: [activitiesStatus.REJECTED],
      icon: <Chip label="Rejected" className={classes.chipRejected} />,
    },
    {
      status: [activitiesStatus.OPEN],
      icon: <Chip label="Open" className={classes.chipOpen} />,
    },
  ];

  const {
    activitiesAction,
    activityDetail,
    history,
    match,
    error,
    errorList,
    timezone,
    token,
  } = props;

  const [activities, setActivities] = useState({ rows: [], total: 0 });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchFilter, setSearchFilter] = useState(activitiesSearchType[0].value);
  const [searchHolder, setSearchHolder] = useState('');
  const [searchText, setSearchText] = useState('');
  const [snackbar, setSnackbar] = useState({ show: false, message: null });
  const [statusFilter, setStatusFilter] = useState(activitiesStatus.OPEN);
  const [currentPage, setCurrentPage] = useState(0);

  const getActivities = useCallback(() => {
    activitiesAction
      .getAllActivities(
        token,
        page + 1,
        rowsPerPage,
        searchFilter,
        statusFilter,
        searchText,
      )
      .then((response) => {
        if (response.data) {
          const { data, total, totalPage, page } = response.data;
          setActivities({ rows: data, total: total });
          setCurrentPage(page);

          if (page >= totalPage && totalPage > 0) {
            setPage(totalPage - 1);
          }
        } else {
          if (response.message.includes('403')) {
            setActivities({ rows: [], total: 0 });
          } else {
            setSnackbar({ show: true, message: response.message });
          }
        }

        setLoading(false);
      });
  }, [
    page,
    rowsPerPage,
    searchFilter,
    searchText,
    statusFilter,
    token,
    activitiesAction,
  ]);

  const handleOpenActivityDetails = async (id) => {
    await activitiesAction.fetchActivityDetail(token, id).then(() => {
      history.push('/admin/dashboard/user-activities/activity-detail');
    });
  };

  useEffect(() => {
    if (error.statusCode) {
      setSnackbar({
        show: true,
        message: `Get activity detail error: ${error.statusCode}`,
      });
    }
  }, [activityDetail.id, error.statusCode, history]);

  const handleSearch = () => {
    setSearch(true);
    setSearchText(searchHolder);
  };

  const handleSearchFilter = (event) => {
    setSearch(false);
    setSearchFilter(event.target.value);
    setSearchHolder('');
    setSearchText('');
  };

  const handleSearchFieldChange = (event) => {
    const searchString = event.target.value;

    if (searchString.length === 0) {
      setSearch(false);
      setSearchText('');
    }

    setSearchHolder(searchString);
  };

  const handleStatusFilter = (_event, newStatus) => {
    if (newStatus === null) {
      setStatusFilter('');
    } else {
      setStatusFilter(newStatus);
    }
  };

  const keyPress = (event) => {
    if (event.keyCode === 13) {
      setSearch(true);
      setSearchText(searchHolder);
    }
  };

  useEffect(() => {
    setLoading(true);

    if (errorList.statusCode) {
      setPage(0);
      setSnackbar({
        show: true,
        message: `Get activity list error: ${errorList.statusCode}`,
      });
    }

    getActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActivities]);

  const prevMatchRef = useRef(match);

  useEffect(() => {
    if (prevMatchRef.current.isExact === false && match.isExact === true) {
      getActivities();
    }

    prevMatchRef.current = match;
  }, [getActivities, match]);

  const columns = [
    {
      align: 'center',
      field: 'activity_status',
      flex: 0.15,
      minWidth: 146.55,
      headerAlign: 'center',
      headerName: customization.activitiesView.headers.status,
      sortable: false,
      renderCell: (params) => {
        const status = params.value;

        if (status) {
          return _.head(
            activityStatus.filter((element) => element.status.includes(status)),
          ).icon;
        } else {
          return null;
        }
      },
    },
    {
      field: 'activity_type',
      flex: 0.2,
      minWidth: 195.39,
      headerName: customization.activitiesView.headers.type,
      renderCell: (params) => {
        if (params.value) {
          const type = Object.values(activitiesType).find(
            (o) => o.value === params.value,
          ) || {
            label: params.value
              .split('_')
              .map(
                (element) =>
                  element.charAt(0).toUpperCase() + element.slice(1).toLocaleLowerCase(),
              )
              .join(' '),
            value: params.value,
          }; // to handle error if params.value not available at activitiesType list

          const replaceLabel = [
            activitiesType.USER_TYPE_NEW.value,
            activitiesType.USER_TYPE_EDIT.value,
            activitiesType.LOCATION_GROUP_NEW.value,
            activitiesType.LOCATION_GROUP_EDIT.value,
          ];

          const activityLabel = replaceLabel.includes(params.value)
            ? activitiesType.CONFIGURATION_CHANGE.label
            : type.label;

          return (
            <div className={classes.activityType}>
              <span title={activityLabel}>{truncateWithEllipses(activityLabel, 25)}</span>
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      field: 'name_location_card',
      flex: 0.3,
      minWidth: 293.09,
      headerName: customization.activitiesView.headers.description,
      renderCell: (params) => {
        const activity_type = params.getValue(params.id, 'activity_type');

        if (activity_type === activitiesType.FUNDS_TRANSFER.value) {
          return truncateWithEllipses(
            params.row.user_name ?? params.row.name_location_card,
            40,
          );
        }

        return truncateWithEllipses(params.value, 40);
      },
    },
    {
      field: 'initiator_name',
      flex: 0.175,
      minWidth: 170.97,
      headerName: customization.activitiesView.headers.submittedBy,
    },
    {
      field: 'created_at',
      flex: 0.175,
      minWidth: 170.97,
      headerName: customization.activitiesView.headers.submittedDate,
      valueFormatter: (params) =>
        moment(params.value).utc().tz(timezone).format('DD/MM/YYYY - hh:mm a'),
    },
    {
      field: 'timestamp',
      flex: 0.175,
      minWidth: 170.97,
      headerName: customization.activitiesView.headers.approvedRejectedDate,
      renderCell: (params) => {
        const status = params.getValue(params.id, 'activity_status');

        if (status !== activitiesStatus.OPEN) {
          return moment(params.value).utc().tz(timezone).format('DD/MM/YYYY - hh:mm a');
        } else {
          return '';
        }
      },
    },
    {
      cellClassName: 'border-right',
      align: 'center',
      field: 'action',
      headerAlign: 'center',
      headerName: customization.activitiesView.headers.action,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <Button
            aria-controls="more-options"
            aria-haspopup="true"
            className={`${classes.actionButton} more`}
            disableElevation
            onClick={() => handleOpenActivityDetails(params.id)}
            variant="contained"
            title={customization.activitiesView.viewActivityDetails}
          >
            <OpenInNewIcon htmlColor="#FFFFFF" fontSize="medium" />
          </Button>
        );
      },
    },
  ];

  const pageInformation = () => {
    if (!activities?.rows.length) {
      return null;
    }

    const fromPage = (currentPage - 1) * rowsPerPage + 1;

    const toPage = (page + 1) * rowsPerPage;

    const totalPage = activities?.total;

    const lastPage =
      currentPage === Math.max(0, Math.ceil(activities?.total / rowsPerPage))
        ? totalPage
        : toPage;

    return `Showing ${fromPage}-${lastPage} of ${totalPage} results`;
  };

  return (
    <DashboardLayout>
      <Switch>
        <Route exact path={match.path}>
          <Tabs
            value={statusFilter}
            onChange={handleStatusFilter}
            className={classes.statusTabs}
            scrollButtons="auto"
          >
            {activitiesStatusFilter.map((status) => (
              <Tab key={status.label} label={status.label} value={status.value} />
            ))}
            {!isMobile && (
              <Tab
                className={classes.tabInformationPage}
                key="pageInformation"
                label={pageInformation()}
                disabled
              />
            )}
          </Tabs>

          <Grid
            className={classes.gridContainer}
            container
            justifyContent="flex-start"
            spacing={2}
          >
            <Grid item xs={12} lg={4} xl={3}>
              <FormControl className={classes.searchFilter} variant="outlined">
                <InputLabel color="secondary" id="search-filter-label">
                  {customization.activitiesView.filterPlaceholder}
                </InputLabel>
                <Select
                  id="search-filter"
                  color="secondary"
                  label={customization.activitiesView.filterPlaceholder}
                  labelId="search-filter-label"
                  value={searchFilter}
                  onChange={handleSearchFilter}
                >
                  {activitiesSearchType.map((searchType) => (
                    <MenuItem key={searchType.value} value={searchType.value}>
                      {searchType.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4} xl={3}>
              <TextField
                className={classes.searchText}
                color="secondary"
                InputProps={{
                  endAdornment: (
                    <Button
                      color="secondary"
                      disableElevation
                      onClick={handleSearch}
                      variant="contained"
                    >
                      {customization.userViews.searchLabel}
                    </Button>
                  ),
                  onKeyDown: keyPress,
                }}
                onChange={handleSearchFieldChange}
                placeholder={`${customization.activitiesView.searchPlaceholder} ${
                  activitiesSearchType.filter(
                    (searchType) => searchType.value === searchFilter,
                  )[0].label
                }`}
                type="search"
                value={searchHolder}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <DataGrid
            autoHeight
            disableColumnMenu
            disableExtendRowFullWidth
            disableSelectionOnClick
            className={classes.table}
            columns={columns}
            components={{
              NoRowsOverlay: EmptyRowsOverlay,
              Pagination: DataGridPagination,
            }}
            componentsProps={{
              noRowsOverlay: {
                label: customization.activitiesView.emptyRows,
                search,
              },
              pagination: {
                count: activities.total,
                onPageChange: (_event, newPage) => {
                  setPage(newPage);
                },
                onRowsPerPageChange: (event) => {
                  setRowsPerPage(event.target.value);
                },
                page: activities.rows.length > 0 ? page : 0,
                rowsPerPage,
              },
            }}
            loading={loading}
            maxColumns={9}
            pagination
            paginationMode="server"
            rows={activities.rows}
            rowHeight={80}
          />
        </Route>
        <ProtectedLayout>
          <LayoutRoute
            component={ActivityDetailPage}
            path="/admin/dashboard/user-activities/activity-detail"
          />
        </ProtectedLayout>
      </Switch>

      <CustomizedSnackbar
        message={snackbar.message}
        showBar={snackbar.show}
        severity="error"
        handleClose={() => setSnackbar({ show: false, message: null })}
      />
    </DashboardLayout>
  );
}

ActivitiesViewPage.propTypes = {
  activitiesAction: PropTypes.object,
  activitiesList: PropTypes.object,
  activityDetail: PropTypes.object,
  error: PropTypes.object,
  errorList: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  requestState: PropTypes.string,
  timezone: PropTypes.string,
  token: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    activitiesList: state.activities.activitiesList,
    activityDetail: state.activities.activityDetail,
    error: state.activities.error,
    errorList: state.activities.errorList,
    requestState: state.activities.loading,
    timezone: state.auth.timezone,
    token: state.auth.inMemoryToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activitiesAction: bindActionCreators(activitiesActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesViewPage);
