import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { loadingBackdropStyle } from './style';

function LoadingBackdrop(props) {
  const { classes, status } = props;
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setOpen(status === 'PENDING');
  }, [status]);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

LoadingBackdrop.propTypes = {
  classes: PropTypes.object,
  status: PropTypes.string,
};

export default compose(withStyles(loadingBackdropStyle))(LoadingBackdrop);
