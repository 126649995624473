const MerchantTypes = {
  GET_MERCHANT_CATEGORY_RESTRICTION_REQUEST: 'GET_MERCHANT_CATEGORY_RESTRICTION_REQUEST',
  GET_MERCHANT_CATEGORY_RESTRICTION_SUCCESS: 'GET_MERCHANT_CATEGORY_RESTRICTION_SUCCESS',
  GET_MERCHANT_CATEGORY_RESTRICTION_ERROR: 'GET_MERCHANT_CATEGORY_RESTRICTION_ERROR',
  PATCH_MERCHANT_CATEGORY_RESTRICTION_REQUEST:
    'PATCH_MERCHANT_CATEGORY_RESTRICTION_REQUEST',
  PATCH_MERCHANT_CATEGORY_RESTRICTION_SUCCESS:
    'PATCH_MERCHANT_CATEGORY_RESTRICTION_SUCCESS',
  PATCH_MERCHANT_CATEGORY_RESTRICTION_ERROR: 'PATCH_MERCHANT_CATEGORY_RESTRICTION_ERROR',
};

export default MerchantTypes;
