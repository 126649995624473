import { isEmpty } from 'lodash';
import { get, post, put } from './baseApi';

const getLocationGroupList = async (companyId, token) => {
  return get(`/admin/location-group?company_id=${companyId}`, token);
};

const postAddLocation = async (params, token) => {
  return post('/admin/location', params, token);
};

const getTimezone = async (token) => {
  return get('/admin/timezones', token);
};

const getLocationList = async (
  token,
  page = 1,
  size = 20,
  search,
  order,
  sort,
  active,
) => {
  let url = `/admin/locations?page=${page}&size=${size}`;
  if (search !== '') {
    url = url + `&keyword=${search}`;
  }
  if (order && sort !== '') {
    url = url + `&order=${order}&sort=${sort}`;
  }

  if (active !== null && !isEmpty(active)) {
    url = url + `&active=${active === 'active' ? true : false}`;
  }

  return get(url, token);
};

const getLocationListByCompany = async (companyId, token) => {
  return get(`/admin/location?company_id=${companyId}`, token);
};

const getLocationById = async (locationId, token) => {
  return get(`/admin/location/${locationId}`, token);
};

const putLocationData = async (params, token) => {
  return put('/admin/location', params, token);
};

export {
  getLocationList,
  getLocationGroupList,
  postAddLocation,
  getLocationById,
  putLocationData,
  getLocationListByCompany,
  getTimezone,
};
