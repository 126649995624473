import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { every, isEmpty } from 'lodash';
import clsx from 'clsx';
import {
  FormControl,
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBase,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import checkValidity from '@utils/checkValidity';
import FormTextInput from '@components/formTextInput';

import { customization } from '@customization/default';
import * as userActions from '@redux/user/userActions';
import { getStateByCountryId } from '@services/api/userApi';
import { useStyles, bootstrapStyles } from './style';

const BootstrapInput = withStyles(bootstrapStyles)((props) => <InputBase {...props} />);

const UpdateAddressDialog = ({
  classes,
  open,
  addressAction,
  onClose,
  userId,
  userAction,
  token,
  handleReplaceCard,
  handleReportCardStolen,
}) => {
  const initialFormState = {
    address1: {
      value: '',
      valid: false,
      required: true,
      touched: false,
      error: null,
    },
    address2: {
      value: '',
      valid: false,
      required: false,
      touched: false,
      error: null,
    },
    suburb: {
      value: '',
      valid: false,
      required: true,
      touched: false,
      error: null,
    },
    postalCode: {
      value: '',
      valid: false,
      required: true,
      touched: false,
      error: null,
    },
    stateId: {
      value: '',
      valid: false,
      required: true,
      touched: false,
      error: null,
    },
  };

  const [form, setForm] = useState(initialFormState);
  const [states, setStates] = useState([]);
  const [confirm, setConfirm] = useState(false);

  const isValid = (state) => {
    const validFields = [];

    for (const keys of Object.values(state)) {
      if (keys.valid) validFields.push(true);
      else validFields.push(false);
    }

    return every(validFields);
  };

  const handleUserInput = (value, field) => {
    let valid = true;
    let error = null;

    if (form[field].required) {
      if (isEmpty(value)) valid = false;
    }

    switch (field) {
      case 'address1':
      case 'address2':
      case 'suburb':
      case 'postalCode':
        if ((field === 'address1' || field === 'address2') && value.length > 50) {
          valid = false;
          error = customization.maximumCharacterError(50);
        }

        if (field === 'suburb' && value.length > 50) {
          valid = false;
          error = customization.maximumCharacterError(50);
        }

        if (field === 'postalCode' && value.length < 4) {
          valid = false;
          error = customization.enterValidPostalError;
        }

        if (!checkValidity(value, { escapedString: true })) {
          valid = false;
          error = customization.enterValidStringError;
        }

        break;
      default:
        break;
    }

    setForm({
      ...form,
      [field]: { ...form[field], touched: false, value, valid, error },
    });
  };

  useEffect(() => {
    if (userId) {
      userAction.getUserDetails(token, userId).then(({ data }) => {
        if (data) {
          setForm({
            address1: {
              ...form.address1,
              value: data.address_1,
              valid: !isEmpty(data.address_1),
              error: !isEmpty(data.address_1)
                ? null
                : customization.enterValidStringError,
            },
            address2: {
              ...form.address2,
              value: data.address_2,
              valid: true,
              error: null,
            },
            suburb: {
              ...form.suburb,
              value: data.suburb,
              valid: !isEmpty(data.suburb),
              error: !isEmpty(data.suburb) ? null : customization.enterValidStringError,
            },
            postalCode: {
              ...form.postalCode,
              value: data.post_code,
              valid: !isEmpty(data.post_code),
              error: !isEmpty(data.post_code)
                ? null
                : customization.enterValidStringError,
            },
            stateId: {
              ...form.stateId,
              value: data.state_fk,
              valid: true,
              error: null,
            },
          });
        }
      });

      // Get Australia states only
      getStateByCountryId(1, token).then(({ data }) => {
        setStates(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, open, token, userAction]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={onClose}
        aria-labelledby="update-address-dialog-title"
        aria-describedby="update-address-dialog-description"
      >
        <DialogTitle id="update-address-dialog-title">
          {customization.cardDetails.updateAddressTitle}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <label className={classes.formLabel}>
                    <b>
                      {customization.cardDetails.updateAddressForm.address1}
                      {customization.requiredMark}
                    </b>
                  </label>
                  <FormTextInput
                    field="address1"
                    placeholder={customization.cardDetails.updateAddressForm.address1}
                    type="text"
                    length={50}
                    onChangeInput={handleUserInput}
                    touched={form.address1.touched}
                    valid={form.address1.valid}
                    value={form.address1.value}
                    validationMessage={
                      form.address1.error ?? customization.validFieldError('Address 1')
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <label className={classes.formLabel}>
                    <b>{customization.cardDetails.updateAddressForm.address2}</b>
                  </label>
                  <FormTextInput
                    field="address2"
                    placeholder={customization.cardDetails.updateAddressForm.address2}
                    type="text"
                    length={50}
                    onChangeInput={handleUserInput}
                    touched={form.address2.touched}
                    valid={form.address2.valid}
                    value={form.address2.value}
                    validationMessage={
                      form.address2.error ?? customization.validFieldError('Address 2')
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <label className={classes.formLabel}>
                    <b>
                      {customization.cardDetails.updateAddressForm.suburb}
                      {customization.requiredMark}
                    </b>
                  </label>
                  <FormTextInput
                    field="suburb"
                    placeholder={customization.cardDetails.updateAddressForm.suburb}
                    type="text"
                    length={50}
                    onChangeInput={handleUserInput}
                    touched={form.suburb.touched}
                    valid={form.suburb.valid}
                    value={form.suburb.value}
                    validationMessage={
                      form.suburb.error ?? customization.validFieldError('Suburb')
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <label className={classes.formLabel}>
                    <b>
                      {customization.cardDetails.updateAddressForm.state}
                      {customization.requiredMark}
                    </b>
                  </label>
                  <Select
                    id="state"
                    value={form.stateId.value}
                    input={<BootstrapInput />}
                    displayEmpty
                    onChange={(event) =>
                      handleUserInput(event.target.value.toString(), 'stateId')
                    }
                    className={clsx(isEmpty(form.stateId.value) && classes.placeholder)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      classes: { paper: classes.menuPaper },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem key={0} value={''} disabled>
                      {customization.cardDetails.updateAddressForm.state}
                    </MenuItem>
                    {states.length > 0 &&
                      states.map((states, index) => (
                        <MenuItem key={index + 1} value={states.id}>
                          {states.internal_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <label className={classes.formLabel}>
                    <b>
                      {customization.cardDetails.updateAddressForm.postalCode}
                      {customization.requiredMark}
                    </b>
                  </label>
                  <FormTextInput
                    field="postalCode"
                    placeholder={customization.cardDetails.updateAddressForm.postalCode}
                    type="number"
                    length={4}
                    onChangeInput={handleUserInput}
                    touched={form.postalCode.touched}
                    valid={form.postalCode.valid}
                    value={form.postalCode.value}
                    validationMessage={
                      form.postalCode.error ??
                      customization.validFieldError('Postal Code')
                    }
                    onKeyDown={(event) => {
                      if (event.key !== 'Backspace') {
                        if (event.key < '0' || event.key > '9') {
                          event.preventDefault();
                        } else if (form.postalCode.value.length >= 4) {
                          event.preventDefault();
                        }
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={(event) => {
                event.preventDefault();

                if (isValid(form)) {
                  setConfirm(true);
                } else {
                  let errorFields = {};

                  for (const [key, value] of Object.entries(form)) {
                    if (!value.valid) {
                      errorFields = {
                        ...errorFields,
                        [key]: { ...value, touched: true },
                      };
                    }
                  }

                  setForm({ ...form, ...errorFields });
                }
              }}
            >
              {addressAction === 'REPLACE_CARD'
                ? customization.cardDetails.replaceButtonLabel
                : customization.cardDetails.reportCardStolenButtonLabel}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={confirm}
        onClose={() => setConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {customization.cardDetails.updateAddressForm.confirmationContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                onClick={() => {
                  setConfirm(false);

                  if (addressAction === 'REPLACE_CARD') {
                    handleReplaceCard(form);
                  } else {
                    handleReportCardStolen(form);
                  }
                }}
                color="primary"
                autoFocus
                fullWidth
              >
                {customization.cardDetails.updateAddressForm.yes}
              </Button>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                onClick={() => {
                  setConfirm(false);
                }}
              >
                {customization.cardDetails.updateAddressForm.no}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

UpdateAddressDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  addressAction: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number,
  handleReplaceCard: PropTypes.func,
  handleReportCardStolen: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    userAction: bindActionCreators(userActions, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withStyles(useStyles), withConnect)(UpdateAddressDialog);
