import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Grid, Typography, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';

import { apiRequestStatus } from '@data/constants';
import { customization } from '@customization/default';
import LoginLayout from '@components/layout/loginLayout';
import { LoginTitle } from '@components/login/loginTitle';
import LoginButton from '@components/login/loginButton';
import history from '@utils/history';
import * as authActions from '@redux/auth/authActions';
import { useStyles } from './style';
function LoginUnlockAccountRequestPage(props) {
  const location = useLocation();
  const classes = useStyles();

  const { adminUnlockAccountError, authActions, adminUnlockAccountStatus } = props;

  const [unlockCodeInput, setUnlockCodeInput] = useState('');
  const [unexpectedError, setUnexpectedError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [continueClicked, setContinueClicked] = useState(false);

  const EXPIRED_UNLOCK_CODE = /expired/i.test(adminUnlockAccountError?.message);
  const INVALID_PIN = /Invalid/i.test(adminUnlockAccountError?.message);
  const INVALID_UNLOCK_CODE = /Failure/i.test(adminUnlockAccountError?.message);
  const FAILED_UNLOCK_CODE = /Failed/i.test(adminUnlockAccountError?.message);
  const MAX_ATTEMPT_LIMIT = /attempt/i.test(adminUnlockAccountError?.message);

  useEffect(() => {
    if (email === '') {
      const path = location.pathname;
      const emailParams = path.split('/').pop();
      setEmail(emailParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAuthCodeInput = (otp) => {
    setUnlockCodeInput(otp);
  };

  const handleBackBtn = (event) => {
    event.preventDefault();
    authActions.adminClearState();
    history.push('/login');
  };

  const handleUnlockAccountConfirm = async (event) => {
    event.preventDefault();
    setContinueClicked(true);
    setLoading(true);
    try {
      const response = await authActions.adminUnlockAccountConfirm({
        email: email,
        unlock_code: unlockCodeInput,
      });

      if (response.status === 200) {
        setLoading(false);
        history.push(
          `/${response.data.flag}/token=${response.data.token}&email=${response.data.email}`,
        );
      } else {
        setLoading(false);
      }
      this.setState({ loading: false });
    } catch (error) {
      setUnexpectedError(error.message);
      setLoading(false);
    }
  };

  return (
    <LoginLayout>
      {!unexpectedError && !continueClicked && (
        <Typography variant="h4" component="div" className={classes.header}>
          {customization.loginUnlockAccountRequestPage.introduction}
          <Typography variant="subtitle1" className={classes.subtitle}>
            {customization.loginUnlockAccountRequestPage.introductionDescription}
          </Typography>
        </Typography>
      )}

      {adminUnlockAccountStatus !== apiRequestStatus.PENDING && continueClicked && (
        <>
          {(INVALID_PIN || INVALID_UNLOCK_CODE || FAILED_UNLOCK_CODE) && (
            <LoginTitle color="error">
              {customization.loginUnlockAccountRequestPage.unlockCodeFailureMessage}
            </LoginTitle>
          )}
          {EXPIRED_UNLOCK_CODE && (
            <LoginTitle color="error">
              {customization.loginUnlockAccountRequestPage.expiredUnlockCode}
            </LoginTitle>
          )}
          {MAX_ATTEMPT_LIMIT && (
            <LoginTitle color="error">
              {customization.loginUnlockAccountRequestPage.attemptLimitMessage}
            </LoginTitle>
          )}
        </>
      )}

      <Box className={classes.formContainer}>
        <form className={classes.form}>
          <OtpInput
            className={classes.otp}
            value={unlockCodeInput}
            onChange={handleAuthCodeInput}
            numInputs={12}
            inputType="tel"
            containerStyle={classes.otpContainer}
            inputStyle={classes.otpInput}
            renderInput={(props) => <input {...props} />}
          />
          <Grid container>
            <Grid item xs={12}>
              <LoginButton
                disabled={!unlockCodeInput || loading || MAX_ATTEMPT_LIMIT}
                onClick={handleUnlockAccountConfirm}
                type="submit"
              >
                {adminUnlockAccountStatus === apiRequestStatus.PENDING
                  ? customization.loading
                  : customization.validate}
              </LoginButton>
            </Grid>
            <Grid item xs={12}>
              <LoginButton variant="text" onClick={handleBackBtn}>
                {customization.back}
              </LoginButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </LoginLayout>
  );
}

LoginUnlockAccountRequestPage.propTypes = {
  adminUnlockAccountError: PropTypes.object,
  adminUnlockAccountStatus: PropTypes.string,
  authActions: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    adminUnlockAccountError: state.auth.error,
    adminUnlockAccountStatus: state.auth.loading,
    adminUnlockAccountConfirmMessage: state.auth.unlockConfirmMessage,
    token: state.auth.inMemoryToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginUnlockAccountRequestPage);
