import { apiRequestStatus } from '@data/constants';
import auditHistoryTypes from './transactionTypes';

const initialTransactionState = {
  loading: '',
  loadingSpendCategory: '',
  loadingTransactionList: '',
  loadingTransactionAudit: '',
  loadingAccountAllocation: '',
  loadingDisputeExpenseStatus: '',
  loadingReceipt: '',
  spendCategoryList: [],
  searchAccountList: [],
  receiptList: [],
  disputeExpenseStatus: {},
  error: {},
  transaction: {},
  accountAllocationList: {},
  transactionAuditList: [],
};

const auditHistoryReducer = (state = initialTransactionState, action) => {
  switch (action.type) {
    case auditHistoryTypes.GET_SPEND_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loadingSpendCategory: apiRequestStatus.RESOLVED,
        spendCategoryList: action.payload,
        error: {},
      };
    case auditHistoryTypes.GET_SPEND_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        loadingSpendCategory: apiRequestStatus.LOADING,
        error: {},
      };
    case auditHistoryTypes.GET_SPEND_CATEGORY_LIST_ERROR:
      return {
        ...state,
        loadingSpendCategory: apiRequestStatus.ERROR,
        error: {
          ...action?.error?.response?.data,
          message: action?.error?.response?.data?.message || action?.error?.message,
          statusCode: action?.error?.response?.status,
        },
      };
    case auditHistoryTypes.GET_RECEIPT_SUCCESS:
      return {
        ...state,
        loadingReceipt: apiRequestStatus.RESOLVED,
        receiptList: action.payload,
        error: {},
      };
    case auditHistoryTypes.GET_RECEIPT_REQUEST:
      return {
        ...state,
        loadingReceipt: apiRequestStatus.LOADING,
        error: {},
      };
    case auditHistoryTypes.GET_RECEIPT_ERROR:
      return {
        ...state,
        loadingReceipt: apiRequestStatus.ERROR,
        error: {
          ...action?.error?.response?.data,
          message: action?.error?.response?.data?.message || action?.error?.message,
          statusCode: action?.error?.response?.status || 500,
        },
      };
    case auditHistoryTypes.UPLOAD_RECEIPT_SUCCESS:
      return {
        ...state,
        loadingReceipt: apiRequestStatus.RESOLVED,
        error: {},
      };
    case auditHistoryTypes.UPLOAD_RECEIPT_REQUEST:
      return {
        ...state,
        loadingReceipt: apiRequestStatus.LOADING,
        error: {},
      };
    case auditHistoryTypes.UPLOAD_RECEIPT_ERROR:
      return {
        ...state,
        loadingReceipt: apiRequestStatus.ERROR,
        error: {
          ...action?.error?.response?.data,
          message: action?.error?.response?.data?.message || action?.error?.message,
          statusCode: action?.error?.response?.status || 500,
        },
      };
    case auditHistoryTypes.DELETE_RECEIPT_SUCCESS:
      return {
        ...state,
        loadingReceipt: apiRequestStatus.RESOLVED,
        error: {},
      };
    case auditHistoryTypes.DELETE_RECEIPT_REQUEST:
      return {
        ...state,
        loadingReceipt: apiRequestStatus.LOADING,
        error: {},
      };
    case auditHistoryTypes.DELETE_RECEIPT_ERROR:
      return {
        ...state,
        loadingReceipt: apiRequestStatus.ERROR,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    // TRANSACTION ALLOCATION==============================================================================
    case auditHistoryTypes.GET_TRANSACTION_ALLOCATION_REQUEST:
      return {
        ...state,
        loadingTransactionList: apiRequestStatus.PENDING,
        error: {},
      };
    case auditHistoryTypes.GET_TRANSACTION_ALLOCATION_SUCCESS:
      return {
        ...state,
        loadingTransactionList: apiRequestStatus.RESOLVED,
        searchAccountList: action.payload,
        error: {},
      };
    case auditHistoryTypes.GET_TRANSACTION_ALLOCATION_ERROR:
      return {
        ...state,
        loadingTransactionList: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          message: action?.error?.response?.data?.message || action?.error?.message,
          statusCode: action?.error?.response?.status,
        },
      };
    // SPLIT TRANSACTION==============================================================================
    case auditHistoryTypes.SPLIT_TRANSACTION_ALLOCATION_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case auditHistoryTypes.SPLIT_TRANSACTION_ALLOCATION_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        transaction: action.payload,
        error: {},
      };
    case auditHistoryTypes.SPLIT_TRANSACTION_ALLOCATION_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          message: action?.error?.response?.data?.message || action?.error?.message,
          statusCode: action?.error?.response?.status,
        },
      };
    // GET ACCOUNT ALLOCATION==============================================================================
    case auditHistoryTypes.GET_ACCOUNT_ALLOCATION_REQUEST:
      return {
        ...state,
        loadingAccountAllocation: apiRequestStatus.PENDING,
        error: {},
      };
    case auditHistoryTypes.GET_ACCOUNT_ALLOCATION_SUCCESS:
      return {
        ...state,
        loadingAccountAllocation: apiRequestStatus.RESOLVED,
        accountAllocationList: action.payload,
        error: {},
      };
    case auditHistoryTypes.GET_ACCOUNT_ALLOCATION_ERROR:
      return {
        ...state,
        loadingAccountAllocation: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          message: action?.error?.response?.data?.message || action?.error?.message,
          statusCode: action?.error?.response?.status,
        },
      };
    // GET TRANSACTION AUDIT==============================================================================
    case auditHistoryTypes.GET_TRANSACTION_AUDIT_REQUEST:
      return {
        ...state,
        loadingTransactionAudit: apiRequestStatus.PENDING,
        transactionAuditList: [],
        error: {},
      };
    case auditHistoryTypes.GET_TRANSACTION_AUDIT_SUCCESS:
      return {
        ...state,
        loadingTransactionAudit: apiRequestStatus.RESOLVED,
        transactionAuditList: action.payload,
        error: {},
      };
    case auditHistoryTypes.GET_TRANSACTION_AUDIT_ERROR:
      return {
        ...state,
        loadingTransactionAudit: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          message: action?.error?.response?.data?.message || action?.error?.message,
          statusCode: action?.error?.response?.status,
        },
      };
    // GET DISPUTE EXPENSE==============================================================================
    case auditHistoryTypes.GET_DISPUTE_EXPENSES_STATUS_REQUEST:
      return {
        ...state,
        loadingDisputeExpenseStatus: apiRequestStatus.PENDING,
        disputeExpenseStatus: {},
        error: {},
      };
    case auditHistoryTypes.GET_DISPUTE_EXPENSES_STATUS_SUCCESS:
      return {
        ...state,
        loadingDisputeExpenseStatus: apiRequestStatus.RESOLVED,
        disputeExpenseStatus: action.payload,
        error: {},
      };
    case auditHistoryTypes.GET_DISPUTE_EXPENSES_STATUS_ERROR:
      return {
        ...state,
        loadingDisputeExpenseStatus: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          message: action?.error?.response?.data?.message || action?.error?.message,
          statusCode: action?.error?.response?.status,
        },
      };
    // CLEAR ERROR STATE===========================================================================
    case auditHistoryTypes.CLEAR_ERROR_STATE:
      return {
        ...state,
        error: {},
      };
    case auditHistoryTypes.CLEAR_DETAIL_VIEW_SEARCH_STATE:
      return {
        ...state,
        searchAccountList: [],
      };
    case auditHistoryTypes.CLEAR_ALLOCATION_STATE:
      return {
        ...state,
        loadingTransactionList: '',
        accountAllocationList: {},
        searchAccountList: [],
      };

    default:
      return state;
  }
};

export default auditHistoryReducer;
