export const userPageStyle = (theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  nav: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 24px',
  },
});
