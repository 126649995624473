import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Link,
  Typography,
  Divider,
  IconButton,
  Paper,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import UserIcon from '@material-ui/icons/PeopleAltOutlined';
import CardIcon from '@material-ui/icons/CreditCardOutlined';
import LocationIcon from '@material-ui/icons/LocationCityOutlined';
import ActivitiyIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as Logo } from '@assets/logo.svg';
import { Link as RouterLink } from 'react-router-dom';

import NavigationBar from '@components/navigationBar';
import CustomizedSnackBar from '@components/customizedSnackBar';
import * as authActions from '@redux/auth/authActions';
import history from '@utils/history';
import { customization } from '@customization/default';
import { useStyles } from './style';

const links = [
  {
    label: 'Users',
    type: 'primary',
    url: '/admin/dashboard/view-users',
    icon: <UserIcon />,
  },
  {
    label: 'Cards',
    type: 'primary',
    url: '/admin/dashboard/user-card-status',
    icon: <CardIcon />,
  },
  {
    label: 'Locations',
    type: 'primary',
    url: '/admin/dashboard/user-locations',
    icon: <LocationIcon />,
  },
  {
    label: 'Activities',
    type: 'primary',
    url: '/admin/dashboard/user-activities',
    icon: <ActivitiyIcon />,
  },
  {
    label: 'Activity Details',
    type: 'primary',
    url: '/admin/dashboard/user-activities/activity-detail',
    icon: null,
  },
  {
    label: 'Settings',
    type: 'secondary',
    url: '/admin/dashboard/user-settings',
    icon: <SettingsIcon />,
  },
];

function DashboardLayout({ token, children }) {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const renderTitle = () => {
    return links.find((path) => path.url === history.location.pathname).label;
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = (event) => {
    event.preventDefault();
    try {
      authActions.adminLogout(token);
    } catch (error) {
      setSnackbar(true);
      setSnackbarMessage(error);
    }

    history.push('/login');
  };

  return (
    <div className={classes.root}>
      {snackbar ? (
        <CustomizedSnackBar
          classes={classes.snackbar}
          showBar={snackbar}
          severity="error"
          message={snackbarMessage}
          handleClose={() => setSnackbar(false)}
        />
      ) : null}

      {isMobile ? (
        <NavigationBar />
      ) : (
        <>
          <AppBar
            position="fixed"
            color="default"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar className={classes.header}>
              <Logo />
              <Typography variant="h5" noWrap>
                {renderTitle()}
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              {open ? (
                <Typography variant="subtitle1" noWrap>
                  <IconButton onClick={handleDrawerClose}>
                    <MenuOpenIcon />
                  </IconButton>
                  {customization.collapseMenu}
                </Typography>
              ) : (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </div>
            <Divider />
            <List className={classes.topList}>
              {links
                .filter((link) => link.type === 'primary')
                .map(
                  (link) =>
                    link.icon && (
                      <Link
                        key={link.label}
                        className={classes.link}
                        component={RouterLink}
                        to={link.url}
                      >
                        <ListItem
                          button
                          className={
                            history.location.pathname.replace('/activity-detail', '') ===
                            link.url
                              ? classes.active
                              : null
                          }
                        >
                          <ListItemIcon>{link.icon}</ListItemIcon>
                          <ListItemText primary={link.label} />
                        </ListItem>
                      </Link>
                    ),
                )}
            </List>
            <Divider />
            <List className={classes.bottomList}>
              {links
                .filter((link) => link.type === 'secondary')
                .map(
                  (link) =>
                    link.icon && (
                      <Link
                        key={link.label}
                        className={classes.link}
                        component={RouterLink}
                        to={link.url}
                      >
                        <ListItem
                          button
                          className={
                            history.location.pathname === link.url ? classes.active : null
                          }
                        >
                          <ListItemIcon>{link.icon}</ListItemIcon>
                          <ListItemText primary={link.label} />
                        </ListItem>
                      </Link>
                    ),
                )}

              <ListItem button onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </Drawer>
        </>
      )}

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Paper className={classes.tableContainer} elevation={4}>
          {children}
        </Paper>
      </main>
    </div>
  );
}

DashboardLayout.propTypes = {
  authActions: PropTypes.object,
  children: PropTypes.node,
  token: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authAction: bindActionCreators(authActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
