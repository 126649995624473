const AuthTypes = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'LOGOUT_ERROR',

  REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_ERROR: 'REFRESH_TOKEN_ERROR',

  MULTI_FACTOR_AUTHENTICATION_REQUEST: 'MULTI_FACTOR_AUTHENTICATION_REQUEST',
  MULTI_FACTOR_AUTHENTICATION_SUCCESS: 'MULTI_FACTOR_AUTHENTICATION_SUCCESS',
  MULTI_FACTOR_AUTHENTICATION_ERROR: 'MULTI_FACTOR_AUTHENTICATION_ERROR',

  NEW_PASSWORD_REQUEST: 'NEW_PASSWORD_REQUEST',
  NEW_PASSWORD_SUCCESS: 'NEW_PASSWORD_SUCCESS',
  NEW_PASSWORD_ERROR: 'NEW_PASSWORD_ERROR',

  VERIFY_TOKEN_REQUEST: 'VERIFY_TOKEN_REQUEST',
  VERIFY_TOKEN_SUCCESS: 'VERIFY_TOKEN_SUCCESS',
  VERIFY_TOKEN_ERROR: 'VERIFY_TOKEN_ERROR',

  UNLOCK_ACCOUNT_REQ_REQUEST: 'UNLOCK_ACCOUNT_REQ_REQUEST',
  UNLOCK_ACCOUNT_REQ_SUCCESS: 'UNLOCK_ACCOUNT_REQ_SUCCESS',
  UNLOCK_ACCOUNT_REQ_ERROR: 'UNLOCK_ACCOUNT_REQ_ERROR',

  UNLOCK_ACCOUNT_CONFIRM_REQUEST: 'UNLOCK_ACCOUNT_CONFIRM_REQUEST',
  UNLOCK_ACCOUNT_CONFIRM_SUCCESS: 'UNLOCK_ACCOUNT_CONFIRM_SUCCESS',
  UNLOCK_ACCOUNT_CONFIRM_ERROR: 'UNLOCK_ACCOUNT_CONFIRM_ERROR',

  CLEAR_ERROR_STATE: 'CLEAR_ERROR_STATE',
};

export default AuthTypes;
