export const newPasswordPageStyle = (theme) => ({
  errorValidation: {
    color: theme.palette.error.main,
  },
  errorPasswordValidation: {
    display: 'flex',
    justifyContent: 'left',
    '& p': {
      paddingTop: 5,
    },
  },
  focused: {},
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',

    '& > :not(:last-child)': {
      margin: '0.5em 0',
    },
  },
  helperTextError: {
    color: theme.palette.error.main,
  },
  inputPasswordLabel: {
    fontSize: '1.125rem',
    fontWeight: 600,
    '&$focused': {
      color: theme.palette.text.secondary,
    },
    userSelect: 'none',
    paddingTop: 20,
  },
  inputPasswordRoot: {
    'label + &': {
      marginTop: theme.spacing(4),
    },
    userSelect: 'none',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: '1rem',
    width: 'auto',
    padding: '4px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
  header: {
    fontWeight: 800,
    color: theme.palette.grey[900],

    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      flex: 0.45,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
    },

    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '1.75rem !important',
    },

    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '3rem',
    },
  },
  subtitle: {
    marginTop: '0.5em',
    fontSize: '1rem',
    lineHeight: 1.5,

    [theme.breakpoints.down('sm')]: {
      marginTop: '0.15em',
    },

    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '0.875rem !important',
    },

    [theme.breakpoints.between('sm', 'lg')]: {
      marginTop: '0.25em',
      fontSize: '1.25rem',
    },
  },
  strengthLow: {
    backgroundColor: '#CCCCCC',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#D32F2F',
    },
  },
  strengthMedium: {
    backgroundColor: '#CCCCCC',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#FDD835',
    },
  },
  strengthHigh: {
    backgroundColor: '#CCCCCC',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#43A047',
    },
  },
  passwordStrengthInformation: {
    display: 'flex',
    marginTop: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& span': {
      fontSize: '0.75rem',
    },
  },
  passwordStrengthTitleBar: {
    display: 'flex',
    marginTop: 0,
    flexDirection: 'row',
    '& h6': {
      marginRight: '5px',
    },
  },
  popover: {
    pointerEvents: 'none',
    '& ul': {
      listStylePosition: 'inside',
      padding: 0,
      marginBottom: 0,
    },
  },
  popoverPaper: {
    padding: theme.spacing(2),
  },
  strongStyle: {
    color: '#388E3C',
    fontWeight: 600,
  },
  weakStyle: {
    color: '#D32F2F',
    fontWeight: 600,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'baseline',

    '& .MuiIconButton-root': {
      color: theme.palette.grey[900],
    },
  },
});
