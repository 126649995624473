import React from 'react';
import BaseComponent from '@containers/BaseComponent';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import DashboardLayout from '@components/layout/dashboardLayout';
import { apiRequestStatus } from '@data/constants';
import { createUser } from '@services/api/userApi';
import { userProvisionPageStyle } from './style';

class UserProvisionPage extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      user_number: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      email: '',
      mobile_number: '',
      address_1: '',
      address_2: '',
      address_3: '',
      suburb: null,
      state_id: null,
      user_type_id: null,
      company_id: null,
      country_id: null,
      status: 'idle',
      error: null,
      result: null,
    };
  }

  getUserState = () => {
    return {
      user_number: this.state.user_number,
      first_name: this.state.first_name,
      middle_name: this.state.middle_name,
      last_name: this.state.last_name,
      email: this.state.email,
      mobile_number: this.state.mobile_number,
      address_1: this.state.address_1,
      address_2: this.state.address_2,
      address_3: this.state.address_3,
      suburb: this.state.suburb,
      state_id: this.state.state_id === 0 ? null : this.state.state_id,
      user_type_id: this.state.user_type_id,
      company_id: this.state.company_id,
      country_id: this.state.country_id,
    };
  };

  handleRegistration = async () => {
    this.setState({ status: 'loading' });
    try {
      const response = await createUser(this.props.token, this.getUserState());
      this.setState({
        status: apiRequestStatus.RESOLVED,
        result: response.data.message,
      });
    } catch (error) {
      this.setState({
        status: apiRequestStatus.REJECTED,
        error: error.message,
      });
    }
  };

  render() {
    const { status, result, error } = this.state;
    const { classes } = this.props;

    return (
      <DashboardLayout>
        {status === apiRequestStatus.PENDING && <div>Loading</div>}
        {status === apiRequestStatus.REJECTED && <div>{error}</div>}
        {result && status === apiRequestStatus.RESOLVED && <div>{result}</div>}
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              User Registration
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="user_number"
                  name="user_number"
                  label="User Number"
                  fullWidth
                  autoComplete="user-number"
                  defaultValue={this.user_number}
                  onChange={this.handleInputValueChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="mobile_number"
                  name="mobile_number"
                  label="Mobile Number"
                  fullWidth
                  defaultValue={this.mobile_number}
                  onChange={this.handleInputValueChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  fullWidth
                  autoComplete="email"
                  type="email"
                  defaultValue={this.email}
                  onChange={this.handleInputValueChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="first_name"
                  name="first_name"
                  label="First name"
                  fullWidth
                  autoComplete="first-name"
                  defaultValue={this.first_name}
                  onChange={this.handleInputValueChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="middle_name"
                  name="middle_name"
                  label="Middle name"
                  fullWidth
                  autoComplete="middle-name"
                  defaultValue={this.middle_name}
                  onChange={this.handleInputValueChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="last_name"
                  name="last_name"
                  label="Last name"
                  fullWidth
                  autoComplete="family-name"
                  defaultValue={this.last_name}
                  onChange={this.handleInputValueChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="address_1"
                  name="address_1"
                  label="Address line 1"
                  fullWidth
                  autoComplete="shipping address-line1"
                  defaultValue={this.address_1}
                  onChange={this.handleInputValueChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="address_2"
                  name="address_2"
                  label="Address line 2"
                  fullWidth
                  autoComplete="shipping address-line2"
                  defaultValue={this.address_2}
                  onChange={this.handleInputValueChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="address_3"
                  name="address_3"
                  label="Address line 3"
                  fullWidth
                  autoComplete="shipping address-line3"
                  defaultValue={this.address_3}
                  onChange={this.handleInputValueChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="suburb"
                  name="suburb"
                  label="Suburb"
                  fullWidth
                  autoComplete="suburb"
                  defaultValue={this.suburb}
                  onChange={this.handleInputValueChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="state_id"
                  name="state_id"
                  label="State"
                  fullWidth
                  type="number"
                  defaultValue={this.state_id}
                  onChange={this.handleInputWithIntegerValue}
                  inputProps={{
                    min: 0,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="user_type_id"
                  name="user_type_id"
                  label="User Type"
                  fullWidth
                  autoComplete="user type"
                  type="number"
                  defaultValue={this.user_type_id}
                  onChange={this.handleInputWithIntegerValue}
                  inputProps={{
                    min: 1,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="company_id"
                  name="company_id"
                  label="Company"
                  fullWidth
                  autoComplete="company"
                  type="number"
                  defaultValue={this.company_id}
                  onChange={this.handleInputWithIntegerValue}
                  inputProps={{
                    min: 1,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="country_id"
                  name="country_id"
                  label="Country"
                  fullWidth
                  autoComplete="country"
                  type="number"
                  defaultValue={this.country_id}
                  onChange={this.handleInputWithIntegerValue}
                  inputProps={{
                    min: 1,
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleRegistration}
            >
              Register
            </Button>
          </Paper>
        </main>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
  };
};

const withConnect = connect(mapStateToProps);
export default compose(
  withStyles(userProvisionPageStyle),
  withConnect,
)(UserProvisionPage);
