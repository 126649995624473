import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@components/dialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { customization } from '@customization/default';
import { dialogConfirmationStyle, dialogActionStyle } from './style';

const DialogActions = withStyles(dialogActionStyle)(MuiDialogActions);

const ConfirmationStatusDialog = (props) => {
  const {
    classes,
    openConfirmation,
    bindHandleCloseConfirmationStatus,
    message,
    status,
  } = props;
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xs"
        onClose={(e) => bindHandleCloseConfirmationStatus(e, false, status)}
        open={openConfirmation}
        classes={{
          paper: classes.paperWarningMessageStyle,
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={(e) => bindHandleCloseConfirmationStatus(e, false, status)}
          className={classes.dialogTitle}
        ></DialogTitle>
        <DialogContent>
          <Box p={3}>
            <div className={classes.warningMsgContainerStyle}>
              <Typography className={classes.warningMessage}>{message}</Typography>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <Button
                autoFocus
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.confirmProceedStyle}
                onClick={(e) => bindHandleCloseConfirmationStatus(e, true, status)}
              >
                {customization.confirmationMessageDialog.yesButton}
              </Button>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Button
                className={classes.closeButton}
                onClick={(e) => bindHandleCloseConfirmationStatus(e, false, status)}
                variant="outlined"
              >
                {customization.confirmationMessageDialog.noButton}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ConfirmationStatusDialog.propTypes = {
  classes: PropTypes.object,
  openConfirmation: PropTypes.bool,
  bindHandleCloseConfirmationStatus: PropTypes.func,
  message: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default compose(withStyles(dialogConfirmationStyle))(ConfirmationStatusDialog);
