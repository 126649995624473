export const formUserSecondBlockStyle = (theme) => ({
  divStyle: {
    position: 'relative',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  table: {
    minWidth: 500,
    borderCollapse: 'collapse',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  divTableContainer: {
    padding: '2px',
  },
  paperCellStyle: {
    border: '1px solid #2D5326 !important',
    maxHeight: '55px',
    backgroundColor: 'white',
  },
  addSelectedStyle: {
    border: '0px !important',
  },
  actionButtonStyle: {
    backgroundColor: '#FEF6F6',
    border: '1px solid #E184A4',
    borderRadius: '10px',
    height: '30px',
    width: '40%',
  },
  emptyRecordContainer: {
    paddingTop: '10px',
    paddingBottom: '10px',
    border: '1px solid #3F85CA',
    backgroundColor: '#EEF5FB',
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
  },
  divEmptyTableContainer: {
    width: '76%',
    paddingLeft: '25%',
  },
  emptyRecordCaption: {
    fontSize: '14px',
    color: '#5A99D3',
    padding: '0.5rem',
    textAlign: 'center',
  },
  searchInputStyle: {
    marginTop: '0px !important',
    width: '130%',
  },
  checkboxContainer: {
    paddingLeft: '12px',
  },
  tableContainerRootStyle: {
    overflowX: 'inherit !important',
  },
  tableContainerSearchRootStyle: {
    overflowX: 'inherit !important',
  },
  input: {
    borderRadius: 24,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 15,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
  searchBtnStyle: {
    width: '20%',
    position: 'absolute',
    top: '7px',
    left: '110%',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  searchIconStyle: {
    color: '#9A9A9A',
  },
  extraSmallWidthCell: {
    width: '5%',
    paddingTop: '8px',
    borderBottom: '0px',
  },
  extraSmallWidthCellDisabled: {
    width: '5%',
    paddingTop: '8px',
    borderBottom: '0px',
    filter: 'grayscale(1)',
  },
  mediumCellStyle: {
    width: '13%',
    paddingTop: '15px',
    borderBottom: '0px',
  },
  largeCellStyle: {
    width: '18%',
    paddingTop: '15px',
    borderBottom: '0px',
  },
  btnActionCellStyle: {
    width: '5%',
    paddingTop: '12px',
    paddingRight: '16px',
    borderBottom: '0px',
  },
  emptyTableCellStyle: {
    border: '0px',
    padding: '6px',
  },
  paginationStyle: {
    fontSize: '16px !important',
  },
  titleRowStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1 1 auto',
  },
  mediumTitleWidthCell: {
    padding: '12px 12px 12px 12px',
    border: '0px',
    width: '13%',
  },
  largeTitleWidthCell: {
    padding: '12px 12px 12px 12px',
    border: '0px',
    width: '26%',
  },
  extraSmallTitleWidthCell: {
    padding: '12px 12px 12px 12px',
    border: '0px',
    width: '5%',
  },
  formControlLabel: {
    marginLeft: '10px',
    fontSize: '0.7rem',
  },
  containerOveralAdministratorCheckbox: {
    paddingLeft: '15px',
    paddingTop: '10px',
  },
  cancelButton: {
    backgroundColor: '#F7F7F7',
    float: 'left',
    width: '100%',
  },
  inviteButton: {
    float: 'right',
    width: '100%',
  },
  deleteIconStyle: {
    maxHeight: '32px',
  },
  deleteIconStyleGrayscale: {
    maxHeight: '32px',
    filter: 'grayscale(1)',
  },
  confirmationMsgContainerStyle: {
    textAlign: 'center',
  },
  paperConfirmationMessageStyle: {
    border: '1px solid',
  },
  buttonContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  confirmProceedStyle: {
    color: 'white',
    width: '85%',
    float: 'right',
    margin: theme.spacing(3, 0, 2),
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
    },
  },
  closeButton: {
    width: '85%',
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#F7F7F7',
    [theme.breakpoints.down('md')]: {
      margin: '0 auto 1rem',
    },
  },
  emptyResultContainer: {
    minWidth: '300px',
  },
  maximumMessage: {
    color: 'red',
  },
});

export const secondBlockCheckboxStyle = (theme) => ({
  root: {
    color: theme.palette.secondary.main,
    width: '22px',
    height: '22px',
    borderRadius: '3px',
    '&$checked': {
      color: 'white',
      backgroundColor: theme.palette.secondary.main,
    },
  },
  checked: {},
});

export const dialogContentStyle = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
});

export const dialogActionStyle = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
});
