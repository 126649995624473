import { AppBar, Link, Toolbar } from '@material-ui/core';
import UserIcon from '@material-ui/icons/PeopleAltOutlined';
import CardIcon from '@material-ui/icons/CreditCardOutlined';
import LocationIcon from '@material-ui/icons/LocationCityOutlined';
import ActivitiyIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import history from '@utils/history';
import Notification from './notification';
import Sidebar from './sidebar';
import { useStyles } from './style';

const links = [
  {
    label: 'Users',
    type: 'primary',
    url: '/admin/dashboard/view-users',
    icon: <UserIcon />,
  },
  {
    label: 'Cards',
    type: 'primary',
    url: '/admin/dashboard/user-card-status',
    icon: <CardIcon />,
  },
  {
    label: 'Locations',
    type: 'primary',
    url: '/admin/dashboard/user-locations',
    icon: <LocationIcon />,
  },
  {
    label: 'Activities',
    type: 'primary',
    url: '/admin/dashboard/user-activities',
    icon: <ActivitiyIcon />,
  },
  {
    label: 'Activity Details',
    type: 'primary',
    url: '/admin/dashboard/user-activities/activity-detail',
    icon: null,
  },
  {
    label: 'Settings',
    type: 'secondary',
    url: '/admin/dashboard/user-settings',
    icon: <SettingsIcon />,
  },
];

function NavigationBar() {
  const classes = useStyles();

  const renderTitle = () => {
    return links.find((path) => path.url === history.location.pathname).label;
  };

  const renderMobile = () => (
    <>
      <Sidebar links={links} />
      <div className={`${classes.flex} ${classes.mobileTitle}`}>{renderTitle()}</div>
      <div className={classes.mobileNotifications}>
        <Link
          className={
            links.find((path) => !path.icon && history.location.pathname === path.url)
              ? 'active'
              : undefined
          }
          component={RouterLink}
          to="/admin/dashboard/user-activities"
        >
          <Notification />
        </Link>
      </div>
    </>
  );

  return (
    <AppBar className={classes.appbar} position="fixed" color="default">
      <Toolbar className={classes.toolbar}>{renderMobile()}</Toolbar>
    </AppBar>
  );
}

export default NavigationBar;
