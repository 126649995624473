const SettingsTypes = {
  GET_USERTYPELIST_REQUEST: 'GET_USERTYPELIST_REQUEST',
  GET_USERTYPELIST_SUCCESS: 'GET_USERTYPELIST_SUCCESS',
  GET_USERTYPELIST_ERROR: 'GET_USERTYPELIST_ERROR',

  GET_USERTYPE_REQUEST: 'GET_USERTYPE_REQUEST',
  GET_USERTYPE_SUCCESS: 'GET_USERTYPE_SUCCESS',
  GET_USERTYPE_ERROR: 'GET_USERTYPE_ERROR',

  GET_NOTIFICATIONTYPE_REQUEST: 'GET_NOTIFICATIONTYPE_REQUEST',
  GET_NOTIFICATIONTYPE_SUCCESS: 'GET_NOTIFICATIONTYPE_SUCCESS',
  GET_NOTIFICATIONTYPE_ERROR: 'GET_NOTIFICATIONTYPE_ERROR',

  INSERT_USERTYPE_REQUEST: 'INSERT_USERTYPE_REQUEST',
  INSERT_USERTYPE_SUCCESS: 'INSERT_USERTYPE_SUCCESS',
  INSERT_USERTYPE_ERROR: 'INSERT_USERTYPE_ERROR',

  UPDATE_USERTYPE_REQUEST: 'UPDATE_USERTYPE_REQUEST',
  UPDATE_USERTYPE_SUCCESS: 'UPDATE_USERTYPE_SUCCESS',
  UPDATE_USERTYPE_ERROR: 'UPDATE_USERTYPE_ERROR',
};

export default SettingsTypes;
