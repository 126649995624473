import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

import LoadingBackdrop from '@components/loadingBackdrop';
import AlertNotification from '@components/alertNotification';
import { apiRequestStatus, userAccessStatus } from '@data/constants';
import { adminActivateUser } from '@services/api/adminUserAccess';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class UserAccessActivation extends Component {
  state = {
    activationDialog: false,
    userAccessActivationData: {
      status: apiRequestStatus.IDLE,
      data: {},
      error: '',
    },
  };

  userActivation = async (token, userId) => {
    this.setState((state) => ({
      userAccessActivationData: {
        status: apiRequestStatus.PENDING,
      },
    }));

    try {
      const response = await adminActivateUser(token, { activate_user_id: userId });
      if (response?.status === 200) {
        this.setState((state) => ({
          userAccessActivationData: {
            status: apiRequestStatus.RESOLVED,
            data: response.data,
          },
          activationDialog: false,
        }));
      } else {
        const error = new Error(response.statusText);
        error.response = response;
        this.setState((state) => ({
          userAccessActivationData: {
            status: apiRequestStatus.REJECTED,
            error: response.statusText,
          },
          activationDialog: false,
        }));
        throw error;
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      this.setState((state) => ({
        userAccessActivationData: {
          status: apiRequestStatus.REJECTED,
          error: errorMessage,
        },
        activationDialog: false,
      }));
    }
  };

  handleDialog = () => {
    this.setState((state) => ({
      activationDialog: !state.activationDialog,
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    const doRefreshAfterActivationSucceed =
      prevState.userAccessActivationData.status !==
        this.state.userAccessActivationData.status &&
      this.state.userAccessActivationData.status === apiRequestStatus.RESOLVED;

    if (doRefreshAfterActivationSucceed) {
      this.props.getAllUserAccess();
    }
  }

  render() {
    const { activationDialog, userAccessActivationData } = this.state;
    const { token, userId, userAccessControlStatus } = this.props;

    return (
      <div>
        <LoadingBackdrop status={userAccessActivationData.status} />
        <AlertNotification
          status={userAccessActivationData.status}
          error={userAccessActivationData.error}
          data={userAccessActivationData.data}
        />
        <Button
          variant="contained"
          onClick={this.handleDialog}
          disabled={!(userAccessControlStatus === userAccessStatus.DEACTIVATED)}
        >
          Activate User
        </Button>
        <Dialog
          open={activationDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleDialog}
          aria-labelledby="activation-user-dialog"
          aria-describedby="activation-user-dialog-slide-description"
        >
          <DialogContent id="activation-user-dialog">
            {'Are you sure want to activate this User?'}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialog} color="primary">
              No
            </Button>
            <Button onClick={() => this.userActivation(token, userId)} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

UserAccessActivation.propTypes = {
  token: PropTypes.string,
  userId: PropTypes.number,
  getAllUserAccess: PropTypes.func,
  userAccessControlStatus: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
  };
};

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(UserAccessActivation);
