export const useStyles = (theme) => ({
  dialogContent: {
    margin: theme.spacing(1, 2, 2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  dropdown: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  formLabel: {
    position: 'relative',
    color: '#555555',
  },
  formControl: {
    width: '100%',

    '& div .MuiInputBase-multiline': {
      padding: 0,
    },
  },
  menuPaper: {
    maxHeight: 260,
  },
  placeholder: {
    color: 'rgba(0, 0, 0, 0.35)',
  },
});

export const bootstrapStyles = (theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
});
