import {
  getCardList as getCardListService,
  getCardDetailById as getCardDetailByIdService,
} from '@services/api/cards';
import cardTypes from './cardTypes';

export const getCardList = (token, page, size, searchBy, cardStatus, searchText) => {
  return async (dispatch) => {
    dispatch({ type: cardTypes.GET_CARD_LIST_REQUEST });
    try {
      const response = await getCardListService(
        token,
        page,
        size,
        searchBy,
        cardStatus,
        searchText,
      );
      dispatch({ type: cardTypes.GET_CARD_LIST_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: cardTypes.GET_CARD_LIST_ERROR, error });
      return error;
    }
  };
};

export const getCardDetailById = (token, cardId) => {
  return async (dispatch) => {
    dispatch({ type: cardTypes.GET_CARD_DETAIL_REQUEST });
    try {
      const response = await getCardDetailByIdService(token, cardId);
      dispatch({ type: cardTypes.GET_CARD_DETAIL_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: cardTypes.GET_CARD_DETAIL_ERROR, error });
      return error;
    }
  };
};

export const getLinkedCard = (token, page, size, searchBy, cardStatus, searchText) => {
  return async (dispatch) => {
    dispatch({ type: cardTypes.GET_CARD_LIST_REQUEST });
    try {
      const response = await getCardListService(
        token,
        page,
        size,
        searchBy,
        cardStatus,
        searchText,
      );
      dispatch({ type: cardTypes.GET_LINKED_CARD_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: cardTypes.GET_LINKED_CARD_ERROR, error });
      return error;
    }
  };
};

export const resetLinkedCardState = () => {
  return async (dispatch) => {
    dispatch({ type: cardTypes.CLEAR_LINKED_CARD_TRIGGERED });
  };
};

export const cardClearErrorState = () => {
  return async (dispatch) => {
    dispatch({ type: cardTypes.CLEAR_ERROR_STATE });
  };
};
