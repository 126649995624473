import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

import { userAccessStatus } from '@data/constants';
import { userCardStyle } from './style';
function UserCard(props) {
  const { user, classes, children } = props;

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {user.first_name} {user.last_name}
          </Typography>
          <Typography className={classes.pos} variant="h5" component="h2">
            {user?.user_access_control?.access_status || userAccessStatus.NOT_AVAILABLE}
          </Typography>
        </CardContent>
        <CardActions>{children}</CardActions>
      </Card>
    </React.Fragment>
  );
}

UserCard.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object,
  children: PropTypes.node,
};

export default withStyles(userCardStyle)(UserCard);
