import { Typography } from '@material-ui/core';
import { GridOverlay } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { ReactComponent as Empty } from '@assets/icons/Empty.svg';
import { useStyles } from './style';

function EmptyRowsOverlay({ label, search }) {
  const classes = useStyles();

  return (
    <GridOverlay className={classes.root}>
      <Empty style={{ width: '120', height: '100' }} />
      <div className={classes.label}>
        <Typography variant="h4">{label.header}</Typography>
        {search ? <Typography variant="body1">{label.body}</Typography> : null}
      </div>
    </GridOverlay>
  );
}

EmptyRowsOverlay.propTypes = {
  label: PropTypes.object,
  search: PropTypes.bool,
};

export default EmptyRowsOverlay;
