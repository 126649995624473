import { config as defaultConfig } from './env/defaults';
import { config as developmentConfig } from './env/development';
import { config as testConfig } from './env/test';

// Only try this if we're not on Production
let config = defaultConfig;
const envPhase = config.env === 'development' ? developmentConfig : testConfig;

if (process.env.NODE_ENV !== 'production') {
  // Load environment-specific settings
  let localConfig = {};

  try {
    localConfig = envPhase;
  } catch (err) {
    localConfig = {};
  }

  // merge the config files
  // localConfig will override defaults
  config = Object.assign({}, defaultConfig, localConfig);
}

export { config };
