import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { cardDetailLoadingStyle } from './style';

function CardDetailLoading(props) {
  const { classes, gridSize, circularNumber } = props;

  const mapLoadingSize = () => {
    const mappedLoadingSize = [];
    const loadingSize = {
      circularNumber: circularNumber || 1,
      gridSize: gridSize || 12,
    };

    for (let i = 0; i < loadingSize.circularNumber; i++) {
      mappedLoadingSize.push(loadingSize.gridSize);
    }

    return mappedLoadingSize;
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={9}
      className={classes.cardDetailLoadingStyle}
    >
      {mapLoadingSize().map((value, index) => (
        <Grid key={index} item xs={value} sm={value} md={value} lg={value}>
          <CircularProgress color="primary" />
        </Grid>
      ))}
    </Grid>
  );
}

CardDetailLoading.propTypes = {
  classes: PropTypes.object,
  gridSize: PropTypes.number,
  circularNumber: PropTypes.number,
};

export default compose(withStyles(cardDetailLoadingStyle))(CardDetailLoading);
