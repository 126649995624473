import { apiRequestStatus } from '@data/constants';
import activitiesTypes from './activitiesTypes';

const initialState = {
  loading: '',
  loadingList: '',
  activitiesList: {},
  activityDetail: {},
  message: {},
  errorList: {},
  error: {},
};

const activitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET ACTIVITES==============================================================================
    case activitiesTypes.GET_ACTIVITIES_LIST_REQUEST:
      return {
        ...state,
        loadingList: apiRequestStatus.PENDING,
        errorList: {},
      };
    case activitiesTypes.GET_ACTIVITIES_LIST_SUCCESS:
      return {
        ...state,
        loadingList: apiRequestStatus.RESOLVED,
        activitiesList: action.payload,
        errorList: {},
      };
    case activitiesTypes.GET_ACTIVITIES_LIST_ERROR:
      return {
        ...state,
        loadingList: apiRequestStatus.REJECTED,
        errorList: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    // GET ACTIVITY DETAIL==============================================================================
    case activitiesTypes.GET_ACTIVITY_DETAIL_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case activitiesTypes.GET_ACTIVITY_DETAIL_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        activityDetail: action.payload,
        error: {},
      };
    case activitiesTypes.GET_ACTIVITY_DETAIL_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // APPROVE ACTIVITY==============================================================================
    case activitiesTypes.APPROVE_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case activitiesTypes.APPROVE_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        message: action.payload,
        error: {},
      };
    case activitiesTypes.APPROVE_ACTIVITY_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // REJECT ACTIVITY==============================================================================
    case activitiesTypes.REJECT_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case activitiesTypes.REJECT_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        message: action.payload,
        error: {},
      };
    case activitiesTypes.REJECT_ACTIVITY_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    case activitiesTypes.CLEAR_ERROR_STATE:
      return {
        ...state,
        error: {},
      };

    case activitiesTypes.CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
};

export default activitiesReducer;
