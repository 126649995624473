import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import LoginPage from '@containers/loginPage';
import UserPage from '@containers/userPage';
import UserListPage from '@containers/userListPage';
import UserProvisionPage from '@containers/userProvisionPage';
import NotFoundPage from '@containers/notFoundPage';
import ProtectedLayout from '@containers/layout/protectedLayout';
import LayoutRoute from '@containers/layout/layoutRoute';
import UserCardStatusPage from '@containers/userCardStatus/index';
import UserSettingsPage from '@containers/userSettingsPage';
import FirstLogin from '@containers/firstLoginPage';
import NewPasswordPage from '@containers/newPasswordPage';
import ForgotPasswordPage from '@containers/forgotPasswordPage';
import LocationViewPage from '@containers/LocationsViewPage';
import ActivitiesViewPage from '@containers/ActivitiesViewPage';
import LoginUnlockAccountRequestPage from '@containers/loginUnlockAccountRequestPage/index';
import NewUserQnAPage from '@containers/NewUserQnAPage';

function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route path="/admin">
        <ProtectedLayout>
          <Switch>
            <LayoutRoute component={UserPage} path="/admin/dashboard/user" />
            <LayoutRoute
              component={UserProvisionPage}
              path="/admin/dashboard/user-provision"
            />
            <LayoutRoute
              component={UserCardStatusPage}
              path="/admin/dashboard/user-card-status"
            />
            <LayoutRoute
              component={UserSettingsPage}
              path="/admin/dashboard/user-settings"
            />
            <LayoutRoute
              component={LocationViewPage}
              path="/admin/dashboard/user-locations"
            />
            <LayoutRoute
              component={ActivitiesViewPage}
              path="/admin/dashboard/user-activities"
            />
            <LayoutRoute component={UserListPage} path="/admin/dashboard/view-users" />
          </Switch>
        </ProtectedLayout>
      </Route>
      <Route path="/new-password">
        <LayoutRoute component={NewPasswordPage} path="/new-password/:generateKey" />
      </Route>
      <Route path="/unlockAccount">
        <LayoutRoute
          component={LoginUnlockAccountRequestPage}
          path="/unlockAccount/:email"
        />
      </Route>
      <Route path="/new-user">
        <LayoutRoute component={NewUserQnAPage} path="/new-user/:generateKey" />
      </Route>
      <LayoutRoute component={ForgotPasswordPage} path="/forgot-password" />
      <LayoutRoute component={LoginPage} path="/login" />
      <LayoutRoute component={FirstLogin} path="/login-setup" />
      <Route
        render={({ staticContext }) => {
          if (staticContext) staticContext.statusCode = 404;
          return <NotFoundPage />;
        }}
        path="*"
      />
    </Switch>
  );
}

export default Routes;
