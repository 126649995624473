import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MenuRounded';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as Banner } from '@assets/brands/banner.svg';
import history from '@utils/history';
import Logout from './logout';
import { useStyles } from './style';

function Sidebar({ links }) {
  const classes = useStyles();
  const [drawer, setDrawer] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawer(open);
  };

  return (
    <>
      <IconButton
        className={classes.sidebarIcons}
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        className={classes.drawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        open={drawer}
      >
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List className={classes.mainList}>
            <ListItem className={classes.sidebarBrand}>
              <Banner height={48} />
            </ListItem>

            {links.reduce(
              (result, path) =>
                path.type === 'primary' && path.icon
                  ? result.push(
                      <li key={path.url}>
                        <ListItem
                          className={
                            history.location.pathname === path.url ? 'active' : undefined
                          }
                          button
                          component={RouterLink}
                          to={path.url}
                        >
                          <ListItemIcon>{path.icon}</ListItemIcon>
                          <ListItemText primary={path.label} />
                        </ListItem>
                      </li>,
                    ) && result
                  : result,
              [],
            )}
          </List>

          <List>
            {links.reduce(
              (result, path) =>
                path.type === 'secondary' && path.icon
                  ? result.push(
                      <li key={path.url}>
                        <ListItem
                          className={
                            history.location.pathname === path.url ? 'active' : undefined
                          }
                          button
                          component={RouterLink}
                          to={path.url}
                        >
                          <ListItemIcon>{path.icon}</ListItemIcon>
                          <ListItemText primary={path.label} />
                        </ListItem>
                      </li>,
                    ) && result
                  : result,
              [],
            )}
            <li>
              <Logout isListItem />
            </li>
          </List>
        </div>
      </SwipeableDrawer>
    </>
  );
}

Sidebar.propTypes = {
  links: PropTypes.array.isRequired,
};

export default Sidebar;
