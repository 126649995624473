import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 800,
    color: theme.palette.grey[900],
  },
  subtitle: {
    marginTop: '0.5em',
    fontSzie: '1rem',
    lineHeight: 1.5,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
  },
  otp: {
    '& input:focus-visible': {
      outlineColor: '#EB4E88',
    },
  },
  otpContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  otpInput: {
    width: '2rem !important',
    height: '2rem',
    margin: '0.35rem',
    fontSize: '1rem',
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.3)',
    color: '#212121',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
}));
