import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

import LoadingBackdrop from '@components/loadingBackdrop';
import AlertNotification from '@components/alertNotification';
import { apiRequestStatus, userAccessStatus } from '@data/constants';
import { adminDeactivateUser } from '@services/api/adminUserAccess';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class UserAccessDeactivation extends Component {
  state = {
    deactivationDialog: false,
    userAccessDeactivationData: {
      status: apiRequestStatus.IDLE,
      data: {},
      error: '',
    },
  };

  userDeactivation = async (token, userId) => {
    this.setState((state) => ({
      userAccessDeactivationData: {
        status: apiRequestStatus.PENDING,
      },
    }));

    try {
      const response = await adminDeactivateUser(token, { deactivate_user_id: userId });
      if (response?.status === 200) {
        this.setState((state) => ({
          userAccessDeactivationData: {
            status: apiRequestStatus.RESOLVED,
            data: response.data,
          },
          deactivationDialog: false,
        }));
      } else {
        const error = new Error(response.statusText);
        error.response = response;
        this.setState((state) => ({
          userAccessDeactivationData: {
            status: apiRequestStatus.REJECTED,
            error: response.statusText,
          },
          deactivationDialog: false,
        }));
        throw error;
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      this.setState((state) => ({
        userAccessDeactivationData: {
          status: apiRequestStatus.REJECTED,
          error: errorMessage,
        },
        deactivationDialog: false,
      }));
    }
  };

  handleDialog = () => {
    this.setState((state) => ({
      deactivationDialog: !state.deactivationDialog,
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    const doRefreshAfterDeactivationSucceed =
      prevState.userAccessDeactivationData.status !==
        this.state.userAccessDeactivationData.status &&
      this.state.userAccessDeactivationData.status === apiRequestStatus.RESOLVED;

    if (doRefreshAfterDeactivationSucceed) {
      this.props.getAllUserAccess();
    }
  }

  render() {
    const { deactivationDialog, userAccessDeactivationData } = this.state;
    const { token, userId, userAccessControlStatus } = this.props;

    return (
      <div>
        <LoadingBackdrop status={userAccessDeactivationData.status} />
        <AlertNotification
          status={userAccessDeactivationData.status}
          error={userAccessDeactivationData.error}
          data={userAccessDeactivationData.data}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={this.handleDialog}
          disabled={!(userAccessControlStatus !== userAccessStatus.DEACTIVATED)}
        >
          Deactivate User
        </Button>
        <Dialog
          open={deactivationDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleDialog}
          aria-labelledby="deactivation-user-dialog"
          aria-describedby="deactivation-user-dialog-slide-description"
        >
          <DialogContent id="deactivation-user-dialog">
            {'Are you sure want to deactivate this User?'}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialog} color="primary">
              No
            </Button>
            <Button onClick={() => this.userDeactivation(token, userId)} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

UserAccessDeactivation.propTypes = {
  token: PropTypes.string,
  userId: PropTypes.number,
  getAllUserAccess: PropTypes.func,
  userAccessControlStatus: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
  };
};

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(UserAccessDeactivation);
