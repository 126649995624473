export const dialogSuccessStyle = (theme) => ({
  successIcon: {
    color: 'green',
    fontSize: '100px',
  },
  successMessage: {
    color: 'green',
  },
  successIconContainerStyle: {
    textAlign: 'center',
    paddingBottom: '30px',
  },
  successMsgContainerStyle: {
    textAlign: 'center',
    paddingBottom: '20px',
  },
  paperSuccessMessageStyle: {
    border: '2px solid green',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});
