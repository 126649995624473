export const transactionListViewStyle = (theme) => ({
  container: {
    marginTop: 10,
  },
  wrapperTitle: {
    display: 'flex',
  },
  table: {
    minWidth: 500,
    borderCollapse: 'collapse',
  },
  titleRowStyle: {
    display: 'flex',
    flexWrap: 'no-wrap',
    flex: '1 1 auto',
  },
  emptyTableCellStyle: {
    border: '0px',
    padding: '6px',
  },
  commonTitleCellStyle: {
    padding: '12px 12px 12px 12px',
    border: '0px',
    width: '33.3%',
  },
  matchContainer: {
    marginTop: '0.5rem',
  },
  matchIcon: {
    margin: '0 1.5px',
  },
  cellIconLoading: {
    marginLeft: '3px',
    marginBottom: '3px',
    width: '12px !important',
    height: '12px !important',
  },
  searchTitleCellStyle: {
    padding: '12px 12px 12px 12px',
    border: '0px',
    width: '13vw',
  },
  smallSearchTitleCellStyle: {
    padding: '12px 12px 12px 12px',
    border: '0px',
  },
  customWidthCellStyle: {
    padding: '12px 12px 12px 12px',
    border: '0px',
  },
  paperCellStyle: {
    display: 'flex',
    flexWrap: 'no-wrap',
    flex: '1 1 auto',
    maxHeight: '55px',
    marginBottom: '5px',
  },
  paperInActiveContentStyle: {
    border: '2px solid #CF2E56 !important',
    paddingBottom: '1rem',
  },
  paperActiveContentStyle: {
    border: '2px solid #9ABF9B !important',
    paddingBottom: '1rem',
  },
  gridBorderStyle: {
    border: '1px solid #f4f5ff !important',
  },
  auditHistoryCell: {
    border: '1px solid #2D5326 !important',
    display: 'flex',
    flex: '1 1 auto',
    marginBottom: '5px',
  },
  auditHistoryCommonCell: {
    width: '50%',
  },
  auditHistoryActionCell: {
    width: '50%',
  },
  commonCellStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '16.67%',
    paddingTop: '15px',
    borderBottom: '0px',
    '& img': {
      height: '30px',
      marginRight: '25px',
    },
  },
  searchResultCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '13vw',
    paddingTop: '15px',
    borderBottom: '0px',
  },
  smallSearchResultCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '15px',
    borderBottom: '0px',
  },
  italicString: {
    fontStyle: 'italic',
  },
  transactionAuditCell: {
    padding: '12px 12px 12px 12px',
    border: '0px',
    width: '25%',
  },
  subsectionDividerStyle: {
    display: 'flex',
    flexWrap: 'no-wrap',
  },
  auditHistoryWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  auditHistoryTitleWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 50,
  },
  auditHistoryTableContainer: {
    maxHeight: '405px',
    padding: '15px',
  },
  transactionReceiptOuterWrapper: {
    display: 'flex',
    padding: '0px',
  },
  transactionSectionTopOuterContainer: {
    display: 'flex',
    minHeight: 100,
    padding: '0',
  },
  transactionAllocationInnerWrapper: {
    display: 'flex',
    minWidth: '65%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '335px',
    position: 'relative',
  },
  transactionAllocationListOuterWrapper: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  transactionAllocationListInnerWrapper: {
    maxHeight: '200px',
    overflowY: 'scroll',
  },
  transactionAllocationItemWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '70px',
    borderBottom: 'solid whitesmoke 1px',
  },
  percentageInput: {
    maxWidth: '100px',
    '& .MuiOutlinedInput-input': {
      textAlign: 'right',
      padding: '8px 0px !important',
    },
  },
  amountInput: {
    maxWidth: '120px',
    '& .MuiOutlinedInput-input': {
      padding: '8px 14px !important',
    },
  },
  spendCategoryInput: {
    minWidth: 200,
    '& .MuiOutlinedInput-input': {
      padding: '8px 14px !important',
    },
  },
  fileUploadInput: { display: 'none' },
  receiptPaginationContainer: { display: 'flex', marginLeft: '5%', paddingTop: '8px' },
  transcationAllocationButtonWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '0 10px',
    paddingTop: '18px',
  },
  changeAllocationButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '0 10px',
  },
  changeAllocationButton: {
    margin: '3px 15px',
    minWidth: '100px',
  },
  searchLocationDefaultButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 0,
  },
  searchCardWrapper: {
    position: 'absolute',
    top: '55%',
    left: '25%',
    zIndex: '1',
  },
  searchCardContentWrapper: {
    padding: 16,
    maxHeight: '302px',
  },
  searchLocationAddButton: {
    margin: '3px 8px 0 0',
    minWidth: '120px',
  },
  searchLocationCancelButton: {
    margin: '3px 8px 0 0',
    minWidth: '120px',
    backgroundColor: '#F5F5F5',
  },
  removeTransactionSplitSubheader: {
    display: 'flex',
    justifyContent: 'center',
    border: 'solid #2185D0 1px',
    borderRadius: '5px',
    width: '80%',
    marginTop: '35px',
  },
  singleTransactionAllocationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '15px',
    height: '100%',
  },
  receiptOuterWrapper: {
    display: 'flex',
    minWidth: '35%',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    border: '1px solid #f4f5ff !important',
  },
  transactionSectionTopInnerContainer: {
    border: '1px solid #f4f5ff !important',
    display: 'flex',
    padding: 0,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  spendCategoryContainer: {
    minWidth: '65%',
  },
  disputeExpenseContainer: {
    minWidth: '35%',
  },
  buttonSection: {
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: '100%',
  },
  bottomButton: {
    margin: '10px 10px 10px 0px',
    minWidth: '150px',
  },
  bottomBackButton: {
    margin: '10px 10px 10px 0px',
    backgroundColor: '#F5F5F5',
    minWidth: '150px',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  searchBarFormWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '30px',
  },
  searchBar: {
    backgroundColor: 'white',
    borderRadius: 25,
    width: '90%',
    padding: '4px 25px 3px 25px',
    border: 'solid grey 1px',
  },
  activePagination: {
    textDecoration: 'underline',
    margin: '0 3px',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  inactivePagination: {
    margin: '0 3px',
    cursor: 'pointer',
    color: '#C1C1C1',
  },
  receiptImageContainer: {
    width: '140px',
    height: '150px',
    cursor: 'pointer',

    '& .react-pdf__Document': {
      height: '100%',
    },
    '& .react-pdf__message ': {
      height: '100%',
    },
  },
  receiptImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  receiptPdf: {
    width: '42px',
    height: '100%',
    display: 'flex',
    margin: 'auto',
  },
  receiptImageDetail: {
    maxHeight: '100vh',
  },
  receiptContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listAction: {
    listStyleType: 'none',
    paddingLeft: 0,
    marginBottom: 0,
  },
  transactionTabStyle: {
    width: '100%',
    paddingTop: '30px',
  },
  transactionButtonTabStyle: {
    minWidth: '33%',
  },
  transactionButtonSelectedStyle: {
    color: '#E10454 !important',
  },
  receiptTaxTitleStyle: {
    position: 'absolute',
    top: '10px',
  },
  allocationTitleStyle: {
    position: 'absolute',
    top: '10px',
  },
  uploadBtnContainer: {
    marginTop: '36px',
  },
  detailImageModalStyle: {
    position: 'relative',
    left: '25%',
    top: '25%',
  },
  modalDetailImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonColorStyle: {
    backgroundColor: '#F5F5F5',
  },
  buttonAddRemoveAccountStyle: {
    fontSize: '0.75rem !important',
    backgroundColor: '#F5F5F5',
  },
  buttonSubmit: {
    fontSize: '0.75rem !important',
  },
  fontAccountList: {
    width: 200,
    marginRight: 15,
    wordBreak: 'break-all',
  },
  fontAccountListEqual: {
    width: 200,
    marginLeft: 70,
    marginRight: 70,
  },
  emptyTransactionAuditListPlaceholder: {
    width: '100%',
    textAlign: 'center',
    padding: '20px 0',
    margin: '5px',
    color: '#9B9B9B',
  },
  confirmDialogPaper: {
    border: '2px solid #ff662c',
  },
  confirmDialogExclamationIconContainter: {
    textAlign: 'center',
  },
  confirmDialogAlertIcon: {
    maxHeight: '100px',
    marginBottom: '24px',
  },
  confirmDialogMessage: {
    color: '#ff662c',
    textAlign: 'center',
  },
  confirmDialogAcceptButton: {
    backgroundColor: '#ff662c',
    color: 'white',
    width: '85%',
    float: 'right',
    margin: theme.spacing(3, 0, 2),
    [theme.breakpoints.down('lg')]: {
      width: 'auto',
    },
  },
  confirmDialogCloseButton: {
    width: '85%',
    margin: theme.spacing(3, 0, 2),
    [theme.breakpoints.down('lg')]: {
      width: 'auto',
    },
  },
  clientCaptionContainer: {
    margin: 'auto auto',
    wordBreak: 'break-all',
  },
  searchResultWrapper: {
    maxHeight: '200px',
  },
  loadingStyle: {
    position: 'relative',
    left: '31vw',
  },
  roundedButton: {
    borderRadius: '8px',
  },
});

export const dialogContentStyle = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
});

export const dialogActionStyle = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
});
