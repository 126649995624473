import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { customization } from '@customization/default';
import SuccessMessageDialog from '@components/successMessageDialog';
import ErrorMessageDialog from '@components/errorMessageDialog';
import FormRootLocation from '@components/formRootLocation';
import {
  getLocationById as getLocationByIdService,
  putLocationData as putLocationDataService,
} from '@services/api/locationApi';
import { editLocationDialogStyle } from './style';

function EditLocationDialog(props) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [newLocationData, setNewLocationData] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState({ isError: false, message: '' });

  const { classes, bindCloseEditDialog, locationData, token } = props;

  React.useEffect(() => {
    const handleClickOpen = async () => {
      try {
        if (props.openEditDialog) {
          const { id } = locationData;
          const response = await getLocationByIdService(id, token);
          setNewLocationData({ ...response.data });
        }
      } catch (error) {
        setErrorMessage({
          isError: true,
          message: error.message,
        });
      }
    };
    handleClickOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openEditDialog]);

  const handleEditLocation = async (values, token) => {
    try {
      await putLocationDataService(values, token);
      setSuccessMessage(customization.addEditLocation.editLocationMessage);
      bindCloseEditDialog();
      setOpenSuccess(true);
    } catch (error) {
      setErrorMessage({
        isError: true,
        message: error.response.data.message || error.message,
      });
    }
  };

  const handleErrorRetrieveData = (errorMessage) => {
    setErrorMessage({
      isError: true,
      message: errorMessage,
    });
  };

  const handleCloseErrorMessage = () => {
    setErrorMessage({
      isError: false,
      message: '',
    });
  };

  const handleClose = () => {
    bindCloseEditDialog();
    setOpenSuccess(false);
  };

  return (
    <div>
      <SuccessMessageDialog
        message={successMessage}
        openSuccess={openSuccess}
        bindHandleClose={handleClose}
        className={classes.roundedButton}
      />
      <ErrorMessageDialog
        bindHandleClose={handleCloseErrorMessage}
        openSuccess={errorMessage.isError}
        errorData={errorMessage.message}
      />
      <Dialog
        fullWidth
        maxWidth="md"
        open={props.openEditDialog}
        aria-labelledby="form-dialog-title"
      >
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h6" className={classes.addUserDialogStyle}>
                {customization.addEditLocation.labelEditLocationTitle}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
        <DialogContent>
          <FormRootLocation
            operationTag="editLocation"
            locationData={newLocationData}
            bindErrorRetrieve={handleErrorRetrieveData}
            bindSubmit={handleEditLocation}
            bindClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

EditLocationDialog.propTypes = {
  classes: PropTypes.object,
  postUserData: PropTypes.func,
  locationData: PropTypes.object,
  token: PropTypes.string,
  openEditDialog: PropTypes.bool,
  bindCloseEditDialog: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
  };
};

const withConnect = connect(mapStateToProps, null);
export default compose(
  withStyles(editLocationDialogStyle),
  withConnect,
)(EditLocationDialog);
