import axiosInstance from '@config/axios';
import { config } from '@config/index';

const get = (endpoint, token = null) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axiosInstance.get(`${config.api_server}${endpoint}`);
};

const post = (endpoint, params = null, token = null, options = null) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;

  return axiosInstance.post(`${config.api_server}${endpoint}`, params, options);
};

const postWithoutBody = (endpoint, token = null) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axiosInstance.post(`${config.api_server}${endpoint}`);
};

const patch = (endpoint, params = null, token = null) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axiosInstance.patch(`${config.api_server}${endpoint}`, params);
};

const put = (endpoint, params = null, token = null) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axiosInstance.put(`${config.api_server}${endpoint}`, params);
};

const deleteRequest = (endpoint, token = null) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axiosInstance.delete(`${config.api_server}${endpoint}`);
};

export { get, post, postWithoutBody, patch, put, deleteRequest };
