const ActivityTypes = {
  GET_ACTIVITIES_LIST_REQUEST: 'GET_ACTIVITIES_LIST_REQUEST',
  GET_ACTIVITIES_LIST_SUCCESS: 'GET_ACTIVITIES_LIST_SUCCESS',
  GET_ACTIVITIES_LIST_ERROR: 'GET_ACTIVITIES_LIST_ERROR',

  GET_ACTIVITY_DETAIL_REQUEST: 'GET_ACTIVITY_DETAIL_REQUEST',
  GET_ACTIVITY_DETAIL_SUCCESS: 'GET_ACTIVITY_DETAIL_SUCCESS',
  GET_ACTIVITY_DETAIL_ERROR: 'GET_ACTIVITY_DETAIL_ERROR',

  APPROVE_ACTIVITY_REQUEST: 'APPROVE_ACTIVITY_REQUEST',
  APPROVE_ACTIVITY_SUCCESS: 'APPROVE_ACTIVITY_SUCCESS',
  APPROVE_ACTIVITY_ERROR: 'APPROVE_ACTIVITY_ERROR',

  REJECT_ACTIVITY_REQUEST: 'REJECT_ACTIVITY_REQUEST',
  REJECT_ACTIVITY_SUCCESS: 'REJECT_ACTIVITY_SUCCESS',
  REJECT_ACTIVITY_ERROR: 'REJECT_ACTIVITY_ERROR',

  CLEAR_ERROR_STATE: 'CLEAR_ERROR_STATE',
  CLEAR_STATE: 'CLEAR_STATE',
};

export default ActivityTypes;
