import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import { ActivityDetailPageStyle } from './style';

function ParentDelegationMatrix({ classes, field }) {
  return (
    <div className={classes.parentDelegationWrapper}>
      {field.value?.length > 0 && <span>{field.displayName}:</span>}
      {field.value?.map((item, itemIndex) => {
        return (
          <div
            key={itemIndex}
            className={[
              classes.parentDelegationCell,
              itemIndex !== 0 ? classes.parentDelegationBorder : '',
            ].join(' ')}
          >
            <RadioButtonUncheckedRoundedIcon
              style={{ color: 'cyan' }}
              className={classes.cellContentIconStyle}
            />
            <span className={classes.cellContentPropertyValueStyle}>
              <b>{item?.value}</b>
            </span>
          </div>
        );
      })}
    </div>
  );
}

ParentDelegationMatrix.propTypes = {
  classes: PropTypes.object,
  field: PropTypes.object,
};

export default compose(withStyles(ActivityDetailPageStyle))(ParentDelegationMatrix);
