import { Badge, Tooltip } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/NotificationsNoneRounded';
import { customization } from '@customization/default';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from '@utils/history';
import * as userActions from '@redux/user/userActions';
import { useStyles } from './style';

const Notification = (props) => {
  const classes = useStyles();

  const { canApprove, counter, token, userAction } = props;

  useEffect(() => {
    async function getNotification() {
      try {
        userAction.getUserNotification(token);
      } catch (error) {
        history.push('/admin/dashboard/view-users');
      }
    }

    getNotification();
  }, [userAction, token]);

  return (
    <>
      {canApprove ? (
        <Tooltip
          classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
          title={customization.activitiesView.notificationMessage}
          placement="bottom-start"
          arrow
        >
          <Badge
            className={classes.notificationBadge}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={counter}
            max={99}
            color="primary"
          >
            <NotificationsIcon className={classes.notificationIcon} />
          </Badge>
        </Tooltip>
      ) : null}
    </>
  );
};

Notification.propTypes = {
  canApprove: PropTypes.bool,
  counter: PropTypes.number,
  token: PropTypes.string,
  userAction: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    canApprove: state.user.notification.canApprove,
    counter: state.user.notification.counter,
    token: state.auth.inMemoryToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userAction: bindActionCreators(userActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
