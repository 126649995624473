import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';

import { customization } from '@customization/default';
import SuccessMessageDialog from '@components/successMessageDialog';
import ErrorMessageDialog from '@components/errorMessageDialog';
import FormRootLocation from '@components/formRootLocation';
import { postAddLocation } from '@services/api/locationApi';
import { addLocationDialogStyle } from './style';

function AddLocationDialog(props) {
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState({ isError: false, message: '' });

  const { classes } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAddLocation = async (values, token) => {
    try {
      const response = await postAddLocation(values, token);
      if (response.status === 200) {
        setSuccessMessage(customization.addEditLocation.addLocationMessage);
        setOpen(false);
        setOpenSuccess(true);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setErrorMessage({
        isError: true,
        message: error.response.data.message || error.message,
      });
    }
  };

  const handleErrorRetrieveData = (errorMessage) => {
    setErrorMessage({
      isError: true,
      message: errorMessage,
    });
  };

  const handleCloseErrorMessage = () => {
    setErrorMessage({
      isError: false,
      message: '',
    });
  };

  const handleClose = () => {
    setOpen(false);
    setOpenSuccess(false);
  };

  return (
    <div>
      <SuccessMessageDialog
        message={successMessage}
        openSuccess={openSuccess}
        bindHandleClose={handleClose}
        className={classes.roundedButton}
      />
      <ErrorMessageDialog
        bindHandleClose={handleCloseErrorMessage}
        openSuccess={errorMessage.isError}
        errorData={errorMessage.message}
      />
      <Button
        className={classes.button}
        color="primary"
        disableElevation
        size="large"
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
      >
        {customization.addEditLocation.labelAddLocationTitle}
      </Button>
      <Dialog fullWidth maxWidth="md" open={open} aria-labelledby="form-dialog-title">
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h6" className={classes.addLocationDialogStyle}>
                {customization.addEditLocation.labelAddLocationTitle}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
        <DialogContent>
          <FormRootLocation
            operationTag="addLocation"
            bindErrorRetrieve={handleErrorRetrieveData}
            bindSubmit={handleAddLocation}
            bindClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

AddLocationDialog.propTypes = {
  classes: PropTypes.object,
  postUserData: PropTypes.func,
};

export default compose(withStyles(addLocationDialogStyle))(AddLocationDialog);
