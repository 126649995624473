import { post, postWithoutBody } from './baseApi';

const adminSignIn = async (token, params) => {
  return post('/admin/sign-in', params, token);
};

const adminSignInWithAuthenticationCode = async (token, params) => {
  return post('/admin/sign-in/authentication-code/verification', params, token);
};

const adminResendAuthenticationCode = async (token) => {
  return postWithoutBody('/admin/sign-in/authentication-code/resend', token);
};

const adminSignOut = async (token) => {
  return postWithoutBody('/admin/sign-out', token);
};

const adminRefreshToken = async (token) => {
  return postWithoutBody('/admin/refresh-token', token);
};

const adminForgotPassword = async (params) => {
  return post('/admin/password/forgot', params);
};

const adminVerifyToken = async (params) => {
  return post('/admin/password/verify-token', params);
};

const adminResetPassword = async (token, params) => {
  return post('/admin/password/reset', params, token);
};

const adminUnlockRequest = async (params) => {
  return post('/admin/unlock-account/request', params);
};

const adminUnlockConfirm = async (params) => {
  return post('/admin/unlock-account/confirm', params);
};

const adminVerifyTokenSetNewPassword = async (params) => {
  return post('/admin/credential/verify-token', params);
};

const adminSetNewPasswordSendEmail = async (params) => {
  return post('/admin/credential/send-email', params);
};

const adminSetNewPassword = async (token, params) => {
  return post('/admin/credential/setup', params, token);
};

const adminVerifyPassword = async (token, password) => {
  return post('/admin/verify-admin', { password }, token);
};

export {
  adminSignInWithAuthenticationCode,
  adminSignIn,
  adminResendAuthenticationCode,
  adminSignOut,
  adminRefreshToken,
  adminForgotPassword,
  adminVerifyToken,
  adminResetPassword,
  adminUnlockRequest,
  adminUnlockConfirm,
  adminVerifyTokenSetNewPassword,
  adminSetNewPasswordSendEmail,
  adminSetNewPassword,
  adminVerifyPassword,
};
