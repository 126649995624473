const UserTypes = {
  GET_USER_LIST_REQUEST: 'GET_USER_LIST_REQUEST',
  GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
  GET_USER_LIST_ERROR: 'GET_USER_LIST_ERROR',

  GET_USER_ACCESS_CREDENTIAL_REQUEST: 'GET_USER_ACCESS_CREDENTIAL_REQUEST',
  GET_USER_ACCESS_CREDENTIAL_SUCCESS: 'GET_USER_ACCESS_CREDENTIAL_SUCCESS',
  GET_USER_ACCESS_CREDENTIAL_ERROR: 'GET_USER_ACCESS_CREDENTIAL_ERROR',
  RESET_USER_ACCESS_CREDENTIAL: 'RESET_USER_ACCESS_CREDENTIAL',

  GET_USER_MASTER_DATA_REQUEST: 'GET_USER_MASTER_DATA_REQUEST',
  GET_USER_MASTER_DATA_SUCCESS: 'GET_USER_MASTER_DATA_SUCCESS',
  GET_USER_MASTER_DATA_ERROR: 'GET_USER_MASTER_DATA_ERROR',

  SEND_USER_UNLOCK_PIN_REQUEST: 'SEND_USER_UNLOCK_PIN_REQUEST',
  SEND_USER_UNLOCK_PIN_SUCCESS: 'SEND_USER_UNLOCK_PIN_SUCCESS',
  SEND_USER_UNLOCK_PIN_ERROR: 'SEND_USER_UNLOCK_PIN_ERROR',

  POST_USER_DATA_REQUEST: 'POST_USER_DATA_REQUEST',
  POST_USER_DATA_SUCCESS: 'POST_USER_DATA_SUCCESS',
  POST_USER_DATA_ERROR: 'POST_USER_DATA_ERROR',

  GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_ERROR: 'GET_NOTIFICATIONS_ERROR',

  CLEAR_ERROR_STATE: 'CLEAR_ERROR_STATE',

  GET_TRANSACTION_LIST_REQUEST: 'GET_TRANSACTION_LIST_REQUEST',
  GET_TRANSACTION_LIST_SUCCESS: 'GET_TRANSACTION_LIST_SUCCESS',
  GET_TRANSACTION_LIST_ERROR: 'GET_TRANSACTION_LIST_ERROR',

  GET_LOCATION_LIST_REQUEST: 'GET_LOCATION_LIST_REQUEST',
  GET_LOCATION_LIST_SUCCESS: 'GET_LOCATION_LIST_SUCCESS',
  GET_LOCATION_LIST_ERROR: 'GET_LOCATION_LIST_ERROR',

  GET_COMPANY_LIST_REQUEST: 'GET_COMPANY_LIST_REQUEST',
  GET_COMPANY_LIST_SUCCESS: 'GET_COMPANY_LIST_SUCCESS',
  GET_COMPANY_LIST_ERROR: 'GET_COMPANY_LIST_ERROR',

  GET_CARDTYPE_BY_USERTYPEID_REQUEST: 'GET_CARDTYPE_BY_USERTYPEID_REQUEST',
  GET_CARDTYPE_BY_USERTYPEID_SUCCESS: 'GET_CARDTYPE_BY_USERTYPEID_SUCCESS',
  GET_CARDTYPE_BY_USERTYPEID_ERROR: 'GET_CARDTYPE_BY_USERTYPEID_ERROR',

  GET_LOCATION_GROUP_REQUEST: 'GET_LOCATION_GROUP_REQUEST',
  GET_LOCATION_GROUP_SUCCESS: 'GET_LOCATION_GROUP_SUCCESS',
  GET_LOCATION_GROUP_ERROR: 'GET_LOCATION_GROUP_ERROR',

  GET_SECURITY_QUESTIONS_REQUEST: 'GET_SECURITY_QUESTIONS_REQUEST',
  GET_SECURITY_QUESTIONS_SUCCESS: 'GET_SECURITY_QUESTIONS_SUCCESS',
  GET_SECURITY_QUESTIONS_ERROR: 'GET_SECURITY_QUESTIONS_ERROR',

  PUT_LOCATION_GROUP_REQUEST: 'PUT_LOCATION_GROUP_REQUEST',
  PUT_LOCATION_GROUP_SUCCESS: 'PUT_LOCATION_GROUP_SUCCESS',
  PUT_LOCATION_GROUP_ERROR: 'PUT_LOCATION_GROUP_ERROR',

  GET_STATE_BY_COUNTRY_REQUEST: 'GET_STATE_BY_COUNTRY_REQUEST',
  GET_STATE_BY_COUNTRY_SUCCESS: 'GET_STATE_BY_COUNTRY_SUCCESS',
  GET_STATE_BY_COUNTRY_ERROR: 'GET_STATE_BY_COUNTRY_ERROR',

  POST_LOCATION_GROUP_REQUEST: 'POST_LOCATION_GROUP_REQUEST',
  POST_LOCATION_GROUP_SUCCESS: 'POST_LOCATION_GROUP_SUCCESS',
  POST_LOCATION_GROUP_ERROR: 'POST_LOCATION_GROUP_ERROR',

  GET_USER_LOCATION_DELEGATION_REQUEST: 'GET_USER_LOCATION_DELEGATION_REQUEST',
  GET_USER_LOCATION_DELEGATION_SUCCESS: 'GET_USER_LOCATION_DELEGATION_SUCCESS',
  GET_USER_LOCATION_DELEGATION_ERROR: 'GET_USER_LOCATION_DELEGATION_ERROR',

  GET_TIME_ZONE_LIST_REQUEST: 'GET_TIME_ZONE_LIST_REQUEST',
  GET_TIME_ZONE_LIST_SUCCESS: 'GET_TIME_ZONE_LIST_SUCCESS',
  GET_TIME_ZONE_LIST_ERROR: 'GET_TIME_ZONE_LIST_ERROR',

  GET_LINKED_USER_REQUEST: 'GET_LINKED_USER_REQUEST',
  GET_LINKED_USER_SUCCESS: 'GET_LINKED_USER_SUCCESS',
  GET_LINKED_USER_ERROR: 'GET_LINKED_USER_ERROR',
  CLEAR_LINKED_USER_TRIGGERED: 'CLEAR_LINKED_USER_TRIGGERED',

  VERIFY_ADMIN_PASSWORD_REQUEST: 'VERIFY_ADMIN_PASSWORD_REQUEST',
  VERIFY_ADMIN_PASSWORD_SUCCESS: 'VERIFY_ADMIN_PASSWORD_SUCCESS',
  VERIFY_ADMIN_PASSWORD_ERROR: 'VERIFY_ADMIN_PASSWORD_ERROR',

  GET_USER_DETAILS_REQUEST: 'GET_USER_DETAILS_REQUEST',
  GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_ERROR: 'GET_USER_DETAILS_ERROR',

  GET_USER_LOCATION_REQUEST: 'GET_USER_LOCATION_REQUEST',
  GET_USER_LOCATION_SUCCESS: 'GET_USER_LOCATION_SUCCESS',
  GET_USER_LOCATION_ERROR: 'GET_USER_LOCATION_ERROR',

  RESEND_SUBSCRIPTION_TOKEN_REQUEST: 'RESEND_SUBSCRIPTION_TOKEN_REQUEST',
  RESEND_SUBSCRIPTION_TOKEN_SUCCESS: 'RESEND_SUBSCRIPTION_TOKEN_SUCCESS',
  RESEND_SUBSCRIPTION_TOKEN_ERROR: 'RESEND_SUBSCRIPTION_TOKEN_ERROR',
};

export default UserTypes;
