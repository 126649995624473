import { grey, lightBlue, green, orange, red } from '@material-ui/core/colors';

export const ActivityDetailPageStyle = (theme) => ({
  divStyle: {
    paddingTop: 24,
  },
  approveBtnStyle: {
    backgroundColor: '#8BC34A',
    color: 'white',
    width: '100%',

    '&:hover': {
      backgroundColor: '#689F38',
    },
  },
  rejectBtnStyle: {
    backgroundColor: '#F44336',
    color: 'white',
    width: '100%',

    '&:hover': {
      backgroundColor: '#D32F2F',
    },
  },
  backBtnStyle: {
    color: '#616161',
    border: '1px solid #9E9E9E',
    width: '100%',
  },
  tabsPaper: {
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: 5,
    height: '100%',
  },
  pageTitle: {
    fontSize: 28,
    paddingLeft: '22px',
    fontWeight: 'bold',
  },
  submittedStyle: {
    color: '#EE9674',
    marginLeft: '20px',
    marginTop: '3px',
    fontSize: '40px',
  },
  activityStatusContainer: {
    padding: theme.spacing(1, 0.25, 1, 1),

    '& svg': {
      margin: theme.spacing(0.75, 0, 0, 1),

      '& circle': {
        stroke: grey[900],
      },

      '& path': {
        fill: grey[900],
      },

      '&.info': {
        '& circle': {
          stroke: lightBlue[600],
        },

        '& path': {
          fill: lightBlue[600],
        },
      },

      '&.success': {
        '& circle': {
          stroke: green[600],
        },

        '& path': {
          fill: green[600],
        },
      },

      '&.warning': {
        '& circle': {
          stroke: orange[600],
        },

        '& path': {
          fill: orange[600],
        },
      },

      '&.error': {
        '& circle': {
          stroke: red[600],
        },

        '& path': {
          fill: red[600],
        },
      },
    },

    '& div.title': {
      marginTop: theme.spacing(1),
      fontSize: 16,
      fontWeight: 600,

      '&.info': {
        color: lightBlue[600],
      },

      '&.success': {
        color: green[600],
      },

      '&.error': {
        color: red[600],
      },
    },
  },
  activityTypeStyle: {
    color: ' #2E5426',
    marginLeft: '20px',
    marginTop: '3px',
    fontSize: '40px',
  },
  activityTypeCaptionContainer: {
    color: '#2E5426',
    marginTop: '3px',
    fontSize: '16px',
  },
  activityTabsContainer: {
    paddingTop: '30px',
  },
  submittedDateStyle: {
    color: '#EE9674',
    marginLeft: '20px',
    marginTop: '3px',
    fontSize: '40px',
  },
  submittedDateCaptionContainer: {
    color: '#EE9674',
    marginTop: '3px',
    fontSize: '16px',
  },
  rejectedDateCaptionContainer: {
    color: '#CF2E56',
    marginTop: '3px',
    fontSize: '16px',
  },
  headingOneStyle: {
    minHeight: '253px',
    paddingLeft: '8px',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingRight: '2px',
    width: '100%',

    '& .MuiGrid-container .MuiGrid-item': {
      '& svg': {
        margin: theme.spacing(0.75, 0, 0, 1),

        '& circle': {
          stroke: grey[900],
        },

        '& path': {
          fill: grey[900],
        },
      },

      '& p': {
        color: grey[900],
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(18),
      },
    },
  },
  activityIndicatorIcon: {
    color: '#50525F',
    marginLeft: '20px',
    marginTop: '3px',
    fontSize: '40px',
  },
  activityIndicatorCaption: {
    color: '#50525F',
    paddingLeft: theme.spacing(3.5),
    marginTop: theme.spacing(1.5),
    fontSize: 16,
  },
  alertIconStyle: {
    maxHeight: '100px',
    marginBottom: '24px',
  },
  tabContentStyle: {
    padding: theme.spacing(3.75, 3.75, 0),
    width: '100%',
  },
  gridContentStyle: {
    '& .MuiGrid-item': {
      paddingBottom: theme.spacing(0.75),
    },

    '&:not(:first-child)': {
      '& .MuiGrid-item .row-spacing': {
        paddingTop: theme.spacing(1.5),
      },
    },
  },
  tabSpecificContentStyle: {
    // padding: '30px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  gridSpecificContentStyle: {
    paddingBottom: '15px',
    flex: 1,
  },
  gridDelegationContentStyle: {
    // marginLeft: '50px',
  },
  gridSecondSpecificContentStyle: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  notFirstSpecificContentStyle: {
    paddingTop: '15px',
  },
  specificContentStyle: {
    paddingTop: '15px',
    paddingBottom: '15px',
    justifyContent: 'space-between',
    display: 'flex',
  },
  delegationContentStyle: {
    justifyContent: 'flex-start',
    width: '75%',
  },
  childDelegationContentStyle: {
    // marginLeft: '50px',
  },
  borderContentStyle: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  cellContentPropertyStyle: {
    display: 'flex',
  },
  cellDelegationContentPropertyStyle: {
    minWidth: '250px',
  },
  cellChildDelegationContentPropertyStyle: {
    minWidth: '200px',
  },
  cellContentPropertyValueStyle: {
    marginLeft: '10px',
  },
  cellContentIconStyle: {
    width: '20px',
    height: '20px',
    position: 'relative',
  },
  contentValueStyle: {
    wordBreak: 'break-word',
  },
  contentDisplayStyle: {
    display: 'block',
  },
  paperRejectMessageStyle: {
    border: '2px solid #EE794F',
  },
  paperConfirmationMessageStyle: {
    border: '2px solid #1E561F',
  },
  exclamationIconContainterStyle: {
    textAlign: 'center',
  },
  exclamationIcon: {
    color: '#EE794F',
    fontSize: '100px',
  },
  rejectMsgStyle: {
    color: '#EE794F',
  },
  approveMsgStyle: {
    color: '#1E561F',
  },
  submit: {
    width: '100%',
    float: 'right',
    margin: theme.spacing(3, 0, 1),
  },
  approveProceedStyle: {
    backgroundColor: '#1E561F',
    color: 'white',
    width: '100%',
    float: 'right',
    margin: theme.spacing(3, 0, 1),
  },
  closeButton: {
    width: '100%',
    margin: theme.spacing(3, 0, 1),
    backgroundColor: '#F7F7F7',
  },
  rejectTextContainerStyle: {
    paddingTop: '45px',
  },
  definitionChildDelegationMatrix: {
    textTransform: 'capitalize',
  },
  parentDelegationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '50%',
  },
  parentDelegationCell: {
    display: 'flex',
    padding: '5px 20px 5px 0px',
    alignItems: 'center',
  },
  parentDelegationBorder: {
    borderTop: 'solid 1px black',
  },
  changeDelegationTitle: {
    position: 'relative',
    top: '40%',
  },
  iconEditDelegationStyle: {
    maxHeight: '12px',
    marginLeft: '8px',
    marginTop: '3px',
  },
  activityTypeLabelAdd: {
    color: '#4CAF50',
    marginLeft: '5px',
  },
  activityTypeLabelElse: {
    color: '#F44336',
    marginLeft: '5px',
  },
  activityTypeGrid: {
    '& .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: '#FAFAFA',
    },
    width: '100%',
  },
  activityTypeValue: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  uploadLinkIconStyle: {
    color: 'black',
  },
  uploadLinkStyle: {
    fontSize: '15px',
  },
  desktopOffset: {
    '@media (max-width: 1199px)': {
      display: 'none',
    },
  },
  buttonGrid: {
    '@media (max-width: 899px)': {
      flexDirection: 'column-reverse',
    },
  },
});

export const dialogContentStyle = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
});

export const dialogActionStyle = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
});
