import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Box,
  DialogActions,
  Dialog,
  Button,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
} from '@material-ui/core';
import DialogTitle from 'components/dialogTitle';
import CustomizedSnackbar from '@components/customizedSnackBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  auditHistoryOperation,
  auditHistoryDetailStatus,
  activitiesType,
  cardActivitiesType,
} from 'data/constants';
import { customization } from 'customization/default';
import { getAuditHistoryDetail } from '@services/api/auditHistory';
import { auditHistoryDetailStyle } from './style';

function AuditHistoryDetailDialog(props) {
  const [detailHistory, setDetailHistory] = useState(null);
  const [snackbar, setSnackbar] = useState({
    show: false,
    severity: 'error',
    message: null,
  });
  const [loading, setLoading] = useState(true);

  const trueDate = true;
  const { open, handleClose, data, classes, timezone, operationTag, token } = props;

  const newUserDelegationData =
    !detailHistory?.newUserDetail?.user_delegation ||
    detailHistory?.newUserDetail?.user_delegation.length > 0;
  const newLocationDelegationData =
    !detailHistory?.newUserDetail?.location_delegation ||
    detailHistory?.newUserDetail?.location_delegation.length > 0;

  const AuditDetailDialogTitle = () => {
    if (operationTag === auditHistoryOperation.LOCATION) {
      return `${customization.auditHistoryDetail.title} (${detailHistory?.code} - ${detailHistory?.description})`;
    }

    if (operationTag === auditHistoryOperation.USER) {
      if (
        !detailHistory?.newUserDetail?.first_name ||
        !detailHistory?.newUserDetail?.last_name
      ) {
        return (
          <b>
            {`${customization.auditHistoryDetail.title} (${detailHistory?.fullName})`}
          </b>
        );
      }

      return (
        <b>
          {`${customization.auditHistoryDetail.title} (${detailHistory?.newUserDetail.first_name} ${detailHistory?.newUserDetail.last_name})`}
        </b>
      );
    }

    if (operationTag === auditHistoryOperation.CARD) {
      const cardHolder = detailHistory?.card_holder.substr(
        detailHistory?.card_holder.length - 9,
      );
      return `${customization.auditHistoryDetail.title} (${cardHolder})`;
    }
  };

  const AuditDetailDialogStringData = ({ string }) => {
    if (string === '' || string === '/' || !string) return '-';
    return string;
  };

  const renderLocationGroup = (location_group) => {
    return location_group?.reduce((acc, curr, index) => {
      if (index === location_group.length - 1) {
        return `${acc} ${curr.location_group?.internal_name} : ${
          !curr.value || curr.value === '' ? '-' : curr.value
        }`;
      }
      return `${acc} ${curr.location_group?.internal_name} : ${
        !curr.value || curr.value === '' ? '-' : curr.value
      },`;
    }, '');
  };

  const renderUserDelegation = (delegations, prev = false) => {
    if (!delegations) return null;
    return (
      <Grid container>
        <b className={classes.headerTitle}>
          {prev
            ? customization.auditHistoryDetail.prevUserDelegation
            : customization.auditHistoryDetail.userDelegation}
        </b>
        <TableContainer>
          <Table>
            <TableHead className={classes.table} aria-label="table">
              <TableRow>
                <TableCell className={classes.mediumTitleWidthCell}>
                  <Typography>
                    <b>{customization.auditHistoryDetail.userNumber}</b>
                  </Typography>
                </TableCell>
                <TableCell className={classes.mediumTitleWidthCell}>
                  <Typography>
                    <b>{customization.auditHistoryDetail.firstName}</b>
                  </Typography>
                </TableCell>
                <TableCell className={classes.mediumTitleWidthCell}>
                  <Typography>
                    <b>{customization.auditHistoryDetail.lastName}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {delegations.map((delegation, index) => (
                <TableRow className={classes.paperCellStyle} key={index}>
                  <TableCell className={classes.mediumCellStyle}>
                    <span>
                      <AuditDetailDialogStringData string={delegation.user_number} />
                    </span>
                  </TableCell>
                  <TableCell className={classes.mediumCellStyle}>
                    <span>
                      <AuditDetailDialogStringData string={delegation.first_name} />
                    </span>
                  </TableCell>
                  <TableCell className={classes.mediumCellStyle}>
                    <span>
                      <AuditDetailDialogStringData string={delegation.last_name} />
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  };

  const renderLocationDelegation = (delegations, prev = false) => {
    if (!delegations) return null;
    return (
      <Grid container>
        <b className={classes.headerTitle}>
          {prev
            ? customization.auditHistoryDetail.prevLocationDelegation
            : customization.auditHistoryDetail.locationDelegation}
        </b>
        <TableContainer>
          <Table>
            <TableHead className={classes.table} aria-label="table">
              <TableRow>
                <TableCell className={classes.mediumTitleWidthCell}>
                  <Typography>
                    <b>{customization.auditHistoryDetail.code}</b>
                  </Typography>
                </TableCell>
                <TableCell className={classes.mediumTitleWidthCell}>
                  <Typography>
                    <b>{customization.auditHistoryDetail.description}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {delegations.map((delegation, index) => (
                <TableRow className={classes.paperCellStyle} key={index}>
                  <TableCell className={classes.mediumCellStyle}>
                    <span>
                      <AuditDetailDialogStringData string={delegation.code} />
                    </span>
                  </TableCell>
                  <TableCell className={classes.mediumCellStyle}>
                    <span>
                      <AuditDetailDialogStringData string={delegation.description} />
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  };

  const LocationDelegationUpdate = () => {
    if (
      !detailHistory?.newUserDetail.location_delegation &&
      detailHistory?.previousUserDetail.location_delegation
    ) {
      return renderLocationDelegation(
        detailHistory?.previousUserDetail.location_delegation,
        true,
      );
    }

    if (
      detailHistory?.newUserDetail.location_delegation &&
      !detailHistory?.previousUserDetail.location_delegation
    ) {
      return renderLocationDelegation(detailHistory?.newUserDetail.location_delegation);
    }

    if (
      detailHistory?.newUserDetail.location_delegation &&
      detailHistory?.previousUserDetail.location_delegation
    ) {
      return (
        <Grid container>
          <Grid item xs={5}>
            {renderLocationDelegation(
              detailHistory?.previousUserDetail.location_delegation,
              true,
            )}
          </Grid>
          <Grid item xs={1} className={classes.tableDivider} />
          <Grid item xs={5}>
            {renderLocationDelegation(detailHistory?.newUserDetail.location_delegation)}
          </Grid>
        </Grid>
      );
    }

    return <></>;
  };

  const UserDelegationUpdate = () => {
    if (
      !detailHistory?.newUserDetail.user_delegation &&
      detailHistory?.previousUserDetail.user_delegation
    ) {
      return renderUserDelegation(
        detailHistory?.previousUserDetail.user_delegation,
        true,
      );
    }

    if (
      detailHistory?.newUserDetail.user_delegation &&
      !detailHistory?.previousUserDetail.user_delegation
    ) {
      return renderUserDelegation(detailHistory?.newUserDetail.user_delegation);
    }

    if (
      detailHistory?.previousUserDetail.user_delegation &&
      detailHistory?.newUserDetail.user_delegation
    ) {
      return (
        <Grid container>
          <Grid item xs={5}>
            {renderUserDelegation(
              detailHistory?.previousUserDetail.user_delegation,
              true,
            )}
          </Grid>
          <Grid item xs={1} className={classes.tableDivider} />
          <Grid item xs={5}>
            {renderUserDelegation(detailHistory?.newUserDetail.user_delegation)}
          </Grid>
        </Grid>
      );
    }

    return <></>;
  };

  const ActivityTypeAddUser = ({ status }) => {
    return (
      <>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.activity} </b>
          <span>{data?.activity_type}</span>
        </Grid>
        {status !== auditHistoryDetailStatus.SUBMITTED && (
          <Grid container>
            <Grid item xs={6}>
              <b>
                {status === auditHistoryDetailStatus.COMPLETED
                  ? customization.auditHistoryDetail.approvedAt
                  : customization.auditHistoryDetail.rejectedAt}
              </b>
              <span>
                {moment(detailHistory?.newUserDetail.updated_at)
                  .utc()
                  .tz(timezone)
                  .format('DD/MM/YYYY - hh:mm a')}
              </span>
            </Grid>
            <Grid item xs={6}>
              <b>
                {status === auditHistoryDetailStatus.COMPLETED
                  ? customization.auditHistoryDetail.approvedBy
                  : customization.auditHistoryDetail.rejectedBy}
              </b>
              <span>
                <AuditDetailDialogStringData
                  string={detailHistory?.newUserDetail.reviewer_name}
                />
              </span>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.addedAt} </b>
            <span>
              {moment(detailHistory?.newUserDetail.created_at)
                .utc()
                .tz(timezone)
                .format('DD/MM/YYYY - hh:mm a')}
            </span>
          </Grid>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.addedBy} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.newUserDetail.added_by}
              />
            </span>
          </Grid>
        </Grid>
        {status === auditHistoryDetailStatus.REJECTED && (
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.rejectionMessageReason}</b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.newUserDetail.reason_desc}
              />
            </span>
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.status} </b>
          <span>
            <AuditDetailDialogStringData
              string={detailHistory?.newUserDetail.access_status}
            />
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.firstName} </b>
          <span>
            <AuditDetailDialogStringData
              string={detailHistory?.newUserDetail.first_name}
            />
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.middleName} </b>
          <span>
            <AuditDetailDialogStringData
              string={detailHistory?.newUserDetail.middle_name}
            />
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.lastName} </b>
          <span>
            <AuditDetailDialogStringData
              string={detailHistory?.newUserDetail.last_name}
            />
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.userType} </b>
          <span>
            <AuditDetailDialogStringData
              string={detailHistory?.newUserDetail.user_type}
            />
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.userNumber} </b>
          <span>
            <AuditDetailDialogStringData
              string={detailHistory?.newUserDetail.user_number}
            />
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.email} </b>
          <span>
            <AuditDetailDialogStringData string={detailHistory?.newUserDetail.email} />
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.mobilePrefix} </b>
          <span>
            <AuditDetailDialogStringData string={detailHistory?.mobilePrefix.iso_code} />
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.mobileNumber} </b>
          <span>
            <AuditDetailDialogStringData
              string={detailHistory?.newUserDetail.mobile_number}
            />
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.defaultLocation} </b>
          <span>
            <AuditDetailDialogStringData
              string={detailHistory?.newUserDetail.default_location_fk}
            />
          </span>
        </Grid>
        {newUserDelegationData &&
          renderUserDelegation(detailHistory?.newUserDetail.user_delegation)}
        {newLocationDelegationData &&
          renderLocationDelegation(detailHistory?.newUserDetail.location_delegation)}
      </>
    );
  };

  const ActivityTypeUpdateUser = ({ status }) => {
    return (
      <>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.activity} </b>
          <span>{data?.activity_type}</span>
        </Grid>
        {status !== auditHistoryDetailStatus.SUBMITTED && (
          <Grid container>
            <Grid item xs={6}>
              <b>
                {status === auditHistoryDetailStatus.APPROVED
                  ? customization.auditHistoryDetail.approvedAt
                  : customization.auditHistoryDetail.rejectedAt}
              </b>
              <span>
                {moment(detailHistory?.newUserDetail.updated_at)
                  .utc()
                  .tz(timezone)
                  .format('DD/MM/YYYY - hh:mm a')}
              </span>
            </Grid>
            <Grid item xs={6}>
              <b>
                {status === auditHistoryDetailStatus.APPROVED
                  ? customization.auditHistoryDetail.approvedBy
                  : customization.auditHistoryDetail.rejectedBy}
              </b>
              <span>
                <AuditDetailDialogStringData
                  string={detailHistory?.newUserDetail.reviewer_name}
                />
              </span>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.updatedAt} </b>
            <span>
              {moment(detailHistory?.newUserDetail.created_at)
                .utc()
                .tz(timezone)
                .format('DD/MM/YYYY - hh:mm a')}
            </span>
          </Grid>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.updatedBy} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.newUserDetail.added_by}
              />
            </span>
          </Grid>
        </Grid>
        {status === auditHistoryDetailStatus.REJECTED && (
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.rejectionMessageReason}</b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.newUserDetail.reason_desc}
              />
            </span>
          </Grid>
        )}
        <Grid container>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.status}</b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.previousUserDetail.access_status}
              />
            </span>
          </Grid>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.status} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.newUserDetail.access_status}
              />
            </span>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.prevFirstName} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.previousUserDetail.first_name}
              />
            </span>
          </Grid>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.firstName} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.newUserDetail.first_name}
              />
            </span>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.prevMiddleName} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.previousUserDetail.middle_name}
              />
            </span>
          </Grid>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.middleName} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.newUserDetail.middle_name}
              />
            </span>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.prevLastName} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.previousUserDetail.last_name}
              />
            </span>
          </Grid>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.lastName} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.newUserDetail.last_name}
              />
            </span>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.prevUserType} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.previousUserDetail.user_type.internal_name}
              />
            </span>
          </Grid>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.userType} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.newUserDetail.user_type}
              />
            </span>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.prevUniqueNumber} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.previousUserDetail.user_number}
              />
            </span>
          </Grid>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.uniqueNumber} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.newUserDetail.user_number}
              />
            </span>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.prevEmail} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.previousUserDetail.email}
              />
            </span>
          </Grid>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.email} </b>
            <span>
              <AuditDetailDialogStringData string={detailHistory?.newUserDetail.email} />
            </span>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.prevMobilePrefix} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.previousUserDetail.Country.iso_code}
              />
            </span>
          </Grid>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.mobilePrefix} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.mobilePrefix.iso_code}
              />
            </span>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.prevMobileNumber} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.previousUserDetail.mobile_number}
              />
            </span>
          </Grid>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.mobileNumber} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.newUserDetail.mobile_number}
              />
            </span>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.prevDefaultLocation} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.previousUserDetail.default_location_fk}
              />
            </span>
          </Grid>
          <Grid item xs={6}>
            <b>{customization.auditHistoryDetail.defaultLocation} </b>
            <span>
              <AuditDetailDialogStringData
                string={detailHistory?.newUserDetail.default_location_fk}
              />
            </span>
          </Grid>
        </Grid>
        <UserDelegationUpdate />
        <LocationDelegationUpdate />
      </>
    );
  };

  const ActivityTypeUserLogin = ({ status }) => {
    return (
      <>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.activity} </b>
          <span>{data?.activity_type}</span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.completedDateTime} </b>
          <span>
            {moment(detailHistory?.completedDateTime)
              .utc()
              .tz(timezone)
              .format('DD/MM/YYYY - hh:mm a')}
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.platform} </b>
          <span>
            <AuditDetailDialogStringData string={detailHistory?.Platform} />
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.method} </b>
          <span>
            <AuditDetailDialogStringData string={detailHistory?.BroadcastMethod} />
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.emailMobileNumber}</b>
          <span>
            <AuditDetailDialogStringData string={detailHistory?.email_mobile_number} />
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.ipAddress} </b>
          <span>
            <AuditDetailDialogStringData string={detailHistory?.IP} />
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.deviceNumber} </b>
          <span>
            <AuditDetailDialogStringData string={detailHistory?.device_number} />
          </span>
        </Grid>
        {status === auditHistoryDetailStatus.SUCCEED && (
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.logoutDateTime} </b>
            {!detailHistory?.LogoutDateTime || detailHistory?.LogoutDateTime === '' ? (
              '-'
            ) : (
              <span>
                {moment(detailHistory?.LogoutDateTime)
                  .utc()
                  .tz(timezone)
                  .format('DD/MM/YYYY - hh:mm a')}
              </span>
            )}
          </Grid>
        )}
      </>
    );
  };

  const ActivyTypeLockUser = ({ type }) => {
    const userLocked =
      type === activitiesType.LOCK_USER.value ||
      type === activitiesType.LOCK_USER_ADMIN.value ||
      type === activitiesType.LOCK_DUE_TO_PASSWORD.value ||
      type === activitiesType.LOCK_DUE_TO_ADMIN.value;
    return (
      <>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.activity} </b>
          <span>{data?.activity_type}</span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.completedAt} </b>
          <span>
            {moment(detailHistory?.completedDateTime)
              .utc()
              .tz(timezone)
              .format('DD/MM/YYYY - hh:mm a')}
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.completedBy} </b>
          <span>
            <AuditDetailDialogStringData string={detailHistory?.completedBy} />
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.completedFor} </b>
          <span>
            <AuditDetailDialogStringData string={detailHistory?.completedFor} />
          </span>
        </Grid>
        {userLocked && (
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.trigger} </b>
            <span>
              <AuditDetailDialogStringData string={detailHistory?.trigger} />
            </span>
          </Grid>
        )}
        {(type === activitiesType.PROVISION_UNLOCK_CODE.value ||
          type === activitiesType.PROVISION_UNLOCK_CODE_VERBAL.value) && (
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.method} </b>
            <span>
              <AuditDetailDialogStringData string={detailHistory?.BroadcastMethod} />
            </span>
          </Grid>
        )}
      </>
    );
  };

  const ActivityTypeForgotPassword = () => {
    return (
      <>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.activity} </b>
          <span>{data?.activity_type}</span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.completedAt} </b>
          <span>
            {moment(detailHistory?.completedDateTime)
              .utc()
              .tz(timezone)
              .format('DD/MM/YYYY - hh:mm a')}
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.completedBy} </b>
          <span>
            <AuditDetailDialogStringData string={detailHistory?.completedBy} />
          </span>
        </Grid>
      </>
    );
  };

  const ActivityTypeChangePin = () => {
    return (
      <>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.activity} </b>
          <span>{data?.activity_type}</span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.completedAt} </b>
          <span>
            {moment(detailHistory?.completedDateTime)
              .utc()
              .tz(timezone)
              .format('DD/MM/YYYY - hh:mm a')}
          </span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.completedBy} </b>
          <span>
            <AuditDetailDialogStringData string={detailHistory?.completedBy} />
          </span>
        </Grid>
      </>
    );
  };

  const renderUserDetail = (activityType, activityStatus) => {
    const type = activityType ?? detailHistory?.newUserDetail.activity_type;
    const status = activityStatus ?? detailHistory?.newUserDetail.activity_status;

    // Activity Type: Add User
    if (type === activitiesType.USER_NEW.value)
      return <ActivityTypeAddUser status={status} />;

    // Activity Type: Update User
    if (
      type === activitiesType.USER_EDIT.value ||
      type === activitiesType.DEACTIVATE_USER.value
    )
      return <ActivityTypeUpdateUser status={status} />;

    // Activity Type: Forgot Password
    if (type === activitiesType.FORGOT_PASSWORD.value)
      return <ActivityTypeForgotPassword />;

    // Activity Type: User Login
    if (
      type === activitiesType.USER_LOG_IN.value ||
      type === activitiesType.USER_LOG_IN_BIO.value ||
      type === activitiesType.USER_LOG_IN_PIN.value
    )
      return <ActivityTypeUserLogin status={status} />;

    // Activity Type: Change PIN
    if (type === activitiesType.CHANGE_PIN.value) return <ActivityTypeChangePin />;

    // Activity Type: Unlock User, Lock User, Privision Unlock Code
    if (
      type === activitiesType.UNLOCK_USER.value ||
      type === activitiesType.LOCK_USER.value ||
      type === activitiesType.LOCK_USER_ADMIN.value ||
      type === activitiesType.LOCK_DUE_TO_PASSWORD.value ||
      type === activitiesType.LOCK_DUE_TO_MFA.value ||
      type === activitiesType.LOCK_DUE_TO_ADMIN.value ||
      type === activitiesType.PROVISION_UNLOCK_CODE.value ||
      type === activitiesType.PROVISION_UNLOCK_CODE_VERBAL.value
    )
      return <ActivyTypeLockUser type={type} />;
  };

  const renderLocationDetail = (type, status) => {
    if (
      type === activitiesType.LOCATION_NEW.value &&
      status === auditHistoryDetailStatus.SUBMITTED
    ) {
      return (
        <>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.activity} </b>
            <span>{data?.activity_type}</span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.addedAt} </b>
            <span>
              {moment(detailHistory?.created_at)
                .utc()
                .tz(timezone)
                .format('DD/MM/YYYY - hh:mm a')}
            </span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.addedBy} </b>
            <span>
              {detailHistory?.initiator_name}, {detailHistory?.initiator_number}
            </span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.locationDetails} </b>
            <span>
              {detailHistory?.status_location}, {detailHistory?.code},{' '}
              {detailHistory?.description}
            </span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.locationGroup} </b>
            <span>{renderLocationGroup(detailHistory?.location_group)}</span>
          </Grid>
        </>
      );
    }

    if (
      type === activitiesType.LOCATION_NEW.value &&
      (status === auditHistoryDetailStatus.APPROVED ||
        status === auditHistoryDetailStatus.REJECTED)
    ) {
      return (
        <>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.activity} </b>
            <span>{data?.activity_type}</span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>
              {status === auditHistoryDetailStatus.APPROVED
                ? customization.auditHistoryDetail.approvedAt
                : customization.auditHistoryDetail.rejectedAt}{' '}
            </b>
            <span>
              {moment(detailHistory?.updated_at)
                .utc()
                .tz(timezone)
                .format('DD/MM/YYYY - hh:mm a')}
            </span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>
              {status === auditHistoryDetailStatus.APPROVED
                ? customization.auditHistoryDetail.approvedBy
                : customization.auditHistoryDetail.rejectedBy}
            </b>
            <span>
              {detailHistory?.reviewer_name}, {detailHistory?.reviewer_number}
            </span>
          </Grid>
          {status === auditHistoryDetailStatus.REJECTED && (
            <Grid item xs={12} sm={12}>
              <b>{customization.auditHistoryDetail.rejectionMessageReason}</b>
              <span>{detailHistory?.reason}</span>
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.addedAt} </b>
            <span>
              {moment(detailHistory?.created_at)
                .utc()
                .tz(timezone)
                .format('DD/MM/YYYY - hh:mm a')}
            </span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.addedBy} </b>
            <span>
              {detailHistory?.initiator_name}, {detailHistory?.initiator_number}
            </span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.locationDetails} </b>
            <span>
              {detailHistory?.status_location}, {detailHistory?.code},{' '}
              {detailHistory?.description}
            </span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.locationGroup} </b>
            <span>{renderLocationGroup(detailHistory?.location_group)}</span>
          </Grid>
        </>
      );
    }

    if (
      (type === activitiesType.LOCATION_EDIT.value ||
        type === activitiesType.LOCATION_BLOCK.value) &&
      status === auditHistoryDetailStatus.SUBMITTED
    ) {
      return (
        <>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.activity} </b>
            <span>{data?.activity_type}</span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.editedAt} </b>
            <span>
              {moment(detailHistory?.updated_at)
                .utc()
                .tz(timezone)
                .format('DD/MM/YYYY - hh:mm a')}
            </span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.editedBy} </b>
            <span>
              {detailHistory?.initiator_name}, {detailHistory?.initiator_number}
            </span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.locationDetails} </b>
            <span>
              {detailHistory?.status_location}, {detailHistory?.code},{' '}
              {detailHistory?.description}
            </span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.locationGroup} </b>
            <span>{renderLocationGroup(detailHistory?.location_group)}</span>
          </Grid>
        </>
      );
    }

    if (
      (type === activitiesType.LOCATION_EDIT.value ||
        type === activitiesType.LOCATION_BLOCK.value) &&
      (status === auditHistoryDetailStatus.APPROVED ||
        status === auditHistoryDetailStatus.REJECTED)
    ) {
      return (
        <>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.activity} </b>
            <span>{data?.activity_type}</span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>
              {status === auditHistoryDetailStatus.APPROVED
                ? customization.auditHistoryDetail.approvedAt
                : customization.auditHistoryDetail.rejectedAt}{' '}
            </b>
            <span>
              {moment(detailHistory?.updated_at)
                .utc()
                .tz(timezone)
                .format('DD/MM/YYYY - hh:mm a')}
            </span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>
              {status === auditHistoryDetailStatus.APPROVED
                ? customization.auditHistoryDetail.approvedBy
                : customization.auditHistoryDetail.rejectedBy}
            </b>
            <span>
              {detailHistory?.reviewer_name}, {detailHistory?.reviewer_number}
            </span>
          </Grid>
          {status === auditHistoryDetailStatus.REJECTED && (
            <Grid item xs={12} sm={12}>
              <b>{customization.auditHistoryDetail.rejectionMessageReason}</b>
              <span>{detailHistory?.reason}</span>
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.addedAt} </b>
            <span>
              {moment(detailHistory?.created_at)
                .utc()
                .tz(timezone)
                .format('DD/MM/YYYY - hh:mm a')}
            </span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.addedBy} </b>
            <span>
              {detailHistory?.initiator_name}, {detailHistory?.initiator_number}
            </span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.locationDetails} </b>
            <span>
              {detailHistory?.status_location}, {detailHistory?.code},{' '}
              {detailHistory?.description}
            </span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <b>{customization.auditHistoryDetail.locationGroup} </b>
            <span>{renderLocationGroup(detailHistory?.location_group)}</span>
          </Grid>
        </>
      );
    }

    return null;
  };

  const templateCardDetails = (label, detailsStatus, isDate = false) => {
    return (
      <Grid item xs={12} sm={12}>
        <b>{label}</b>
        {isDate ? (
          <span>
            {moment(detailsStatus).utc().tz(timezone).format('DD/MM/YYYY - hh:mm a')}
          </span>
        ) : (
          <span>{detailsStatus}</span>
        )}
      </Grid>
    );
  };

  const changeCardHolderNameCardDetails = (changeCardHolderNameStatus) => {
    return (
      <>
        {templateCardDetails(
          customization.auditHistoryDetail.activity,
          detailHistory?.activity_type,
        )}
        {changeCardHolderNameStatus === auditHistoryDetailStatus.APPROVED
          ? templateCardDetails(
              customization.auditHistoryDetail.approvedAt,
              detailHistory?.approved_date,
              trueDate,
            )
          : changeCardHolderNameStatus === auditHistoryDetailStatus.REJECTED
          ? templateCardDetails(
              customization.auditHistoryDetail.rejectedAt,
              detailHistory?.rejected_date,
              trueDate,
            )
          : templateCardDetails(
              customization.auditHistoryDetail.completedAt,
              detailHistory?.completed_date,
              trueDate,
            )}
        {changeCardHolderNameStatus && (
          <>
            {changeCardHolderNameStatus === auditHistoryDetailStatus.APPROVED
              ? templateCardDetails(
                  customization.auditHistoryDetail.approvedBy,
                  detailHistory?.approved_by,
                )
              : templateCardDetails(
                  customization.auditHistoryDetail.rejectedBy,
                  detailHistory?.rejected_by,
                )}
          </>
        )}
        {detailHistory?.reason_desc && (
          <>
            {templateCardDetails(
              customization.auditHistoryDetail.rejectionMessageReason,
              detailHistory?.reason_desc,
            )}
          </>
        )}
        {templateCardDetails(
          customization.auditHistoryDetail.completedBy,
          detailHistory?.completed_by,
        )}
        {templateCardDetails(
          customization.auditHistoryDetail.cardHolderDetail,
          detailHistory?.card_holder,
        )}
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.oldName}</b>
          <span>{detailHistory?.old_name}</span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.newName}</b>
          <span>{detailHistory?.new_name}</span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.oldAddress}</b>
          <span>{detailHistory?.old_address}</span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <b>{customization.auditHistoryDetail.newAddress}</b>
          <span>{detailHistory?.new_address}</span>
        </Grid>
      </>
    );
  };

  const AuditHistoryDialogError = () => {
    return (
      <>
        <CustomizedSnackbar
          message={snackbar.message}
          showBar={snackbar.show}
          severity={snackbar.severity}
          handleClose={onCloseHistoryDetailDialog}
        />
        <Dialog fullWidth maxWidth="md" open={open}>
          <DialogTitle
            id="approval-dialog-slide-title"
            onClose={onCloseHistoryDetailDialog}
          >
            <b>
              <AuditDetailDialogTitle />
            </b>
          </DialogTitle>
          <Grid className={classes.auditDetail}>
            <Grid item xs={12} sm={12}>
              <b>{customization.auditHistory.noRecord}</b>
            </Grid>
          </Grid>
          <DialogActions className={classes.auditDetailAction}>
            <Button
              type="button"
              variant="contained"
              onClick={onCloseHistoryDetailDialog}
            >
              {customization.back}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const renderBasicCardDetails = () => {
    return (
      <>
        {templateCardDetails(
          customization.auditHistoryDetail.activity,
          detailHistory?.activity_type,
        )}
        {templateCardDetails(
          customization.auditHistoryDetail.completedAt,
          detailHistory?.completed_date,
          trueDate,
        )}
        {templateCardDetails(
          customization.auditHistoryDetail.completedBy,
          detailHistory?.completed_by,
        )}
        {templateCardDetails(
          customization.auditHistoryDetail.cardHolderDetail,
          detailHistory?.card_holder,
        )}
      </>
    );
  };

  const setCardSettings = (label, settings) => {
    if (settings) {
      return (
        <>
          <Grid item xs={12} sm={12}>
            <b>{label}</b>
            {settings.map((setting) => {
              return (
                <Grid item xs={12} sm={12}>
                  <span>{`${customization.auditHistoryDetail.name}${setting.setting_name}, ${customization.auditHistoryDetail.value}${setting.value}`}</span>
                </Grid>
              );
            })}
          </Grid>
        </>
      );
    }
    return <></>;
  };

  const setCardSettingUpdateTemplate = () => {
    return (
      <>
        {renderBasicCardDetails()}
        {setCardSettings(
          customization.auditHistoryDetail.oldSettings,
          detailHistory?.old_settings,
        )}
        {setCardSettings(
          customization.auditHistoryDetail.newSettings,
          detailHistory?.new_settings,
        )}
      </>
    );
  };

  const renderCardDetail = (type) => {
    const renderObject = [
      {
        types: [
          cardActivitiesType.CHANGE_CARD_PIN.value,
          cardActivitiesType.ACTIVATE_CARD.value,
          cardActivitiesType.BLOCK_CARD.value,
          cardActivitiesType.UNBLOCK_CARD.value,
          cardActivitiesType.CANCEL_CARD.value,
          cardActivitiesType.REPLACE_CARD.value,
          cardActivitiesType.ADD_CARD_TO_WALLET.value,
        ],
        template: () => renderBasicCardDetails(),
      },
      {
        types: [cardActivitiesType.CHANGE_CARD_HOLDER_NAME.value],
        template: () => changeCardHolderNameCardDetails(false),
      },
      {
        types: [cardActivitiesType.CHANGE_CARD_HOLDER_NAME_APPROVED.value],
        template: () =>
          changeCardHolderNameCardDetails(auditHistoryDetailStatus.APPROVED),
      },
      {
        types: [cardActivitiesType.CHANGE_CARD_HOLDER_NAME_REJECTED.value],
        template: () =>
          changeCardHolderNameCardDetails(auditHistoryDetailStatus.REJECTED),
      },
      {
        types: [cardActivitiesType.OTHER_CARD_SETTINGS_UPDATE.value],
        template: () => setCardSettingUpdateTemplate(),
      },
    ];
    const matchRenderObject = renderObject.find((data) => data.types.includes(type));
    if (matchRenderObject) {
      return matchRenderObject.template();
    }
    return <></>;
  };

  const onCloseHistoryDetailDialog = () => {
    setSnackbar({ show: false, severity: 'error', message: null });
    setLoading(true);
    handleClose();
  };

  const renderLoading = () => {
    return (
      <Box className={classes.loadingStyle}>
        <CircularProgress />
      </Box>
    );
  };

  useEffect(() => {
    setSnackbar({ show: false, severity: 'error', message: null });
    if (open) {
      (async function () {
        try {
          const response = await getAuditHistoryDetail(operationTag, data.id, token);
          setDetailHistory(response.data);
          setLoading(false);
        } catch (error) {
          setDetailHistory(null);
          setLoading(false);
          if (error.response.status === 404) {
            setSnackbar({ show: true, severity: 'error', message: 'Not Found' });
          } else {
            setSnackbar({
              show: true,
              severity: 'error',
              message: error?.response?.data?.message || error.message,
            });
          }
        }
      })();
    }
  }, [data, open, operationTag, token]);

  if (snackbar.show && snackbar.severity === 'error') {
    return <AuditHistoryDialogError />;
  }

  return (
    <Dialog fullWidth maxWidth="lg" open={open}>
      {loading ? (
        renderLoading()
      ) : (
        <>
          <DialogTitle
            id="audit-history-detail-dialog-title"
            onClose={onCloseHistoryDetailDialog}
          >
            <AuditDetailDialogTitle />
          </DialogTitle>
          <Grid container className={classes.auditDetail}>
            {operationTag === auditHistoryOperation.USER &&
              detailHistory &&
              renderUserDetail(
                detailHistory?.activity_type,
                detailHistory?.activity_status,
              )}
            {operationTag === auditHistoryOperation.LOCATION &&
              detailHistory &&
              renderLocationDetail(detailHistory?.activity_type, detailHistory?.status)}
            {operationTag === auditHistoryOperation.CARD &&
              detailHistory &&
              renderCardDetail(detailHistory?.activity_type)}
          </Grid>
          <DialogActions className={classes.auditDetailAction}>
            <Button
              type="button"
              variant="contained"
              onClick={onCloseHistoryDetailDialog}
            >
              {customization.back}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

AuditHistoryDetailDialog.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  timezone: PropTypes.string,
  token: PropTypes.string,
  auditName: PropTypes.string,
  operationTag: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
    timezone: state.auth.timezone,
  };
};

const withConnect = connect(mapStateToProps, null);
export default compose(
  withStyles(auditHistoryDetailStyle),
  withConnect,
)(AuditHistoryDetailDialog);
