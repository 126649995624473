import {
  Grid,
  useMediaQuery,
  TextField,
  Button,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Chip,
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
  AssignmentInd as AssignmentIndIcon,
  AttachMoney as AttachMoneyIcon,
  GetApp as GetAppIcon,
  History as HistoryIcon,
} from '@material-ui/icons';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import AddLocationDialog from '@components/addLocationDialog';
import AuditHistoryDialog from '@components/auditHistoryDialog';
import DashboardLayout from '@components/layout/dashboardLayout';
import DataGridPagination from '@components/dataGridPagination';
import EditLocationDialog from '@components/editLocationDialog';
import EmptyRowsOverlay from '@components/emptyRowsOverlay';
import ErrorMessageDialog from '@components/errorMessageDialog';
import FundTransferDialog from '@components/fundTransferDialog';
import { customization } from '@customization/default';
import * as locationAction from '@redux/locations/locationActions';
import truncateWithEllipses from '@utils/truncateWithEllipses';
import {
  auditHistoryOperation,
  locationStatusFilter,
  transactionTypeFrom,
} from '@data/constants';
import TransactionViewList from '../transactionListView';
import { useStyles } from './style';

function LocationViewPage(props) {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const { token, error, locationAction } = props;

  const ACTIVE_ICON = (
    <Chip label={customization.statusActive} className={classes.chipActive} />
  );
  const INACTIVE_ICON = (
    <Chip label={customization.statusInactive} className={classes.chipInactive} />
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [errorMessage, setErrorMessage] = useState({ isError: false, message: '' });
  const [locations, setLocations] = useState({ rows: [], total: 0 });
  const [search, setSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchHolder, setSearchHolder] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [openEditLocationDialog, setOpenEditLocationDialog] = useState(false);
  const [openTransactionDialog, setOpenTransactionDialog] = useState(false);
  const [openAuditHistoryDialog, setOpenAuditHistoryDialog] = useState(false);
  const [fundTransferDialog, setFundTransferDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const getLocations = useCallback(() => {
    locationAction
      .getLocationList(token, page + 1, rowsPerPage, searchText, null, null, statusFilter)
      .then((response) => {
        if (response.data) {
          const { items, totalItems, totalPages, currentPage } = response.data;
          setLocations({ rows: items, total: totalItems });
          setCurrentPage(currentPage);

          if (currentPage >= totalPages) {
            setPage(totalPages - 1);
          }
        } else {
          if (response.message.includes('403')) {
            setLocations({ rows: [], total: 0 });
          } else {
            setErrorMessage({
              isError: true,
              message: response.message,
            });
          }
        }
        setLoading(false);
      });
  }, [locationAction, page, rowsPerPage, searchText, statusFilter, token]);

  const handleSearch = () => {
    setSearch(true);
    setSearchText(searchHolder);
  };

  const keyPress = (event) => {
    if (event.keyCode === 13) {
      setSearch(true);
      setSearchText(searchHolder);
    }
  };

  const handleSearchFieldChange = (event) => {
    const searchString = event.target.value;

    if (searchString.length === 0) {
      setSearch(false);
      setSearchText('');
    }

    setSearchHolder(searchString);
  };

  const handleStatusFilter = (_event, newStatus) => {
    if (newStatus === null) {
      setStatusFilter('');
    } else {
      setStatusFilter(newStatus);
    }
  };

  const handleOpenEditLocation = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    setOpenEditLocationDialog(true);
  };

  const handleCloseEditLocation = () => {
    setAnchorEl(null);
    setOpenEditLocationDialog(false);
  };

  const handleOpenTransaction = () => {
    setAnchorEl(null);
    setOpenTransactionDialog(true);
  };

  const handleCloseTransaction = () => {
    setAnchorEl(null);
    setOpenTransactionDialog(false);
  };

  const handleOpenAuditHistory = async () => {
    setOpenAuditHistoryDialog(true);
    setAnchorEl(null);
  };

  const handleCloseAuditHistory = () => {
    setSelectedLocation(null);
    setOpenAuditHistoryDialog(false);
  };

  const handleCloseFundTransfer = () => {
    setSelectedLocation(null);
    setFundTransferDialog(false);
  };

  const handleTransferFunds = () => {
    setAnchorEl(null);
    setFundTransferDialog(true);
  };

  useEffect(() => {
    setLoading(true);

    if (error.statusCode) {
      setPage(0);
    }

    getLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLocations]);

  const columns = [
    {
      align: 'center',
      field: 'is_active',
      headerAlign: 'center',
      minWidth: isMobile ? 100 : 200,
      headerName: customization.userViews.headers.userStatus,
      sortable: false,
      renderCell: (params) => {
        const value = params.value;
        if (value) {
          return ACTIVE_ICON;
        } else {
          return INACTIVE_ICON;
        }
      },
    },
    {
      field: 'code',
      flex: 0.5,
      headerName: customization.locationViews.codeHeader,
      headerClassName: classes.headerCell,
      minWidth: 109.2,
      valueFormatter: (params) =>
        params.value ? truncateWithEllipses(params.value, 20) : '',
    },
    {
      field: 'description',
      flex: 0.85,
      minWidth: 204.75,
      headerName: customization.locationViews.descriptionHeader,
    },
    {
      align: 'left',
      field: 'location_account_map',
      flex: 0.5,
      headerAlign: 'left',
      headerClassName: 'text-left',
      headerName: customization.locationViews.balanceHeader,
      minWidth: 136.5,
      valueFormatter: (params) => {
        const { value, symbol } =
          params.row.location_account_map.account.available_balance;
        return `${symbol}${value}`;
      },
      valueGetter: (params) => {
        const { value } = params.value.account.available_balance;
        const removedCommas = value.replace(/,/g, '');
        const removedPeriods = removedCommas.replace(/\./g, '');
        return parseInt(removedPeriods) / 100;
      },
    },
    {
      align: 'center',
      field: 'action',
      headerAlign: 'center',
      headerName: customization.userViews.headers.action,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const id = params.row.id;
        const code = params.getValue(params.id, 'code');
        const isActive = params.getValue(params.id, 'is_active');
        const timezone = params.row.timezone;
        const description = params.getValue(params.id, 'description');
        const account = params.row.location_account_map;

        return (
          <>
            <Button
              aria-controls="more-options"
              aria-haspopup="true"
              className={`${classes.actionButton} more`}
              disableElevation
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setSelectedLocation({
                  code,
                  id,
                  isActive,
                  timezone,
                  description,
                  account,
                });
              }}
              variant="contained"
            >
              <MoreVertIcon htmlColor="#FFFFFF" fontSize="medium" />
            </Button>
          </>
        );
      },
    },
  ];

  const moreOptionsItems = [
    {
      id: 1,
      label: customization.actionItems.locationDetails,
      icon: <AssignmentIndIcon />,
      onClick: handleOpenEditLocation,
    },
    {
      id: 2,
      label: customization.actionItems.viewTransactions,
      icon: <AttachMoneyIcon />,
      onClick: handleOpenTransaction,
    },
    {
      id: 3,
      label: customization.actionItems.auditHistory,
      icon: <HistoryIcon />,
      onClick: handleOpenAuditHistory,
    },
    {
      id: 4,
      label: customization.actionItems.transferFunds,
      icon: <GetAppIcon />,
      onClick: handleTransferFunds,
    },
  ];

  const handleCloseErrorMessage = () => {
    setErrorMessage({
      isError: false,
      message: '',
    });
  };

  const pageInformation = () => {
    if (!locations?.rows.length) {
      return null;
    }

    const fromPage = (currentPage - 1) * rowsPerPage + 1;

    const toPage = (page + 1) * rowsPerPage;

    const totalPage = locations?.total;

    const lastPage =
      currentPage === Math.max(0, Math.ceil(locations?.total / rowsPerPage))
        ? totalPage
        : toPage;

    return `Showing ${fromPage}-${lastPage} of ${totalPage} results`;
  };

  return (
    <DashboardLayout>
      <Tabs
        value={statusFilter}
        onChange={handleStatusFilter}
        className={classes.statusTabs}
        scrollButtons="auto"
      >
        {locationStatusFilter.map((status) => (
          <Tab key={status.label} label={status.label} value={status.value} />
        ))}
        {!isMobile && (
          <Tab
            className={classes.tabInformationPage}
            key="pageInformation"
            label={pageInformation()}
            disabled
          />
        )}
      </Tabs>
      <ErrorMessageDialog
        bindHandleClose={handleCloseErrorMessage}
        openSuccess={errorMessage.isError}
        errorData={errorMessage.message}
      />
      <Grid
        className={classes.gridContainer}
        container
        justifyContent="flex-start"
        spacing={2}
      >
        <Grid item xs={12} lg={6} xl={6}>
          <TextField
            className={classes.searchText}
            color="secondary"
            InputProps={{
              endAdornment: (
                <Button
                  color="secondary"
                  disableElevation
                  onClick={handleSearch}
                  variant="contained"
                >
                  {customization.userViews.searchLabel}
                </Button>
              ),
              onKeyDown: keyPress,
            }}
            onChange={handleSearchFieldChange}
            placeholder={customization.locationViews.searchPlaceHolder}
            type="search"
            value={searchHolder}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={6} className={classes.alignEnd}>
          <AddLocationDialog />
        </Grid>
      </Grid>

      <DataGrid
        autoHeight
        disableColumnMenu
        disableExtendRowFullWidth
        disableSelectionOnClick
        className={classes.table}
        columns={columns}
        components={{
          NoRowsOverlay: EmptyRowsOverlay,
          Pagination: DataGridPagination,
        }}
        componentsProps={{
          noRowsOverlay: {
            label: customization.locationViews.emptyRows,
            search,
          },
          pagination: {
            count: locations.total,
            onPageChange: (_event, newPage) => {
              setPage(newPage);
            },
            onRowsPerPageChange: (event) => {
              setRowsPerPage(event.target.value);
            },
            page: locations.rows.length > 0 ? page : 0,
            rowsPerPage,
          },
        }}
        loading={loading}
        maxColumns={9}
        pagination
        paginationMode="server"
        rows={locations.rows}
        rowHeight={80}
      />

      <Menu
        anchorEl={anchorEl}
        className={classes.moreMenu}
        id="more-options"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {moreOptionsItems.map((item, index) => {
          if (item.id === 4) {
            return (
              selectedLocation?.account && (
                <MenuItem key={index} onClick={item.onClick}>
                  {item.icon}
                  {item.label}
                </MenuItem>
              )
            );
          }

          return (
            <MenuItem key={index} onClick={item.onClick}>
              {item.icon}
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>

      <EditLocationDialog
        bindCloseEditDialog={handleCloseEditLocation}
        openEditDialog={openEditLocationDialog}
        locationData={selectedLocation}
      />

      <TransactionViewList
        open={openTransactionDialog}
        handleClose={handleCloseTransaction}
        data={selectedLocation}
        typeData={transactionTypeFrom.LOCATION}
      />

      <AuditHistoryDialog
        openAuditHistoryDialog={openAuditHistoryDialog}
        bindCloseAuditHistory={handleCloseAuditHistory}
        auditData={selectedLocation}
        operationTag={auditHistoryOperation.LOCATION}
      />

      <FundTransferDialog
        handleClose={handleCloseFundTransfer}
        open={fundTransferDialog}
        location={selectedLocation}
      />
    </DashboardLayout>
  );
}

LocationViewPage.propTypes = {
  locationAction: PropTypes.object,
  classes: PropTypes.object,
  locationList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  error: PropTypes.object,
  getLocationList: PropTypes.func,
  token: PropTypes.string,
  loading: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    locationList: state.locations.locationList,
    error: state.locations.error,
    loading: state.locations.loading,
    token: state.auth.inMemoryToken.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    locationAction: bindActionCreators(locationAction, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LocationViewPage);
