import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { tabContainerStyle } from './style';

function TabPanel(props) {
  const { classes, children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`setting-tabpanel-${index}`}
      aria-labelledby={`setting-tab-${index}`}
      className={classes.divContainerStyle}
      {...other}
    >
      {value === index && (
        <Box className={classes.tabPanelContainerStyle}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  classes: PropTypes.object,
};
export default compose(withStyles(tabContainerStyle))(TabPanel);
