import { post } from './baseApi';

const adminDeactivateUser = async (token, params) => {
  return post('/admin/user/access/deactivate', params, token);
};

const adminActivateUser = async (token, params) => {
  return post('/admin/user/access/activate', params, token);
};

export { adminActivateUser, adminDeactivateUser };
