export const formRootUserStyle = (theme) => ({
  containerFormSection: {
    paddingBottom: 15,
  },
  formControl: {
    width: '100%',
  },
  formLabel: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    color: '#555555',
  },
  formButtonSaveAndClose: {
    marginRight: 88,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginTop: '1rem',
      '& button': {
        width: '100%',
      },
    },
  },
  formButtonCancel: {
    right: 15,
    position: 'absolute',
    '& button': {
      fontWeight: 'bold',
      color: '#555555',
    },
    [theme.breakpoints.down('sm')]: {
      right: 0,
      position: 'relative',
      '& button': {
        width: '100%',
      },
    },
  },
  formControlInput: {
    minWidth: '100%',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  loadingStatus: {
    marginLeft: '400px',
  },
});

export const bootStrapInput = (theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
});
