export const addLocationDialogStyle = (theme) => ({
  roundedButton: {
    borderRadius: '8px',
  },
  dialogContent: {
    minHeight: '500px',
    padding: '0',
    overflowX: 'hidden',
  },
  sectionOneGrid: {
    borderBottom: '2px solid #B2B1B9',
    width: '100%',
  },
  cardGrid: {
    padding: '0px',
    margin: '0',
    width: '45%',
  },
  cardContainer: {
    position: 'relative',
  },
  cardInformationLabel: {
    paddingBottom: '10px',
  },
  cardHolderLink: {
    display: 'inline',
    textDecoration: 'underline',
    color: '#5493cf',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  lastTransactionLabel: {
    paddingBottom: '10px',
  },
  sectionTwoGrid: {
    width: '95%',
    paddingTop: '25px',
  },
  bottomButtonContainer: {
    width: '100%',
  },
  buttonGroupsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    borderRight: '2px solid #B2B1B9',
  },
  labelForm: {
    paddingRight: '5px',
    fontWeight: '700',
    width: '70%',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    borderRight: '2px solid #B2B1B9',
    height: '200px',
  },
  leftPanelMessage: {
    textAlign: 'center',
    paddingTop: '4px',
    width: '380px',
    marginTop: '59px',
    color: '#5996d1',
    fontWeight: 'bold',
    background: '#d9e8f4',
    border: '2px solid #5996d1',
  },
  exceededPinMessage: {
    textAlign: 'center',
  },
  buttonCancel: {
    width: '100%',
  },
  buttonUnblock: {
    width: '100%',
    backgroundColor: 'white',
    color: '#dc4b7b',
    fontWeight: 'bold',
    border: '2px solid #dc4b7b',
    '&:hover': {
      backgroundColor: '#B2B1B9',
    },
    selected: {},
  },
  buttonReplaceCard: {
    width: '100%',
    backgroundColor: '#f3f3f3',
    color: '#7b7b7b',
    border: '2px solid #7b7b7b',
    fontWeight: 'bold',
    selected: {},
  },
  buttonLostStolenCard: {
    width: '100%',
    backgroundColor: '#dc4b7b',
    fontWeight: 'bold',
    selected: {},
  },
  buttonCancelCard: {
    width: '100%',
    backgroundColor: '#dc4b7b',
    fontWeight: 'bold',
    selected: {},
  },
  buttonContainer: {
    width: '330px',
  },
  inputPasswordRoot: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    userSelect: 'none',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    height: '30px',
    width: '110px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
  inputPasswordLabel: {
    fontSize: 18,
    fontWeight: 700,
    '&$focused': {
      color: theme.palette.text.secondary,
    },
    userSelect: 'none',
  },
  focused: {},
  formContainer: {
    width: '385px',
    marginRight: '0px',
    paddingTop: '41px',
  },
  formControl: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: '15px',
    paddingRight: '35px',
    paddingLeft: '65px',
    justifyContent: 'space-between',
  },
  limitMessageNullContainer: {
    background: '#d9e8f4',
    height: '80px',
    width: '380px',
    border: '2px solid #5996d1',
    marginLeft: '32px',
  },
  limitMessageNullTitle: {
    textAlign: 'center',
    paddingTop: '15px',
    color: '#5996d1',
    fontWeight: 'bold',
  },
  limitMessageNullBody: {
    textAlign: 'center',
    color: '#5996d1',
  },
  inputAdornmentStyle: {
    margin: '0 5px',
  },
});
