export const auditHistoryDetailStyle = (theme) => ({
  auditDetail: {
    padding: '1rem 1rem 1.2rem',
    textAlign: 'left',
    fontSize: '1.1em',

    '@media (max-width: 900px)': {
      padding: '1rem',
    },

    '& > div': {
      border: '1px solid #000',
      borderRadius: '3px',
      padding: '1rem 2rem',
      marginBottom: '1rem',

      '@media (max-width: 900px)': {
        padding: '1rem',
      },
    },
  },
  auditDetailAction: {
    padding: '0 1rem 1.5rem',
  },
  table: {
    minWidth: 500,
    borderCollapse: 'collapse',
    border: 'none',
  },
  tableDivider: {
    position: 'relative',
    '&:before': {
      content: "''",
      width: '1px',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundColor: '#000',
    },
  },
  headerTitle: {
    marginLeft: '2.4%',
  },
  bigTitleCellStyle: {
    padding: '12px 12px 12px 12px',
    border: '0px',
    width: '20%',
  },
  mediumCellStyle: {
    paddingTop: '15px',
    borderBottom: '0px',
  },
  commonTitleCellStyle: {
    padding: '12px 12px 12px 12px',
    border: '0px',
    width: '16.5%',
  },
  paperCellStyle: {
    flexWrap: 'wrap',
    flex: '1 1 auto',
    maxHeight: '55px',
  },
  mediumTitleWidthCell: {
    padding: '12px 12px 12px 12px',
    border: '0px',
    width: '13%',
  },
  loadingStyle: {
    height: 448,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
