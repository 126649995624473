import React, { useEffect, useState } from 'react';
import {
  InputBase,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
  Typography,
  LinearProgress,
  Box,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import LoginLayout from '@components/layout/loginLayout';
import CustomizedInputText from '@components/customInputText';
import CustomizedInputSelect from '@components/customInputSelect';
import { LoginTitle } from '@components/login/loginTitle';
import LoginButton from '@components/login/loginButton';
import { apiRequestStatus } from '@data/constants';
import { customization } from '@customization/default';
import { bindActionCreators } from 'redux';
import * as authActions from '@redux/auth/authActions';
import history from '@utils/history';
import { useStyles } from './style';

function FirstLogin(props) {
  const classes = useStyles();

  const {
    securityQuestionsMasterData,
    token,
    adminRequestError,
    adminRequestStatus,
    userEmail,
    authAction,
  } = props;

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [securityAnswer, setSecurityAnswer] = useState('');
  const [newPasswordValidation, setNewPasswordValidation] = useState('');
  const [confirmPasswordValidation, setConfirmPasswordValidation] = useState('');
  const [securityAnswerValidation, setSecurityAnswerValidation] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [securityQuestionId, setSecurityQuestionId] = useState(null);
  const [securityQuestion, setSecurityQuestion] = useState('');
  const [formErrorValidation, setFormErrorValidation] = useState(null);
  const [strongPass, setStrongPass] = React.useState(0);
  const [isFormValid, setIsFormValid] = React.useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowPasswordConfirmation = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  const handlePassword = (event) => {
    const passwordValidationRegex = /^(?=.{8,}$)(?=.*[0-9])(?=.*[a-zA-Z]).*?$/;
    setNewPassword(event.target.value);
    setNewPasswordValidation(
      !passwordValidationRegex.test(event.target.value)
        ? customization.firstLogin.newPasswordErrorMessage
        : null,
    );
  };

  const barColor = (strength) => {
    if (strength >= 80) {
      return classes.strengthHigh;
    }

    if (strength >= 50) {
      return classes.strengthMedium;
    }

    return classes.strengthLow;
  };

  useEffect(() => {
    let initialStrength = 0;

    if (newPassword.length >= 8) {
      initialStrength += 20;
    }

    const checkNumeric = /\d+/;
    if (checkNumeric.test(newPassword)) {
      initialStrength += 20;
    }

    const checkLetter = /[a-zA-Z]+/;
    if (checkLetter.test(newPassword)) {
      initialStrength += 20;
    }

    const checkSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    if (checkSpecialChar.test(newPassword)) {
      initialStrength += 40;
    }

    setStrongPass(newPassword.length >= 3 ? initialStrength : 0);
  }, [newPassword, newPasswordValidation]);

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    setConfirmPasswordValidation(
      newPassword !== event.target.value
        ? customization.firstLogin.confirmPasswordErrorMessage
        : null,
    );
  };

  useEffect(() => {
    if (confirmPassword === '') {
      setConfirmPasswordValidation(null);
    }
  }, [confirmPassword]);

  const handleQuestionChange = (event) => {
    setSecurityQuestion(event.target.value);
    setSecurityQuestionId(Number(event.currentTarget.getAttribute('data-question-id')));
  };

  const handleFirstLoginAttempt = async () => {
    setFormErrorValidation(null);
    authAction.adminClearErrorState();
    setFormErrorValidation('');

    try {
      const transformedNewUserParams = {
        new_password: newPassword,
        confirm_new_password: confirmPassword,
        security_qa: {
          security_answer: securityAnswer,
          security_question_id: securityQuestionId,
        },
      };
      const response = await authAction.adminSetNewPassword(
        token,
        transformedNewUserParams,
      );
      if (adminRequestError?.message) {
        const transformedError = transformServerError(adminRequestError?.message);
        throw new Error(transformedError);
      }
      if (!response?.data) return;
      history.push('/admin/dashboard/view-users');
    } catch (error) {
      setFormErrorValidation(error.message);
    }
  };

  useEffect(() => {
    const checkFormValid = () => {
      const newPasswordCheckValue = newPassword !== '' || false;
      const confirmNewPasswordCheckValue = confirmPassword !== '' || false;
      const securityQuestionIdCheckValue = securityQuestionId !== null || false;
      const securityAnswerCheckValue = securityAnswer !== '' || false;

      return {
        newPasswordCheckValue,
        confirmNewPasswordCheckValue,
        securityQuestionIdCheckValue,
        securityAnswerCheckValue,
      };
    };

    if (formErrorValidation === '') {
      const checkFormValidity = checkFormValid();
      if (
        !checkFormValidity.newPasswordCheckValue ||
        !checkFormValidity.confirmNewPasswordCheckValue
      ) {
        const formErrorValidationTemp = customization.login.passwordErrorMessage;
        setFormErrorValidation(formErrorValidationTemp);
      } else if (!checkFormValidity.securityAnswerCheckValue) {
        const formErrorValidationTemp = customization.login.securityAnswerErrorMessage;
        setFormErrorValidation(formErrorValidationTemp);
      } else if (!checkFormValidity.securityQuestionIdCheckValue) {
        const formErrorValidationTemp = customization.login.securityQuestionErrorMessage;
        setFormErrorValidation(formErrorValidationTemp);
      }
    }
  }, [
    confirmPassword,
    formErrorValidation,
    newPassword,
    securityAnswer,
    securityQuestionId,
  ]);

  const transformServerError = (message) => {
    const USER_ANSWER_ERROR =
      /Your entered password or security question does not conform to the defined rules/i.test(
        message,
      );

    if (USER_ANSWER_ERROR) {
      return customization.login.securityQuestionAndAnswerErrorMessage;
    }

    return message;
  };

  const handleSecurityAnswer = (event) => {
    const securityAnswerValidationRegex =
      /^(?=.{1,50}$)[a-z|A-Z|0-9]+(?: [a-z|A-Z|0-9]+)*$/;
    setSecurityAnswer(event.target.value);
    setSecurityAnswerValidation(
      !securityAnswerValidationRegex.test(event.target.value)
        ? customization.firstLogin.securityAnswerErrorMessage
        : null,
    );
  };

  useEffect(() => {
    if (securityAnswer === '') {
      setSecurityAnswerValidation(null);
    }
  }, [securityAnswer]);

  useEffect(() => {
    if (adminRequestStatus === apiRequestStatus.PENDING) {
      setIsFormValid(false);
    } else {
      if (newPassword === '' || confirmPassword === '' || securityAnswer === '') {
        setIsFormValid(false);
      } else {
        if (
          newPasswordValidation !== null ||
          confirmPasswordValidation !== null ||
          securityAnswerValidation !== null
        ) {
          setIsFormValid(false);
        }
        setIsFormValid(true);
      }
    }
  }, [
    adminRequestStatus,
    confirmPassword,
    confirmPasswordValidation,
    newPassword,
    newPasswordValidation,
    securityAnswer,
    securityAnswerValidation,
  ]);

  return (
    <LoginLayout>
      {formErrorValidation && (
        <LoginTitle color="error">{formErrorValidation}</LoginTitle>
      )}

      <Box className={classes.titleContainer}>
        <IconButton
          title="Return to Login"
          aria-label="Return to Login"
          onClick={() => history.push('/login')}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" component="div" className={classes.header}>
          {customization.firstLogin.title}
          <Typography variant="subtitle1" className={classes.subtitle}>
            {customization.firstLogin.titleDesc}
          </Typography>
        </Typography>
      </Box>

      <form className={classes.form}>
        <FormControl>
          <InputBase
            id="emailAddress"
            type={'text'}
            name="emailAddress"
            value={userEmail}
            className={classes.inputPasswordRoot}
            placeholder="Email Address"
            disabled={true}
          />
        </FormControl>

        <FormControl>
          <InputBase
            id="newPassword"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle new password visibility"
                  onClick={handleShowPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            name="newPassword"
            value={newPassword}
            onChange={handlePassword}
            className={classes.inputPasswordRoot}
            error={!!newPasswordValidation}
            placeholder="New Password"
            autoComplete="off"
          />
          {!!newPasswordValidation && (
            <FormHelperText className={classes.errorValidation}>
              {newPasswordValidation}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <InputBase
            id="confirmPassword"
            type={showPasswordConfirmation ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle new password visibility"
                  onClick={handleShowPasswordConfirmation}
                  edge="end"
                >
                  {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            name="confirmPassword"
            value={confirmPassword}
            onChange={handleConfirmPassword}
            className={classes.inputPasswordRoot}
            error={!!confirmPasswordValidation}
            placeholder="Confirm Password"
            autoComplete="off"
          />
          {!!confirmPasswordValidation && (
            <FormHelperText className={classes.errorValidation}>
              {confirmPasswordValidation}
            </FormHelperText>
          )}
        </FormControl>

        <div className={classes.passwordStrengthContainer}>
          <Typography variant="subtitle1" className={classes.subtitle}>
            <b>{customization.firstLogin.passwordStrength}</b>
          </Typography>
          <LinearProgress
            variant="determinate"
            value={strongPass}
            className={barColor(strongPass)}
          />
          <div className={classes.labelContainer}>
            <div className={classes.weakStyle}>{customization.firstLogin.weak}</div>
            <div className={classes.strongStyle}>{customization.firstLogin.strong}</div>
          </div>
        </div>

        <CustomizedInputSelect
          label={customization.firstLogin.securityQuestion}
          name="securityQuestion"
          value={securityQuestion}
          options={securityQuestionsMasterData}
          onChange={handleQuestionChange}
          displayEmpty
        />

        <CustomizedInputText
          name="securityAnswer"
          onChange={handleSecurityAnswer}
          value={securityAnswer}
          error={!!securityAnswerValidation}
          placeholder="Security Answer"
          autoComplete="off" // prevent field to show option/suggestion text input
        >
          {!!securityAnswerValidation && (
            <FormHelperText className={classes.errorValidation}>
              {securityAnswerValidation}
            </FormHelperText>
          )}
        </CustomizedInputText>

        <LoginButton onClick={handleFirstLoginAttempt} disabled={isFormValid === false}>
          {customization.createAccount}
        </LoginButton>
      </form>
    </LoginLayout>
  );
}

FirstLogin.propTypes = {
  classes: PropTypes.object,
  securityQuestionsMasterData: PropTypes.array,
  token: PropTypes.string,
  adminRequestError: PropTypes.object,
  adminRequestStatus: PropTypes.string,
  userEmail: PropTypes.string,
  authAction: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    adminRequestError: state.auth.error,
    adminRequestStatus: state.auth.loading,
    token: state.auth.inMemoryToken.token,
    securityQuestionsMasterData: state.auth.securityQuestionMasterData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authAction: bindActionCreators(authActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstLogin);
