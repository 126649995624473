import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { alertNotificationStyle } from './style';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertNotification(props) {
  const { classes, status, error, data } = props;
  const [openSuccessMessage, setOpenSuccessMessage] = React.useState(false);
  const [openFailedMessage, setOpenFailedMessage] = React.useState(false);

  const handleCloseSuccessMessage = (event) => {
    setOpenSuccessMessage(false);
  };

  const handleCloseFailedMessage = (event) => {
    setOpenFailedMessage(false);
  };

  React.useEffect(() => {
    setOpenSuccessMessage(status === 'RESOLVED');
    setOpenFailedMessage(status === 'REJECTED');
  }, [status]);

  return (
    <div className={classes.root}>
      {status === 'RESOLVED' && (
        <Snackbar
          open={openSuccessMessage}
          autoHideDuration={4000}
          onClose={handleCloseSuccessMessage}
        >
          <Alert onClose={handleCloseSuccessMessage} severity="success">
            {data.message}
          </Alert>
        </Snackbar>
      )}
      {status === 'REJECTED' && (
        <Snackbar
          open={openFailedMessage}
          autoHideDuration={4000}
          onClose={handleCloseFailedMessage}
        >
          <Alert onClose={handleCloseFailedMessage} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

AlertNotification.propTypes = {
  classes: PropTypes.object,
  status: PropTypes.string,
  error: PropTypes.string,
  data: PropTypes.object,
};

export default compose(withStyles(alertNotificationStyle))(AlertNotification);
