import React, { useEffect, useState, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@components/dialogTitle';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { Container } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ThreeDotsIcon from '@assets/icons/three-dots.png';
import moment from 'moment';

import CustomizedSnackbar from '@components/customizedSnackBar';
import AuditHistoryDetailDialog from '@components/auditHistoryDetailDialog';
import { customization } from '@customization/default';
import { getAuditHistoryList } from '@services/api/auditHistory';
import { auditHistoryStyle } from './style';

function AuditHistoryDialog(props) {
  const [auditHistory, setAuditHistory] = useState({});
  const [snackbar, setSnackbar] = useState({
    show: false,
    severity: 'error',
    message: null,
  });
  const [selectedAuditHistory, setSelectedAuditHistory] = useState(null);
  const [openDetailAuditHistory, setOpenDetailAuditHistory] = useState(false);

  const { classes, bindCloseAuditHistory, auditData, token, timezone, operationTag } =
    props;

  useEffect(() => {
    const mappingAuditHistory = async () => {
      try {
        if (props.openAuditHistoryDialog) {
          const { id } = auditData;
          const response = await getAuditHistoryList(operationTag, id, token);
          setAuditHistory({ ...response.data });
        }
      } catch (error) {
        if (error.response.status === 404) {
          setSnackbar({ show: true, severity: 'error', message: 'Not Found' });
        } else {
          setSnackbar({
            show: true,
            severity: 'error',
            message: error?.response?.data?.message || error.message,
          });
        }
      }
    };

    mappingAuditHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openAuditHistoryDialog]);

  const renderAuditHistoryTableHeader = () => {
    const header = [
      { headerName: 'Activity', id: 1 },
      { headerName: 'Date', id: 2 },
      { headerName: 'User', id: 3 },
      { headerName: 'Action', id: 4 },
    ];

    const auditHistoryHeader = header.map((header) => (
      <TableCell
        className={[
          classes.commonTitleCellStyleAuditHistory,
          header.headerName === 'Action' && classes.actionColumnWidth,
        ].join(' ')}
        key={header.id}
      >
        <Typography variant="h6">
          <b>{header.headerName}</b>
        </Typography>
      </TableCell>
    ));

    return auditHistoryHeader;
  };

  const renderAuditHistoryTableContent = () => {
    const auditHistoryBody =
      auditHistory?.events?.length > 0 ? (
        <>
          {auditHistory?.events?.map((item) => (
            <TableRow
              className={[classes.titleRowStyleAuditHistory, classes.cellBorder].join(
                ' ',
              )}
              key={item?.id}
            >
              <TableCell
                key="search-type"
                className={classes.commonTitleCellStyleAuditHistory}
              >
                <b>{item?.activity_type}</b>
              </TableCell>
              <TableCell
                key="search-code"
                className={classes.commonTitleCellStyleAuditHistory}
              >
                <b>
                  {moment(item?.updated_date)
                    .utc()
                    .tz(timezone)
                    .format('DD/MM/YYYY - hh:mm a')}
                </b>
              </TableCell>
              <TableCell
                key="search-fullname"
                className={classes.commonTitleCellStyleAuditHistory}
              >
                <b>{item?.updated_by}</b>
              </TableCell>
              <TableCell
                key="search-action"
                className={[
                  classes.commonTitleCellStyleAuditHistory,
                  classes.buttonBackCellWidth,
                ].join(' ')}
              >
                <Button
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  variant="outlined"
                  className={classes.moreButtonStyle}
                  onClick={() => {
                    setSelectedAuditHistory(item);
                    setOpenDetailAuditHistory(true);
                  }}
                >
                  <img
                    src={ThreeDotsIcon}
                    alt="threedot_icon"
                    loading="lazy"
                    className={classes.iconMoreBtnStyle}
                  />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </>
      ) : (
        <>
          <TableRow
            className={[classes.titleRowStyleAuditHistory, classes.cellBorder].join(' ')}
          >
            <TableCell key="search-type" className={classes.noRecordFoundStyle}>
              {customization.auditHistory.noRecord}
            </TableCell>
          </TableRow>
        </>
      );
    return auditHistoryBody;
  };

  const handleClose = () => {
    bindCloseAuditHistory();
    setAuditHistory({});
  };

  const handleCloseDetailAuditHistoryDialog = () => {
    setOpenDetailAuditHistory(false);
  };

  return (
    <Fragment>
      <AuditHistoryDetailDialog
        open={openDetailAuditHistory}
        token={token}
        handleClose={handleCloseDetailAuditHistoryDialog}
        data={selectedAuditHistory}
        operationTag={operationTag}
      />
      <Dialog fullWidth maxWidth="lg" open={props.openAuditHistoryDialog}>
        <DialogTitle
          id="approval-dialog-slide-title"
          onClose={handleClose}
          className={classes.dialogTitle}
        >
          <b>
            {customization.auditHistory.title} ({auditHistory?.auditee_name})
          </b>
        </DialogTitle>

        <TableContainer className={classes.tableContainer}>
          <Table className={classes.modalTable} aria-label="table">
            <TableHead>
              <TableRow
                className={classes.titleRowStyleAuditHistory}
                key="transaction-table-header"
              >
                {renderAuditHistoryTableHeader()}
              </TableRow>
            </TableHead>

            <TableBody>{renderAuditHistoryTableContent()}</TableBody>
          </Table>

          <Container className={classes.auditHistoryButtonWrapper}>
            <Button
              type="button"
              variant="contained"
              className={classes.auditHistoryButton}
              onClick={handleClose}
            >
              {customization.back}
            </Button>
          </Container>
        </TableContainer>

        <CustomizedSnackbar
          message={snackbar.message}
          showBar={snackbar.show}
          severity={snackbar.severity}
          handleClose={() =>
            setSnackbar({ show: false, severity: 'error', message: null })
          }
        />
      </Dialog>
    </Fragment>
  );
}

AuditHistoryDialog.propTypes = {
  classes: PropTypes.object,
  openAuditHistoryDialog: PropTypes.bool,
  bindCloseAuditHistory: PropTypes.func,
  auditData: PropTypes.object,
  token: PropTypes.string,
  operationTag: PropTypes.string,
  timezone: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
    timezone: state.auth.timezone,
  };
};

const withConnect = connect(mapStateToProps, null);
export default compose(withStyles(auditHistoryStyle), withConnect)(AuditHistoryDialog);
