import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './style';

function LoginTitle(props) {
  const { color, children, ...otherProps } = props;
  const classes = useStyles(props);

  return (
    <Typography
      component="p"
      variant="subtitle1"
      className={classes.loginTitle}
      {...otherProps}
    >
      {children}
    </Typography>
  );
}

LoginTitle.propTypes = {
  color: PropTypes.oneOf(['error', 'main']).isRequired,
  children: PropTypes.node,
};

export { LoginTitle };
