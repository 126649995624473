import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/styles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { dialogErrorStyle } from './style';

const ErrorMessageDialog = (props) => {
  const { classes, openSuccess, bindHandleClose, errorData } = props;
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xs"
        onClose={bindHandleClose}
        open={openSuccess}
        classes={{
          paper: classes.paperErrorMessageStyle,
        }}
      >
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <IconButton
                edge="start"
                color="inherit"
                onClick={bindHandleClose}
                aria-label="close"
                className={classes.closeButton}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
        <DialogContent>
          <Box p={3}>
            <div className={classes.errorIconContainerStyle}>
              <ErrorOutlineOutlinedIcon
                fontSize="inherit"
                className={classes.errorIcon}
              ></ErrorOutlineOutlinedIcon>
            </div>
            <div className={classes.errorMsgContainerStyle}>
              <Typography className={classes.errorMessage}>
                <b>{errorData}</b>
              </Typography>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ErrorMessageDialog.propTypes = {
  classes: PropTypes.object,
  openSuccess: PropTypes.bool,
  bindHandleClose: PropTypes.func,
  errorData: PropTypes.string,
};

export default compose(withStyles(dialogErrorStyle))(ErrorMessageDialog);
