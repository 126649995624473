export const transactionListViewStyle = (theme) => ({
  toolbar: {
    minHeight: 100,
  },
  container: {
    marginTop: 10,
  },
  checkboxWrapper: {
    marginLeft: 10,
  },
  typography: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
    },
  },
  table: {
    minWidth: 500,
    borderCollapse: 'collapse',
  },
  titleRowStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1 1 auto',
  },
  emptyTableCellStyle: {
    border: '0px',
    padding: '6px',
  },
  bigTitleCellStyle: {
    padding: '12px 12px 12px 12px',
    border: '0px',
    width: '20%',
  },
  commonTitleCellStyle: {
    padding: '12px 12px 12px 12px',
    border: '0px',
    width: '16.5%',
  },
  actionTitleCellStyle: {
    padding: '12px',
    border: '0px',
    width: '7%',
  },
  smallTitleCellStyle: {
    padding: '12px 0',
    border: '0px',
    width: '7%',
  },
  paperCellStyle: {
    border: '1px solid #2D5326 !important',
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1 1 auto',
    maxHeight: '55px',
  },
  paperActiveCellStyle: {
    border: '1px solid #2D5326 !important',
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1 1 auto',
    maxHeight: '55px',
    borderRadius: '4px',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  paperInactiveCellStyle: {
    border: '1px solid #CF2E56 !important',
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1 1 auto',
    backgroundColor: '#FEF6F6',
    maxHeight: '55px',
    borderRadius: '4px',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  bigCellStyle: {
    display: 'flex',
    flexDirection: 'row',
    width: '20%',
    paddingTop: '15px',
    borderBottom: '0px',
    '& img': {
      height: '30px',
      marginRight: '25px',
    },
  },
  commonCellStyle: {
    display: 'flex',
    flexDirection: 'row',
    width: '16.5%',
    paddingTop: '15px',
    borderBottom: '0px',
    '& img': {
      height: '30px',
      marginRight: '25px',
    },
  },
  smallCellStyle: {
    display: 'flex',
    flexDirection: 'row',
    width: '7%',
    paddingTop: '15px',
    borderBottom: '0px',
    '& img': {
      height: '30px',
      marginRight: '25px',
    },
  },
  btnActionCellStyle: {
    width: '7%',
    paddingTop: '12px',
    paddingRight: '0px',
    borderBottom: '0px',
  },
  buttonSection: {
    paddingTop: 5,
    paddingBottom: 15,
  },
  moreButtonStyle: {
    backgroundColor: '#EEEEF0',
    border: '1px solid #777778',
    borderRadius: '10px',
    height: '30px',
  },
  iconMoreBtnStyle: {
    maxHeight: '12px',
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  loading: {
    textAlign: 'center',
  },
});
