import { apiRequestStatus } from '@data/constants';
import authTypes from './authTypes';

const initialState = {
  loading: '',
  loadingResetPassword: '',
  timezone: '',
  unlockRequestMessage: {},
  unlockConfirmMessage: {},
  securityQuestionMasterData: [],
  inMemoryToken: {},
  error: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    // LOG IN ==============================================================================
    case authTypes.LOGIN_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
      };
    case authTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        timezone: action?.payload?.data.timezone,
        inMemoryToken: {
          token: action?.payload?.access_token,
          tokenExpiry: action?.payload?.expires_in,
        },
        error: {},
      };
    case authTypes.LOGIN_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    // LOG OUT ==============================================================================
    case authTypes.LOGOUT_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
      };
    case authTypes.LOGOUT_SUCCESS:
      return initialState;
    case authTypes.LOGOUT_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    // REFRESH TOKEN ==============================================================================
    case authTypes.REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
      };
    case authTypes.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        inMemoryToken: {
          token: action.payload.access_token,
          tokenExpiry: action.payload.expires_in,
        },
        timezone: action.payload.timezone,
        loading: apiRequestStatus.RESOLVED,
      };
    case authTypes.REFRESH_TOKEN_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    // MULTIFACTOR AUTHENTICATION==============================================================================
    case authTypes.MULTI_FACTOR_AUTHENTICATION_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
      };
    case authTypes.MULTI_FACTOR_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        inMemoryToken: {
          token: action.payload.access_token,
          tokenExpiry: action.payload.expires_in,
        },
      };
    case authTypes.MULTI_FACTOR_AUTHENTICATION_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    // NEW PASSWORD TOKEN =======================================================================
    case authTypes.NEW_PASSWORD_REQUEST:
      return {
        ...state,
        loadingResetPassword: apiRequestStatus.PENDING,
      };
    case authTypes.NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        loadingResetPassword: apiRequestStatus.RESOLVED,
        timezone: action?.payload?.data.timezone,
        inMemoryToken: {
          token: action.payload.access_token,
          tokenExpiry: action.payload.expires_in,
        },
      };
    case authTypes.NEW_PASSWORD_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        loadingResetPassword: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    // VERIFY TOKEN =======================================================================
    case authTypes.VERIFY_TOKEN_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
      };
    case authTypes.VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        securityQuestionMasterData: action.payload?.data?.security_questions || [],
        inMemoryToken: {
          token: action.payload.access_token,
          tokenExpiry: action.payload.expires_in,
        },
      };
    case authTypes.VERIFY_TOKEN_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    // UNLOCK ACCOUNT REQUEST =======================================================================
    case authTypes.UNLOCK_ACCOUNT_REQ_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case authTypes.UNLOCK_ACCOUNT_REQ_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        unlockRequestMessage: action.payload,
      };
    case authTypes.UNLOCK_ACCOUNT_REQ_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    // UNLOCK ACCOUNT CONFIRM =======================================================================
    case authTypes.UNLOCK_ACCOUNT_CONFIRM_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case authTypes.UNLOCK_ACCOUNT_CONFIRM_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        unlockConfirmMessage: action.payload,
      };
    case authTypes.UNLOCK_ACCOUNT_CONFIRM_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    case authTypes.CLEAR_ERROR_STATE:
      return {
        ...state,
        error: {},
      };
    case 'CLEAR_STATE':
      return initialState;

    default:
      return state;
  }
};

export default authReducer;
