import { transactionTypeFrom } from '@data/constants';
import { get, post, put, deleteRequest } from './baseApi';

const getSpendCategoryList = async (user_id, token) => {
  return get(`/admin/transaction/${user_id}/spend-categories`, token);
};

const uploadReceipt = async (params, token, options) => {
  return post(`/admin/transaction/receipt`, params, token, options);
};

const getReceipt = async (txn_id, user_id, token) => {
  return get(
    `/admin/transaction/receipt?transaction_id=${txn_id}&user_id=${user_id}`,
    token,
  );
};

const deleteReceipt = async (txn_receipt_id, account_id, token) => {
  return deleteRequest(
    `/admin/transaction/receipt?transaction_receipt_id=${txn_receipt_id}&account_id=${account_id}`,
    token,
  );
};

const getTransactionAllocation = async (
  token,
  transactionId,
  keyword = '',
  defaultLocation = 0,
) => {
  return get(
    `/admin/transaction/search-allocation?txn_detail_id=${transactionId}&keyword=${keyword}&default_location=${defaultLocation}`,
    token,
  );
};

const disputeExpense = async (params, token) => {
  return post(`/admin/transaction/dispute`, params, token);
};

const cancelDisputeExpense = async (params, token) => {
  return put(`/admin/transaction/dispute`, params, token);
};

const changeSpendCategory = async (params, token) => {
  return put(`/admin/transaction/spend-category`, params, token);
};

const getTransactionDetails = async (module_id, transaction_id, token, typeData) => {
  if (typeData === transactionTypeFrom.LOCATION) {
    return get(
      `/admin/transaction/${module_id}/detail?txn_detail_id=${transaction_id}&is_location=1`,
      token,
    );
  }
  return get(
    `/admin/transaction/${module_id}/detail?txn_detail_id=${transaction_id}&is_location=0`,
    token,
  );
};

const splitTransactionAllocation = async (params, token) => {
  return post('/admin/transaction/split', params, token);
};

const getAccountAllocation = async (token, id) => {
  return get(`/admin/transaction/account-allocation?txn_detail_id=${id}`, token);
};

const getTransactionAuditById = async (id, token) => {
  return get(`/admin/transaction/${id}/audit`, token);
};

const deleteSplitAccount = async (txn_id, account_id, token) => {
  return deleteRequest(
    `/admin/transaction/split?txn_detail_id=${txn_id}&account_id=${account_id}`,
    token,
  );
};

export {
  getSpendCategoryList,
  uploadReceipt,
  deleteReceipt,
  getReceipt,
  disputeExpense,
  cancelDisputeExpense,
  changeSpendCategory,
  getTransactionAllocation,
  splitTransactionAllocation,
  getAccountAllocation,
  getTransactionAuditById,
  deleteSplitAccount,
  getTransactionDetails,
};
