const paymentCardActivityStatus = {
  SUBMITTED: 'SUBMITTED',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  COMPLETED: 'COMPLETED',
};

const userAccessStatus = {
  NOT_AVAILABLE: 'NA',
  TEMP_LOCK: 'TL',
  FULL_LOCK: 'FL',
  CANCELLED: 'C',
  DEACTIVATED: 'D',
  ACTIVE: 'A',
};

const registrationStatus = {
  PROVISIONED: 'P',
  ACTIVATED: 'P',
  COMPLETED: 'C',
};

const userTypes = {
  ADMINISTRATOR: 'ADMIN',
  DELEGATE: 'DELEGATE',
  OBSERVER: 'OBSERVER',
  NONE: 'NONE',
};

const userLocationType = {
  LOCATION: 'LOCATION',
  CLIENT: 'CLIENT',
};

const cardStatus = {
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
  EXCEEDED_INCORRECT_PIN: 'EXCEEDED_INCORRECT_PIN',
  FRAUD_DETECTED: 'FRAUD_DETECTED',
  INACTIVE: 'INACTIVE',
  INACTIVE_LOST_STOLEN: 'INACTIVE_LOST_STOLEN',
  BLOCKED: 'BLOCKED',
  LOCKED: 'LOCKED',
  PENDING: 'PENDING',
};

const activitiesStatus = {
  OPEN: 'SUBMITTED',
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED',
  APPROVED: 'APPROVED',
  FINAL: 'FINAL',
};

const activitiesSearchType = [
  { label: 'Activity Type', value: 'activity_type' },
  { label: 'Activity Initiator Name', value: 'initiator_name' },
  { label: 'User Name', value: 'user_name' },
  { label: 'Location Code/Description', value: 'location' },
];

const activitiesStatusFilter = [
  { label: 'All Activities', value: '' },
  { label: 'Open', value: 'SUBMITTED' },
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'Approved', value: 'APPROVED' },
];

const activitiesType = {
  CARD_NEW: {
    label: 'New Card Application',
    value: 'NEW_CARD_APPLICATION',
  },
  CARD_CHANGE: {
    label: 'Card Name Changed',
    value: 'CARD_NAME_CHANGE',
  },
  CARD_REPLACEMENT: {
    label: 'Card Replacement',
    value: 'CARD_REPLACEMENT',
  },
  CARD_EXPENSE: {
    label: 'Disputed Expense',
    value: 'EXPENSE_DISPUTE',
  },
  DISPUTE_TRANSACTION: {
    label: 'Dispute Transaction',
    value: 'DISPUTE_TRANSACTION',
  },
  CARD_BLOCK: {
    label: 'Card Blocked',
    value: 'CARD_BLOCK',
  },
  LOCATION_NEW: {
    label: 'New Location',
    value: 'NEW_LOCATION',
  },
  LOCATION_EDIT: {
    label: 'Edit Location',
    value: 'EDIT_LOCATION',
  },
  LOCATION_BLOCK: {
    label: 'Deactivate Location',
    value: 'DEACTIVATE_LOCATION',
  },
  LOCATION_GROUP_NEW: {
    label: 'New Location Group',
    value: 'NEW_LOCATION_GROUP',
  },
  LOCATION_GROUP_EDIT: {
    label: 'Edit Location Group',
    value: 'EDIT_LOCATION_GROUP',
  },
  USER_NEW: {
    label: 'Add User',
    value: 'ADD_USER',
  },
  USER_EDIT: {
    label: 'Edit User',
    value: 'EDIT_USER',
  },
  USER_BLOCK: {
    label: 'Deactivate User',
    value: 'DEACTIVATE_USER',
  },
  USER_TYPE_NEW: {
    label: 'New User Type',
    value: 'NEW_USER_TYPE',
  },
  USER_TYPE_EDIT: {
    label: 'Edit User Type',
    value: 'EDIT_USER_TYPE',
  },
  USER_LOG_IN: {
    label: 'User Log in',
    value: 'USER_LOG_IN',
  },
  USER_LOG_IN_BIO: {
    label: 'User Log in Biometric',
    value: 'USER_LOG_IN_BIO',
  },
  USER_LOG_IN_PIN: {
    label: 'User Log in PIN',
    value: 'USER_LOG_IN_PIN',
  },
  DEACTIVATE_USER: {
    label: 'Deactivate User',
    value: 'DEACTIVATE_USER',
  },
  LOCK_USER: {
    label: 'Lock User',
    value: 'LOCK_USER',
  },
  LOCK_USER_ADMIN: {
    label: 'Lock User Admin',
    value: 'LOCK_USER_ADMIN',
  },
  LOCK_DUE_TO_PASSWORD: {
    label: 'Lock Due To Password',
    value: 'LOCK_USER_PASSWORD',
  },
  LOCK_DUE_TO_ADMIN: {
    label: 'Lock Due To Admin',
    value: 'LOCK_ADMIN_PASSWORD',
  },
  LOCK_DUE_TO_MFA: {
    label: 'Lock Due To MFA',
    value: 'LOCK_USER_MFA',
  },
  UNLOCK_USER: {
    label: 'Unlock User',
    value: 'UNLOCK_USER',
  },
  PROVISION_UNLOCK_CODE: {
    label: 'Provision Unlock Code',
    value: 'PROVISION_UNLOCK_CODE',
  },
  PROVISION_UNLOCK_CODE_VERBAL: {
    label: 'Provision Unlock Code Verbal',
    value: 'PROVISION_UNLOCK_CODE_VERBAL',
  },
  FORGOT_PASSWORD: {
    label: 'Forgot Password',
    value: 'FORGOT_PASSWORD',
  },
  CONFIGURATION_CHANGE: {
    label: 'Configuration Changes',
    value: 'CONFIGURATION_CHANGE',
  },
  CHANGE_PIN: {
    label: 'Change Log In Pin',
    value: 'CHANGE_PIN',
  },
  NEW_BANK_ACCOUNT: {
    label: 'New Bank Account',
    value: 'NEW_BANK_ACCOUNT',
  },
  EDIT_BANK_ACCOUNT: {
    label: 'Edit Bank Account',
    value: 'EDIT_BANK_ACCOUNT',
  },
  FUNDS_TRANSFER: {
    label: 'Funds Transfer',
    value: 'FUNDS_TRANSFER',
  },
  CHANGE_TRANSACTION_ALLOCATION: {
    label: 'Change Transaction Allocation',
    value: 'CHANGE_TRANSACTION_ALLOCATION',
  },
};

const cardActivitiesType = {
  CHANGE_CARD_PIN: {
    value: 'Change Card PIN',
  },
  ACTIVATE_CARD: {
    value: 'Activate Card',
  },
  BLOCK_CARD: {
    value: 'Block Card',
  },
  UNBLOCK_CARD: {
    value: 'Unblock Card',
  },
  CANCEL_CARD: {
    value: 'Cancel Card',
  },
  REPLACE_CARD: {
    value: 'Replace Card',
  },
  CHANGE_CARD_HOLDER_NAME: {
    value: 'Change Card Holder Name',
  },
  CHANGE_CARD_HOLDER_NAME_APPROVED: {
    value: 'Change Card Holder Name (Approved)',
  },
  CHANGE_CARD_HOLDER_NAME_REJECTED: {
    value: 'Change Card Holder Name (Rejected)',
  },
  OTHER_CARD_SETTINGS_UPDATE: {
    value: 'Other Card Settings Update',
  },
  ADD_CARD_TO_WALLET: {
    value: 'Add Card to Wallet',
  },
};

const activitiesCell = {
  ACTIVITY_INITIATOR: 'Activity Initiator',
  LOCATION_DETAILS: 'Location Details',
  CHANGE: 'Change',
  CHANGE_CHILD_DELEGATION: 'Change on Child Delegation',
  USERTYPE_DELEGATION: 'User Type/Card Count Matrix',
  PARENT_DELEGATION: 'Parent delegation matrix',
  CHILD_DELEGATION: 'Child delegation matrix',
  CHILD_DELEGATION_CHANGE: 'Child delegation',
  CARD_HOLDER: 'Card Holder',
  CARD_DETAILS: 'Card Details',
  NEW_USER_DETAIL: 'New User Details',
  USER_DETAIL: 'User Details',
  EDIT_USER_DETAIL: 'Edit User Details',
  DEACTIVATE_USER_DETAIL: 'Deactivate User Details',
  TRANSACTION_DETAILS: 'Transaction Details',
  ALLOCATED_ACCOUNT: 'Allocated Account',
  UPLOADED_TAX_INVOICES: 'Uploaded Tax Invoices',
  BANK_ACCOUNT_DETAILS: 'Bank Account Details',
  SOURCE_ACCOUNT: 'Source Account',
  DESTINATION_ACCOUNT: 'Destination Account',
  TRANSFER_DETAILS: 'Transfer Details',
  NEW_SPLIT: 'New Split',
};

const activitiesDetailLabel = {
  OLDVALUE: 'Old Value',
  NEWVALUE: 'New Value',
  ATTRIBUTEFIELD: 'Attribute/field',
  NO_ACTIVE_CARDS: 'User types / Active cards is unavailable in this default location',
};

const apiRequestStatus = {
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  RESOLVED: 'RESOLVED',
  REJECTED: 'REJECTED',
};

const dialog = {
  APPROVAL_DIALOG: 'approvalDialog',
  REJECTION_DIALOG: 'rejectionDialog',
};

const aliases = {
  APPROVED: 'A',
  COMPLETED: 'C',
  REJECTED: 'R',
  SUBMITTED: 'S',
};

const timeConversion = {
  MINUTE_IN_MILISECOND: 60000,
};

const reasonDescriptionLimit = {
  CARD_APPLICATION_REJECTION: 300,
  CARD_APPLICATION_APPROVAL: 300,
};

const windowNavigation = {
  RELOAD: 'reload',
  NAVIGATION: 'navigation',
};

const userRoles = {
  ADMIN: 'ADMIN',
  DELEGATE: 'DELEGATE',
  OBSERVER: 'OBSERVER',
  NONE: 'NONE',
};

const scanningStatus = {
  COMPLETED: 'COMPLETED',
  NONE: 'NONE',
  INPROGRESS: 'INPROGRESS',
};

const userRoleOptions = [
  { value: 'DELEGATE', label: 'Delegate' },
  { value: 'ADMIN', label: 'Admin' },
  { value: 'OBSERVER', label: 'Observer' },
  { value: 'NONE', label: 'None' },
];

const splitMethodOptions = [
  { value: 'PERCENTAGE', label: 'By Percentage' },
  { value: 'AMOUNT', label: 'By Amount' },
  { value: 'EQUAL', label: 'Equal Portions' },
];

const dateFormatOptions = [
  { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
  { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
];

const errorLocationGroupContain = {
  INTERNALNAMEUNIQUE: 'Internal Name Must Be Unique',
  MAXIMUM: 'have maximum Location-Group',
};

const errorLocationGroupMessage = {
  INTERNALNAMEUNIQUE:
    'The same internal name already exists for your selected Company. Please ensure Internal Name are unique per Company.',
  MAXIMUM:
    'The Company you selected already has a maximum of 6 groups. Please select a different Company to continue.',
};

const userSearchType = [
  { label: 'First Name', value: 'first_name' },
  { label: 'Last Name', value: 'last_name' },
  { label: 'Unique Reference Number', value: 'user_number' },
  { label: 'Mobile Number', value: 'mobile_number' },
  { label: 'Email Address', value: 'email' },
  { label: 'Company', value: 'company' },
  { label: 'Default and Allocated Location Code', value: 'location_code' },
  { label: 'Default and Allocated Location Description', value: 'location_desc' },
];

const cardSearchOptions = [
  { id: '1', label: 'Card Holder First Name', value: 'first_name' },
  { id: '2', label: 'Card Holder Last Name', value: 'last_name' },
  { id: '3', label: 'Card Number', value: 'card_number' },
  { id: '4', label: 'Card Type', value: 'card_type' },
];

const userStatusFilter = [
  { label: 'All Users', value: '' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'Locked', value: 'locked' },
  { label: 'Pending', value: 'pending' },
];

const locationStatusFilter = [
  { label: 'All Locations', value: '' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

const cardStatusFilter = [
  { label: 'All Cards', value: '' },
  { label: 'Active', value: 'active' },
  { label: 'Blocked', value: 'blocked' },
  { label: 'Inactive', value: 'inactive' },
];

const transactionTypeCode = {
  DEPOSIT: 2404,
  EXTERNAL_TRANSACTION_TRANFER_FUND: '2980',
};

const transactionUserAccessStatus = {
  NON_AKTIF: 'D',
};

const transactionTypeFrom = {
  LOCATION: 'location',
  USER: 'user',
  INTERNAL_TRANSACTION_TRANSFER_FUND: 'internal_transaction_transfer_fund',
};

const transactionDetailConfirmationType = {
  DISPUTE_EXPENSE: 'DISPUTE_EXPENSE',
  CANCEL_DISPUTE_EXPENSE: 'CANCEL_DISPUTE_EXPENSE',
  CHANGE_SPEND_CATEGORY: 'CHANGE_SPEND_CATEGORY',
  REMOVE_ACCOUNT_TRANSACTION_ALLOCATION: 'REMOVE_ACCOUNT_TRANSACTION_ALLOCATION',
  DELETE_RECEIPT: 'DELETE_RECEIPT',
};

const availableForCard = [
  { value: true, label: 'No' },
  { value: false, label: 'Yes' },
];

const auditHistoryOperation = {
  LOCATION: 'location',
  USER: 'user',
  CARD: 'card',
};

const userOperationTag = {
  EDIT_USER: 'editUser',
  ADD_USER: 'addUser',
};

const auditHistoryDetailStatus = {
  APPROVED: 'APPROVED',
  SUBMITTED: 'SUBMITTED',
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED',
  SUCCEED: 'SUCCEED',
  FAILED: 'FAILED',
};

const fundTransferOptions = [
  { label: 'To/from user/location', value: 'user-location' },
  { label: 'To bank account', value: 'bank-account' },
];

const fundTransferSearchFilter = [
  { type: 'user', label: 'Name', value: 'NAME' },
  { type: 'user', label: 'Mobile Number', value: 'MOBILE_NUMBER' },
  { type: 'location', label: 'Location Description', value: 'LOCATION_DESCRIPTION' },
  { type: 'location', label: 'Location Code', value: 'LOCATION_CODE' },
];

const receiptStatusType = {
  NOT_MATCH: 'not_match',
  PARTIALLY_MATCH: 'partially_match',
  MATCH: 'match',
};

const userSettingsFilter = [
  { label: 'Location setting', value: 'Locations' },
  { label: 'User setting', value: 'Users' },
  { label: 'Card/Account setting', value: 'Cards/Accounts' },
  { label: 'Other settings', value: 'Others' },
];

export {
  paymentCardActivityStatus,
  userAccessStatus,
  apiRequestStatus,
  dialog,
  aliases,
  timeConversion,
  reasonDescriptionLimit,
  windowNavigation,
  userRoleOptions,
  cardStatus,
  splitMethodOptions,
  dateFormatOptions,
  activitiesStatus,
  activitiesType,
  cardActivitiesType,
  userRoles,
  errorLocationGroupContain,
  errorLocationGroupMessage,
  userTypes,
  userLocationType,
  activitiesCell,
  activitiesDetailLabel,
  activitiesSearchType,
  activitiesStatusFilter,
  userSearchType,
  userStatusFilter,
  locationStatusFilter,
  availableForCard,
  transactionTypeCode,
  transactionTypeFrom,
  transactionUserAccessStatus,
  cardSearchOptions,
  cardStatusFilter,
  auditHistoryOperation,
  userOperationTag,
  auditHistoryDetailStatus,
  transactionDetailConfirmationType,
  fundTransferOptions,
  fundTransferSearchFilter,
  scanningStatus,
  receiptStatusType,
  userSettingsFilter,
  registrationStatus,
};
