import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import PhoneInput from 'react-phone-input-2';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormControl, Select, MenuItem, Grid, InputBase } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import 'react-phone-input-2/lib/style.css';
import TimezoneSelect from 'react-timezone-select';

import { userOperationTag } from '@data/constants';
import BaseComponent from '@containers/BaseComponent';
import FormTextInput from '@components/formTextInput';
import CustomizedSnackBar from '@components/customizedSnackBar';
import ConfirmationStatusDialog from '@components/confirmationUserDialog';
import * as userTypeActions from '@redux/settings/userTypeActions';
import * as userActions from '@redux/user/userActions';
import { customization } from '@customization/default';
import {
  transformUserData,
  transformEditUserData,
  transformRequiredValidation,
  transformPreAddUser,
} from '@utils/transformation';
import { formRootUserStyle, bootStrapInput } from './style';

const BootStrapInput = withStyles(bootStrapInput)((props) => <InputBase {...props} />);
class FormRootUser extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        id: {
          validation: {
            required: false,
          },
          value: '',
          valid: true,
          isValidFormat: true,
          touched: false,
        },
        status: {
          validation: {
            required: true,
          },
          value: !props.userData?.user_access_control?.access_status
            ? customization.userRootForm.statusPending
            : customization.userRootForm.statusActive,
          valid: true,
          isValidFormat: true,
          touched: false,
        },
        company: {
          validation: {
            required: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        userType: {
          validation: {
            required: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        uniqueDepositReference: {
          validation: {
            required: true,
            escapedString: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        legalFirstName: {
          validation: {
            required: true,
            escapedString: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        legalMiddleName: {
          validation: {
            required: false,
            escapedString: true,
          },
          value: '',
          valid: true,
          isValidFormat: true,
          touched: false,
        },
        legalLastName: {
          validation: {
            required: true,
            escapedString: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        emailAddress: {
          validation: {
            required: false,
            isEmail: true,
          },
          value: '',
          valid: true,
          isValidFormat: true,
          touched: false,
        },
        countryCode: {
          validation: {
            required: false,
          },
          value: 'AU',
          valid: true,
          isValidFormat: true,
          touched: false,
        },
        fullMobileNumber: {
          validation: {
            required: false,
            isPhoneNumber: true,
          },
          value: '',
          valid: true,
          isValidFormat: true,
          touched: false,
        },
        defaultLocation: {
          validation: {
            required: false,
          },
          value: '',
          valid: true,
          isValidFormat: true,
          touched: true,
        },
        defaultTimeZone: {
          validation: {
            required: false,
          },
          value: '',
          valid: true,
          isValidFormat: true,
          touched: true,
        },
        countryDialCode: {
          validation: {
            required: false,
          },
          value: '',
          valid: true,
          isValidFormat: true,
          touched: true,
        },
      },
      isFormValid: false,
      userMasterData: {
        companies: [],
        userTypes: [],
        countryCodes: [],
        timeZones: [],
      },
      defaultLocations: [],
      serverErrorMessage: null,
      submitErrorMessage: null,
      confirmationStatus: false,
      openConfirmationStatus: false,
      showSnackBar: false,
      snackBarSeverity: '',
      snackBarMessage: '',
      requiresDefaultLocation: false,
      selectedUserTypeId: '',
      phoneNumberTemp: '',
      tempSelectedDefaultLoc: '',
    };
  }

  fetchUserMasterData = () => {
    this.setState(
      (state) => ({
        ...state,
        userMasterData: {
          companies: this.props.responseCompanies?.data || [],
          userTypes: this.props.responseUserTypes?.data || [],
          countryCodes: this.props.responseCountries?.data || [],
          timeZones: this.props.responseTimeZones?.data || [],
        },
      }),
      () => {
        if (this.state.userMasterData.companies.length === 1) {
          this.setState(
            (state) => ({
              ...state,
              user: {
                ...state.user,
                company: {
                  validation: {
                    required: false,
                  },
                  value: state.userMasterData.companies[0].company_name,
                  valid: true,
                  isValidFormat: true,
                  touched: false,
                },
              },
            }),
            () => {
              const masterData = this.state.userMasterData;
              this.getSelectedCompanyId(masterData.companies[0].id);
            },
          );
        }
      },
    );

    if (
      this.props.userData?.id &&
      this.props.operationTag === userOperationTag.EDIT_USER
    ) {
      this.initEditForm();
    }
  };

  getCompletePropsUserType = (val) => {
    const { userMasterData } = this.state;
    const result = userMasterData.userTypes.filter(
      (data) => data.display_name.toLowerCase() === val.toLowerCase(),
    )[0];
    return result !== undefined ? result : 0;
  };

  initEditForm = async () => {
    const { userData } = this.props;

    await this.getSelectedCompanyId(userData.company_fk);
    this.getSelectedUserTypeId(userData.user_type.id);

    const selectedUserType = await this.getCompletePropsUserType(
      userData.user_type.display_name,
    );
    await this.props.setUserType(selectedUserType);

    const mappedUserData = transformEditUserData(
      this.props.userData,
      this.state.userMasterData,
      this.state.defaultLocations,
    );
    const mappedRequiredData = await transformRequiredValidation(
      mappedUserData.userType,
      this.state.userMasterData.userTypes,
      this.state.user,
    );
    const updatedForm = this.mappedUserToFormRootState(
      mappedUserData,
      mappedRequiredData,
    );
    this.props.setIsFormValid(updatedForm.formIsValid);
    const tempDefaultLoc = updatedForm.user.defaultLocation.value;

    this.setState(
      {
        user: {
          ...updatedForm.user,
          fullMobileNumber: {
            ...updatedForm.user.fullMobileNumber,
            validation: {
              ...updatedForm.user.fullMobileNumber.validation,
              required:
                updatedForm.user.status.value ===
                customization.userRootForm.statusInactive
                  ? false
                  : updatedForm.user.fullMobileNumber.validation.required,
            },
          },
        },
        tempSelectedDefaultLoc: tempDefaultLoc,
        isFormValid: updatedForm.formIsValid,
        requiresDefaultLocation:
          selectedUserType.user_type_transaction_settings?.requires_default_location ||
          false,
      },
      async () => {
        this.setState({ phoneNumberTemp: this.state.user.fullMobileNumber.value });
        // separate country dial code from full mobile number
        const tempCountryCode = {
          countryCode: updatedForm.user.countryCode.value,
          dialCode: updatedForm.user.countryDialCode.value,
        };
        this.onHandlePhoneNumber(
          updatedForm.user.fullMobileNumber.value,
          tempCountryCode,
        );

        const currentDefaultLocation = this.state.defaultLocations.filter(
          (data) => data.code === updatedForm.user.defaultLocation.value,
        )[0];
        await this.props.setDefaultLocation(currentDefaultLocation);
        this.handleDataToProps();

        if (
          this.state.user.fullMobileNumber.value === '' &&
          !this.state.user.fullMobileNumber.validation.required
        ) {
          this.state.user.fullMobileNumber.valid = true;
          this.state.user.fullMobileNumber.isValidFormat = true;
          this.setState({
            user: this.state.user,
            isFormValid: this.checkValidityRequired(this.state.user),
          });
        }
        if (
          this.state.user.emailAddress.value === '' &&
          !this.state.user.emailAddress.validation.required
        ) {
          this.state.user.emailAddress.isValidFormat = true;
          this.setState({
            user: this.state.user,
            isFormValid: this.checkValidityRequired(this.state.user),
          });
        }
      },
    );
  };

  handleSnackBar = () => {
    this.setState({ showSnackBar: false });
  };

  handleDataToProps = () => {
    const transformedUserData = transformUserData(
      this.state.user,
      this.state.userMasterData,
      this.props.operationTag,
      this.props.locationList,
    );
    this.props.bindSubmit(transformedUserData);
  };

  handleCancel = (event) => {
    event.preventDefault();
    this.props.bindClose();
  };

  handleOpenConfirmationStatus = () => {
    this.setState({
      openConfirmationStatus: true,
    });
  };

  handleCloseConfirmationStatus = (event, value, status) => {
    event.preventDefault();
    this.setState(
      {
        confirmationStatus: value,
      },
      () => {
        if (this.state.confirmationStatus === true) {
          this.state.user.status.value = customization.userRootForm.statusInactive;
          this.setState({
            user: this.state.user,
            openConfirmationStatus: false,
          });
        } else if (this.state.confirmationStatus === false) {
          if (!status || status === 'Pending') {
            this.state.user.status.value = customization.userRootForm.statusPending;
          } else {
            this.state.user.status.value = customization.userRootForm.statusActive;
          }
          this.setState({
            user: this.state.user,
            openConfirmationStatus: false,
          });
        }
      },
    );
  };

  handleSelectDefaultLocation = (val) => {
    const { setDefaultLocation } = this.props;
    setDefaultLocation(val);
  };

  getSelectedUserTypeId = (id) => {
    this.setState({ selectedUserTypeId: id });
    this.props.userAction.getCardTypeByUserType(id, this.props.token).then(() => {
      if (this.props.userError.statusCode) {
        this.setState({
          showSnackBar: true,
          snackBarSeverity: 'error',
          snackBarMessage: `Get card type list error: ${this.props.userError.statusCode}`,
          notFound: true,
        });
      }
    });
  };

  setTimeZone = (companyId) => {
    const { userMasterData, user } = this.state;
    const selectedCompany = userMasterData.companies.filter(
      (data) => data.id === companyId,
    )[0];
    user.defaultTimeZone.value = selectedCompany?.timezone || '';
    user.defaultTimeZone.valid = true;
    this.setState({ user });
  };

  onChangeCompany = async (companyId) => {
    await this.getSelectedCompanyId(companyId);
    const { user, defaultLocations, requiresDefaultLocation } = this.state;
    // when company changed, location list will be changed also
    if (requiresDefaultLocation) {
      const result = defaultLocations.filter(
        (data) => data.code === user.defaultLocation.value,
      )[0];
      if (!result) {
        user.defaultLocation.valid = false;
      } else {
        user.defaultLocation.valid = true;
      }
      user.defaultLocation.touch = true;
      await this.onChangeInput(user.defaultLocation.value, 'company');
    }
    this.setState({
      user,
    });
  };

  getSelectedCompanyId = async (companyId) => {
    await this.props.setCompanyId(companyId);

    // set default timezone based on selected company
    this.setTimeZone(companyId);

    await this.props.userAction
      .getUserLocationList(companyId, this.props.token)
      .then(() => {
        if (this.props.userError.statusCode) {
          this.setState({
            showSnackBar: true,
            snackBarSeverity: 'error',
            snackBarMessage: `Get location list error: ${this.props.userError.statusCode}`,
            notFound: true,
          });
        } else {
          const defaultLocationList = [];
          this.props.locationList.forEach((element) => {
            const defaultLocationObj = {
              id: element.id,
              code: element.code,
              default: true,
              description: element.description,
              is_active: false,
              type: 'Location',
            };
            defaultLocationList.push(defaultLocationObj);
          });
          this.setState({
            defaultLocations: defaultLocationList,
          });
        }
      });
  };

  onHandlePhoneNumber = (event, country) => {
    const { user } = this.state;
    const phoneNumber = event.replace(country.dialCode, '');
    user.countryCode.value = country.countryCode.toUpperCase();
    this.setState(
      {
        user: user,
      },
      async () => {
        await this.onChangeInput(phoneNumber, 'fullMobileNumber');
      },
    );
  };

  onChangeInput = async (eventValue, inputIdentifier) => {
    const updateState = this.changeInputWithValidation(
      eventValue,
      this.state.user,
      inputIdentifier,
    );
    this.setState(
      {
        user: updateState.form,
        isFormValid: updateState.valid,
      },
      async () => {
        const { setIsFormValid } = this.props;
        const { userMasterData, user } = this.state;

        if (inputIdentifier === 'defaultLocation') {
          this.setState({
            tempSelectedDefaultLoc: eventValue,
          });
        }
        if (inputIdentifier === 'userType') {
          const selectedUserType = this.getCompletePropsUserType(eventValue);

          if (
            !selectedUserType.user_type_transaction_settings?.requires_default_location
          ) {
            user.defaultLocation.value = '';
          } else {
            user.defaultLocation.value = this.state.tempSelectedDefaultLoc;
          }

          this.setState({
            user,
          });
          await this.props.setUserType(selectedUserType);

          const mappedRequiredData = await transformRequiredValidation(
            eventValue,
            userMasterData.userTypes,
            user,
          );
          const mappedTransformPreAddUser = transformPreAddUser(mappedRequiredData);
          const updatedForm = this.mappedUserToFormRootState(
            mappedTransformPreAddUser,
            mappedRequiredData,
          );
          this.setState(
            {
              user: updatedForm.user,
              isFormValid: updatedForm.formIsValid,
              requiresDefaultLocation:
                selectedUserType.user_type_transaction_settings
                  ?.requires_default_location || false,
            },
            () => {
              if (
                this.state.user.fullMobileNumber.value === '' &&
                !this.state.user.fullMobileNumber.validation.required
              ) {
                this.state.user.fullMobileNumber.isValidFormat = true;
                this.setState({
                  user: this.state.user,
                  isFormValid: this.checkValidityRequired(this.state.user),
                });
              }
              if (
                this.state.user.emailAddress.value === '' &&
                !this.state.user.emailAddress.validation.required
              ) {
                this.state.user.emailAddress.isValidFormat = true;
                this.setState({
                  user: this.state.user,
                  isFormValid: this.checkValidityRequired(this.state.user),
                });
              }
            },
          );
        }
        if (
          inputIdentifier === 'emailAddress' &&
          this.state.user[inputIdentifier].value.trim() === '' &&
          !this.state.user[inputIdentifier].validation.required
        ) {
          this.state.user[inputIdentifier].isValidFormat = true;
          this.setState({
            user: this.state.user,
            isFormValid: this.checkValidityRequired(this.state.user),
          });
        }
        if (
          inputIdentifier === 'fullMobileNumber' &&
          this.state.user[inputIdentifier].value.trim() === '' &&
          !this.state.user[inputIdentifier].validation.required
        ) {
          this.state.user[inputIdentifier].isValidFormat = true;
          this.state.user[inputIdentifier].valid = true;
          this.setState({
            user: this.state.user,
            isFormValid: this.checkValidityRequired(this.state.user),
          });
        }
        if (
          inputIdentifier === 'countryCode' &&
          this.state.user[inputIdentifier].value.trim() === '' &&
          !this.state.user[inputIdentifier].validation.required
        ) {
          this.state.user[inputIdentifier].isValidFormat = true;
          this.setState({
            user: this.state.user,
            isFormValid: this.checkValidityRequired(this.state.user),
          });
        }
        if (
          inputIdentifier === 'status' &&
          this.state.user[inputIdentifier].value ===
            customization.userRootForm.statusInactive
        ) {
          this.handleOpenConfirmationStatus();
        }

        // send form is valid status to parent
        setIsFormValid(this.state.isFormValid);
        if (this.state.isFormValid) {
          this.handleDataToProps();
        }
      },
    );
  };

  componentDidMount() {
    this.fetchUserMasterData();
  }

  renderUniqueDepositRef = () => {
    const { user } = this.state;
    const { classes } = this.props;

    return (
      <Grid id="text-field-unique-deposit" item xs sm md>
        <FormControl className={classes.formControlInput}>
          <div>
            <label className={classes.formLabel}>
              {customization.userRootForm.uniqueDepositLabel}
              {customization.userRootForm.requiredMark}
            </label>
          </div>
          <FormTextInput
            field="uniqueDepositReference"
            touched={user.uniqueDepositReference.touched}
            valid={user.uniqueDepositReference.valid}
            value={user.uniqueDepositReference.value}
            validFormat={user.uniqueDepositReference.isValidFormat}
            onChangeInput={this.onChangeInput}
            validationMessage={customization.requiredError}
            errorMode={customization.userRootForm.invalidString}
            placeholder={customization.userRootForm.uniqueDepositLabel}
            type="text"
          />
        </FormControl>
      </Grid>
    );
  };

  renderFirstMiddleNameField = () => {
    const { classes } = this.props;
    const { user } = this.state;

    return (
      <>
        <Grid item xs={12} sm={12} md={4}>
          <FormControl className={classes.formControl}>
            <div>
              <label className={classes.formLabel}>
                {customization.userRootForm.legalFirstNameLabel}
              </label>
              <span>{customization.userRootForm.requiredMark}</span>
            </div>
            <FormTextInput
              field="legalFirstName"
              touched={user.legalFirstName.touched}
              valid={user.legalFirstName.valid}
              value={user.legalFirstName.value}
              onChangeInput={this.onChangeInput}
              validFormat={user.legalFirstName.isValidFormat}
              validationMessage={customization.requiredError}
              errorMode={customization.userRootForm.invalidString}
              placeholder={customization.userRootForm.legalFirstNameLabel}
              type="text"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <FormControl className={classes.formControl}>
            <div>
              <label className={classes.formLabel}>
                {customization.userRootForm.legalMiddleNameLabel}
              </label>
            </div>
            <FormTextInput
              field="legalMiddleName"
              touched={user.legalMiddleName.touched}
              valid={user.legalMiddleName.valid}
              value={user.legalMiddleName.value}
              onChangeInput={this.onChangeInput}
              validFormat={user.legalMiddleName.isValidFormat}
              validationMessage={customization.requiredError}
              errorMode={customization.userRootForm.invalidString}
              placeholder={customization.userRootForm.legalMiddleNameLabel}
              type="text"
            />
          </FormControl>
        </Grid>
      </>
    );
  };

  renderDefaultLocation = () => {
    const { classes } = this.props;
    const { user, defaultLocations } = this.state;

    return (
      <Grid id="section-4" container className={classes.containerFormSection} spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <FormControl className={classes.formControl}>
            <div>
              <label className={classes.formLabel}>
                {customization.userRootForm.defaultLocationLabel}
                {customization.userRootForm.requiredMark}
              </label>
            </div>
            <Select
              id="defaultLocation"
              value={user.defaultLocation.value}
              displayEmpty
              onChange={(e) => {
                this.onChangeInput(e.target.value, 'defaultLocation');
              }}
              input={<BootStrapInput />}
            >
              <MenuItem disabled value="" key="0">
                <span className={classes.placeholder}>
                  {customization.userRootForm.defaultLocationPlaceHolder}
                </span>
              </MenuItem>
              {!_.isEmpty(defaultLocations) &&
                defaultLocations.map((element) => (
                  <MenuItem
                    key={element.id}
                    value={element.code}
                    onClick={() => this.handleSelectDefaultLocation(element)}
                  >
                    {element.code} - {element.description}
                  </MenuItem>
                ))}
            </Select>
            <span
              style={{
                color: '#d32f2f',
                display:
                  user.defaultLocation.touched && !user.defaultLocation.valid
                    ? 'block'
                    : 'none',
              }}
            >
              {!user.defaultLocation.valid && customization.requiredError}
            </span>
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  renderDefaultTimeZone = () => {
    const { classes } = this.props;
    const { user } = this.state;

    return (
      <Grid id="select-default-timezone" item xs sm={12} md={4}>
        <FormControl className={classes.formControl}>
          <div>
            <label className={classes.formLabel}>
              {customization.userRootForm.defaultTimeZoneLabel}
            </label>
            <span>{customization.userRootForm.requiredMark}</span>
          </div>
          <TimezoneSelect
            value={user.defaultTimeZone.value}
            onChange={(e) => {
              this.onChangeInput(e.value, 'defaultTimeZone');
            }}
          />
        </FormControl>
      </Grid>
    );
  };

  statusMenuItems = () => {
    const { userData } = this.props;
    const { status } = this.state.user;
    const items = [];

    if (!userData.user_access_control?.access_status)
      items.push(customization.userRootForm.statusPending);
    else
      items.push(
        customization.userRootForm.statusActive,
        customization.userRootForm.statusInactive,
      );

    if (status.value === customization.userRootForm.statusLocked)
      items.push(customization.userRootForm.statusLocked);

    return items;
  };

  render() {
    const {
      user: {
        status,
        company,
        userType,
        legalLastName,
        emailAddress,
        fullMobileNumber,
        countryCode,
      },
      userMasterData,
      showSnackBar,
      snackBarSeverity,
      snackBarMessage,
      requiresDefaultLocation,
      phoneNumberTemp,
    } = this.state;

    const { classes, operationTag, userData } = this.props;
    return (
      <div>
        <CustomizedSnackBar
          showBar={showSnackBar}
          severity={snackBarSeverity}
          message={snackBarMessage}
          handleClose={this.handleSnackBar}
        ></CustomizedSnackBar>
        <ConfirmationStatusDialog
          openConfirmation={this.state.openConfirmationStatus}
          bindHandleCloseConfirmationStatus={this.handleCloseConfirmationStatus}
          message={customization.confirmationMessageDialog.questionMessage}
          status={
            operationTag === userOperationTag.EDIT_USER &&
            userData.user_access_control?.access_status
          }
        />
        <form>
          <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={1}>
            <Grid
              id="section-1"
              container
              spacing={2}
              className={classes.containerFormSection}
            >
              {operationTag === userOperationTag.EDIT_USER && (
                <Grid id="select-status" item xs={12} sm={12} md={2} lg={2}>
                  <FormControl className={classes.formControl}>
                    <div>
                      <label className={classes.formLabel}>
                        {customization.userRootForm.statusLabel}
                      </label>
                      <span>{customization.userRootForm.requiredMark}</span>
                    </div>
                    <Select
                      id="status"
                      disabled={status.value === 'Locked' || status.value === 'Pending'}
                      value={status.value}
                      onChange={(e) => {
                        this.onChangeInput(e.target.value, 'status');
                      }}
                      input={<BootStrapInput />}
                    >
                      {this.statusMenuItems().map((status) => (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {userMasterData.companies.length > 1 && (
                <Grid id="select-company" item xs={12} sm={12} md={3} lg={3}>
                  <FormControl className={classes.formControl}>
                    <div>
                      <label className={classes.formLabel}>
                        {customization.userRootForm.companyLabel}
                      </label>
                      <span>{customization.userRootForm.requiredMark}</span>
                    </div>
                    <Select
                      id="company"
                      value={company.value}
                      onChange={(e) => {
                        this.onChangeInput(e.target.value, 'company');
                      }}
                      input={<BootStrapInput />}
                    >
                      <MenuItem value="">None</MenuItem>
                      {!_.isEmpty(userMasterData.companies) &&
                        userMasterData.companies.map((company) => (
                          <MenuItem
                            key={company.id}
                            value={company.company_name}
                            onClick={() => this.onChangeCompany(company.id)}
                          >
                            {company.company_name}
                          </MenuItem>
                        ))}
                    </Select>
                    <span
                      style={{
                        color: '#d32f2f',
                        display:
                          (company.touched && !company.valid) ||
                          (company.touched && !company.isValidFormat)
                            ? 'block'
                            : 'none',
                      }}
                    >
                      {!company.valid && customization.requiredError}
                    </span>
                  </FormControl>
                </Grid>
              )}
              <Grid id="select-user-type" item xs={12} sm={12} md={3} lg={3}>
                <FormControl className={classes.formControl}>
                  <div>
                    <label className={classes.formLabel}>
                      {customization.userRootForm.userTypeLabel}
                    </label>
                    <span>{customization.userRootForm.requiredMark}</span>
                  </div>
                  <Select
                    id="userType"
                    value={userType.value}
                    onChange={(e) => this.onChangeInput(e.target.value, 'userType')}
                    displayEmpty
                    input={<BootStrapInput />}
                    disabled={operationTag === userOperationTag.EDIT_USER}
                  >
                    {!_.isEmpty(userMasterData.userTypes) &&
                      userMasterData.userTypes.map((userType) => (
                        <MenuItem
                          key={userType.id}
                          value={userType.display_name}
                          onClick={() => this.getSelectedUserTypeId(userType.id)}
                        >
                          {userType.display_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              {this.renderUniqueDepositRef()}
            </Grid>
            <Grid
              id="section-2"
              container
              spacing={2}
              className={classes.containerFormSection}
            >
              {this.renderFirstMiddleNameField()}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl className={classes.formControl}>
                  <div>
                    <label className={classes.formLabel}>
                      {customization.userRootForm.legalLastNameLabel}
                    </label>
                    <span>{customization.userRootForm.requiredMark}</span>
                  </div>
                  <FormTextInput
                    field="legalLastName"
                    touched={legalLastName.touched}
                    valid={legalLastName.valid}
                    value={legalLastName.value}
                    onChangeInput={this.onChangeInput}
                    validFormat={legalLastName.isValidFormat}
                    validationMessage={customization.requiredError}
                    errorMode={customization.userRootForm.invalidString}
                    placeholder={customization.userRootForm.legalLastNameLabel}
                    type="text"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              id="section-3"
              container
              spacing={2}
              className={classes.containerFormSection}
            >
              <Grid item xs={12} sm={12} md={4}>
                <FormControl className={classes.formControl}>
                  <div>
                    <label className={classes.formLabel}>
                      {customization.userRootForm.emailAddressLabel}
                    </label>
                    {emailAddress.validation.required && (
                      <span>{customization.userRootForm.requiredMark}</span>
                    )}
                  </div>
                  <FormTextInput
                    field="emailAddress"
                    touched={emailAddress.touched}
                    valid={emailAddress.valid}
                    value={emailAddress.value}
                    onChangeInput={this.onChangeInput}
                    validFormat={emailAddress.isValidFormat}
                    validationMessage={customization.requiredError}
                    errorMode={customization.userRootForm.invalidEmailFormat}
                    placeholder={customization.userRootForm.emailAddressLabel}
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl className={classes.formControlInput}>
                  <div>
                    <label className={classes.formLabel}>
                      {customization.userRootForm.fullMobileNumberLabel}
                    </label>
                    {fullMobileNumber.validation.required && (
                      <span>{customization.userRootForm.requiredMark}</span>
                    )}
                  </div>
                  <PhoneInput
                    country={countryCode.value.toLowerCase()}
                    value={phoneNumberTemp}
                    onChange={(e, country) => this.onHandlePhoneNumber(e, country)}
                    inputClass={classes.phoneInputStyle}
                    enableSearch={true}
                  />
                </FormControl>
                <span
                  className={
                    (fullMobileNumber.touched && !fullMobileNumber.valid) ||
                    (fullMobileNumber.touched && !fullMobileNumber.isValidFormat)
                      ? classes.validationMessageShow
                      : classes.validationMessageHide
                  }
                >
                  {!fullMobileNumber.valid && customization.requiredError}
                  {fullMobileNumber.valid &&
                    !fullMobileNumber.isValidFormat &&
                    customization.userRootForm.invalidPhoneNumberFormat.message}
                </span>
              </Grid>
              {this.renderDefaultTimeZone()}
            </Grid>
            {requiresDefaultLocation && this.renderDefaultLocation()}
          </Grid>
        </form>
      </div>
    );
  }
}

FormRootUser.propTypes = {
  bindSubmit: PropTypes.func,
  bindClose: PropTypes.func,
  setUserType: PropTypes.func,
  classes: PropTypes.object,
  userData: PropTypes.object,
  operationTag: PropTypes.string,
  bindOpenConfirmationStatus: PropTypes.func,
  confirmStatus: PropTypes.string,
  setIsFormValid: PropTypes.func,
  setDefaultLocation: PropTypes.func,
  setCompanyId: PropTypes.func,
  responseCompanies: PropTypes.object,
  responseUserTypes: PropTypes.object,
  responseCountries: PropTypes.object,
  responseTimeZones: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
    userMasterData: state.user.userMasterData,
    userError: state.user.error,
    locationList: state.user.locationList,
    cardTypes: state.user.cardTypes,
    error: state.userType.error,
    userType: state.userType.userType,
    loading: state.userType.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userTypeAction: bindActionCreators(userTypeActions, dispatch),
    userAction: bindActionCreators(userActions, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withStyles(formRootUserStyle), withConnect)(FormRootUser);
