export const cardStyle = (theme) => ({
  cardImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '220px',
  },
  cardImage: {
    width: '335px',
    position: 'absolute',
  },
  cardNumberContainer: {
    position: 'absolute',
    zIndex: 1,
  },
  cardNumber: {
    color: 'white',
    fontSize: '17px',
  },
  cardStatusActiveBlockedClosedContainer: {
    position: 'absolute',
    zIndex: 1,
    height: '25px',
    border: '2px solid #dc4b7b',
    width: '108px',
    backgroundColor: 'white',
  },
  cardStatusExceededFraudContainer: {
    position: 'absolute',
    zIndex: 1,
    height: '25px',
    border: '2px solid #dc4b7b',
    width: '174px',
    backgroundColor: 'white',
  },
  cardStatus: {
    color: '#dc4b7b',
    fontSize: '13px',
    paddingLeft: '5px',
    textAlign: 'center',
    paddingTop: '2px',
    fontWeight: 'bolder',
  },
});
