import { apiRequestStatus } from '@data/constants';
import merchantTypes from './merchantTypes';

const initialState = {
  loading: '',
  merchantCategory: {},
  error: {},
};

const merchantReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET MERCHANT CATEGORY
    case merchantTypes.GET_MERCHANT_CATEGORY_RESTRICTION_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case merchantTypes.GET_MERCHANT_CATEGORY_RESTRICTION_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        merchantCategory: action.payload,
        error: {},
      };
    case merchantTypes.GET_MERCHANT_CATEGORY_RESTRICTION_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          message: action?.error?.message,
          statusCode: action?.error?.response?.status,
        },
      };
    // PATCH MERCHANT CATEGORY
    case merchantTypes.PATCH_MERCHANT_CATEGORY_RESTRICTION_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case merchantTypes.PATCH_MERCHANT_CATEGORY_RESTRICTION_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        merchantCategory: action.payload,
        error: {},
      };
    case merchantTypes.PATCH_MERCHANT_CATEGORY_RESTRICTION_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          message: action?.error?.message,
          statusCode: action?.error?.response?.status,
        },
      };
    default:
      return state;
  }
};

export default merchantReducer;
