import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import EditIcon from '@material-ui/icons/Edit';
import { DataGrid } from '@mui/x-data-grid';
import CardIcon from '@material-ui/icons/CreditCardOutlined';

import DialogTitle from '@components/dialogTitle';
import FormTextInput from '@components/formTextInput';
import BaseComponent from '@containers/BaseComponent';
import SuccessMessageDialog from '@components/successMessageDialog';
import ErrorMessageDialog from '@components/errorMessageDialog';
import EmptyRowsOverlay from '@components/emptyRowsOverlay';
import * as bankAction from 'redux/bank/bankActions';
import { customization } from '@customization/default';
import { useStyles } from './style';

function UserCardAccountsTab(props) {
  const _baseComponent = new BaseComponent();

  const [isFormValid, setIsFormValid] = useState(false);
  const [bankList, setBankList] = useState({ rows: [], total: 0 });
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [form, setForm] = useState({
    id: {
      validation: {
        required: false,
      },
      value: '',
      valid: true,
      isValidFormat: true,
      touched: false,
    },
    bsb: {
      validation: {
        required: true,
        minLengthString: 6,
        maxLengthString: 6,
        escapedString: true,
      },
      value: '',
      valid: false,
      isValidFormat: true,
      touched: false,
    },
    accountNumber: {
      validation: {
        required: true,
        maxLengthString: 50,
        escapedString: true,
      },
      value: '',
      valid: false,
      isValidFormat: true,
      touched: false,
    },
    accountName: {
      validation: {
        required: true,
        escapedString: true,
      },
      value: '',
      valid: false,
      isValidFormat: true,
      touched: false,
    },
  });
  const [isAdd, setIsAdd] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ isError: false, message: '' });
  const [maxID, setMaxID] = useState(0);
  const [bankAccount, setbankAccount] = useState({});

  const { bankAction, error, message, token } = props;

  const handleOpenEditDialog = async (id) => {
    const response = await bankAction.getBankAccount(token, id);
    if (response.data) {
      setbankAccount(response.data);
      setIsAdd(false);
    } else {
      if (response.message.includes('403')) {
        setbankAccount({});
        setErrorMessage({
          isError: true,
          message: response.message,
        });
      } else {
        setbankAccount({});
        setErrorMessage({
          isError: true,
          message: response.message,
        });
      }
    }

    setLoading(false);
  };

  const handleOpenAddDialog = () => {
    setIsAdd(true);
    const tmpBankAccount = { ...form };
    tmpBankAccount.id.value = 17;
    setForm(tmpBankAccount);
    setOpenDialog(true);
  };

  const getCardAccounts = useCallback(() => {
    bankAction.getBankAccountList(token, { page: 1, size: 100 }).then((response) => {
      if (response.data.totalItems === 0) {
        setBankList({ rows: [], total: 0 });
      } else if (response.data) {
        const { items, totalItems } = response.data;
        const newObj = items.map((form) => {
          return {
            id: form.id,
            bsb: form.bsb,
            accountNumber: form.account_number,
            accountName: form.account_name,
          };
        });
        setBankList({ rows: newObj, total: totalItems });

        const getLastID = items[items.length - 1].id;
        setMaxID(getLastID + 1);
      } else {
        if (response.message.includes('403')) {
          setBankList({ rows: [], total: 0 });
          setErrorMessage({
            isError: true,
            message: response.message,
          });
        } else {
          setBankList({ rows: [], total: 0 });
          setErrorMessage({
            isError: true,
            message: response.message,
          });
        }
      }

      setLoading(false);
    });
  }, [bankAction, token]);

  useEffect(() => {
    setLoading(true);
    getCardAccounts();
  }, [getCardAccounts]);

  useEffect(() => {
    if (bankAccount.id) {
      const formSingle = { ...form };
      formSingle.id.value = bankAccount.id;
      formSingle.bsb.value = bankAccount.bsb;
      formSingle.bsb.valid = true;
      formSingle.accountNumber.value = bankAccount.account_number;
      formSingle.accountNumber.valid = true;
      formSingle.accountName.value = bankAccount.account_name;
      formSingle.accountName.valid = true;
      setForm(formSingle);
      setIsFormValid(true);
      setOpenDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccount]);

  const handleCloseDialog = () => {
    bankAction.bankResetState();
    setbankAccount({});

    const tmpBankAccount = { ...form };
    tmpBankAccount.id.value = '';
    tmpBankAccount.bsb.value = '';
    tmpBankAccount.bsb.isValidFormat = true;
    tmpBankAccount.accountNumber.value = '';
    tmpBankAccount.accountNumber.isValidFormat = true;
    tmpBankAccount.accountName.value = '';
    tmpBankAccount.accountName.isValidFormat = true;

    setIsFormValid(false);
    setOpenDialog(false);
    setForm(tmpBankAccount);
  };

  const onChangeInput = (eventValue, inputIdentifier) => {
    const updateState = _baseComponent.changeInputWithValidation(
      eventValue,
      form,
      inputIdentifier,
    );
    setForm(updateState.form);
    setIsFormValid(updateState.valid);
  };

  const saveOrEditData = () => {
    const formParams = {
      bank_account_id: Number(form?.id.value),
      bsb: form?.bsb.value,
      account_number: form?.accountNumber.value,
      account_name: form?.accountName.value,
    };
    if (isAdd) {
      bankAction.createBankAccount(token, formParams);
    } else {
      bankAction.updateBankAccount(token, formParams);
    }
  };

  useEffect(() => {
    if (message) {
      setOpenSuccess(true);
    }
    if (error?.statusCode) {
      setErrorMessage({ isError: true, message: error.message });
    }
  }, [message, error]);

  const handleCloseErrorMessage = () => {
    setErrorMessage({
      isError: false,
      message: '',
    });
  };

  const closeSuccessModal = () => {
    setOpenSuccess(false);
    handleCloseDialog();
  };

  const renderSuccessDialog = () => {
    return (
      <SuccessMessageDialog
        message={customization.userSettingsPage.cardAccountsTransferBankSuccess}
        openSuccess={openSuccess}
        bindHandleClose={closeSuccessModal}
        className={classes.roundedButton}
      />
    );
  };

  const renderErrorDialog = () => {
    return (
      <ErrorMessageDialog
        bindHandleClose={handleCloseErrorMessage}
        openSuccess={errorMessage.isError}
        errorData={errorMessage.message}
      />
    );
  };

  const renderDialogAddOrEdit = () => {
    return (
      <Dialog fullWidth maxWidth="lg" open={openDialog}>
        <DialogTitle id="audit-history-detail-dialog-title" onClose={handleCloseDialog}>
          <b>
            {isAdd
              ? customization.userSettingsPage.cardAccountTransferBankTitle
              : `${customization.userSettingsPage.cardAccountsTransferBankTableTitle} - ${form.bsb.value}`}
          </b>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={1}>
            <Grid
              id="section-1"
              container
              spacing={2}
              className={classes.containerFormSection}
            >
              <Grid id="text-field-code" item xs={12} sm={12} md={1}>
                <FormControl className={classes.formControlInput}>
                  <div>
                    <label className={classes.formLabel}>
                      {customization.userSettingsPage.idLabel}
                    </label>
                  </div>
                  <FormTextInput
                    field="id"
                    isDisabled={true}
                    touched={form.id.touched}
                    valid={form.id.valid}
                    value={!isAdd ? form.id.value : maxID}
                    validFormat={form.id.isValidFormat}
                    validationMessage={customization.requiredError}
                    type="number"
                  />
                </FormControl>
              </Grid>
              <Grid id="text-field-code" item xs={12} sm={12} md={5}>
                <FormControl className={classes.formControlInput}>
                  <div>
                    <label className={classes.formLabel}>
                      {
                        customization.userSettingsPage
                          .cardAccountsTransferBankTableHeaderFirst
                      }
                    </label>
                  </div>
                  <FormTextInput
                    field="bsb"
                    touched={form.bsb.touched}
                    value={form.bsb.value}
                    valid={form.bsb.valid}
                    validFormat={form.bsb.isValidFormat}
                    onChangeInput={(value, field) => onChangeInput(value, field)}
                    validationMessage={customization.userSettingsPage.errorBSB}
                    errorMode={customization.userSettingsPage.invalidLengthBSB}
                    type="number"
                  />
                </FormControl>
              </Grid>
              <Grid id="text-field-code" item xs={12} sm={12} md={6}>
                <FormControl className={classes.formControlInput}>
                  <div>
                    <label className={classes.formLabel}>
                      {
                        customization.userSettingsPage
                          .cardAccountsTransferBankAccountNumber
                      }
                    </label>
                  </div>
                  <FormTextInput
                    field="accountNumber"
                    touched={form.accountNumber.touched}
                    valid={form.accountNumber.valid}
                    value={form.accountNumber.value}
                    validFormat={form.accountNumber.isValidFormat}
                    onChangeInput={(value, field) => onChangeInput(value, field)}
                    validationMessage={customization.userSettingsPage.errorAccountNumber}
                    errorMode={customization.userSettingsPage.invalidLengthAccountNumber}
                    type="number"
                  />
                </FormControl>
              </Grid>
              <Grid id="text-field-code" item xs={12} sm={12} md={12}>
                <FormControl className={classes.formControlInput}>
                  <div>
                    <label className={classes.formLabel}>
                      {
                        customization.userSettingsPage
                          .cardAccountsTransferBankTableHeaderThird
                      }
                    </label>
                  </div>
                  <FormTextInput
                    field="accountName"
                    touched={form.accountName.touched}
                    valid={form.accountName.valid}
                    value={form.accountName.value}
                    validFormat={form.accountName.isValidFormat}
                    onChangeInput={(value, field) => onChangeInput(value, field)}
                    validationMessage={customization.userSettingsPage.errorAccountName}
                    errorMode={customization.userSettingsPage.invalidString}
                    type="text"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              id="section-4"
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.containerFormSection}
              spacing={1}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                className={classes.formButtonSaveAndClose}
              >
                <Button
                  disabled={!isFormValid}
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={saveOrEditData}
                >
                  {customization.userSettingsPage.saveChangesButtonLabel}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={2} className={classes.formButtonCancel}>
                <Button type="button" variant="outlined" onClick={handleCloseDialog}>
                  {customization.userSettingsPage.closeButtonLabel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  const columns = [
    {
      cellClassName: 'transform-capitalize',
      field: 'bsb',
      flex: 1,
      minWidth: 110,
      sortable: false,
      headerName: 'BSB',
    },
    {
      cellClassName: 'transform-capitalize',
      field: 'accountNumber',
      flex: 1,
      minWidth: 110,
      sortable: false,
      headerName: 'Account',
    },
    {
      cellClassName: 'transform-capitalize',
      field: 'accountName',
      flex: 1,
      minWidth: 210,
      sortable: false,
      headerName: 'Account Name',
    },
    {
      align: 'center',
      field: 'action',
      headerAlign: 'center',
      headerName: customization.userViews.headers.action,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Button
              aria-controls="more-options"
              aria-haspopup="true"
              className={`${classes.actionButton} more`}
              disableElevation
              onClick={() => handleOpenEditDialog(params.id)}
              variant="contained"
            >
              <EditIcon htmlColor="#FFFFFF" fontSize="medium" />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <div className={classes.divContainerStyle}>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item xs={12} lg={4} xl={6} className={classes.alignEnd}>
          <Button
            className={classes.buttonAddDialog}
            color="primary"
            disableElevation
            onClick={handleOpenAddDialog}
            startIcon={<CardIcon />}
            size="large"
            variant="contained"
          >
            {customization.userSettingsPage.addButtonLabel}
          </Button>
        </Grid>
      </Grid>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableExtendRowFullWidth
        disableSelectionOnClick
        className={classes.table}
        columns={columns}
        components={{
          NoRowsOverlay: EmptyRowsOverlay,
        }}
        loading={loading}
        componentsProps={{
          noRowsOverlay: {
            label: customization.userViews.emptyRows,
          },
        }}
        maxColumns={9}
        hideFooter={true}
        rows={bankList.rows}
        rowHeight={80}
      />
      {renderDialogAddOrEdit()}
      {renderSuccessDialog()}
      {renderErrorDialog()}
    </div>
  );
}

UserCardAccountsTab.propTypes = {
  error: PropTypes.object,
  message: PropTypes.string,
  bankAccountList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  token: PropTypes.string,
  bankAction: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    error: state.bank.error,
    message: state.bank.message,
    bankAccountList: state.bank.bankAccountList,
    token: state.auth.inMemoryToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bankAction: bindActionCreators(bankAction, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(UserCardAccountsTab);
