import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { apiRequestStatus, userAccessStatus } from '@data/constants';
import { getUsersList, unlockPin } from '@services/api/userApi';
import DashboardLayout from '@components/layout/dashboardLayout';
import UserCard from '@components/userCard';
import LoadingBackdrop from '@components/loadingBackdrop';
import AlertNotification from '@components/alertNotification';
import UserAccessActivation from '@containers/UserAccessActivation';
import UserAccessDeactivation from '@containers/UserAccessDeactivation';
import { userPageStyle } from './style';
class UserPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      unlockPinData: {
        data: {},
        status: apiRequestStatus.IDLE,
        error: '',
      },
    };
  }

  getAllUserAccess = async () => {
    try {
      const { data } = await getUsersList(this.props.token);
      if (data.length > 0) {
        this.setState({ users: data });
      }
    } catch (error) {
      console.log(`error ${JSON.stringify(error)}`);
    }
  };

  componentDidMount() {
    this.getAllUserAccess();
  }

  unlockPinNumber = async (token, userMobileNumber) => {
    this.setState({
      unlockPinData: {
        ...this.state.unlockPinData,
        status: apiRequestStatus.PENDING,
      },
    });
    try {
      const params = {
        mobile_number: userMobileNumber,
      };
      const response = await unlockPin(token, params);
      if (response?.status === 200) {
        this.setState({
          unlockPinData: {
            ...this.state.unlockPinData,
            data: response.data,
            status: apiRequestStatus.RESOLVED,
          },
        });
      } else {
        const error = new Error(response.statusText);
        error.response = response;
        this.setState({
          unlockPinData: {
            ...this.state.unlockPinData,
            error: response.statusText,
            status: apiRequestStatus.REJECTED,
          },
        });
        throw error;
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      this.setState({
        unlockPinData: {
          ...this.state.unlockPinData,
          error: errorMessage,
          status: apiRequestStatus.REJECTED,
        },
      });
    }
  };

  render() {
    const { users, unlockPinData } = this.state;
    const { classes, token } = this.props;

    return (
      <DashboardLayout>
        <LoadingBackdrop status={unlockPinData.status} />
        <AlertNotification
          status={unlockPinData.status}
          error={unlockPinData.error}
          data={unlockPinData}
        />
        <div className={classes.nav}>
          <Link to="/admin/dashboard/user-provision">User Provision</Link>
          <Link to="/admin/dashboard/user-card-status">User Card Status</Link>
          <Link to="/admin/dashboard/user-settings">User Settings</Link>
          <Link to="/admin/dashboard/view-users">View Users</Link>
        </div>
        <main>
          <Container className={classes.cardGrid} maxWidth="lg">
            <Grid container spacing={4}>
              {!_.isEmpty(users) &&
                users.map((user) => (
                  <Grid item key={user.id} xs={12} sm={6} md={4}>
                    <UserCard
                      user={user}
                      status={unlockPinData.status}
                      error={unlockPinData.error}
                    >
                      <UserAccessActivation
                        userId={user.id}
                        getAllUserAccess={this.getAllUserAccess}
                        userAccessControlStatus={user?.user_access_control?.access_status}
                      />
                      <UserAccessDeactivation
                        userId={user.id}
                        getAllUserAccess={this.getAllUserAccess}
                        userAccessControlStatus={user?.user_access_control?.access_status}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.unlockPinNumber(token, user.mobile_number)}
                        disabled={
                          user?.user_access_control?.access_status !==
                          userAccessStatus.FULL_LOCK
                        }
                      >
                        Send Unlock Pin
                      </Button>
                    </UserCard>
                  </Grid>
                ))}
            </Grid>
          </Container>
        </main>
      </DashboardLayout>
    );
  }
}

UserPage.propTypes = {
  classes: PropTypes.object,
  token: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
  };
};

const withConnect = connect(mapStateToProps);
export default compose(withStyles(userPageStyle), withConnect)(UserPage);
