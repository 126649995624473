import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { formCurrencyInputStyle } from './style';

function FormCurrencyInput(props) {
  function handleInput(val) {
    const { onChangeInput, field } = props;
    const regex = /^[0-9.]+$/;
    if (val.match(regex) !== null) {
      onChangeInput(val, field);
    }
  }

  function parsingValue(val, decimalPlaces) {
    if (val !== '') {
      const { onChangeInput, field } = props;
      const toNumber = parseFloat(val);
      const parseVal = toNumber.toFixed(decimalPlaces);
      onChangeInput(parseVal.toString(), field);
    }
  }

  function handleDelete(e) {
    const { onChangeInput } = props;
    if (e.keyCode === 8 || e.keyCode === 46) {
      if (e.target.value.length === 1) {
        onChangeInput('', field);
      }
    }
  }

  const {
    valid,
    validationMessage,
    value,
    field,
    isDisabled,
    touched,
    validFormat = true,
    boldMode = false,
    classes,
    currencySymbol = '$',
    decimalPlaces = 2,
  } = props;
  return (
    <div>
      <FormControl fullWidth variant="outlined">
        <OutlinedInput
          id={field}
          disabled={!!isDisabled}
          value={value}
          onChange={(e) => handleInput(e.target.value)}
          onBlur={(e) => parsingValue(e.target.value, decimalPlaces)}
          onKeyDown={(e) => handleDelete(e)}
          startAdornment={
            <InputAdornment position="start">{currencySymbol}</InputAdornment>
          }
          color="secondary"
          type="text"
          classes={{
            root: classes.root,
            input: boldMode ? classes.textBoldInput : classes.input,
          }}
        />
      </FormControl>
      <span
        style={{
          color: '#d32f2f',
          display: (touched && !valid) || (touched && !validFormat) ? 'block' : 'none',
        }}
      >
        {validationMessage}
      </span>
    </div>
  );
}

FormCurrencyInput.propTypes = {
  valid: PropTypes.bool,
  onChangeInput: PropTypes.func,
  validationMessage: PropTypes.string,
  value: PropTypes.any,
  field: PropTypes.string,
  isDisabled: PropTypes.bool,
  touched: PropTypes.bool,
  validFormat: PropTypes.bool,
  classes: PropTypes.object,
  boldMode: PropTypes.bool,
  currencySymbol: PropTypes.string,
  decimalPlaces: PropTypes.number,
};
export default compose(withStyles(formCurrencyInputStyle))(FormCurrencyInput);
