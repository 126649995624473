import {
  getSpendCategoryList as getSpendCategoryListService,
  getReceipt as getReceiptService,
  uploadReceipt as uploadReceiptService,
  deleteReceipt as deleteReceiptService,
  getTransactionAllocation as getTransactionAllocationService,
  splitTransactionAllocation as splitTransactionAllocationService,
  getAccountAllocation as getAccountAllocationService,
  getTransactionAuditById,
} from '@services/api/transactionApi';
import auditHistoryTypes from './transactionTypes';

export const getSpendCategoryList = (user_id, token) => {
  return async (dispatch) => {
    dispatch({ type: auditHistoryTypes.GET_SPEND_CATEGORY_LIST_REQUEST });
    try {
      const response = await getSpendCategoryListService(user_id, token);
      dispatch({
        type: auditHistoryTypes.GET_SPEND_CATEGORY_LIST_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: auditHistoryTypes.GET_SPEND_CATEGORY_LIST_ERROR, error });
    }
  };
};

export const getReceipt = (txn_id, user_id, token) => {
  return async (dispatch) => {
    dispatch({ type: auditHistoryTypes.GET_RECEIPT_REQUEST });
    try {
      const response = await getReceiptService(txn_id, user_id, token);
      dispatch({
        type: auditHistoryTypes.GET_RECEIPT_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: auditHistoryTypes.GET_RECEIPT_ERROR, error });
    }
  };
};

export const uploadReceipt = (params, token, options) => {
  return async (dispatch) => {
    dispatch({ type: auditHistoryTypes.UPLOAD_RECEIPT_REQUEST });
    try {
      const response = await uploadReceiptService(params, token, options);
      dispatch({
        type: auditHistoryTypes.UPLOAD_RECEIPT_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: auditHistoryTypes.UPLOAD_RECEIPT_ERROR, error });
    }
  };
};

export const deleteReceipt = (txn_receipt_id, account_id, token) => {
  return async (dispatch) => {
    dispatch({ type: auditHistoryTypes.DELETE_RECEIPT_REQUEST });
    try {
      const response = await deleteReceiptService(txn_receipt_id, account_id, token);
      dispatch({
        type: auditHistoryTypes.DELETE_RECEIPT_SUCCESS,
      });
      return response;
    } catch (error) {
      dispatch({ type: auditHistoryTypes.DELETE_RECEIPT_ERROR, error });
    }
  };
};

export const getTransactionAllocation = (
  token,
  transactionId,
  keyword,
  defaultLocation,
) => {
  return async (dispatch) => {
    dispatch({ type: auditHistoryTypes.GET_TRANSACTION_ALLOCATION_REQUEST });
    try {
      const response = await getTransactionAllocationService(
        token,
        transactionId,
        keyword,
        defaultLocation,
      );
      dispatch({
        type: auditHistoryTypes.GET_TRANSACTION_ALLOCATION_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: auditHistoryTypes.GET_TRANSACTION_ALLOCATION_REQUEST, error });
      return error;
    }
  };
};

export const splitTransactionAllocation = (token) => {
  return async (dispatch) => {
    dispatch({ type: auditHistoryTypes.SPLIT_TRANSACTION_ALLOCATION_REQUEST });
    try {
      const response = await splitTransactionAllocationService(token);
      dispatch({
        type: auditHistoryTypes.SPLIT_TRANSACTION_ALLOCATION_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: auditHistoryTypes.SPLIT_TRANSACTION_ALLOCATION_ERROR, error });
      return error;
    }
  };
};

export const getAccountAllocation = (id, token) => {
  return async (dispatch) => {
    dispatch({ type: auditHistoryTypes.GET_ACCOUNT_ALLOCATION_REQUEST });
    try {
      const response = await getAccountAllocationService(token, id);
      dispatch({
        type: auditHistoryTypes.GET_ACCOUNT_ALLOCATION_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: auditHistoryTypes.GET_ACCOUNT_ALLOCATION_ERROR, error });
    }
  };
};

export const getTransactionAuditList = (id, token) => {
  return async (dispatch) => {
    dispatch({ type: auditHistoryTypes.GET_TRANSACTION_AUDIT_REQUEST });
    try {
      const response = await getTransactionAuditById(id, token);
      dispatch({
        type: auditHistoryTypes.GET_TRANSACTION_AUDIT_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: auditHistoryTypes.GET_TRANSACTION_AUDIT_ERROR, error });
      return error;
    }
  };
};

export const transactionClearErrorState = () => {
  return async (dispatch) => {
    dispatch({ type: auditHistoryTypes.CLEAR_ERROR_STATE });
  };
};

export const transactionClearDetailViewSearchState = () => {
  return async (dispatch) => {
    dispatch({ type: auditHistoryTypes.CLEAR_DETAIL_VIEW_SEARCH_STATE });
  };
};

export const transactionClearAllocationState = () => {
  return async (dispatch) => {
    dispatch({ type: auditHistoryTypes.CLEAR_ALLOCATION_STATE });
  };
};
