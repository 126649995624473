export const formDatePickerStyle = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    width: '100%',
    padding: '11px 12px !important',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
  textBoldInput: {
    fontWeight: 'bold',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    width: '100%',
    padding: '11px 12px !important',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        padding: '12.5px 14px',
      },
    },
  },
});
