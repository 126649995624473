import { get, patch } from './baseApi';

const getClientMerchantCategoryRestriction = async (token, cardId) => {
  const url = `/admin/card/client-merchant-category-restriction/${cardId}`;
  return get(url, token);
};

const patchClientMerchantCategoryRestriction = async (token, requestBody) => {
  const url = '/admin/card/client-merchant-category-restriction';
  return patch(url, requestBody, token);
};

export { getClientMerchantCategoryRestriction, patchClientMerchantCategoryRestriction };
