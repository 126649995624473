import { userAccessStatus, userOperationTag } from '@data/constants';
import { customization } from '@customization/default';
import checkValidity from '@utils/checkValidity';
import { updateState } from '@redux/utility';

const linkTransformation = (params) => {
  const convert = params.split('&').map((data) => {
    const newObject = {};
    const splitted = data.split('=');
    newObject[splitted[0]] = splitted[1];
    return newObject;
  });

  const result = Object.assign({}, ...convert);
  return result;
};

const transformUserData = (params, masterData, operationTag, defaultLocationsData) => {
  const stateData = {};

  for (const key in params) {
    stateData[key] = params[key].value;
  }

  const countryId = masterData.countryCodes.filter(
    (data) => data.iso_code === stateData.countryCode,
  )[0];

  const companyId = masterData.companies.filter(
    (data) => data.company_name === stateData.company,
  )[0];

  const userTypeId = masterData.userTypes.filter(
    (data) => data.display_name === stateData.userType,
  )[0];

  const defaultLocationId = defaultLocationsData.filter(
    (data) => data.code === stateData.defaultLocation,
  )[0];

  const resultData = {
    user_number: stateData.uniqueDepositReference || null,
    first_name: stateData.legalFirstName || null,
    middle_name: stateData.legalMiddleName || null,
    last_name: stateData.legalLastName || null,
    email: stateData.emailAddress || null,
    mobile_number: stateData.fullMobileNumber || null,
    suburb: null,
    state_id: null,
    user_type_id: userTypeId?.id || null,
    company_id: companyId?.id || null,
    country_id: countryId?.id || null,
    default_location_id: defaultLocationId?.id || null,
    default_timezone: stateData.defaultTimeZone || null,
  };

  if (operationTag === userOperationTag.ADD_USER) {
    return resultData;
  }

  return {
    body: {
      ...resultData,
      status:
        (stateData.status !== customization.userRootForm.statusPending &&
          stateData.status.toLowerCase()) ||
        null,
    },
    id: stateData.id || null,
  };
};

const transformEditUserData = (userData, userMasterData, defaultLocationsData) => {
  const company = userMasterData.companies.filter(
    (data) => data.id === userData.company_fk,
  )[0];
  const country = userMasterData.countryCodes.filter(
    (data) => data.id === userData.country_fk,
  )[0];

  let selectedCompany = {};
  if (userData.default_timezone === null) {
    selectedCompany = userMasterData.companies.filter(
      (data) => data.id === userData.company_fk,
    )[0];
  } else {
    selectedCompany.timezone = userData.default_timezone;
  }

  const selectedDefaultLocation = defaultLocationsData.filter(
    (data) => data.id === userData.default_location_fk,
  )[0];

  let status = null;
  if (userData.user_access_control?.access_status === userAccessStatus.ACTIVE) {
    status = customization.userRootForm.statusActive;
  } else if (
    userData.user_access_control?.access_status === userAccessStatus.TEMP_LOCK ||
    userData.user_access_control?.access_status === userAccessStatus.FULL_LOCK
  ) {
    status = customization.userRootForm.statusLocked;
  } else if (
    userData.user_access_control?.access_status === userAccessStatus.CANCELLED ||
    userData.user_access_control?.access_status === userAccessStatus.DEACTIVATED
  ) {
    status = customization.userRootForm.statusInactive;
  } else {
    status = customization.userRootForm.statusPending;
  }

  let getFullMobileNumber = '';
  if (userData.mobile_prefix !== null) {
    getFullMobileNumber = userData.mobile_prefix.replace('+', '');
  }
  if (userData.mobile_number !== null) {
    getFullMobileNumber = getFullMobileNumber + userData.mobile_number;
  }

  return {
    id: userData.id,
    status: status,
    company: company?.company_name || '',
    userType: userData.user_type.display_name,
    uniqueDepositReference: userData.user_number || '',
    legalFirstName: userData.first_name || '',
    legalLastName: userData.last_name || '',
    legalMiddleName: userData.middle_name || '',
    emailAddress: userData.email || '',
    countryCode: country?.iso_code || '',
    fullMobileNumber: getFullMobileNumber,
    countryDialCode: userData.mobile_prefix | '',
    defaultLocation: selectedDefaultLocation?.code || '',
    defaultTimeZone: selectedCompany?.timezone || '',
  };
};

const valuesValidation = (eventValue, formObject, inputIdentifier) => {
  let isNotEmpty = false;
  let isValidFormat = false;
  if (formObject[inputIdentifier].validation.required) {
    if (typeof eventValue === 'string') {
      isNotEmpty = eventValue.trim() !== '';
    } else if (typeof eventValue === 'boolean') {
      isNotEmpty = eventValue === true;
    }
  } else {
    isNotEmpty = true;
  }

  isValidFormat = checkValidity(eventValue, formObject[inputIdentifier].validation);

  const updatedFormElement = updateState(formObject[inputIdentifier], {
    value: eventValue,
    valid: isNotEmpty,
    isValidFormat: isValidFormat,
    touched: true,
  });

  return updatedFormElement;
};

const transformRequiredValidation = (userTypeValue, userTypes, existingUserData) => {
  const selectedUserType = userTypes.filter(
    (data) => data.display_name === userTypeValue,
  )[0];

  const requiredMapped = existingUserData;
  for (const keyUser in requiredMapped) {
    if (keyUser === 'emailAddress') {
      requiredMapped.emailAddress.validation.required =
        selectedUserType?.user_type_subscription_settings?.subscription_via_email ||
        selectedUserType?.user_type_access_rights?.allow_web_access ||
        false;
    } else if (keyUser === 'fullMobileNumber') {
      requiredMapped.fullMobileNumber.validation.required =
        selectedUserType?.user_type_subscription_settings?.subscription_via_sms ||
        selectedUserType?.user_type_access_rights?.allow_mobile_access ||
        false;
    } else if (keyUser === 'defaultLocation') {
      requiredMapped.defaultLocation.validation.required =
        selectedUserType?.user_type_transaction_settings?.requires_default_location ||
        false;
    }
  }

  if (
    requiredMapped?.fullMobileNumber?.value !== '' &&
    !requiredMapped.countryCode.validation.required
  ) {
    requiredMapped.countryCode.validation.required = true;
  }

  return requiredMapped;
};

const transformPreAddUser = (existingUserData) => {
  return {
    id: existingUserData.id?.value,
    status: existingUserData.status?.value,
    company: existingUserData.company?.value,
    userType: existingUserData.userType?.value,
    uniqueDepositReference: existingUserData.uniqueDepositReference?.value,
    legalFirstName: existingUserData.legalFirstName?.value,
    legalMiddleName: existingUserData.legalMiddleName?.value,
    legalLastName: existingUserData.legalLastName?.value,
    emailAddress: existingUserData.emailAddress?.value,
    countryCode: existingUserData.countryCode?.value,
    fullMobileNumber: existingUserData.fullMobileNumber?.value,
    defaultLocation: existingUserData.defaultLocation?.value,
    defaultTimeZone: existingUserData.defaultTimeZone?.value,
    location: existingUserData.location?.value,
  };
};

const transformAddLocationGroup = (locationGroupData) => {
  return {
    company_id: locationGroupData.company.value,
    internal_name: locationGroupData.internal_name.value,
  };
};

const transformEditLocationGroup = (locationGroupData) => {
  return {
    location_group_id: locationGroupData.id,
    company_id: locationGroupData.company.value,
    internal_name: locationGroupData.internal_name.value,
  };
};

const transformLocationGroup = (locationData) => {
  if (locationData.length === 0 || !locationData) {
    return {
      locationState: {},
      componentPropertiesData: [],
    };
  }

  let locationState = {};
  const componentPropertiesData = [];

  for (const element of locationData) {
    let tempObject = {};

    tempObject = {
      id: element.id,
      locationProperties: element.internal_name.replaceAll(/\s/g, ''),
      displayName: element.internal_name,
      validation: {
        required: false,
        escapedString: true,
      },
      value: '',
      valid: true,
      isValidFormat: true,
      touched: false,
    };
    componentPropertiesData.push(tempObject);
  }

  for (const element of componentPropertiesData) {
    let tempObject = {};

    tempObject = {
      [element.locationProperties]: {
        id: element.id,
        locationProperties: element.locationProperties,
        validation: {
          required: false,
          escapedString: true,
        },
        value: '',
        valid: true,
        isValidFormat: true,
        touched: false,
      },
    };

    locationState = { ...locationState, ...tempObject };
  }

  return {
    locationState,
    componentPropertiesData,
  };
};

const addEditLocationTransformation = (params, masterData, operationTag) => {
  const locationGroups = [];
  const stateData = {};
  for (const key in params) {
    if (
      key !== 'code' &&
      key !== 'company' &&
      key !== 'status' &&
      key !== 'locationDescription' &&
      key !== 'id' &&
      key !== 'locationTimezone'
    ) {
      const locationObject = {
        group_id: params[key].id,
        value: params[key].value,
      };

      locationGroups.push(locationObject);
    } else {
      stateData[key] = params[key].value;
    }
  }

  const filteredCompany = masterData.companies.filter(
    (data) => data.company_name === stateData.company,
  )[0];

  const resultData = {
    company_id: filteredCompany?.id || null,
    status: stateData?.status === 'Active',
    description: stateData?.locationDescription || null,
    code: stateData?.code || null,
    location_groups: locationGroups,
    timezone: stateData?.locationTimezone || null,
  };

  if (operationTag === 'addLocation') {
    return resultData;
  }

  return {
    company_id: filteredCompany?.id || null,
    status: stateData?.status === 'Active',
    description: stateData?.locationDescription || null,
    code: stateData?.code || null,
    timezone: stateData?.locationTimezone || null,
    id: stateData.id || null,
  };
};

const transformEditLocation = (
  locationData,
  propsLocation,
  locationState,
  companyName,
) => {
  let populatedLocationState = {};
  const populatedComponentProperties = [];
  const temporaryLocationState = locationState;
  const propsLocationGroups = propsLocation?.location_groups || [];

  for (const location of propsLocationGroups) {
    let tempObject = {};
    const filteredLocation = locationData.filter((element) => {
      return element.id === location.location_group_fk;
    })[0];

    if (filteredLocation.id === location.location_group_fk) {
      tempObject = {
        locationId: location.id,
        id: filteredLocation.id,
        locationProperties: filteredLocation.internal_name.replace(/\s/g, ''),
        displayName: filteredLocation.internal_name,
        validation: {
          required: false,
        },
        value: location.value || '',
        valid: true,
        isValidFormat: true,
        touched: false,
      };
    }
    populatedComponentProperties.push(tempObject);
  }

  for (const element of populatedComponentProperties) {
    let tempObject = {};

    tempObject = {
      [element.locationProperties]: {
        locationId: element.locationId,
        id: element.id,
        locationProperties: element.locationProperties,
        validation: {
          required: false,
        },
        value: element.value,
        valid: true,
        isValidFormat: true,
        touched: false,
      },
    };

    populatedLocationState = { ...populatedLocationState, ...tempObject };
  }

  temporaryLocationState.id.value = propsLocation.id;
  temporaryLocationState.code.value = propsLocation.code;
  temporaryLocationState.status.value = propsLocation.is_active ? 'Active' : 'Inactive';
  temporaryLocationState.company.value = companyName;
  temporaryLocationState.locationDescription.value = propsLocation.description;
  temporaryLocationState.locationTimezone.value = propsLocation.timezone || '';

  populatedLocationState = { ...populatedLocationState, ...temporaryLocationState };

  for (const key in populatedLocationState) {
    const checkedValidity = valuesValidation(
      populatedLocationState[key].value,
      populatedLocationState,
      key,
    );

    populatedLocationState = updateState(populatedLocationState, {
      [key]: checkedValidity,
    });
  }

  return {
    populatedLocationState,
    populatedComponentProperties,
  };
};

const transformViewEditCard = (card) => {
  const mappedCardDetailToState = {
    cardId: card?.id || null,
    userId: card?.user_fk || null,
    accountId: card?.account_id || null,
    cardStatus: card?.card_status || null,
    cardHolderName:
      card?.user?.first_name && card?.user?.last_name
        ? `${card.user.first_name} ${card.user.last_name}`
        : !card.user.last_name && card.user.first_name
        ? card.user.first
        : 'N/A',
    cardNumber: card?.card_number ? `**** ${card.card_number}` : 'N/A',
    userType: card?.user?.user_type?.display_name
      ? `${card.user.user_type.display_name}`
      : 'N/A',
    lastTransactionDate: card?.last_transaction_occurred,
    lastTransactionMerchantName: card?.last_transaction_merchant || 'N/A',
    currencySymbol: card?.last_transaction_currency || '$',
    lastTransactionAmount:
      card?.last_transaction_amount && card?.last_transaction_currency
        ? convertLastTransaction(
            card.last_transaction_currency,
            card.last_transaction_amount,
          )
        : card?.last_transaction_amount && !card?.last_transaction_currency
        ? card.last_transaction_amount
        : 'N/A',
    lastDecimalPlace: card?.limit_decimal_place || 2,
  };

  const mappedTransactionToState = {
    cashWithdrawalLimit:
      card?.account_id && card?.cash_withdrawal_limit
        ? card.cash_withdrawal_limit
        : 'N/A',
    transactionLimit:
      card?.account_id && card?.transaction_limit ? card.transaction_limit : 'N/A',
    dailyLimit: card?.account_id && card?.daily_limit ? card.daily_limit : 'N/A',
  };

  return {
    mappedCardDetailToState,
    mappedTransactionToState,
  };
};

const convertLastTransaction = (symbol, lastTransaction) => {
  const convertToArray = String(lastTransaction).split('');

  if (convertToArray[0] === '-' || convertToArray[0] === '+') {
    convertToArray.splice(1, 0, symbol);
    return convertToArray.join('');
  }

  return `${symbol}${lastTransaction}`;
};

const transformAccountLimitCard = (card) => {
  return {
    cash_limit: card?.cashWithdrawalLimit || null,
    transaction_limit: card?.transactionLimit || null,
    daily_transaction_limit: card?.dailySpendLimit || null,
    account_id: card?.accountId || null,
    card_id: card?.cardId || null,
  };
};

export {
  linkTransformation,
  transformUserData,
  transformEditUserData,
  valuesValidation,
  transformRequiredValidation,
  transformPreAddUser,
  transformAddLocationGroup,
  transformEditLocationGroup,
  transformLocationGroup,
  addEditLocationTransformation,
  transformEditLocation,
  transformViewEditCard,
  transformAccountLimitCard,
};
