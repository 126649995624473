import { apiRequestStatus } from '@data/constants';
import bankTypes from './bankTypes';

const initialState = {
  bankAccount: {},
  bankAccountList: {},
  error: {},
  loading: '',
  message: '',
};

const bankReducer = (state = initialState, action) => {
  switch (action.type) {
    case bankTypes.GET_BANK_LIST_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case bankTypes.GET_BANK_LIST_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        bankAccountList: action.payload,
        error: {},
      };
    case bankTypes.GET_BANK_LIST_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        bankAccountList: {},
        error: {
          ...action?.error?.response?.data,
          message: action?.error?.response?.data?.message || action?.error?.message,
          statusCode: action?.error?.response?.status || 500,
        },
      };
    case bankTypes.GET_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case bankTypes.GET_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        bankAccount: action.payload,
        error: {},
      };
    case bankTypes.GET_BANK_ACCOUNT_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        bankAccount: {},
        error: {
          ...action?.error?.response?.data,
          message: action?.error?.response?.data?.message || action?.error?.message,
          statusCode: action?.error?.response?.status || 500,
        },
      };
    case bankTypes.CREATE_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        message: '',
        error: {},
      };
    case bankTypes.CREATE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        message: action.payload.message,
        error: {},
      };
    case bankTypes.CREATE_BANK_ACCOUNT_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        message: '',
        error: {
          ...action?.error?.response?.data,
          message: action?.error?.response?.data?.message || action?.error?.message,
          statusCode: action?.error?.response?.status || 500,
        },
      };
    case bankTypes.UPDATE_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        message: '',
        error: {},
      };
    case bankTypes.UPDATE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        message: action.payload.message,
        error: {},
      };
    case bankTypes.UPDATE_BANK_ACCOUNT_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        message: '',
        error: {
          ...action?.error?.response?.data,
          message: action?.error?.response?.data?.message || action?.error?.message,
          statusCode: action?.error?.response?.status || 500,
        },
      };
    case bankTypes.RESET_ERROR_STATE:
      return {
        ...state,
        error: {},
      };
    case bankTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default bankReducer;
