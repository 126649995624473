export const customCheckboxStyle = (theme) => ({
  root: {
    color: theme.palette.secondary.main,
    width: '22px',
    height: '22px',
    borderRadius: '3px',
    '&$checked': {
      color: 'white',
      backgroundColor: theme.palette.secondary.main,
    },
  },
  checked: {},
});

export const labelStyle = (theme) => ({
  formControlLabel: {
    marginLeft: '10px',
  },
});
