import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import Link from '@material-ui/core/Link';
import { ActivityDetailPageStyle } from './style';

function TaxInvoicesList({ classes, field }) {
  return (
    <div className={classes.parentDelegationWrapper}>
      {field.value?.length > 0 && <span>{field.displayName}:</span>}
      {field.value?.map((item, itemIndex) => {
        return (
          <div
            key={itemIndex}
            className={[
              classes.parentDelegationCell,
              itemIndex !== 0 ? classes.parentDelegationBorder : '',
            ].join(' ')}
          >
            <RadioButtonUncheckedRoundedIcon
              className={[classes.cellContentIconStyle, classes.uploadLinkIconStyle].join(
                ' ',
              )}
            ></RadioButtonUncheckedRoundedIcon>
            <span className={classes.cellContentPropertyValueStyle}>
              <Link
                className={classes.uploadLinkStyle}
                href={item?.value}
                color="secondary"
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>{`Invoice ${itemIndex + 1}`}</b>
              </Link>
            </span>
          </div>
        );
      })}
    </div>
  );
}

TaxInvoicesList.propTypes = {
  classes: PropTypes.object,
  field: PropTypes.object,
};

export default compose(withStyles(ActivityDetailPageStyle))(TaxInvoicesList);
