import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import CircleBlueIcon from '@assets/icons/Circle-blue.png';
import CircleRedIcon from '@assets/icons/Circle-red.png';
import CircleYellowIcon from '@assets/icons/Circle-yellow.png';
import { activitiesCell, activitiesDetailLabel } from '@data/constants';
import { ReactComponent as Empty } from '@assets/icons/Empty.svg';
import { ActivityDetailPageStyle } from './style';

function UserTypeDelegationMatrix({ classes, field }) {
  if (field.value.length === 0) {
    return (
      <div className={classes.gridSecondSpecificContentStyle}>
        <span>{activitiesCell.USERTYPE_DELEGATION}</span>
        <div className={classes.data_empty_root}>
          <Empty style={{ width: '160', height: '151.23' }} />
          <div className={classes.data_empty_label}>
            <Typography variant="h6">{activitiesDetailLabel.NO_ACTIVE_CARDS}</Typography>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.gridSecondSpecificContentStyle}>
      <span>{activitiesCell.USERTYPE_DELEGATION} :</span>
      {field.value.map((v, i) => {
        const CircleComponent = {
          0: {
            src: CircleBlueIcon,
            alt: 'CircleBlueIcon',
          },
          1: {
            src: CircleRedIcon,
            alt: 'CircleRedIcon',
          },
          2: {
            src: CircleYellowIcon,
            alt: 'CircleYellowIcon',
          },
        }[i % 3];

        let CellClass = [classes.specificContentStyle];

        if (i !== 0) {
          CellClass.push(classes.notFirstSpecificContentStyle);
        }

        if (i !== field.value.length - 1) {
          CellClass.push(classes.borderContentStyle);
        }

        CellClass = CellClass.join(' ');

        return (
          <Grid container key={i} className={CellClass}>
            <Grid item xs={6} className={classes.cellContentPropertyStyle}>
              <img
                src={CircleComponent.src}
                alt={CircleComponent.alt}
                loading="lazy"
                className={classes.cellContentIconStyle}
              />
              <span className={classes.cellContentPropertyValueStyle}>
                {v.displayName}:{' '}
              </span>
            </Grid>
            <Grid item xs={6} className={classes.contentValueStyle}>
              <b>{v.value} Active Card(s)</b>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
}

UserTypeDelegationMatrix.propTypes = {
  classes: PropTypes.object,
  field: PropTypes.object,
};

export default compose(withStyles(ActivityDetailPageStyle))(UserTypeDelegationMatrix);
