const auditHistoryTypes = {
  GET_SPEND_CATEGORY_LIST_REQUEST: 'GET_SPEND_CATEGORY_LIST_REQUEST',
  GET_SPEND_CATEGORY_LIST_SUCCESS: 'GET_SPEND_CATEGORY_LIST_SUCCESS',
  GET_SPEND_CATEGORY_LIST_ERROR: 'GET_SPEND_CATEGORY_LIST_ERROR',

  GET_RECEIPT_REQUEST: 'GET_RECEIPT_REQUEST',
  GET_RECEIPT_SUCCESS: 'GET_RECEIPT_SUCCESS',
  GET_RECEIPT_ERROR: 'GET_RECEIPT_ERROR',

  UPLOAD_RECEIPT_REQUEST: 'UPLOAD_RECEIPT_REQUEST',
  UPLOAD_RECEIPT_SUCCESS: 'UPLOAD_RECEIPT_SUCCESS',
  UPLOAD_RECEIPT_ERROR: 'UPLOAD_RECEIPT_ERROR',

  DELETE_RECEIPT_REQUEST: 'DELETE_RECEIPT_REQUEST',
  DELETE_RECEIPT_SUCCESS: 'DELETE_RECEIPT_SUCCESS',
  DELETE_RECEIPT_ERROR: 'DELETE_RECEIPT_ERROR',

  GET_TRANSACTION_ALLOCATION_REQUEST: 'GET_TRANSACTION_ALLOCATION_REQUEST',
  GET_TRANSACTION_ALLOCATION_SUCCESS: 'GET_TRANSACTION_ALLOCATION_SUCCESS',
  GET_TRANSACTION_ALLOCATION_ERROR: 'GET_TRANSACTION_ALLOCATION_ERROR',

  SPLIT_TRANSACTION_ALLOCATION_REQUEST: 'SPLIT_TRANSACTION_ALLOCATION_REQUEST',
  SPLIT_TRANSACTION_ALLOCATION_SUCCESS: 'SPLIT_TRANSACTION_ALLOCATION_SUCCESS',
  SPLIT_TRANSACTION_ALLOCATION_ERROR: 'SPLIT_TRANSACTION_ALLOCATION_ERROR',

  GET_ACCOUNT_ALLOCATION_REQUEST: 'GET_ACCOUNT_ALLOCATION_REQUEST',
  GET_ACCOUNT_ALLOCATION_SUCCESS: 'GET_ACCOUNT_ALLOCATION_SUCCESS',
  GET_ACCOUNT_ALLOCATION_ERROR: 'GET_ACCOUNT_ALLOCATION_ERROR',

  GET_TRANSACTION_AUDIT_REQUEST: 'GET_TRANSACTION_AUDIT_REQUEST',
  GET_TRANSACTION_AUDIT_SUCCESS: 'GET_TRANSACTION_AUDIT_SUCCESS',
  GET_TRANSACTION_AUDIT_ERROR: 'GET_TRANSACTION_AUDIT_ERROR',

  GET_DISPUTE_EXPENSES_STATUS_REQUEST: 'GET_DISPUTE_EXPENSES_STATUS_REQUEST',
  GET_DISPUTE_EXPENSES_STATUS_SUCCESS: 'GET_DISPUTE_EXPENSES_STATUS_SUCCESS',
  GET_DISPUTE_EXPENSES_STATUS_ERROR: 'GET_DISPUTE_EXPENSES_STATUS_ERROR',

  CLEAR_ERROR_STATE: 'CLEAR_ERROR_STATE',
  CLEAR_ALLOCATION_STATE: 'CLEAR_ALLOCATION_STATE',
  CLEAR_DETAIL_VIEW_SEARCH_STATE: 'CLEAR_DETAIL_VIEW_SEARCH_STATE',
  CLEAR_STATE: 'CLEAR_STATE',
};

export default auditHistoryTypes;
