import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import AlertOrangeRoundedIcon from '@assets/icons/Alert-orange-rounded.png';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { customization } from 'customization/default';
import StoreIcon from '@material-ui/icons/Storefront';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CalendarIcon from '@material-ui/icons/DateRange';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CancelOutlineIcon from '@material-ui/icons/Close';
import CheckOutlineIcon from '@material-ui/icons/Check';
import Paper from '@material-ui/core/Paper';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Deposit from 'assets/userTransactions/Deposit.png';
import Trolley from 'assets/userTransactions/Trolley.png';
import Button from '@material-ui/core/Button';
import ErrorMessageDialog from 'components/errorMessageDialog';
import SuccessMessageDialog from '@components/successMessageDialog';
import FormCurrencyInput from '@components/formCurrencyInput';
import * as transactionActions from '@redux/transactions/transactionActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  apiRequestStatus,
  splitMethodOptions,
  scanningStatus,
  receiptStatusType,
} from '@data/constants';
import {
  cancelDisputeExpense,
  disputeExpense,
  changeSpendCategory,
  splitTransactionAllocation,
  deleteSplitAccount,
  getTransactionDetails,
} from '@services/api/transactionApi';
import CustomizedSnackbar from '@components/customizedSnackBar';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment-timezone';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Input,
  InputAdornment,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  Modal,
  Backdrop,
} from '@material-ui/core';
import { isArray, startCase, find } from 'lodash';
import {
  activitiesStatus,
  transactionDetailConfirmationType,
  transactionTypeFrom,
  transactionTypeCode,
} from '@data/constants';
import usePreviousHelper from '@utils/usePreviousHelper';
import FileIcon from '@assets/icons/file-icon.svg';
import FilePDFIcon from '@assets/icons/file-pdf-icon.svg';
import { transactionListViewStyle, dialogActionStyle, dialogContentStyle } from './style';

const ConfirmDialogContent = withStyles(dialogContentStyle)(DialogContent);
const ConfirmDialogActions = withStyles(dialogActionStyle)(DialogActions);
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

function TransactionDetailView(props) {
  const {
    classes,
    data,
    open,
    handleClose,
    transactionActions,
    token,
    spendCategoryList,
    transaction: defaultTransaction,
    timezone,
    searchAccountList,
    loadingTransactionList,
    errorTransaction,
    typeData,
    transactionAuditList,
  } = props;

  const [transaction, setTransaction] = useState(defaultTransaction);
  const [errorMessage, setErrorMessage] = useState({ isError: false, message: '' });
  const [openSuccess, setOpenSuccess] = useState({ isSuccess: false, message: '' });
  const [accountList, setAccountList] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({ type: null, message: '' });
  const [transactionAllocationTab, setTransactionAllocationTab] = useState(0);
  const [searchCardToggle, setSearchCardToggle] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchDefaultLocation, setSearchDefaultLocation] = useState(false);
  const [transactionAllocationView, setTransactionAllocationView] = useState(0);
  const [receiptList, setReceiptList] = useState([]);
  const [deleteReceipt, setDeleteReceipt] = useState(false);
  const [uploadReceiptSuccess, setUploadReceiptSuccess] = useState(false);
  const [isScanningReceipt, setIsScanningReceipt] = useState(scanningStatus.NONE);
  const [receiptPagination, setReceiptPagination] = useState(0);
  const [onDisputeAction, setOnDisputeAction] = useState(false);
  const [detailImageModal, setDetailImageModal] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [keyEntered, setKeyEntered] = useState('');
  const [currencySymbol, setCurrencySymbol] = useState('$');
  const [totalTransaction, setTotalTransaction] = useState(0);
  const [actionButtonTransactionDetailView, setActionButtonTransactionDetailView] =
    useState(true);
  const [nameErrorAllocation, setNameErrorAllocation] = useState('');
  const [decimalPlaces, setDecimalPlaces] = useState(2);
  const [initData, setInitData] = useState(false);
  const [accountAllocationToBeDelete, setAccountAllocationToBeDelete] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [snackbar, setSnackbar] = useState({
    show: false,
    severity: 'error',
    message: null,
  });
  const prevSearchAccountList = usePreviousHelper(searchAccountList);
  const prevDefaultTransaction = usePreviousHelper(defaultTransaction);
  const prevSearchDefaultLocation = usePreviousHelper(searchDefaultLocation);

  useEffect(() => {
    if (prevDefaultTransaction !== defaultTransaction) {
      setTransaction(defaultTransaction);
    }
    if (errorTransaction.statusCode) {
      const ERROR_DISPUTED =
        /This transaction has been disputed or refunded and not allowed to be change/i.test(
          errorTransaction?.message,
        );

      if (ERROR_DISPUTED) {
        setNameErrorAllocation(defaultTransaction.accounts.name);
        setActionButtonTransactionDetailView(false);
      }

      setSnackbar({
        show: true,
        severity: 'error',
        message: errorTransaction.statusCode + ': ' + errorTransaction.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevDefaultTransaction, defaultTransaction, errorTransaction, transactionActions]);

  const mapExistingAccountAllocation = (accountAllocationList) => {
    if (accountAllocationList?.items?.length > 0) {
      const firstLandingTabAllocation =
        accountAllocationList?.split_method === splitMethodOptions[1].value
          ? 0
          : accountAllocationList?.split_method === splitMethodOptions[2].value
          ? 1
          : 2;
      const currencySymbol = accountAllocationList?.currency?.symbol || '%';
      const newObj = accountAllocationList.items.map((element) => {
        const userName = element?.user
          ? element?.user?.first_name + ' ' + element?.user?.last_name
          : element?.location?.description;
        return {
          type: element?.type || '',
          code: element?.code || '',
          fullName: userName ? userName : '',
          id: element?.account_id || 0,
          percentage: firstLandingTabAllocation === 2 ? element?.percentage : 0,
          actual_amount:
            firstLandingTabAllocation === 0 ? element?.actual_amount : '0.00',
        };
      });
      setDecimalPlaces(accountAllocationList?.currency.decimal_places);
      setTransactionAllocationTab(firstLandingTabAllocation);
      setTotalTransaction(Number(accountAllocationList?.total_amount));
      setCurrencySymbol(currencySymbol);
      setAccountList(newObj);
    } else {
      setAccountList([]);
    }
  };

  useEffect(() => {
    if (open && defaultTransaction?.id && defaultTransaction?.card?.id && token) {
      if (isScanningReceipt === scanningStatus.INPROGRESS) {
        setTimeout(() => {
          transactionActions
            .getReceipt(defaultTransaction?.id, defaultTransaction?.card?.user?.id, token)
            .then((response) => {
              setReceiptList(response.data);
              setUploadReceiptSuccess(false);
              setDeleteReceipt(false);
              setIsScanningReceipt(scanningStatus.COMPLETED);
            });
        }, 8000);
      } else {
        transactionActions
          .getReceipt(defaultTransaction?.id, defaultTransaction?.card?.user?.id, token)
          .then((response) => {
            if (response.data) {
              setReceiptList(response.data);
              setUploadReceiptSuccess(false);
              setDeleteReceipt(false);
            } else {
              setReceiptList([]);
            }
          })
          .catch((error) => {
            setReceiptList([]);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, uploadReceiptSuccess, deleteReceipt]);

  function populateTransactionDetails() {
    transactionActions.getTransactionAuditList(defaultTransaction?.id, token);

    if (
      transaction?.spend_category_user_id &&
      transaction?.access_status &&
      transaction?.transaction_type_code !==
        transactionTypeCode.EXTERNAL_TRANSACTION_TRANFER_FUND &&
      transaction?.transaction_type !==
        transactionTypeFrom.INTERNAL_TRANSACTION_TRANSFER_FUND
    ) {
      transactionActions.getSpendCategoryList(transaction?.spend_category_user_id, token);
    }

    getTransactionDetails(
      data?.id,
      defaultTransaction?.transaction_details?.id,
      token,
      typeData,
    ).then(({ data }) => {
      if (
        data?.card.id === null ||
        (!data?.transaction_details.latest && !data?.transactionAllocation?.isTrue)
      ) {
        setActionButtonTransactionDetailView(false);
      }

      setTransaction({
        ...data,
        dispute_in_progress: data.disputeTransaction?.dispute_in_progress,
        activity_status: data.disputeTransaction?.activity_status,
      });

      mapExistingAccountAllocation(data.transactionAllocation?.data);
    });
  }

  useEffect(() => {
    if (open && defaultTransaction?.id && token) {
      setInitData(true);
      populateTransactionDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    open,
    transaction?.access_status,
    transaction?.transaction_type,
    transaction?.transaction_type_code,
  ]);

  function onDocumentLoadSuccess({ numPages }) {
    setPageNumber(numPages);
  }

  const handleCloseErrorMessage = () => {
    setErrorMessage({
      isError: false,
      message: '',
    });
  };

  const matchIcons = [
    {
      Icon: <StoreIcon fontSize="small" htmlColor="#737373" />,
      data: receiptList[receiptPagination]?.merchant_status,
    },
    {
      Icon: <AttachMoneyIcon fontSize="small" htmlColor="#737373" />,
      data: receiptList[receiptPagination]?.date_status,
    },
    {
      Icon: <CalendarIcon fontSize="small" htmlColor="#737373" />,
      data: receiptList[receiptPagination]?.amount_status,
    },
  ];

  const handleTransactionAllocationTabChange = (e, value) => {
    setTransactionAllocationTab(value);
  };

  const handleOKConfirmDialog = () => {
    if (confirmDialog.type === transactionDetailConfirmationType.DISPUTE_EXPENSE) {
      handleDisputeExpense();
    }

    if (confirmDialog.type === transactionDetailConfirmationType.CANCEL_DISPUTE_EXPENSE) {
      handleCancelDispute();
    }

    if (confirmDialog.type === transactionDetailConfirmationType.CHANGE_SPEND_CATEGORY) {
      handleSubmitSpendCategory();
    }

    if (confirmDialog.type === transactionDetailConfirmationType.DELETE_RECEIPT) {
      handleDeleteReceiptImage(receiptPagination);
    }

    if (
      confirmDialog.type ===
      transactionDetailConfirmationType.REMOVE_ACCOUNT_TRANSACTION_ALLOCATION
    ) {
      const tempArr = accountList.filter(
        (account) => account.id !== accountAllocationToBeDelete,
      );
      setAccountList(tempArr);
    }

    handleCloseConfirmDialog();
  };

  const handleCancelConfirmDialog = () => {
    if (confirmDialog.type === transactionDetailConfirmationType.CHANGE_SPEND_CATEGORY) {
      setTransaction((old) => ({
        ...old,
        spend_category: {
          ...old.spend_category,
          id: old.spend_category.old_id,
          internal_name: old.spend_category.old_internal_name,
        },
      }));
    }

    handleCloseConfirmDialog();
  };

  const handleSpendCategoryChange = (event) => {
    const selectedIndex = event.nativeEvent.target.selectedIndex;

    setTransaction((old) => ({
      ...old,
      spend_category: {
        ...old.spend_category,
        id: parseInt(event.target.value),
        internal_name: event.nativeEvent.target[selectedIndex].text,
        old_id: old.spend_category.id,
        old_internal_name: old.spend_category.internal_name,
      },
    }));
    setConfirmDialog({
      type: transactionDetailConfirmationType.CHANGE_SPEND_CATEGORY,
      message: customization.viewTransactionModal.changeSpendCategoryMessage,
    });
  };

  const handleSubmitSpendCategory = () => {
    changeSpendCategory(
      {
        spend_category_id: parseInt(transaction?.spend_category?.id),
        transaction_id: transaction?.id,
      },
      token,
    ).catch((error) => {
      setErrorMessage({
        isError: true,
        message: error.response.data.message || error.message,
      });
      setTransaction((old) => ({
        ...old,
        spend_category: {
          ...old.spend_category,
          id: old.spend_category.old_id,
          internal_name: old.spend_category.old_internal_name,
        },
      }));
    });
  };

  const handleDisputeButton = () => {
    setConfirmDialog({
      type: !transaction?.activity_status
        ? transactionDetailConfirmationType.DISPUTE_EXPENSE
        : transaction?.activity_status === activitiesStatus.OPEN
        ? transactionDetailConfirmationType.CANCEL_DISPUTE_EXPENSE
        : transactionDetailConfirmationType.DISPUTE_EXPENSE,
      message: !transaction?.activity_status
        ? customization.viewTransactionModal.disputeExpenseMessage
        : transaction?.activity_status === activitiesStatus.OPEN
        ? customization.viewTransactionModal.cancelDisputeExpenseMessage
        : customization.viewTransactionModal.disputeExpenseMessage,
    });
  };

  const handleConfirmDeleteReceipt = () => {
    setConfirmDialog({
      type: transactionDetailConfirmationType.DELETE_RECEIPT,
      message: customization.viewTransactionModal.deleteReceiptMessage,
    });
  };

  const handleDisputeExpense = () => {
    setOnDisputeAction(true);
    disputeExpense(
      {
        user_id: transaction?.card?.user?.id,
        txn_id: transaction?.id,
      },
      token,
    )
      .then(() =>
        setTransaction((old) => ({ ...old, activity_status: activitiesStatus.OPEN })),
      )
      .catch((error) => {
        setErrorMessage({
          isError: true,
          message: error.response.data.message || error.message,
        });
      })
      .finally(() => setOnDisputeAction(false));
  };

  const handleCancelDispute = () => {
    setOnDisputeAction(true);
    cancelDisputeExpense(
      {
        user_id: transaction?.card?.user?.id,
        txn_id: transaction?.id,
      },
      token,
    )
      .then(() => setTransaction((old) => ({ ...old, activity_status: null })))
      .catch((error) => {
        setErrorMessage({
          isError: true,
          message: error.response.data.message || error.message,
        });
      })
      .finally(() => setOnDisputeAction(false));
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;
    const formData = new FormData();

    Array.from(files).forEach((file) => {
      formData.append('receipt', file);
      formData.append('transaction_id', transaction?.id);
      formData.append('user_id', transaction?.card?.user?.id);

      const options = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      transactionActions
        .uploadReceipt(formData, token, options)
        .then((response) => {
          setUploadReceiptSuccess(true);
          setIsScanningReceipt(scanningStatus.INPROGRESS);
          setOpenSuccess({
            isSuccess: true,
            message: `${response.data.message}, scanning data please wait 3-4 seconds to complete ...`,
          });
        })
        .catch(() => {
          setUploadReceiptSuccess(false);
        });
    });
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialog({ type: null, message: '' });
  };

  const renderConfirmationDialog = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        onClose={handleCloseConfirmDialog}
        open={!!confirmDialog.type}
        classes={{
          paper: classes.confirmDialogPaper,
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseConfirmDialog}
        ></DialogTitle>
        <ConfirmDialogContent>
          <Box p={2}>
            <div className={classes.confirmDialogExclamationIconContainter}>
              <img
                src={AlertOrangeRoundedIcon}
                alt="alertOrangeRoundedIcon"
                loading="lazy"
                className={classes.confirmDialogAlertIcon}
              />
            </div>
            <div className={classes.exclamationIconContainterStyle}>
              <Typography className={classes.confirmDialogMessage}>
                <b>{confirmDialog.message}</b>
              </Typography>
            </div>
          </Box>
        </ConfirmDialogContent>
        <ConfirmDialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
              <Button
                autoFocus
                type="submit"
                variant="contained"
                className={classes.confirmDialogAcceptButton}
                onClick={handleOKConfirmDialog}
              >
                {customization.activityDetail.approveConfirmBtn}
              </Button>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Button
                className={classes.confirmDialogCloseButton}
                onClick={handleCancelConfirmDialog}
                variant="outlined"
              >
                {customization.activityDetail.cancelBtn}
              </Button>
            </Grid>
          </Grid>
        </ConfirmDialogActions>
      </Dialog>
    );
  };

  const renderAuditHistoryFull = () => {
    if (transactionAuditList.length === 0) {
      return (
        <div className={classes.emptyTransactionAuditListPlaceholder}>
          <Typography variant="subtitle2">
            {customization.viewTransactionModal.emptyAuditHistoryList}
          </Typography>
        </div>
      );
    }

    return (
      <>
        <Box className={classes.auditHistoryTitleWrapper}>
          <Typography variant="h6">
            {customization.viewTransactionModal.auditHistory}
          </Typography>
        </Box>
        <TableContainer className={classes.auditHistoryTableContainer}>
          <Table className={classes.table} aria-label="table">
            <TableHead>
              <TableRow className={classes.titleRowStyle} key="history-table-header">
                {renderAuditHistoryHeader()}
              </TableRow>
            </TableHead>
            <TableBody>{renderAuditHistoryBody()}</TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const renderAuditHistoryHeader = () => {
    const { classes } = props;
    const auditHistoryTableHeader = [
      { headerLabel: customization.viewTransactionModal.dateTime, id: 1 },
      { headerLabel: customization.viewTransactionModal.userCardholder, id: 2 },
      { headerLabel: customization.viewTransactionModal.action, id: 3 },
    ];

    return auditHistoryTableHeader.map((headerItem) => (
      <TableCell
        className={[
          classes.customWidthCellStyle,
          headerItem.headerLabel === customization.viewTransactionModal.action
            ? classes.auditHistoryCommonCell
            : classes.auditHistoryActionCell,
        ].join(' ')}
        key={headerItem.id}
      >
        <Typography variant="subtitle2">
          <b>{headerItem.headerLabel}</b>
        </Typography>
      </TableCell>
    ));
  };

  const AuditHistorySpendCategories = ({ item }) => {
    const isDefaultValue = item.includes(
      customization.viewTransactionModal.notPresentOriginal,
    );
    if (isDefaultValue) {
      return (
        <ul className={classes.listAction}>
          <li>
            {customization.viewTransactionModal.original}
            <span className={classes.italicString}>
              {customization.viewTransactionModal.notPresent}
            </span>
          </li>
          {item
            .filter(
              (filterItem) =>
                filterItem !== customization.viewTransactionModal.notPresentOriginal,
            )
            .map((allocationItem, index) => {
              return (
                <li key={`details-${index}`}>
                  {index + 2}. {allocationItem}
                </li>
              );
            })}
        </ul>
      );
    }

    return (
      <ul className={classes.listAction}>
        {item.map((allocationItem, index) => {
          return (
            <li key={`details-${index}`}>
              {index + 1}. {allocationItem}
            </li>
          );
        })}
      </ul>
    );
  };

  const renderAuditHistoryBody = () => {
    if (!isArray(transactionAuditList)) {
      return null;
    }

    return (
      <>
        {transactionAuditList.map((item) => (
          <TableRow
            className={classes.auditHistoryCell}
            key={`history-table-body-${item.id}`}
          >
            <TableCell
              className={[
                classes.customWidthCellStyle,
                classes.auditHistoryCommonCell,
              ].join(' ')}
            >
              <b>
                {moment(item.occured_at).utc().tz(timezone).format('DD/MM/YYYY hh:mm a')}
              </b>
            </TableCell>
            <TableCell
              className={[
                classes.customWidthCellStyle,
                classes.auditHistoryCommonCell,
              ].join(' ')}
            >
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">
                      {item.is_by_system
                        ? customization.viewTransactionModal.bySystem
                        : `${item.user.first_name} ${item.user.last_name}`}
                    </Typography>
                  </React.Fragment>
                }
              >
                <b>
                  {item.is_by_system
                    ? customization.viewTransactionModal.bySystem
                    : `${item.user.first_name} ${item.user.last_name}`}
                </b>
              </HtmlTooltip>
            </TableCell>
            <TableCell
              className={[
                classes.customWidthCellStyle,
                classes.auditHistoryActionCell,
              ].join(' ')}
            >
              {item.action?.status && (
                <>
                  <span>
                    {item.action?.status}{' '}
                    {item.action?.splitBy &&
                      `Split By ${startCase(item.action.splitBy.toLowerCase())}:`}
                  </span>
                  {item.action?.details?.length > 0 && (
                    <AuditHistorySpendCategories item={item.action?.details} />
                  )}
                </>
              )}

              {item.action.new_transaction_state && (
                <ul className={classes.listAction}>
                  <li>
                    Old State: <b>{item.action?.old_transaction_state}</b>
                  </li>
                  <li>
                    New State: <b>{item.action?.new_transaction_state}</b>
                  </li>
                </ul>
              )}
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const renderSearchResultHeader = () => {
    const { classes } = props;
    const searchResultTableHeader = [
      { headerLabel: customization.viewTransactionModal.type, id: 0 },
      { headerLabel: customization.viewTransactionModal.codeMobileNumber, id: 1 },
      { headerLabel: customization.viewTransactionModal.fullNameDescription, id: 2 },
      { headerLabel: customization.viewTransactionModal.action, id: 3 },
    ];
    return searchResultTableHeader.map((header) => (
      <TableCell
        key={header.id}
        className={
          header.id < 3 ? classes.searchTitleCellStyle : classes.smallSearchTitleCellStyle
        }
      >
        <Typography variant="subtitle2" key={header.id}>
          <b>{header.headerLabel}</b>
        </Typography>
      </TableCell>
    ));
  };

  const renderTransactionAllocationHeader = () => {
    const { classes } = props;
    const searchResultTableHeader = [
      { headerLabel: customization.viewTransactionModal.merchantName, id: 0 },
      { headerLabel: customization.viewTransactionModal.date, id: 1 },
      { headerLabel: customization.viewTransactionModal.amount, id: 2 },
      { headerLabel: customization.viewTransactionModal.cardHolder, id: 3 },
      { headerLabel: customization.viewTransactionModal.receipt, id: 4 },
      { headerLabel: customization.viewTransactionModal.inDispute, id: 5 },
    ];
    return searchResultTableHeader.map((header) => (
      <TableCell key={header.id} className={classes.commonTitleCellStyle}>
        <Typography variant="subtitle2" key={header.id}>
          <b>{header.headerLabel}</b>
        </Typography>
      </TableCell>
    ));
  };

  const selectSingleAccount = (id) => {
    const tempArr = accountList.filter((account) => account.id === id);
    setAccountList(tempArr);
    setTransactionAllocationView(0);
  };

  const executeSearch = useCallback(async () => {
    await transactionActions.getTransactionAllocation(
      token,
      defaultTransaction?.transaction_details?.id,
      searchKeyword,
      searchDefaultLocation ? 1 : 0,
    );
  }, [
    defaultTransaction,
    transactionActions,
    token,
    searchKeyword,
    searchDefaultLocation,
  ]);

  const getEnteredKey = async (event) => {
    setKeyEntered(event.key);
  };

  useEffect(() => {
    if (keyEntered === 'Enter' && searchKeyword !== '') {
      executeSearch();
    } else if (keyEntered === 'Enter' && searchKeyword === '') {
      setSearchResult([]);
      setSearchCardToggle(false);
    }
  }, [executeSearch, keyEntered, searchKeyword]);

  const mappingSearchResultData = useCallback(() => {
    const searchResultTemp = searchAccountList.map((element) => {
      return {
        type: element?.type || '',
        code: element?.code || '',
        fullName: element?.description || '',
        id: element?.accountId || 0,
        action: false,
        percentage: element?.percentage || 0,
        actual_amount: element?.actual_amount || '0.00',
      };
    });
    setKeyEntered('');
    setSearchResult(searchResultTemp);
  }, [searchAccountList]);

  useEffect(() => {
    if (
      prevSearchAccountList !== searchAccountList &&
      loadingTransactionList === apiRequestStatus.RESOLVED &&
      initData
    ) {
      mappingSearchResultData();
      setSearchCardToggle(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingTransactionList]);

  const addSearchAccount = () => {
    setKeyEntered('');
    setSearchKeyword('');
    setSearchCardToggle(false);
    const checkedUser = searchResult.filter((item) => {
      if (item.action) {
        return item;
      } else {
        return false;
      }
    });
    const checkIfDouble = checkedUser.filter(
      (element) => accountList.find((account) => account.id === element.id) === undefined,
    );
    const accountListTemp = [...accountList, ...checkIfDouble];
    setAccountList(accountListTemp);
  };

  const onCancelSearch = () => {
    setKeyEntered('');
    setSearchKeyword('');
    setSearchCardToggle(false);
    setSearchResult([]);
    setSearchDefaultLocation(false);
  };

  const toggleSearchDefaultLocation = () => {
    setSearchDefaultLocation(!searchDefaultLocation);
  };

  // this useEffect for checkbutton (location) on search user
  useEffect(() => {
    if (open && prevSearchDefaultLocation !== searchDefaultLocation) {
      executeSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDefaultLocation]);

  const toggleSearchResultAction = (index) => {
    const shallowState = searchResult;
    const target = { ...searchResult[index] };
    target.action = !target.action;
    shallowState[index] = target;
    setSearchResult(shallowState);
  };

  const onSaveChangesTransactionAllocation = () => {
    setTransactionAllocationView(0);
  };

  const onCancelTransactionAllocation = () => {
    setSearchKeyword('');
    setKeyEntered('');
    setTransactionAllocationView(0);
    transactionActions.transactionClearAllocationState();
  };

  const onSearchChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const onRemoveSelectedItemFromList = ({ id, fullName }) => {
    setAccountAllocationToBeDelete(id);
    setConfirmDialog({
      type: transactionDetailConfirmationType.REMOVE_ACCOUNT_TRANSACTION_ALLOCATION,
      message: `${customization.viewTransactionModal.removeAccountTransactionAllocationMessage} ${fullName}?`,
    });
  };

  const handleAmountChange = (eventValue, id) => {
    const tempArr = accountList.map((account) =>
      account.id === id ? { ...account, actual_amount: eventValue } : account,
    );
    setAccountList(tempArr);
  };

  // parsed value onBlur, empty = 0.
  function parsingValue(val, id) {
    const parseVal = Number(val);
    const tempArr = accountList.map((account) =>
      account.id === id
        ? { ...account, percentage: val === '' ? '0' : parseVal.toString() }
        : account,
    );
    setAccountList(tempArr);
  }

  // just allow number and empty string (empty will be '0', it handle on parsingValue)
  const handlePercentageChange = (eventValue, id) => {
    const regex = /^[\s\d]*$/;
    if (eventValue.match(regex) !== null) {
      const tempArr = accountList.map((account) =>
        account.id === id ? { ...account, percentage: eventValue } : account,
      );
      setAccountList(tempArr);
    }
  };

  const totalPercentage = () =>
    accountList.reduce((counting, account) => {
      return counting + Number(account.percentage);
    }, 0);

  const settingErrorBalanceOnAccount = (error) => {
    if (error.response.data?.details) {
      if (error.response.data?.details.length === 1) {
        return `${error.response.data?.details[0].name}.`;
      } else {
        const errorSettingBalance = error.response.data?.details.reduce(
          (string, detail, index) => {
            if (index === 0) {
              return `${detail.name},`;
            } else if (index === error.response.data?.details.length - 1) {
              return `${string} and ${detail.name}.`;
            } else {
              return `${string} ${detail.name},`;
            }
          },
          '',
        );
        return errorSettingBalance;
      }
    }
    return '';
  };

  const submitData = async () => {
    let splitMethod;
    let accountId;
    const actualAmount =
      transaction?.transactionAllocation?.data?.total_amount.replace('-', '') ?? null;

    switch (transactionAllocationTab) {
      case 0:
        splitMethod = splitMethodOptions[1].value;
        accountId = find(accountList, { actual_amount: actualAmount })?.id;
        break;
      case 1:
        splitMethod = splitMethodOptions[2].value;
        break;
      default:
        splitMethod = splitMethodOptions[0].value;
        accountId = find(accountList, { percentage: '100' })?.id;
        break;
    }

    /*
      Use DELETE method, if transaction allocation has 1 account
      or a full transaction amount was allocated to other account
      either by Amount or by Percentage
    */
    if (accountList.length === 1 || accountId) {
      try {
        const response = await deleteSplitAccount(
          defaultTransaction?.transaction_details?.id,
          accountList.length === 1 ? accountList[0].id : accountId,
          token,
        );
        if (response.status === 200) {
          setOpenSuccess({ isSuccess: true, message: response.data.message });
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        const haveDetailsError = settingErrorBalanceOnAccount(error);
        setErrorMessage({
          isError: true,
          message: haveDetailsError
            ? `${error.response.data.message}: ${haveDetailsError}`
            : error.response.data.message || error.message,
        });
      }
      // else transaction allocation has more than 1 accounts (SPLIT API)
    } else {
      const params = {
        txn_detail_id: defaultTransaction?.transaction_details?.id,
        split_method: splitMethod,
        details: accountList.map((account) => ({
          account_id: account.id,
          percentage: transactionAllocationTab === 2 ? Number(account.percentage) : null,
          amount: transactionAllocationTab === 0 ? Number(account.actual_amount) : null,
        })),
      };

      try {
        const response = await splitTransactionAllocation(params, token);
        if (response.status === 200) {
          setOpenSuccess({ isSuccess: true, message: response.data.message });
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        const ERROR_AMOUNT =
          /Total split amount should be equal with transaction amount/i.test(
            error.response.data.message,
          );
        const haveDetailsError = settingErrorBalanceOnAccount(error);
        setErrorMessage({
          isError: true,
          message: ERROR_AMOUNT
            ? `${error.response.data.message} ${currencySymbol} ${totalTransaction}`
            : haveDetailsError
            ? `${error.response.data.message}: ${haveDetailsError}`
            : error.response.data.message || error.message,
        });
      }
    }

    populateTransactionDetails();
  };

  const setErrorAllocation = (message) => {
    setErrorMessage({
      isError: true,
      message,
    });
  };

  const submitTranscationAllocation = () => {
    if (accountList.length === 1) {
      submitData();
    } else if (transactionAllocationTab === 2 && totalPercentage() !== 100) {
      setErrorAllocation('Total split amount should be 100%');
    } else {
      submitData();
    }
  };

  const renderClientList = (mode) => {
    return (
      <>
        {accountList.length > 0 ? (
          accountList?.map((item, index) => (
            <Container key={index} className={classes.transactionAllocationItemWrapper}>
              <Typography
                variant="subtitle2"
                key={index}
                className={
                  transactionAllocationTab === 1
                    ? classes.fontAccountListEqual
                    : classes.fontAccountList
                }
              >
                {index + 1}. {item.fullName}
              </Typography>
              {mode === 1 ? (
                <Button
                  key={index}
                  type="button"
                  variant="outlined"
                  onClick={() => onRemoveSelectedItemFromList(item)}
                  className={classes.buttonColorStyle}
                >
                  {customization.viewTransactionModal.remove}
                </Button>
              ) : mode === 2 ? (
                <Button
                  key={index}
                  type="button"
                  variant="outlined"
                  onClick={() => selectSingleAccount(item.id)}
                  className={classes.buttonColorStyle}
                >
                  {customization.viewTransactionModal.select}
                </Button>
              ) : (
                <>
                  <FormControl variant="outlined" key={index}>
                    {transactionAllocationTab === 0 && (
                      <FormCurrencyInput
                        field="lowBalanceValue"
                        className={classes.amountInput}
                        touched={false}
                        valid={true}
                        decimalPlaces={decimalPlaces}
                        value={item?.actual_amount}
                        onChangeInput={(value) => handleAmountChange(value, item.id)}
                        currencySymbol={currencySymbol}
                      />
                    )}
                    {transactionAllocationTab === 2 && (
                      <OutlinedInput
                        className={classes.percentageInput}
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        type="text"
                        key={`percentage ${index}`}
                        value={item?.percentage}
                        onBlur={(e) => parsingValue(e.target.value, item.id)}
                        onChange={(e) => handlePercentageChange(e.target.value, item.id)}
                      />
                    )}
                  </FormControl>
                </>
              )}
            </Container>
          ))
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderTransactionAllocationView = () => {
    switch (transactionAllocationView) {
      case 1: //Search client/location mode
        return (
          <>
            <div className={classes.allocationTitleStyle}>
              {customization.viewTransactionModal.changeTransactionAllocation}
            </div>
            <Container className={classes.transactionAllocationListOuterWrapper}>
              <FormControl maxWidth={true} className={classes.searchBarFormWrapper}>
                <Input
                  id="search-user-input"
                  disableUnderline={true}
                  className={classes.searchBar}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  onKeyDown={getEnteredKey}
                  onChange={onSearchChange}
                  inputProps={{ className: classes.receiptPaginationContainer }}
                  placeholder={customization.viewTransactionModal.search}
                  value={searchKeyword}
                />
              </FormControl>
              <Container className={classes.transactionAllocationListInnerWrapper}>
                {renderClientList(1)}
              </Container>
              <Container className={classes.changeAllocationButtonWrapper}>
                <Button
                  className={classes.changeAllocationButton}
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={onSaveChangesTransactionAllocation}
                >
                  {customization.viewTransactionModal.saveChanges}
                </Button>
                <Button
                  className={[
                    classes.changeAllocationButton,
                    classes.buttonColorStyle,
                  ].join(' ')}
                  type="button"
                  variant="outlined"
                  onClick={onCancelTransactionAllocation}
                >
                  {customization.viewTransactionModal.cancel}
                </Button>
              </Container>
            </Container>
          </>
        );
      case 2: // Remove selected location/user on list mode
        return (
          <>
            <div className={classes.allocationTitleStyle}>
              {customization.viewTransactionModal.removeTransactionSplit}
            </div>
            <Container className={classes.removeTransactionSplitSubheader}>
              <Typography className={classes.changeAllocationButton} color="secondary">
                {customization.viewTransactionModal.selectTheTransactionSingleAccount}
              </Typography>
            </Container>
            <Container className={classes.transactionAllocationListOuterWrapper}>
              <Container className={classes.transactionAllocationListInnerWrapper}>
                {renderClientList(2)}
              </Container>
              <Container className={classes.changeAllocationButtonWrapper}>
                <Button
                  className={[
                    classes.changeAllocationButton,
                    classes.buttonColorStyle,
                  ].join(' ')}
                  type="button"
                  variant="outlined"
                  onClick={() => setTransactionAllocationView(0)}
                >
                  {customization.viewTransactionModal.cancel}
                </Button>
              </Container>
            </Container>
          </>
        );
      default:
        return (
          <>
            <div className={classes.allocationTitleStyle}>
              {customization.viewTransactionModal.transactionAllocation}
            </div>
            {accountList?.length > 1 ? (
              <>
                <Tabs
                  indicatorColor="primary"
                  value={transactionAllocationTab}
                  onChange={handleTransactionAllocationTabChange}
                  className={classes.transactionTabStyle}
                >
                  <Tab
                    label={customization.viewTransactionModal.amount}
                    className={classes.transactionButtonTabStyle}
                    classes={{
                      selected: classes.transactionButtonSelectedStyle,
                    }}
                  />
                  <Tab
                    label={customization.viewTransactionModal.equalParts}
                    className={classes.transactionButtonTabStyle}
                    classes={{
                      selected: classes.transactionButtonSelectedStyle,
                    }}
                  />
                  <Tab
                    label={customization.viewTransactionModal.percentage}
                    className={classes.transactionButtonTabStyle}
                    classes={{
                      selected: classes.transactionButtonSelectedStyle,
                    }}
                  />
                </Tabs>
                <Container className={classes.transactionAllocationListOuterWrapper}>
                  <Container className={classes.transactionAllocationListInnerWrapper}>
                    {renderClientList(3)}
                  </Container>
                  <Container className={classes.transcationAllocationButtonWrapper}>
                    <Button
                      disabled={!actionButtonTransactionDetailView}
                      type="button"
                      variant="outlined"
                      onClick={() => setTransactionAllocationView(1)}
                      className={classes.buttonAddRemoveAccountStyle}
                    >
                      {customization.viewTransactionModal.addRemoveAccount}
                    </Button>
                    <Button
                      disabled={!actionButtonTransactionDetailView}
                      type="button"
                      variant="outlined"
                      onClick={() => setTransactionAllocationView(2)}
                      className={classes.buttonAddRemoveAccountStyle}
                    >
                      {customization.viewTransactionModal.removeSplit}
                    </Button>
                    <Button
                      disabled={!actionButtonTransactionDetailView}
                      className={classes.buttonSubmit}
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={submitTranscationAllocation}
                    >
                      {customization.viewTransactionModal.saveChanges}
                    </Button>
                  </Container>
                </Container>
              </>
            ) : (
              <>
                <Container className={classes.singleTransactionAllocationContainer}>
                  <Typography variant="h6" className={classes.clientCaptionContainer}>
                    {customization.viewTransactionModal.transactionAllocationClient}{' '}
                    {accountList.length < 1 && <b>-</b>}
                    {nameErrorAllocation
                      ? nameErrorAllocation
                      : accountList?.[0]?.fullName}
                  </Typography>
                  <Container className={classes.transcationAllocationButtonWrapper}>
                    <Button
                      disabled={!actionButtonTransactionDetailView}
                      type="button"
                      variant="outlined"
                      onClick={() => setTransactionAllocationView(1)}
                    >
                      {customization.viewTransactionModal.addChangeAccount}
                    </Button>
                    <Button
                      disabled={!actionButtonTransactionDetailView}
                      className={classes.changeAllocationButton}
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={submitTranscationAllocation}
                    >
                      {customization.viewTransactionModal.saveChanges}
                    </Button>
                  </Container>
                </Container>
              </>
            )}
          </>
        );
    }
  };

  const renderReceiptListPagination = () => {
    const pagination = receiptList.map((item, index) => (
      <Typography
        onClick={() => setReceiptPagination(index)}
        key={`receipt-item-${item?.signedUrl}`}
        className={
          receiptPagination === index
            ? classes.activePagination
            : classes.inactivePagination
        }
      >
        {index + 1}
      </Typography>
    ));
    return pagination;
  };

  const parseBalance = useCallback((amount) => {
    const transactionViewAmount = amount?.value;
    const transactionViewSymbol = amount?.symbol || '$';

    if (transactionViewAmount) {
      if (transactionViewAmount.charAt(0) === '-') {
        return `-${transactionViewSymbol}${transactionViewAmount.slice(1)}`;
      }
      return `${transactionViewSymbol}${transactionViewAmount}`;
    } else {
      return '';
    }
  }, []);

  const cardHolder = useMemo(() => {
    return `${transaction?.transaction_details?.card_holder?.first_name} ${transaction?.transaction_details?.card_holder?.last_name}`;
  }, [transaction]);

  const handleCloseDetailView = async () => {
    setReceiptList([]);
    setReceiptPagination(0);
    transactionActions.transactionClearDetailViewSearchState();
    setInitData(false);
    setSearchCardToggle(false);
    setSearchKeyword('');
    setTransactionAllocationView(0);
    setActionButtonTransactionDetailView(true);
    setNameErrorAllocation('');
    setSearchDefaultLocation(false);
    transactionActions.transactionClearAllocationState();
    handleClose();
  };

  const isSpendCategoryDisabled = useMemo(() => {
    if (!transaction?.access_status) {
      return true;
    }

    if (
      transaction?.transaction_type_code ===
      transactionTypeCode.EXTERNAL_TRANSACTION_TRANFER_FUND
    ) {
      return true;
    }

    if (
      transaction?.transaction_type ===
      transactionTypeFrom.INTERNAL_TRANSACTION_TRANSFER_FUND
    ) {
      return true;
    }

    return false;
  }, [transaction]);

  const isDisputeButtonDisabled = useMemo(() => {
    if (onDisputeAction) {
      return true;
    }
    if (transaction?.card?.id === null) {
      return true;
    }
    if (
      !transaction?.transaction_details.latest &&
      !transaction?.transactionAllocation?.isTrue
    ) {
      return true;
    }
    if (transaction?.is_disputed) {
      return true;
    }
    if (!transaction?.activity_status) {
      return false;
    }
    return transaction?.activity_status !== activitiesStatus.OPEN;
  }, [transaction, onDisputeAction]);

  const handleCloseImageDetail = () => {
    setDetailImageModal(false);
  };

  const renderDetailImageModal = () => {
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={detailImageModal}
          onClose={handleCloseImageDetail}
          className={classes.modalDetailImage}
          BackdropComponent={Backdrop}
        >
          {receiptList[receiptPagination]?.format === 'pdf' ? (
            <Document
              file={receiptList[receiptPagination]?.signedUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              error={renderIconPDF()}
            >
              <Page pageNumber={pageNumber} height={window.innerHeight - 100} />
            </Document>
          ) : (
            <img
              alt="Upload Receipt"
              src={receiptList[receiptPagination]?.signedUrl}
              className={classes.receiptImageDetail}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.className = classes.receiptPdf;
                currentTarget.src =
                  receiptList[receiptPagination]?.format === 'pdf'
                    ? FilePDFIcon
                    : FileIcon;
              }}
            />
          )}
        </Modal>
      </div>
    );
  };

  const renderMatchIcon = (type) => {
    if (isScanningReceipt === scanningStatus.INPROGRESS) {
      return <CircularProgress className={classes.cellIconLoading} />;
    } else {
      if (type === customization.match.isMatch) {
        return (
          <CheckOutlineIcon
            className={classes.cellIcon}
            fontSize="small"
            htmlColor="#388E3C"
          />
        );
      } else {
        return (
          <CancelOutlineIcon
            className={classes.cellIcon}
            fontSize="small"
            htmlColor="#D32F2F"
          />
        );
      }
    }
  };

  const renderIconPDF = () => {
    return <img className={classes.receiptPdf} alt="Upload Receipt" src={FilePDFIcon} />;
  };

  const renderReceiptUploadSection = () => {
    if (!transaction?.card?.id) return null;
    return (
      <>
        <div className={classes.receiptTaxTitleStyle}>
          {customization.viewTransactionModal.receiptTaxInvoices}
        </div>
        <input
          className={classes.fileUploadInput}
          id="upload-receipt"
          multiple
          type="file"
          onChange={(e) => handleImageUpload(e)}
          disabled={!actionButtonTransactionDetailView}
        />
        <div className={classes.uploadBtnContainer}>
          <label htmlFor="upload-receipt">
            <Button
              variant="outlined"
              component="span"
              className={classes.buttonColorStyle}
              disabled={!actionButtonTransactionDetailView}
            >
              {customization.viewTransactionModal.uploadReceipt}
            </Button>
          </label>
        </div>
        <div>
          {receiptList?.length > 0 && (
            <Box className={classes.receiptPaginationContainer}>
              <Typography>
                <b>View:</b>
              </Typography>
              {renderReceiptListPagination()}
            </Box>
          )}
        </div>
        {receiptList?.length > 0 && (
          <>
            <Paper className={classes.receiptImageContainer}>
              <img
                className={
                  receiptList[receiptPagination]?.format === 'pdf'
                    ? classes.receiptPdf
                    : classes.receiptImage
                }
                alt="Upload Receipt"
                src={
                  receiptList[receiptPagination]?.format === 'pdf'
                    ? FilePDFIcon
                    : receiptList[receiptPagination]?.signedUrl
                }
                onClick={() => openImageModal()}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = FileIcon;
                }}
              />
            </Paper>
            {receiptList.length > 0 && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                className={classes.matchContainer}
              >
                {matchIcons.map((item) => (
                  <Grid key={item.id} item className={classes.matchIcon}>
                    {item.Icon}
                    {renderMatchIcon(item.data)}
                  </Grid>
                ))}
              </Grid>
            )}
            <Container className={classes.transcationAllocationButtonWrapper}>
              <Button
                type="button"
                variant="outlined"
                onClick={() => handleImageDownload(receiptPagination)}
                className={classes.buttonColorStyle}
                disabled={!actionButtonTransactionDetailView}
              >
                {customization.viewTransactionModal.download}
              </Button>
              <Button
                type="button"
                variant="outlined"
                onClick={() => handleConfirmDeleteReceipt()}
                className={classes.buttonColorStyle}
                disabled={!actionButtonTransactionDetailView}
              >
                {customization.viewTransactionModal.delete}
              </Button>
            </Container>
          </>
        )}
      </>
    );
  };

  const openImageModal = () => {
    setDetailImageModal(true);
  };

  const handleImageDownload = (index) => {
    window.open(receiptList[index].signedUrl, '_self');
  };

  const handleDeleteReceiptImage = (index) => {
    if (receiptList.length > 0) {
      setReceiptPagination(0);
    }

    const accountId = accountList[0]?.id ?? transaction?.accounts?.account_fk;

    transactionActions
      .deleteReceipt(receiptList[index]?.id, accountId, token)
      .then((response) => {
        if (response.status === 200) {
          setDeleteReceipt(true);
          setOpenSuccess({ isSuccess: true, message: response.data.message });
        } else {
          setDeleteReceipt(false);
        }
      })
      .catch((error) => {
        setDeleteReceipt(false);
      });
  };

  const closeSuccessModal = () => {
    setOpenSuccess({ isSuccess: false, message: '' });
  };

  const renderSuccessDialog = () => {
    return (
      <SuccessMessageDialog
        message={openSuccess.message}
        openSuccess={openSuccess.isSuccess}
        bindHandleClose={closeSuccessModal}
        className={classes.roundedButton}
      />
    );
  };

  const renderTitle = (data, typeData) => {
    switch (typeData) {
      case transactionTypeFrom.USER:
        return `${data?.name}`;
      case transactionTypeFrom.LOCATION:
        return `${data?.description} ${data?.code}`;
      default:
        return '';
    }
  };

  const noDataFoundWhenSearch = () => {
    return (
      <Typography variant="h6" align="center">
        {customization.viewTransactionModal.noDataFound}
      </Typography>
    );
  };

  const receiptStatus = (status) => {
    if (status === receiptStatusType.MATCH) return '#3E8250';
    if (status === receiptStatusType.PARTIALLY_MATCH) return '#FF7842';
    if (status === receiptStatusType.NOT_MATCH) return '#E10454';
    return '#000';
  };

  return (
    <div>
      {renderConfirmationDialog()}
      {renderSuccessDialog()}
      <CustomizedSnackbar
        message={snackbar.message}
        showBar={snackbar.show}
        severity={snackbar.severity}
        handleClose={() => setSnackbar({ show: false, severity: 'error', message: null })}
      />
      <ErrorMessageDialog
        bindHandleClose={handleCloseErrorMessage}
        openSuccess={errorMessage.isError}
        errorData={errorMessage.message}
      />
      {renderDetailImageModal()}
      <Dialog fullWidth maxWidth="xl" open={open} aria-labelledby="form-dialog-title">
        {searchCardToggle && (
          <Card className={classes.searchCardWrapper}>
            <CardContent className={classes.searchCardContentWrapper}>
              <FormControl>
                <FormControlLabel
                  label={
                    customization.viewTransactionModal.onlyDisplayDefaultLocationUsers
                  }
                  control={
                    <Checkbox
                      name="search-location-default"
                      checked={searchDefaultLocation}
                      onChange={toggleSearchDefaultLocation}
                    />
                  }
                />
              </FormControl>
              <TableContainer className={classes.searchResultWrapper}>
                <Table className={classes.tableSearch} aria-label="table">
                  <TableHead>
                    <TableRow
                      className={classes.titleRowStyle}
                      key="search-result-table-header"
                    >
                      {renderSearchResultHeader()}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingTransactionList === apiRequestStatus.PENDING && (
                      <Box>
                        <CircularProgress className={classes.loadingStyle} />
                      </Box>
                    )}
                    {loadingTransactionList === apiRequestStatus.RESOLVED &&
                      (searchResult.length === 0
                        ? noDataFoundWhenSearch()
                        : searchResult.map((item, index) => (
                            <TableRow
                              component={Paper}
                              className={classes.paperCellStyle}
                              key={`search-result-table-body-${index}`}
                            >
                              <TableCell
                                key={`search-type-${item?.id}`}
                                className={classes.searchResultCell}
                              >
                                {item?.type}
                              </TableCell>
                              <TableCell
                                key={`search-code-${item?.id}`}
                                className={classes.searchResultCell}
                              >
                                {item?.code}
                              </TableCell>
                              <TableCell
                                key={`search-fullName-${item?.id}`}
                                className={classes.searchResultCell}
                              >
                                {item?.fullName}
                              </TableCell>
                              <TableCell
                                key={`search-action-${item?.id}`}
                                className={classes.smallSearchResultCell}
                              >
                                <Checkbox
                                  key={`search-location-default-${index}`}
                                  defaultChecked={searchResult[index]?.action}
                                  onChange={() => toggleSearchResultAction(index)}
                                />
                              </TableCell>
                            </TableRow>
                          )))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Container className={classes.searchLocationDefaultButtonWrapper}>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  className={classes.searchLocationAddButton}
                  onClick={addSearchAccount}
                >
                  {customization.viewTransactionModal.add}
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  className={classes.searchLocationCancelButton}
                  onClick={onCancelSearch}
                >
                  {customization.viewTransactionModal.cancel}
                </Button>
              </Container>
            </CardContent>
          </Card>
        )}
        <Toolbar>
          <Grid container direction="column" className={classes.container}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              className={classes.wrapperTitle}
            >
              <Grid item>
                <Typography variant="h5">
                  <b>
                    {customization.viewTransactionModal.modalTitle} (
                    {renderTitle(data, typeData)})
                  </b>
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => handleCloseDetailView()}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        <DialogContent>
          <TableContainer>
            <Table className={classes.table} aria-label="table">
              <TableHead>
                <TableRow
                  className={classes.titleRowStyle}
                  key="transaction-table-header"
                >
                  {renderTransactionAllocationHeader()}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Grid
            container
            spacing={0}
            component={Paper}
            className={
              transaction?.is_disputed
                ? classes.paperInActiveContentStyle
                : classes.paperActiveContentStyle
            }
          >
            <Grid item xs={12} md={12} lg={12}>
              <TableContainer>
                <Table className={classes.table} aria-label="table">
                  <TableBody>
                    <TableRow
                      className={classes.paperCellStyle}
                      key="transaction-table-body"
                    >
                      <TableCell className={classes.commonCellStyle}>
                        {transaction?.prefixForAmount ===
                        customization.transactionPrefixForAmount.transaction ? (
                          <img src={Trolley} alt="Trolley" loading="lazy" />
                        ) : (
                          <img src={Deposit} alt="Deposit" loading="lazy" />
                        )}
                        <HtmlTooltip
                          title={
                            <Typography color="inherit">
                              {transaction?.transaction_details?.merchant?.name
                                ? transaction?.transaction_details?.merchant?.name
                                : ''}
                            </Typography>
                          }
                          placement="top-end"
                        >
                          <b>
                            {transaction?.transaction_details?.merchant?.name.length > 20
                              ? transaction?.transaction_details?.merchant?.name.substring(
                                  0,
                                  20,
                                ) + '...'
                              : transaction?.transaction_details?.merchant?.name}
                          </b>
                        </HtmlTooltip>
                      </TableCell>
                      <TableCell className={classes.commonCellStyle}>
                        <b>
                          {transaction?.occured_at
                            ? moment(transaction?.occured_at)
                                .utc()
                                .tz(timezone)
                                .format('DD/MM/YYYY - hh:mm a')
                            : customization.notAvailable}
                        </b>
                      </TableCell>
                      <TableCell className={classes.commonCellStyle}>
                        <b>
                          {parseBalance(transaction?.transaction_details?.actual_amount)}
                        </b>
                      </TableCell>
                      <TableCell className={classes.commonCellStyle}>
                        <HtmlTooltip
                          title={<Typography color="inherit">{cardHolder}</Typography>}
                        >
                          <b>
                            {cardHolder !== 'null null'
                              ? cardHolder.length <= 20
                                ? cardHolder
                                : cardHolder.substring(0, 20) + '...'
                              : ''}
                          </b>
                        </HtmlTooltip>
                      </TableCell>
                      <TableCell className={classes.commonCellStyle}>
                        <ReceiptIcon
                          fontSize="small"
                          htmlColor={receiptStatus(transaction?.receipt_status)}
                        />
                      </TableCell>
                      <TableCell className={classes.commonCellStyle}>
                        <b>
                          {transaction?.is_disputed
                            ? customization.confirm.yes
                            : customization.confirm.no}
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={6} md={6} lg={6} className={classes.gridBorderStyle}>
              <div className={classes.auditHistoryWrapper}>
                {renderAuditHistoryFull()}
              </div>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Box className={classes.flexColumn}>
                <Container className={classes.transactionSectionTopOuterContainer}>
                  <div
                    className={[
                      classes.transactionSectionTopInnerContainer,
                      classes.spendCategoryContainer,
                    ].join(' ')}
                  >
                    <div>{customization.viewTransactionModal.spendCategory}</div>
                    <Select
                      disabled={
                        !actionButtonTransactionDetailView ||
                        isSpendCategoryDisabled ||
                        transaction?.lock_date_at_end_of_month
                      }
                      native
                      id="spent-category-input"
                      variant="outlined"
                      className={classes.spendCategoryInput}
                      value={transaction?.spend_category?.id ?? ''}
                      onChange={handleSpendCategoryChange}
                      placeholder="Categories"
                    >
                      {!transaction?.spend_category?.id ? (
                        <option value={''}>-- SELECT --</option>
                      ) : (
                        <option value={transaction?.spend_category?.id}>
                          {transaction?.spend_category?.internal_name}
                        </option>
                      )}
                      {spendCategoryList
                        .filter((item) => item.id !== transaction?.spend_category?.id)
                        .map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.internal_name}
                          </option>
                        ))}
                    </Select>
                  </div>
                  <div
                    className={[
                      classes.transactionSectionTopInnerContainer,
                      classes.disputeExpenseContainer,
                    ].join(' ')}
                  >
                    <Button
                      type="button"
                      variant="outlined"
                      disabled={isDisputeButtonDisabled}
                      onClick={handleDisputeButton}
                    >
                      {!transaction?.activity_status
                        ? customization.viewTransactionModal.disputeExpenseButton
                        : transaction?.activity_status === activitiesStatus.OPEN
                        ? customization.viewTransactionModal.cancelDisputeExpenseButton
                        : customization.viewTransactionModal.disputeExpenseButton}
                    </Button>
                  </div>
                </Container>
                <Container className={classes.transactionReceiptOuterWrapper}>
                  <div className={classes.transactionAllocationInnerWrapper}>
                    {renderTransactionAllocationView()}
                  </div>
                  <div className={classes.receiptOuterWrapper}>
                    {renderReceiptUploadSection()}
                  </div>
                </Container>
              </Box>
            </Grid>
          </Grid>
          <Container className={classes.buttonSection}>
            <Button
              className={classes.bottomBackButton}
              type="button"
              variant="outlined"
              onClick={handleCloseDetailView}
            >
              {customization.viewTransactionModal.backToTransaction}
            </Button>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
}

TransactionDetailView.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  typeData: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  errorTransaction: PropTypes.object,
  transactionAuditList: PropTypes.array,
  token: PropTypes.string,
  transaction: PropTypes.object,
  timezone: PropTypes.string,
  searchAccountList: PropTypes.array,
  spendCategoryList: PropTypes.array,
  loadingTransactionList: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
    transactionAuditList: state.transactions.transactionAuditList,
    spendCategoryList: state.transactions.spendCategoryList,
    searchAccountList: state.transactions.searchAccountList,
    loadingTransactionList: state.transactions.loadingTransactionList,
    loading: state.transactions.loading,
    errorTransaction: state.transactions.error,
    timezone: state.auth.timezone,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    transactionActions: bindActionCreators(transactionActions, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withStyles(transactionListViewStyle),
  withConnect,
)(TransactionDetailView);
