import { get, post, put } from './baseApi';

const getBankAccountList = async (token, params) => {
  let url = '/admin/bank-account';

  if (params) {
    const queryParams = new URLSearchParams(params);
    url = `${url}?${queryParams.toString()}`;
  }

  return get(url, token);
};

const getBankAccount = async (token, id) => {
  return get(`/admin/bank-account/${id}`, token);
};

const createBankAccount = async (token, params) => {
  return post('/admin/bank-account', params, token);
};

const updateBankAccount = async (token, params) => {
  return put('/admin/bank-account', params, token);
};

export { getBankAccountList, getBankAccount, createBankAccount, updateBankAccount };
