import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LockOutlineIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlineIcon from '@material-ui/icons/LockOpenOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PersonIcon from '@material-ui/icons/Person';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import HistoryRoundedIcon from '@material-ui/icons/HistoryRounded';
import { DataGrid } from '@mui/x-data-grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AuditHistoryDialog from '@components/auditHistoryDialog';
import CardDetailsDialog from '@components/cardDetailsDialog';
import ConfirmationStatus from '@components/confirmationUserDialog';
import DashboardLayout from '@components/layout/dashboardLayout';
import DataGridPagination from '@components/dataGridPagination';
import EmptyRowsOverlay from '@components/emptyRowsOverlay';
import ErrorMessageDialog from '@components/errorMessageDialog';
import SuccessMessageDialog from '@components/successMessageDialog';
import { customization } from '@customization/default';
import {
  cardStatus,
  availableForCard,
  cardSearchOptions,
  cardStatusFilter,
  auditHistoryOperation,
} from '@data/constants';
import * as cardAction from '@redux/card/cardActions';
import * as userActions from '@redux/user/userActions';
import * as merchantCategoryAction from '@redux/merchantCategoryRestriction/merchantCategoryRestrictionActions';
import {
  putCardBlock as putCardBlockService,
  putCardUnblock as putCardUnblockService,
} from '@services/api/cards';
import truncateWithEllipses from '@utils/truncateWithEllipses';
import history from '@utils/history';
import { useStyles } from './style';

function CardListPage(props) {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const ACTIVE_ICON = <Chip label="Active" className={classes.chipActive} />;
  const INACTIVE_ICON = <Chip label="Inactive" className={classes.chipInactive} />;
  const LOCKED_ICON = <Chip label="Blocked" className={classes.chipLocked} />;

  const commonStatus = [
    {
      status: [cardStatus.ACTIVE],
      icon: ACTIVE_ICON,
    },
    {
      status: [
        cardStatus.CLOSED,
        cardStatus.FRAUD_DETECTED,
        cardStatus.INACTIVE,
        cardStatus.INACTIVE_LOST_STOLEN,
      ],
      icon: INACTIVE_ICON,
    },
    {
      status: [cardStatus.EXCEEDED_INCORRECT_PIN, cardStatus.BLOCKED, cardStatus.LOCKED],
      icon: LOCKED_ICON,
    },
  ];

  const currentCardStatus = [
    {
      status: cardStatus.ACTIVE,
      icon: ACTIVE_ICON,
    },
    {
      status: cardStatus.INACTIVE,
      icon: INACTIVE_ICON,
    },
    {
      status: cardStatus.CLOSED,
      icon: LOCKED_ICON,
    },
  ];

  const {
    cardAction,
    userAction,
    merchantCategoryAction,
    error,
    merchantCategoryError,
    token,
    linkedCardList,
    linkedCardTriggered,
    card,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [cards, setCards] = useState({ rows: [], total: 0 });
  const [cardId, setCardId] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState(false);
  const [searchFilter, setSearchFilter] = useState(cardSearchOptions[0].value);
  const [searchHolder, setSearchHolder] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedCard, setSelectedCard] = useState(null);
  const [statusFilter, setStatusFilter] = useState('');
  const [openCardDetailDialog, setOpenCardDetailDialog] = useState(false);
  const [blockCardConfirmationDialog, setBlockCardConfirmationDialog] = useState(false);
  const [unblockCardConfirmationDialog, setUnblockCardConfirmationDialog] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState({ isError: false, message: '' });
  const [successMessage, setSuccessMessage] = useState({ isSuccess: false, message: '' });
  const [userFullname, setUserFullname] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [merchantCategories, setMerchantCategories] = useState([]);
  const [showMerchantCategoryBlocking, setShowMerchantCategoryBlocking] = useState(false);
  const [restrictionMapping, setRestrictionMapping] = useState([]);
  const [auditHistoryDialog, setAuditHistoryDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const getCards = useCallback(() => {
    cardAction
      .getCardList(token, page + 1, rowsPerPage, searchFilter, statusFilter, searchText)
      .then((response) => {
        if (response.data) {
          const { items, totalItems, totalPages, currentPage } = response.data;
          setCards({ rows: items, total: totalItems });
          setCurrentPage(currentPage);

          if (currentPage >= totalPages) {
            setPage(totalPages - 1);
          }
        } else {
          if (response.message.includes('403')) {
            setCards({ rows: [], total: 0 });
          } else {
            setErrorMessage({
              isError: true,
              message: response.message,
            });
          }
        }

        setLoading(false);
      });
  }, [cardAction, page, rowsPerPage, searchFilter, searchText, statusFilter, token]);

  const handleSearch = () => {
    setSearch(true);
    setSearchText(searchHolder);
  };

  const handleSearchFilter = (event) => {
    setSearch(false);
    setSearchFilter(event.target.value);
    setSearchHolder('');
    setSearchText('');
  };

  const handleSearchFieldChange = (event) => {
    const searchString = event.target.value;

    if (searchString.length === 0) {
      setSearch(false);
      setSearchText('');
    }

    setSearchHolder(searchString);
  };

  const handleStatusFilter = (_event, newStatus) => {
    if (newStatus === null) {
      setStatusFilter('');
    } else {
      setStatusFilter(newStatus);
    }
  };

  const keyPress = (event) => {
    if (event.keyCode === 13) {
      setSearch(true);
      setSearchText(searchHolder);
    }
  };

  const handleCloseMoreBtn = () => {
    setAnchorEl(null);
  };

  const handleBlock = (cardId) => {
    setCardId(cardId);
    setBlockCardConfirmationDialog(true);
    setAnchorEl(null);
  };

  const handleUnlock = (cardId) => {
    setCardId(cardId);
    setUnblockCardConfirmationDialog(true);
    setAnchorEl(null);
  };

  const handleOpenCardDetailDialog = async () => {
    setAnchorEl(null);
    await cardAction.getCardDetailById(token, selectedCard.id);

    if (error?.statusCode) {
      setErrorMessage({
        isError: true,
        message: `Get card detail error:${error?.statusCode}
        (${error?.message})`,
      });
    } else {
      setOpenCardDetailDialog(true);
      setAnchorEl(null);
    }
  };

  const handleDialogMerchantCategoryRestriction = async (cardId) => {
    setAnchorEl(null);
    const userFound = cards.rows.find((card) => card.id === cardId);

    await merchantCategoryAction.getMerchantCategory(token, cardId).then((response) => {
      if (userFound && response.status === 200) {
        setCardId(userFound.id);
        setUserFullname(userFound.user_fullname);
        setCardNumber(userFound.card_number);
        setMerchantCategories(response.data);
        setShowMerchantCategoryBlocking(!showMerchantCategoryBlocking);
      } else {
        setErrorMessage({
          isError: true,
          message: merchantCategoryError?.message || customization.tokenError,
        });
      }
    });
  };

  const handleCloseCardDetailDialog = () => {
    setOpenCardDetailDialog(false);
    setAnchorEl(null);
  };

  const handleCloseSuccessMessage = () => {
    setAnchorEl(null);
    setCardId(null);
    setBlockCardConfirmationDialog(false);
    setUnblockCardConfirmationDialog(false);
    setSuccessMessage({ isSuccess: false, message: '' });
  };

  const setCardBlock = async (flag) => {
    let response = null;
    try {
      if (flag === customization.cardDetails.cardStatusDisplay.BLOCK) {
        response = await putCardBlockService(token, {
          card_id: cardId,
        });
      } else {
        response = await putCardUnblockService(token, {
          card_id: cardId,
        });
      }
      if (response.status === 200) {
        setSuccessMessage({
          isSuccess: false,
          message:
            flag === customization.cardDetails.cardStatusDisplay.UNBLOCK
              ? customization.cardDetails.successMessage.unblockCard
              : customization.cardDetails.successMessage.blockCard,
        });
      } else {
        throw new Error(response.data.message);
      }
      setLoading(false);
      await getCards();
    } catch (error) {
      setUnblockCardConfirmationDialog(false);
      setBlockCardConfirmationDialog(false);
      setErrorMessage({
        isError: true,
        message: error?.response?.data?.message || error.message,
      });
      setLoading(false);
    }
  };

  const handleConfirmationBlockCard = async (_e, action) => {
    if (!action) {
      setAnchorEl(null);
      return setBlockCardConfirmationDialog(false);
    }
    setBlockCardConfirmationDialog(false);
    setLoading(true);

    setCardBlock(customization.cardDetails.cardStatusDisplay.BLOCK);
  };

  const handleConfirmationUnblockCard = async (_e, action) => {
    if (!action) {
      setAnchorEl(null);
      return setUnblockCardConfirmationDialog(false);
    }
    setUnblockCardConfirmationDialog(true);
    setLoading(true);

    setCardBlock(customization.cardDetails.cardStatusDisplay.UNBLOCK);
  };

  const handleCloseErrorMessage = () => {
    setBlockCardConfirmationDialog(false);
    setUnblockCardConfirmationDialog(false);
    setErrorMessage({
      isError: false,
      message: '',
    });
  };

  const handleAvailableForCardOption = (clientMerchantCategoryId, value) => {
    setRestrictionMapping([
      ...restrictionMapping,
      { client_merchant_category_id: clientMerchantCategoryId, is_blocked: value },
    ]);
  };

  const handleShowDialogMerchantCategoryRestriction = () => {
    setShowMerchantCategoryBlocking(!showMerchantCategoryBlocking);
  };

  const submitEditMerchantCategoryRestriction = async () => {
    const body = {
      card_id: selectedCard.id,
      restriction_mapping: restrictionMapping,
    };

    const patchClientMerchantCategoryRestriction =
      await merchantCategoryAction.patchMerchantCategory(token, body);

    if (patchClientMerchantCategoryRestriction.status === 200) {
      setSuccessMessage({
        isSuccess: true,
        message: patchClientMerchantCategoryRestriction.data.message,
      });
    } else {
      setErrorMessage({
        isError: true,
        message: merchantCategoryError?.message || customization.tokenError,
      });
    }
    setShowMerchantCategoryBlocking(!showMerchantCategoryBlocking);
    setRestrictionMapping([]);
  };

  const handleViewUsers = () => {
    setAnchorEl(null);
    userAction.getLinkedUser(token, 1, 20, 'user_id', '', card.user_fk).then(() => {
      if (error?.statusCode) {
        setErrorMessage({
          isError: true,
          message: `Get linked card list error: ${error?.statusCode}`,
        });
      } else {
        history.push('/admin/dashboard/view-users');
      }
    });
  };

  const mappingLinkedCard = () => {
    const tempCards = cards;
    if (linkedCardList.items.length > 0) {
      linkedCardList.items.forEach((element) => {
        if (cards.rows.length < linkedCardList.totalItems) {
          tempCards.rows = linkedCardList.items;
        }
      });
    }
    setCards({ rows: tempCards.rows, total: linkedCardList.totalItems });
    setPage(linkedCardList.currentPage - 1);
    setLoading(false);
    cardAction.resetLinkedCardState();
  };

  const handleOpenAuditHistory = () => {
    setAuditHistoryDialog(true);
    setAnchorEl(null);
  };

  const handleCloseAuditHistory = () => {
    setAuditHistoryDialog(false);
    setSelectedCard(null);
  };

  useEffect(() => {
    setLoading(true);

    if (error?.statusCode) {
      setPage(0);
    }

    if (linkedCardTriggered) {
      mappingLinkedCard();
    } else {
      getCards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCards]);

  const columns = [
    {
      align: 'center',
      field: 'card_status',
      headerAlign: 'center',
      headerName: customization.cardView.cardStatusLabel,
      sortable: false,
      renderCell: (params) => {
        const cardStatus = params.value;
        if (cardStatus) {
          return _.head(
            currentCardStatus.filter((element) => element.status === cardStatus),
          ).icon;
        } else {
          return null;
        }
      },
    },
    {
      align: 'center',
      field: 'user_access',
      headerAlign: 'center',
      headerClassName: 'text-center',
      headerName: customization.cardView.userStatusLabel,
      sortable: false,
      renderCell: (params) => {
        const userAccess = params.value;
        if (userAccess) {
          return _.head(
            commonStatus.filter((element) =>
              element.status.includes(userAccess.toUpperCase()),
            ),
          ).icon;
        } else {
          return null;
        }
      },
    },
    {
      field: 'user_fullname',
      flex: 0.5,
      headerName: customization.cardView.cardHolderNameLabel,
      minWidth: 204.75,
      valueFormatter: (params) =>
        params.value ? truncateWithEllipses(params.value, 20) : '',
    },
    {
      field: 'card_number',
      flex: 0.5,
      minWidth: 204.75,
      headerName: customization.cardView.cardNumberLabel,
    },
    {
      cellClassName: 'transform-capitalize',
      field: 'card_type',
      flex: 0.5,
      minWidth: 204.75,
      headerName: customization.cardView.cardTypeLabel,
      valueFormatter: (params) => params.value.toLocaleLowerCase(),
    },
    {
      cellClassName: 'transform-capitalize',
      field: 'plastic_active',
      flex: 0.5,
      minWidth: 204.75,
      headerName: customization.cardView.plasticActiveLabel,
      valueFormatter: (params) => params.value.toLocaleLowerCase(),
    },
    {
      field: 'id',
      headerName: customization.userViews.headers.id,
      hide: true,
    },
    {
      align: 'right',
      field: 'action',
      cellClassName: 'border-right',
      headerAlign: 'center',
      headerName: customization.userViews.headers.action,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const thisCardNumber = params.getValue(params.id, 'card_number');
        const thisCardType = params.getValue(params.id, 'card_type');
        const thisPlasticActive = params.getValue(params.id, 'plastic_active');
        const thisCardHolder = params.getValue(params.id, 'user_fullname');
        const thisCardStatus = params.getValue(params.id, 'card_status');
        const thisCardId = params.getValue(params.id, 'id');

        return (
          <>
            {thisCardStatus !== cardStatus.INACTIVE && (
              <>
                {thisCardStatus === cardStatus.ACTIVE ? (
                  <Button
                    className={`${classes.actionButton} lock`}
                    disableElevation
                    variant="contained"
                    onClick={() => handleBlock(thisCardId)}
                  >
                    <LockOutlineIcon htmlColor="#FFFFFF" fontSize="medium" />
                  </Button>
                ) : (
                  <Button
                    className={`${classes.actionButton} unlock`}
                    disableElevation
                    variant="contained"
                    onClick={() => handleUnlock(thisCardId)}
                  >
                    <LockOpenOutlineIcon htmlColor="#ff9800" fontSize="medium" />
                  </Button>
                )}
              </>
            )}

            <Button
              aria-controls="more-options"
              aria-haspopup="true"
              className={`${classes.actionButton} more`}
              disableElevation
              onClick={(event) => {
                setSelectedCard({
                  id: thisCardId,
                  cardHolder: thisCardHolder,
                  cardNumber: thisCardNumber,
                  cardType: thisCardType,
                  plasticActive: thisPlasticActive,
                  cardStatus: thisCardStatus,
                });
                cardAction.getCardDetailById(token, thisCardId);
                setAnchorEl(event.currentTarget);
              }}
              variant="contained"
            >
              <MoreVertIcon htmlColor="#FFFFFF" fontSize="medium" />
            </Button>
          </>
        );
      },
    },
  ];

  const merchantCategoryColumns = [
    {
      align: 'center',
      field: 'client_merchant_name',
      headerAlign: 'center',
      headerName: customization.cardView.merchantCategoryTitle,
      sortable: false,
      minWidth: 260,
      renderCell: (params) => {
        const merchantName = params.value;
        return <b className={classes.setupMerchantTitleWidth}>{merchantName}</b>;
      },
    },
    {
      align: 'center',
      field: 'block_status',
      headerAlign: 'center',
      headerName: customization.cardView.merchantAvailableTitle,
      sortable: false,
      minWidth: 240,
      renderCell: (params) => {
        const merchantBlockStatus = params.value;
        return merchantBlockStatus === 'BLOCKED' ? INACTIVE_ICON : ACTIVE_ICON;
      },
    },
    {
      align: 'center',
      field: 'is_blocked',
      headerAlign: 'center',
      headerName: customization.cardView.merchantForCardTitle,
      sortable: false,
      minWidth: 210,
      renderCell: (params) => {
        const merchantBlockStatus = params.getValue(params.id, 'block_status');
        const merchantCategoryId = params.getValue(
          params.id,
          'client_merchant_category_id',
        );
        const merchantIsBlock = params.value;
        return merchantBlockStatus === 'ALLOWED' ? (
          <FormControl
            variant="outlined"
            className={classes.formControlEditMerchantCategory}
          >
            <Select
              color="secondary"
              defaultValue={merchantIsBlock}
              className={classes.selectOptionEditMerchantCategory}
              onChange={(e) =>
                handleAvailableForCardOption(merchantCategoryId, e.target.value)
              }
            >
              {availableForCard.map((data, index) => (
                <MenuItem value={data.value} key={index}>
                  {data.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <div></div>
        );
      },
    },
  ];

  const moreOptionsItems = [
    {
      id: 1,
      label: 'Card Details & Settings',
      icon: <CreditCardIcon />,
      onClick: handleOpenCardDetailDialog,
    },
    {
      id: 2,
      label: 'Merchant Category Blocking',
      icon: <ShoppingCartIcon />,
      onClick: () => handleDialogMerchantCategoryRestriction(selectedCard.id),
    },
    {
      id: 3,
      label: 'View Transactions',
      icon: <AttachMoneyIcon />,
      onClick: handleCloseMoreBtn,
    },
    {
      id: 4,
      label: 'View Linked User',
      icon: <PersonIcon />,
      onClick: handleViewUsers,
    },
    {
      id: 5,
      label: 'Audit History',
      icon: <HistoryRoundedIcon />,
      onClick: handleOpenAuditHistory,
    },
  ];

  const renderDialogMerchantCategoryRestriction = () => {
    return (
      <Dialog
        aria-labelledby="example-dialog"
        open={showMerchantCategoryBlocking}
        fullWidth
        maxWidth="sm"
        classes={{ paperWidthSm: classes.editMerchantCategoryDialog }}
      >
        <DialogTitle id="example-dialog">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={10}>
              <Typography variant="h6">
                <b>{`${customization.editMerchantCategoryBlockings.title} (${userFullname} / ${cardNumber})`}</b>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleShowDialogMerchantCategoryRestriction}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.editMerchantDialogParent}>
          <div className={classes.editMerchantCategoryContainerContent}>
            <div className={classes.editMerchantCategoryContainerGrid}>
              <DataGrid
                disableColumnMenu
                disableSelectionOnClick
                className={classes.table}
                columns={merchantCategoryColumns}
                hideFooter={true}
                components={{
                  NoRowsOverlay: EmptyRowsOverlay,
                }}
                getRowId={(row) => row.client_merchant_name}
                componentsProps={{
                  noRowsOverlay: {
                    label: customization.cardView.merchantEmptyRows,
                  },
                }}
                loading={loading}
                maxColumns={3}
                rows={merchantCategories}
                rowHeight={80}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.editMerchantCategoryDialogAction}>
          <Button
            autoFocus
            type="submit"
            variant="contained"
            color="secondary"
            onClick={submitEditMerchantCategoryRestriction}
          >
            {customization.editMerchantCategoryBlockings.saveChangesButtonLabel}
          </Button>
          <Button
            className={classes.closeButton}
            onClick={handleShowDialogMerchantCategoryRestriction}
            variant="outlined"
          >
            {customization.editMerchantCategoryBlockings.closeButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const pageInformation = () => {
    if (!cards?.rows.length) {
      return null;
    }

    const fromPage = (currentPage - 1) * rowsPerPage + 1;
    const toPage = (page + 1) * rowsPerPage;
    const totalPage = cards?.total;
    const lastPage =
      currentPage === Math.max(0, Math.ceil(cards?.total / rowsPerPage))
        ? totalPage
        : toPage;

    return `Showing ${fromPage}-${lastPage} of ${totalPage} results`;
  };

  return (
    <DashboardLayout>
      <Tabs
        value={statusFilter}
        onChange={handleStatusFilter}
        className={classes.statusTabs}
        scrollButtons="auto"
      >
        {cardStatusFilter.map((status) => (
          <Tab key={status.label} label={status.label} value={status.value} />
        ))}
        {!isMobile && (
          <Tab
            className={classes.tabInformationPage}
            key="pageInformation"
            label={pageInformation()}
            disabled
          />
        )}
      </Tabs>
      <Grid
        className={classes.gridContainer}
        container
        justifyContent="flex-start"
        spacing={2}
      >
        <Grid item xs={12} lg={4} xl={3}>
          <FormControl className={classes.searchFilter} variant="outlined">
            <InputLabel color="secondary" id="search-filter-label">
              {customization.userViews.filterPlaceholder}
            </InputLabel>
            <Select
              id="search-filter"
              color="secondary"
              label={customization.userViews.filterPlaceholder}
              labelId="search-filter-label"
              value={searchFilter}
              onChange={handleSearchFilter}
            >
              {cardSearchOptions.map((searchType) => (
                <MenuItem key={searchType.value} value={searchType.value}>
                  {searchType.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <TextField
            className={classes.searchText}
            color="secondary"
            InputProps={{
              endAdornment: (
                <Button
                  color="secondary"
                  disableElevation
                  onClick={handleSearch}
                  variant="contained"
                >
                  {customization.userViews.searchLabel}
                </Button>
              ),
              onKeyDown: keyPress,
            }}
            onChange={handleSearchFieldChange}
            placeholder={`${customization.userViews.searchPlaceholder} ${
              cardSearchOptions.filter(
                (searchType) => searchType.value === searchFilter,
              )[0].label
            }`}
            type="search"
            value={searchHolder}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableExtendRowFullWidth
        disableSelectionOnClick
        className={classes.table}
        columns={columns}
        components={{
          NoRowsOverlay: EmptyRowsOverlay,
          Pagination: DataGridPagination,
        }}
        componentsProps={{
          noRowsOverlay: {
            label: customization.cardView.emptyRows,
            search,
          },
          pagination: {
            count: cards.total,
            onPageChange: (_event, newPage) => {
              setPage(newPage);
            },
            onRowsPerPageChange: (event) => {
              setRowsPerPage(event.target.value);
            },
            page: cards.rows.length > 0 ? page : 0,
            rowsPerPage,
          },
        }}
        loading={loading}
        maxColumns={9}
        pagination
        paginationMode="server"
        rows={cards.rows}
        rowHeight={80}
      />
      <Menu
        anchorEl={anchorEl}
        className={classes.moreMenu}
        id="more-options"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {moreOptionsItems.map((item, index) => (
          <MenuItem key={index} onClick={item.onClick}>
            {item.icon}
            {item.label}
          </MenuItem>
        ))}
      </Menu>
      <CardDetailsDialog
        bindCloseCardDetail={handleCloseCardDetailDialog}
        openCardDetailDialog={openCardDetailDialog}
      />
      <ConfirmationStatus
        openConfirmation={blockCardConfirmationDialog}
        bindHandleCloseConfirmationStatus={handleConfirmationBlockCard}
        message={customization.confirmationMessageDialog.questionBlockCardConfirmation}
      />
      <ConfirmationStatus
        openConfirmation={unblockCardConfirmationDialog}
        bindHandleCloseConfirmationStatus={handleConfirmationUnblockCard}
        message={customization.confirmationMessageDialog.questionUnblockCardConfirmation}
      />
      <AuditHistoryDialog
        openAuditHistoryDialog={auditHistoryDialog}
        bindCloseAuditHistory={handleCloseAuditHistory}
        auditData={selectedCard}
        operationTag={auditHistoryOperation.CARD}
      />
      <SuccessMessageDialog
        message={successMessage.message}
        openSuccess={successMessage.isSuccess}
        bindHandleClose={handleCloseSuccessMessage}
      />
      <ErrorMessageDialog
        openSuccess={errorMessage.isError}
        errorData={errorMessage.message}
        bindHandleClose={handleCloseErrorMessage}
      />
      {renderDialogMerchantCategoryRestriction()}
    </DashboardLayout>
  );
}

CardListPage.propTypes = {
  cardAction: PropTypes.object,
  userAction: PropTypes.object,
  cardList: PropTypes.object,
  card: PropTypes.object,
  error: PropTypes.object,
  linkedCardTriggered: PropTypes.bool,
  linkedCardList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  merchantCategoryAction: PropTypes.object,
  merchantCategory: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  merchantCategoryError: PropTypes.object,
  token: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    error: state.card.error,
    linkedCardTriggered: state.card.linkedCardTriggered,
    linkedCardList: state.card.linkedCardList,
    card: state.card.card,
    merchantCategory: state.merchantCategory.merchantCategory,
    merchantCategoryError: state.merchantCategory.error,
    token: state.auth.inMemoryToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cardAction: bindActionCreators(cardAction, dispatch),
    userAction: bindActionCreators(userActions, dispatch),
    merchantCategoryAction: bindActionCreators(merchantCategoryAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardListPage);
