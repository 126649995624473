import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: '1rem',
  },
  actionButton: {
    margin: theme.spacing(0, 1),
    minWidth: 38,
    height: 38,
    width: 38,
    borderRadius: 999,

    '&.lock': {
      backgroundColor: '#ff9800',

      '&:hover': {
        backgroundColor: '#D46B45',
      },
    },

    '&.unlock': {
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ff9800',

      '&:hover': {
        backgroundColor: 'rgba(255,152,0,0.15)',
      },
    },

    '&.more': {
      backgroundColor: '#B4075B',

      '&:hover': {
        backgroundColor: '#8C0446',
      },
    },
  },

  moreMenu: {
    '& .MuiMenuItem-root': {
      borderRadius: 5,
      fontSize: '1rem',
      margin: theme.spacing(0.5, 1),
      color: '#9e9e9e',

      '& .MuiSvgIcon-root': {
        height: 32,
        marginRight: theme.spacing(1),
        width: 32,
      },

      '&:hover': {
        backgroundColor: 'rgba(235,78,136,0.15)',
        color: '#EB4E88',
      },
    },
  },

  searchFilter: {
    width: '100%',

    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.common.white,
      height: 44,

      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.secondary.dark,
        },
      },

      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  searchText: {
    backgroundColor: theme.palette.common.white,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      height: 44,

      '&.MuiOutlinedInput-adornedEnd': {
        paddingRight: 0,
      },

      '& .MuiOutlinedInput-inputAdornedEnd': {
        paddingRight: theme.spacing(1),
      },

      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.secondary.dark,
        },
      },

      '& .MuiButton-root': {
        fontSize: '1rem',
        height: 42,
        width: 96,
      },
    },
  },
  table: {
    marginTop: theme.spacing(2),
    border: 'unset',

    '& .MuiDataGrid-cell': {
      '&:focus': {
        outline: 'unset',

        '&-within': {
          outline: 'unset',
        },
      },
    },

    '& .MuiDataGrid-withBorder': {
      borderRight: 'unset',
    },

    '& .MuiDataGrid-columnsContainer': {
      borderBottom: 'unset',
    },

    '& .MuiDataGrid-columnHeaderWrapper': {
      backgroundColor: '#EBF3FA',
      borderRadius: 5,
    },

    '& .MuiDataGrid-columnHeader': {
      color: theme.palette.grey[900],
      fontSize: '1.125rem',

      [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
      },

      '&:focus': {
        outline: 'unset',

        '&-within': {
          outline: 'unset',
        },
      },

      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 700,
        lineHeight: '1.43rem',
        overflow: 'hidden',
        whiteSpace: 'normal',
      },
    },

    '& .MuiDataGrid-row': {
      backgroundColor: theme.palette.common.white,
      borderRadius: 5,
      fontSize: '1rem',

      [theme.breakpoints.down('lg')]: {
        fontSize: '0.875rem',
      },

      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },

    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },

    '& .MuiDataGrid-overlay': {
      zIndex: 999,

      '& .MuiCircularProgress-root': {
        width: '56px !important',
        height: '56px !important',
        color: theme.palette.primary.main,
      },
    },

    '& .justify-center': {
      justifyContent: 'center',
    },

    '& .multi-line': {
      alignItems: 'center',
      display: 'flex',
      lineHeight: '1.43 !important',
      overflow: 'hidden',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },

    '& .text-center': {
      textAlign: 'center',
    },

    '& .text-right': {
      textAlign: 'right',
    },

    '& .transform-capitalize': {
      textTransform: 'capitalize',
    },
  },
  toggleButton: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    height: 42,

    '& > .MuiToggleButton-root': {
      color: theme.palette.grey[600],
      fontSize: '1rem',
      flex: 1,

      '&.Mui-selected': {
        backgroundColor: 'rgba(33,133,208,0.1)',
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
      },
    },
  },
  editMerchantCategoryDialog: {
    maxWidth: '800px !important',
  },
  editMerchantCategoryDialogTitle: {
    padding: '0 1em 2em',
  },
  editMerchantCategoryContainerGrid: {
    flexGrow: 1,
    textAlign: 'center',
    margin: '2em 0',
  },
  editMerchantDialogParent: {
    overflowY: 'hidden !important',
  },
  editMerchantCategoryContainerContent: {
    flexGrow: 1,
    textAlign: 'center',
    overflowY: 'auto',
    maxHeight: '50vh',
    overflowX: 'hidden',
  },
  setupMerchantTitleWidth: {
    minWidth: '100px',
    lineHeight: '25px !important',
    whiteSpace: 'break-spaces',
  },
  categoryName: {
    textAlign: 'left',
    paddingLeft: '4.7em !important',
  },
  formControlEditMerchantCategory: {
    width: '70%',
  },
  selectOptionEditMerchantCategory: {
    textAlign: 'left',
  },
  gridContainer: {
    marginTop: theme.spacing(3),
  },
  statusTabs: {
    width: '100%',
    padding: theme.spacing(0, 1),

    '& .MuiTabs-fixed': {
      '& .MuiTabs-flexContainer': {
        borderBottom: ' 4px solid #E0E0E0',

        '& .MuiTab-root': {
          fontWeight: 700,
          color: '#BDBDBD',
          fontSize: '1rem',

          '&.Mui-selected': {
            color: '#EB4E88',
          },
        },
      },

      '& .MuiTabs-indicator': {
        backgroundColor: '#EB4E88',
        height: 4,
      },
    },
  },
  alignEnd: {
    textAlign: 'end',
  },
  chipActive: {
    backgroundColor: '#DCEDC8',
    color: '#558B2F',
    textTransform: 'uppercase',
    border: '1px solid #558B2F',
  },
  chipInactive: {
    backgroundColor: '#FFCDD2',
    color: '#C62828',
    textTransform: 'uppercase',
    border: '1px solid #C62828',
  },
  tabInformationPage: {
    position: 'absolute',
    right: 0,
    color: '#BDBDBD',

    '& .MuiTab-wrapper': {
      fontWeight: 400,
    },
  },
}));
