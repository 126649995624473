import {
  getUserTypeList,
  getUserType,
  insertUserType,
  editUserType,
  getNotificationTypes,
} from '@services/api/userType';
import userTypeTypes from './userTypeTypes';

export const getAllUserType = (token) => {
  return async (dispatch) => {
    dispatch({ type: userTypeTypes.GET_USERTYPELIST_REQUEST });
    try {
      const response = await getUserTypeList(token);
      dispatch({ type: userTypeTypes.GET_USERTYPELIST_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: userTypeTypes.GET_USERTYPELIST_ERROR, error });
      return error;
    }
  };
};

export const getUserTypeDetail = (token, id) => {
  return async (dispatch) => {
    dispatch({ type: userTypeTypes.GET_USERTYPE_REQUEST });
    try {
      const response = await getUserType(token, id);
      dispatch({ type: userTypeTypes.GET_USERTYPE_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: userTypeTypes.GET_USERTYPE_ERROR, error });
      return error;
    }
  };
};
export const getNotificationTypeList = (token) => {
  return async (dispatch) => {
    dispatch({ type: userTypeTypes.GET_NOTIFICATIONTYPE_REQUEST });
    try {
      const response = await getNotificationTypes(token);
      dispatch({
        type: userTypeTypes.GET_NOTIFICATIONTYPE_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: userTypeTypes.GET_NOTIFICATIONTYPE_ERROR, error });
      return error;
    }
  };
};

export const createUserType = (token, requestParam) => {
  return async (dispatch) => {
    dispatch({ type: userTypeTypes.INSERT_USERTYPE_REQUEST });
    try {
      const response = await insertUserType(token, requestParam);
      dispatch({ type: userTypeTypes.INSERT_USERTYPE_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: userTypeTypes.INSERT_USERTYPE_ERROR, error });
      return error;
    }
  };
};

export const updateUserType = (token, requestParam, id) => {
  return async (dispatch) => {
    dispatch({ type: userTypeTypes.UPDATE_USERTYPE_REQUEST });
    try {
      const response = await editUserType(token, requestParam, id);
      dispatch({ type: userTypeTypes.UPDATE_USERTYPE_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: userTypeTypes.UPDATE_USERTYPE_ERROR, error });
      return error;
    }
  };
};
