import React from 'react';
import BaseComponent from '@containers/BaseComponent';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@material-ui/core';

import DashboardLayout from '@components/layout/dashboardLayout';
import TabPanel from '@components/tabPanel';
import SuccessMessageDialog from '@components/successMessageDialog';
import UserTypesTab from '@containers/userTypesTab/index';
import UserLocationsTab from '@containers/userLocationsTab/index';
import UserCardAccountsTab from '@containers/userCardAccountsTab/index';
import ErrorMessageDialog from '@components/errorMessageDialog';
import { withStyles } from '@material-ui/styles';
import { customization } from '@customization/default';
import { userSettingsFilter } from '@data/constants';
import { userSettingsPageStyle } from './style';

class userSettingsPage extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      result: null,
      showMessageDialog: false,
      isFormValid: false,
      errorMessage: { isError: false, message: '' },
    };
  }

  componentDidMount = () => {};

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  onSaveUserType = () => {
    this.setState({ showMessageDialog: true });
  };

  onSaveUserLocations = () => {
    this.setState({ showMessageDialog: true });
  };

  onError = (message) => {
    this.setState({
      errorMessage: { isError: true, message: message },
    });
  };

  handleCloseErrorMessage = () => {
    this.setState({
      errorMessage: { isError: false, message: '' },
    });
  };

  handleCloseSuccessDialog = () => {
    this.setState({
      showMessageDialog: false,
    });
  };

  render() {
    const { classes } = this.props;
    const { value, showMessageDialog } = this.state;
    return (
      <DashboardLayout>
        <ErrorMessageDialog
          bindHandleClose={this.handleCloseErrorMessage}
          openSuccess={this.state.errorMessage.isError}
          errorData={this.state.errorMessage.message}
        />
        <SuccessMessageDialog
          message={customization.userSettingsPage.saveSuccessMessage}
          openSuccess={showMessageDialog}
          bindHandleClose={this.handleCloseSuccessDialog}
          className={classes.roundedButton}
        />
        <Tabs
          value={value}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          className={classes.statusTabs}
          scrollButtons="auto"
        >
          {userSettingsFilter.map((status) => (
            <Tab key={status.label} label={status.value} />
          ))}
        </Tabs>
        <TabPanel value={value} index={0}>
          <UserLocationsTab
            onSaveHandled={this.onSaveUserLocations}
            successMessageDialog={this.state.showMessageDialog}
            onErrorHandled={this.onError}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UserTypesTab
            onSaveHandled={this.onSaveUserType}
            successMessageDialog={this.state.showMessageDialog}
            onErrorHandled={this.onError}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <UserCardAccountsTab
            onSaveHandled={this.onSaveUserLocations}
            successMessageDialog={this.state.showMessageDialog}
            onErrorHandled={this.onError}
          />
        </TabPanel>
        <TabPanel value={value} index={3}></TabPanel>
      </DashboardLayout>
    );
  }
}

userSettingsPage.propTypes = {
  classes: PropTypes.object,
  userTypeList: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    userTypeList: state.userType.userTypeList,
    userType: state.userType.userType,
    token: state.auth.inMemoryToken.token,
  };
};

const withConnect = connect(mapStateToProps);
export default compose(withStyles(userSettingsPageStyle), withConnect)(userSettingsPage);
