import { apiRequestStatus } from '@data/constants';
import transferFundsTypes from './transferFundsTypes';

const initialState = {
  error: {},
  loading: '',
};

const transferFundsReducer = (state = initialState, action) => {
  switch (action.type) {
    case transferFundsTypes.TRANSFER_FUND_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case transferFundsTypes.TRANSFER_FUND_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        error: {},
      };
    case transferFundsTypes.TRANSFER_FUND_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    case transferFundsTypes.RESET_ERROR_STATE:
      return {
        ...state,
        error: {},
      };
    case transferFundsTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default transferFundsReducer;
