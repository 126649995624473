/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import { timeConversion } from '@data/constants';
import history from '@utils/history';
import { adminRefreshToken } from '@redux';

const calculateTimeLeft = (inMemoryToken) => {
  let timeLeft = {};

  if (_.isEmpty(inMemoryToken)) {
    return timeLeft;
  }

  const expiryDate = moment(inMemoryToken?.tokenExpiry);
  const now = moment.utc().toISOString();

  timeLeft = {
    minutes: expiryDate.diff(now, 'minutes'),
  };

  return timeLeft;
};

const ProtectedLayout = (props) => {
  const { children, inMemoryToken, adminRefreshToken } = props;
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(inMemoryToken));
  const isMountedRef = useRef(null);
  const [renderChildren, setRenderChildren] = useState(false);

  async function fetchRefreshToken() {
    try {
      const response = await adminRefreshToken(inMemoryToken?.token);
      if (response?.status !== 200) {
        history.push('/login');
      } else {
        setRenderChildren(true);
      }

      const updatedInMemoryToken = {
        tokenExpiry: response?.data?.expires_in,
      };

      if (isMountedRef.current) {
        setTimeLeft(calculateTimeLeft(updatedInMemoryToken));
      }
    } catch (error) {
      history.push('/login');
    }
  }

  // Will trigger when the user re-open the app (user closes the tab or opens and closes the browser)
  useEffect(() => {
    isMountedRef.current = true;

    if (_.isEmpty(inMemoryToken)) {
      if (isMountedRef.current) {
        fetchRefreshToken();
      }
    } else {
      setRenderChildren(true);
    }

    return () => (isMountedRef.current = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // trigger silent refresh token
  useEffect(() => {
    isMountedRef.current = true;

    if (!_.isEmpty(timeLeft)) {
      if (timeLeft?.minutes <= 1) {
        if (isMountedRef.current) {
          fetchRefreshToken();
        }
      }
    }

    return () => (isMountedRef.current = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft?.minutes]);

  // Countdown of the expiry token to trigger the refresh token
  useEffect(() => {
    isMountedRef.current = true;
    const intervalTime =
      timeLeft?.minutes !== 1 && timeLeft?.minutes * timeConversion.MINUTE_IN_MILISECOND;
    const interval = setInterval(async () => {
      if (!_.isEmpty(inMemoryToken)) {
        if (isMountedRef.current) {
          setTimeLeft(calculateTimeLeft(inMemoryToken));
        }
      }
    }, intervalTime || timeConversion.MINUTE_IN_MILISECOND);

    return () => {
      clearInterval(interval);
      isMountedRef.current = false;
    };
  }, [timeLeft, inMemoryToken]);

  return <>{renderChildren && children}</>;
};

ProtectedLayout.propTypes = {
  children: PropTypes.node,
  inMemoryToken: PropTypes.object,
  adminRefreshToken: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    inMemoryToken: state?.auth?.inMemoryToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminRefreshToken: (token) => dispatch(adminRefreshToken(token)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ProtectedLayout);
