export const auditHistoryStyle = (theme) => ({
  dialogTitle: {
    '& button': {
      color: 'black',
    },
  },
  modalTable: {
    minWidth: 700,
    borderCollapse: 'collapse',
  },
  titleRowStyleAuditHistory: {
    display: 'flex',
    flexWrap: 'no-wrap',
    flex: '1 1 auto',
    marginBottom: '10px',
  },
  noRecordFoundStyle: {
    padding: '12px 12px 12px 12px',
    border: '0px',
    width: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  commonTitleCellStyleAuditHistory: {
    padding: '12px 12px 12px 12px',
    border: '0px',
    width: '30%',
  },
  buttonBackCellWidth: {
    width: '10%',
  },
  tableContainer: {
    padding: '15px',
  },
  cellBorder: {
    border: 'solid 1px black',
    borderRadius: '5px',
  },
  auditHistoryButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 0,
  },
  iconMoreBtnStyle: {
    maxHeight: '12px',
  },
  moreButtonStyle: {
    backgroundColor: '#EEEEF0',
    border: '1px solid #777778',
    borderRadius: '10px',
    height: '30px',
  },
  auditHistoryButton: {
    margin: '3px 0 0 0',
    minWidth: '120px',
  },
  actionColumnWidth: {
    width: '10%',
  },
});
