import { apiRequestStatus } from '@data/constants';
import cardTypes from './cardTypes';

const initialState = {
  loading: '',
  cardList: [],
  linkedCardList: [],
  card: {},
  linkedCardTriggered: false,
  error: {},
};

const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET CARDS==============================================================================
    case cardTypes.GET_CARD_LIST_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case cardTypes.GET_CARD_LIST_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        cardList: action.payload,
        linkedCardTriggered: false,
        error: {},
      };
    case cardTypes.GET_CARD_LIST_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    // GET CARD DETAIL==============================================================================
    case cardTypes.GET_CARD_DETAIL_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case cardTypes.GET_CARD_DETAIL_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        card: action.payload,
        error: {},
      };
    case cardTypes.GET_CARD_DETAIL_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    // GET LINKED CARDS==============================================================================
    case cardTypes.GET_LINKED_CARD_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case cardTypes.GET_LINKED_CARD_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        linkedCardList: action.payload,
        linkedCardTriggered: true,
        error: {},
      };
    case cardTypes.GET_LINKED_CARD_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    // SET LINKED CARD TRIGGERED FALSE =====================
    case cardTypes.CLEAR_LINKED_CARD_TRIGGERED:
      return {
        ...state,
        linkedCardTriggered: false,
      };
    // CLEAR ERROR STATE===========================================================================
    case cardTypes.CLEAR_ERROR_STATE:
      return {
        ...state,
        error: {},
      };
    case cardTypes.CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
};

export default cardReducer;
