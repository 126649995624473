/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import NewPasswordLayout from '@components/layout/loginLayout';
import NewPasswordButton from '@components/login/loginButton';
import { LoginTitle as NewPasswordTitle } from '@components/login/loginTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { apiRequestStatus } from '@data/constants';
import { customization } from '@customization/default';
import history from '@utils/history';
import { adminResetToken, adminVerifyToken, adminClearErrorState } from '@redux';
import { linkTransformation } from '@utils/transformation';
import { newPasswordPageStyle } from './style';

function NewPasswordPage(props) {
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorPasswordValidation, setErrorPasswordValidation] = useState(null);
  const [unexpectedError, setUnexpectedError] = useState(null);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [popupAnchorEl, setPopupAnchorEl] = useState(null);

  const {
    match,
    token,
    classes,
    adminVerifyToken,
    adminClearErrorState,
    adminResetToken,
    adminRequestError,
    adminRequestStatus,
    newPasswordRequestStatus,
  } = props;

  const sendGenerateKey = async () => {
    const { generateKey } = match.params;
    const transformedLink = linkTransformation(generateKey);
    try {
      const response = await adminVerifyToken(transformedLink);
      if (response?.status !== 200) {
        return history.push('/login?expired=true');
      }
    } catch (error) {
      setErrorPasswordValidation(null);
      setUnexpectedError(error.message);
      return history.push('/login');
    }
  };

  const passwordValidation = (value) => {
    const regex = /^(?=.{8,}$)(?=.*[0-9])(?=.*[a-zA-Z]).*?$/;
    return regex.test(value);
  };

  const checkPasswordStrength = (value) => {
    let initialStrength = 0;

    if (value.length >= 8) {
      initialStrength += 20;
    }

    const checkNumeric = /\d+/;
    if (checkNumeric.test(value)) {
      initialStrength += 20;
    }

    const checkLetter = /[a-zA-Z]+/;
    if (checkLetter.test(value)) {
      initialStrength += 20;
    }

    const checkSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    if (checkSpecialChar.test(value)) {
      initialStrength += 40;
    }

    return newPassword.length >= 3 ? initialStrength : 0;
  };

  const barColor = (strength) => {
    if (strength >= 80) {
      return classes.strengthHigh;
    }

    if (strength >= 50) {
      return classes.strengthMedium;
    }

    return classes.strengthLow;
  };

  const handleNewPassword = async (event) => {
    event.preventDefault();
    await adminClearErrorState();
    if (!newPassword && !confirmPassword) {
      setErrorPasswordValidation(customization.newPassword.emptyFieldsError);
      setUnexpectedError(null);
      return;
    } else if (newPassword !== confirmPassword) {
      setErrorPasswordValidation(customization.newPassword.missMatchError);
      setUnexpectedError(null);
      return;
    } else if (newPassword && confirmPassword && !passwordValidation(newPassword)) {
      setErrorPasswordValidation(customization.newPassword.passwordCriteriaError);
      setUnexpectedError(null);
      return;
    }

    try {
      const params = {
        new_password: newPassword,
        confirm_new_password: confirmPassword,
      };

      const response = await adminResetToken(token, params);
      if (!response?.data) return;
      history.push('/admin/dashboard/view-users');
    } catch (error) {
      console.log(error);
      setErrorPasswordValidation(null);
      setUnexpectedError(error?.response?.data?.message || customization.unexpectedError);
    }
  };

  const handleShowNewPassword = () => {
    setShowNewPassword((old) => !old);
  };

  const handleShowConfirmNewPassword = () => {
    setShowConfirmNewPassword((old) => !old);
  };

  useEffect(() => {
    setPasswordStrength(checkPasswordStrength(newPassword));
  }, [checkPasswordStrength, newPassword]);

  useEffect(() => {
    sendGenerateKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {match.params.generateKey !== 'invalid' &&
      adminRequestStatus !== apiRequestStatus.PENDING ? (
        <>
          <NewPasswordLayout>
            <Box className={classes.titleContainer}>
              <IconButton
                title="Return to Login"
                aria-label="Return to Login"
                onClick={() => history.push('/login')}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h4" component="div" className={classes.header}>
                {customization.newPassword.introduction}
                <Typography variant="subtitle1" className={classes.subtitle}>
                  {customization.newPassword.introductionDescription}
                </Typography>
              </Typography>
            </Box>

            {unexpectedError && (
              <div className={classes.errorPasswordValidation}>
                <NewPasswordTitle color="error">{unexpectedError}</NewPasswordTitle>
              </div>
            )}
            {adminRequestError.statusCode && (
              <div className={classes.errorPasswordValidation}>
                <NewPasswordTitle color="error">
                  {adminRequestError.message}
                </NewPasswordTitle>
              </div>
            )}
            <Box className={classes.formContainer}>
              <form className={classes.form}>
                <TextField
                  variant="outlined"
                  required
                  label={customization.newPassword.labelNewPassword}
                  name="newPassword"
                  type={showNewPassword ? 'text' : 'password'}
                  onChange={(event) => setNewPassword(event.target.value)}
                  value={newPassword}
                  autoComplete="off"
                  error={!!errorPasswordValidation}
                  helperText={errorPasswordValidation}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  variant="outlined"
                  required
                  label={customization.newPassword.labelConfirmNewPassword}
                  name="confirmPassword"
                  type={showConfirmNewPassword ? 'text' : 'password'}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  value={confirmPassword}
                  error={!!errorPasswordValidation}
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowConfirmNewPassword}
                          edge="end"
                        >
                          {showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box>
                  <div className={classes.passwordStrengthTitleBar}>
                    <Typography variant="subtitle1">
                      <b>{customization.passwordStrength}</b>
                    </Typography>
                    <InfoIcon
                      aria-owns={popupAnchorEl ? 'mouse-over-popover' : undefined}
                      aria-haspopup="true"
                      onMouseEnter={(event) => setPopupAnchorEl(event.currentTarget)}
                      onMouseLeave={() => setPopupAnchorEl(null)}
                      color="primary"
                    />
                    <Popover
                      id="mouse-over-popover"
                      className={classes.popover}
                      classes={{
                        paper: classes.popoverPaper,
                      }}
                      open={!!popupAnchorEl}
                      anchorEl={popupAnchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      onClose={() => setPopupAnchorEl(null)}
                      disableRestoreFocus
                    >
                      <Typography variant="subtitle2">
                        <b>{customization.newPassword.popup.title}</b>
                      </Typography>
                      <ul>
                        <li>{customization.newPassword.popup.point1}</li>
                        <li>{customization.newPassword.popup.point2}</li>
                        <li>{customization.newPassword.popup.point3}</li>
                        <li>{customization.newPassword.popup.point4}</li>
                      </ul>
                    </Popover>
                  </div>

                  <LinearProgress
                    variant="determinate"
                    value={passwordStrength}
                    className={barColor(passwordStrength)}
                  />
                  <p className={classes.passwordStrengthInformation}>
                    <span className={classes.weakStyle}>
                      {customization.newPassword.strength.weak}
                    </span>
                    <span className={classes.strongStyle}>
                      {customization.newPassword.strength.strong}
                    </span>
                  </p>
                </Box>
                <NewPasswordButton
                  disabled={
                    newPasswordRequestStatus === apiRequestStatus.PENDING ||
                    !newPassword ||
                    !confirmPassword
                  }
                  type="submit"
                  onClick={handleNewPassword}
                >
                  {customization.newPassword.buttonResetPassword}
                </NewPasswordButton>
              </form>
            </Box>
          </NewPasswordLayout>
        </>
      ) : (
        <LinearProgress />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    adminRequestError: state.auth.error,
    adminRequestStatus: state.auth.loading,
    newPasswordRequestStatus: state.auth.loadingResetPassword,
    token: state.auth.inMemoryToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminResetToken: bindActionCreators(adminResetToken, dispatch),
    adminVerifyToken: bindActionCreators(adminVerifyToken, dispatch),
    adminClearErrorState: bindActionCreators(adminClearErrorState, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withStyles(newPasswordPageStyle), withConnect)(NewPasswordPage);
