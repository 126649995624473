import {
  getClientMerchantCategoryRestriction,
  patchClientMerchantCategoryRestriction,
} from '@services/api/merchantCategory';
import merchantTypes from './merchantTypes';

export const getMerchantCategory = (token, cardId) => {
  return async (dispatch) => {
    dispatch({ type: merchantTypes.GET_MERCHANT_CATEGORY_RESTRICTION_REQUEST });
    try {
      const response = await getClientMerchantCategoryRestriction(token, cardId);
      dispatch({
        type: merchantTypes.GET_MERCHANT_CATEGORY_RESTRICTION_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: merchantTypes.GET_MERCHANT_CATEGORY_RESTRICTION_ERROR, error });
      return error;
    }
  };
};

export const patchMerchantCategory = (token, requestBody) => {
  return async (dispatch) => {
    dispatch({ type: merchantTypes.PATCH_MERCHANT_CATEGORY_RESTRICTION_REQUEST });
    try {
      const response = await patchClientMerchantCategoryRestriction(token, requestBody);
      dispatch({
        type: merchantTypes.PATCH_MERCHANT_CATEGORY_RESTRICTION_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: merchantTypes.PATCH_MERCHANT_CATEGORY_RESTRICTION_ERROR, error });
      return error;
    }
  };
};
