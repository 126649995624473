import React from 'react';
import { compose, bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import moment from 'moment-timezone';
import {
  Box,
  Grid,
  Button,
  Paper,
  Dialog,
  Typography,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import AlertGreenRoundedIcon from '@assets/icons/Alert-green-rounded.png';
import { ReactComponent as ApprovedIcon } from '@assets/icons/approved-circle.svg';
import { ReactComponent as BankIcon } from '@assets/icons/bank-circle.svg';
import { ReactComponent as BankEditIcon } from '@assets/icons/bank-edit-circle.svg';
import { ReactComponent as CardIcon } from '@assets/icons/card-circle.svg';
import { ReactComponent as ClockIcon } from '@assets/icons/clock-circle.svg';
import { ReactComponent as DelegateIcon } from '@assets/icons/delegate-circle.svg';
import { ReactComponent as DisputeIcon } from '@assets/icons/dispute-circle.svg';
import { ReactComponent as GearIcon } from '@assets/icons/gear-circle.svg';
import { ReactComponent as LocationIcon } from '@assets/icons/location-circle.svg';
import { ReactComponent as LocationBlockIcon } from '@assets/icons/location-block-circle.svg';
import { ReactComponent as LockIcon } from '@assets/icons/lock-circle.svg';
import { ReactComponent as RejectedIcon } from '@assets/icons/rejected-circle.svg';
import { ReactComponent as PasswordIcon } from '@assets/icons/password-circle.svg';
import { ReactComponent as SubmittedIcon } from '@assets/icons/submitted-circle.svg';
import { ReactComponent as TransferFundIcon } from '@assets/icons/transfer-fund-circle.svg';
import { ReactComponent as UnlockIcon } from '@assets/icons/unlock-circle.svg';
import { ReactComponent as UserIcon } from '@assets/icons/user-circle.svg';
import { ReactComponent as UserBlockIcon } from '@assets/icons/user-block-circle.svg';
import { ReactComponent as UserEditIcon } from '@assets/icons/user-edit-circle.svg';
import { ReactComponent as UserLockIcon } from '@assets/icons/user-lock-circle.svg';
import { ReactComponent as UserLoginIcon } from '@assets/icons/user-login-circle.svg';
import { ReactComponent as UserUnlockIcon } from '@assets/icons/user-unlock-circle.svg';

import ErrorMessageDialog from '@components/errorMessageDialog';
import DialogTitle from '@components/dialogTitle';
import FormTextInput from '@components/formTextInput';
import LoadingBackdrop from '@components/loadingBackdrop';
import CustomizedSnackBar from '@components/customizedSnackBar';
import {
  activitiesStatus,
  activitiesType,
  activitiesCell,
  activitiesDetailLabel,
  apiRequestStatus,
} from '@data/constants';
import _ from 'lodash';
import BaseComponent from '@containers/BaseComponent';
import * as activitiesActions from '@redux/activities/activitiesActions';
import * as userActions from '@redux/user/userActions';
import ToCamelCase from '@utils/convertToCamelCase';
import RenderMobileNumber from '@utils/renderMobileNumber';
import { customization } from '@customization/default';
import jwt_decode from 'jwt-decode';
import UserTypeDelegationMatrix from './userTypeDelegationMatrix';
import { ActivityDetailPageStyle, dialogContentStyle, dialogActionStyle } from './style';
import ChildDelegationMatrix from './childDelegationMatrix';
import ParentDelegationMatrix from './parentDelegationMatrix';
import ChildDelegationEditUser from './childDelegationEditUser';
import TaxInvoicesList from './taxInvoicesList';

const DialogContent = withStyles(dialogContentStyle)(MuiDialogContent);
const DialogActions = withStyles(dialogActionStyle)(MuiDialogActions);
class ActivityDetailPage extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      activityDetail: {},
      activityId: null,
      showRejectDialog: false,
      showApproveDialog: false,
      rejectForm: {
        rejectReason: {
          validation: {
            required: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
      },
      isFormValid: false,
      showSnackBar: false,
      snackBarSeverity: 'error',
      snackBarMessage: '',
      disableApproveBtn: false,
      disableRejectBtn: false,
      isError: false,
      errorMessage: '',
    };
  }

  findLocationCell = (cells) => cells.find((cell) => cell.name === 'Location Details');

  getActivityDetail = async () => {
    const mapped = this.mappingActivityDetail();
    this.setState({
      activityDetail: mapped,
    });
  };

  mappingActivityDetail = () => {
    const activityDetail = {};

    if (this.props.activityDetail) {
      activityDetail.id = this.props.activityDetail.id;
      activityDetail.cells = this.props.activityDetail.cells;
      activityDetail.reason = this.props.activityDetail.reason;
      activityDetail.status = this.props.activityDetail.status;
      activityDetail.submittedDate = this.props.activityDetail.submittedDate;
      activityDetail.timestamp =
        this.props.activityDetail.status !== activitiesStatus.OPEN
          ? this.props.activityDetail.timestamp
          : '';

      switch (this.props.activityDetail.type) {
        case activitiesType.USER_NEW.value:
          activityDetail.type = activitiesType.USER_NEW.label;
          break;
        case activitiesType.USER_BLOCK.value:
          activityDetail.type = activitiesType.USER_BLOCK.label;
          break;
        case activitiesType.USER_EDIT.value:
          activityDetail.type = activitiesType.USER_EDIT.label;
          break;
        case activitiesType.DEACTIVATE_USER.value:
          activityDetail.type = activitiesType.DEACTIVATE_USER.label;
          break;
        case activitiesType.CARD_NEW.value:
          activityDetail.type = activitiesType.CARD_NEW.label;
          break;
        case activitiesType.CARD_CHANGE.value:
          activityDetail.type = activitiesType.CARD_CHANGE.label;
          break;
        case activitiesType.CARD_REPLACEMENT.value:
          activityDetail.type = activitiesType.CARD_REPLACEMENT.label;
          break;
        case activitiesType.CARD_BLOCK.value:
          activityDetail.type = activitiesType.CARD_BLOCK.label;
          break;
        case activitiesType.LOCATION_NEW.value:
          activityDetail.type = activitiesType.LOCATION_NEW.label;
          break;
        case activitiesType.LOCATION_EDIT.value:
          activityDetail.type = activitiesType.LOCATION_EDIT.label;
          break;
        case activitiesType.LOCATION_BLOCK.value:
          activityDetail.type = activitiesType.LOCATION_BLOCK.label;
          break;
        case activitiesType.CONFIGURATION_CHANGE.value:
        case activitiesType.USER_TYPE_NEW.value:
        case activitiesType.USER_TYPE_EDIT.value:
        case activitiesType.LOCATION_GROUP_NEW.value:
        case activitiesType.LOCATION_GROUP_EDIT.value:
          activityDetail.type = activitiesType.CONFIGURATION_CHANGE.label;
          break;
        case activitiesType.DISPUTE_TRANSACTION.value:
          activityDetail.type = activitiesType.DISPUTE_TRANSACTION.label;
          break;
        case activitiesType.CARD_EXPENSE.value:
          activityDetail.type = activitiesType.CARD_EXPENSE.label;
          break;
        case activitiesType.NEW_BANK_ACCOUNT.value:
          activityDetail.type = activitiesType.NEW_BANK_ACCOUNT.label;
          break;
        case activitiesType.EDIT_BANK_ACCOUNT.value:
          activityDetail.type = activitiesType.EDIT_BANK_ACCOUNT.label;
          break;
        case activitiesType.FUNDS_TRANSFER.value:
          activityDetail.type = activitiesType.FUNDS_TRANSFER.label;
          break;
        case activitiesType.CHANGE_TRANSACTION_ALLOCATION.value:
          activityDetail.type = activitiesType.CHANGE_TRANSACTION_ALLOCATION.label;
          break;
        default:
          activityDetail.type = activitiesType[activityDetail.type].value;
      }
    }

    return activityDetail;
  };

  componentDidMount = () => {
    this.getActivityDetail();

    const { id } = jwt_decode(this.props.token);

    if (
      this.props.activityDetail.status !== activitiesStatus.OPEN ||
      id === this.props.activityDetail.initiator_id
    ) {
      this.setState({
        disableApproveBtn: true,
        disableRejectBtn: true,
      });
    }
  };

  componentDidUpdate = (prevProps, _prevState) => {
    if (_.isEmpty(this.props.activityDetail)) {
      this.props.history.push('/admin/dashboard/user-activities');
    }
    if (prevProps.activityDetail !== this.props.activityDetail) {
      this.getActivityDetail();
    }
  };

  renderActivityTypeIcon = (data) => {
    let component;

    if (data.type) {
      switch (data.type) {
        case activitiesType.USER_NEW.label:
          component = <UserIcon className="success" />;
          break;
        case activitiesType.USER_EDIT.label:
          component = <UserEditIcon className="success" />;
          break;
        case activitiesType.USER_BLOCK.label:
        case activitiesType.DEACTIVATE_USER.label:
          component = <UserBlockIcon className="error" />;
          break;
        case activitiesType.CARD_NEW.label:
        case activitiesType.CARD_CHANGE.label:
        case activitiesType.CARD_REPLACEMENT.label:
        case activitiesType.CARD_BLOCK.label:
          component = <CardIcon className="success" />;
          break;
        case activitiesType.LOCATION_NEW.label:
        case activitiesType.LOCATION_EDIT.label:
          component = <LocationIcon className="success" />;
          break;
        case activitiesType.LOCATION_BLOCK.label:
          component = <LocationBlockIcon className="error" />;
          break;
        case activitiesType.DISPUTE_TRANSACTION.label:
        case activitiesType.CARD_EXPENSE.label:
          component = <DisputeIcon className="success" />;
          break;
        case activitiesType.NEW_BANK_ACCOUNT.label:
          component = <BankIcon />;
          break;
        case activitiesType.EDIT_BANK_ACCOUNT.label:
          component = <BankEditIcon />;
          break;
        case activitiesType.FUNDS_TRANSFER.label:
          component = <TransferFundIcon className="success" />;
          break;
        case activitiesType.CHANGE_TRANSACTION_ALLOCATION.label:
          component = <DisputeIcon className="success" />;
          break;
        case activitiesType.CONFIGURATION_CHANGE.label:
          component = <GearIcon />;
          break;
        case activitiesType.LOCK_USER.label:
        case activitiesType.LOCK_USER_ADMIN.label:
          component = <UserLockIcon />;
          break;
        case activitiesType.UNLOCK_USER.label:
          component = <UserUnlockIcon />;
          break;
        case activitiesType.LOCK_DUE_TO_PASSWORD.label:
        case activitiesType.LOCK_DUE_TO_ADMIN.label:
        case activitiesType.LOCK_DUE_TO_MFA.label:
          component = <LockIcon />;
          break;
        case activitiesType.PROVISION_UNLOCK_CODE.label:
        case activitiesType.PROVISION_UNLOCK_CODE_VERBAL.label:
          component = <UnlockIcon />;
          break;
        case activitiesType.USER_LOG_IN.label:
        case activitiesType.USER_LOG_IN_BIO.label:
        case activitiesType.USER_LOG_IN_PIN.label:
          component = <UserLoginIcon />;
          break;
        case activitiesType.CHANGE_PIN.label:
          component = <PasswordIcon />;
          break;
        default:
          component = <div></div>;
          break;
      }
    } else {
      component = <div></div>;
    }

    return component;
  };

  updateActivityDetailData = (mode) => {
    const { activityDetail } = this.props;
    try {
      this.props.activitiesAction
        .fetchActivityDetail(this.props.token, activityDetail.id)
        .then(() => {
          if (this.props.error.statusCode) {
            this.setState({
              isError: true,
              errorMessage: `Error on fetch detail: ${this.props.error.statusCode}`,
            });
          } else {
            this.setState({
              showSnackBar: true,
              snackBarSeverity: 'success',
              snackBarMessage: `${mode} activity success`,
              disableApproveBtn: true,
              disableRejectBtn: true,
              showApproveDialog: false,
              showRejectDialog: false,
            });
            this.props.userAction.getUserNotification(this.props.token).then(() => {
              if (this.props.notificationError.statusCode) {
                this.setState({
                  isError: true,
                  errorMessage: `Error on update notification: ${this.props.notificationError.statusCode}`,
                });
              }
            });
          }
        });
    } catch (error) {
      this.setState({
        isError: true,
        errorMessage: `Error on update detail data: ${error}`,
      });
    }
  };

  approveActivity = () => {
    const { activityDetail } = this.props;
    this.setState({ showApproveDialog: false });
    const params = {
      id: activityDetail.id,
      type: activityDetail.type,
    };
    try {
      this.props.activitiesAction
        .approveActivityDetail(this.props.token, params)
        .then(() => {
          if (this.props.error.statusCode) {
            this.setState({
              isError: true,
              errorMessage: `${this.props.error.message}`,
            });
          } else {
            this.updateActivityDetailData('Approve');
          }
        });
    } catch (error) {
      this.setState({
        isError: true,
        errorMessage: `Error on update notification: ${error}`,
      });
    }
  };

  rejectActivity = () => {
    const { activityDetail } = this.props;
    const { rejectForm } = this.state;
    this.setState({ showRejectDialog: false });
    const params = {
      id: activityDetail.id,
      reason_description: rejectForm.rejectReason.value,
      type: activityDetail.type,
    };
    try {
      this.props.activitiesAction
        .rejectActivityDetail(this.props.token, params)
        .then(() => {
          if (this.props.error.statusCode) {
            this.setState({
              isError: true,
              errorMessage: `${this.props.error.message}`,
            });
          } else {
            this.updateActivityDetailData('Reject');
          }
        });
    } catch (error) {
      this.setState({
        isError: true,
        errorMessage: `Error on update notification: ${error}`,
      });
    }
  };

  handleSnackBar = () => {
    this.setState({ showSnackBar: false });
  };

  renderTopTabs = () => {
    const { classes } = this.props;
    const { activityDetail } = this.state;
    return (
      <Grid container spacing={2} className={classes.activityTabsContainer}>
        <Grid item xs={12} md={12} lg={2}>
          <Paper className={classes.tabsPaper} variant="outlined" elevation={0}>
            <Grid container>
              <Grid item xs={12} md={12} lg={4}>
                <div className={classes.activityStatusContainer}>
                  {activityDetail?.status === activitiesStatus.OPEN ? (
                    <SubmittedIcon className="info" />
                  ) : activityDetail?.status === activitiesStatus.REJECTED ? (
                    <RejectedIcon className="error" />
                  ) : (
                    <ApprovedIcon className="success" />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <div className={classes.activityStatusContainer}>
                  <div
                    className={`title ${
                      activityDetail?.status === activitiesStatus.OPEN
                        ? 'info'
                        : activityDetail?.status === activitiesStatus.REJECTED
                        ? 'error'
                        : 'success'
                    }`}
                  >
                    {ToCamelCase(activityDetail?.status || '')}
                  </div>
                  <div>{customization.activityDetail.activityStatus}</div>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Paper className={classes.tabsPaper} variant="outlined" elevation={0}>
            <Grid container>
              <Grid item xs={12} md={12} lg={2}>
                <div className={classes.activityStatusContainer}>
                  {this.renderActivityTypeIcon(activityDetail)}
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <div className={classes.activityStatusContainer}>
                  <div className="title">{activityDetail?.type}</div>
                  <div>{customization.activityDetail.activityType}</div>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Paper className={classes.tabsPaper} variant="outlined" elevation={0}>
            <Grid container>
              <Grid item xs={12} md={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={3}>
                    <div className={classes.activityStatusContainer}>
                      <ClockIcon className="warning" />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} lg={9}>
                    <div className={classes.activityStatusContainer}>
                      <div className="title">
                        {moment(activityDetail?.submittedDate)
                          .utc()
                          .tz(this.props.timezone)
                          .format('DD/MM/YYYY - hh:mm a')}
                      </div>
                      <div>{customization.activityDetail.submittedDate}</div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12}>
                    <div className={classes.activityStatusContainer}>
                      <div className="title">
                        {activityDetail?.status === activitiesStatus.APPROVED ||
                        activityDetail?.status === activitiesStatus.COMPLETED
                          ? moment(activityDetail?.timestamp)
                              .utc()
                              .tz(this.props.timezone)
                              .format('DD/MM/YYYY - hh:mm a')
                          : customization.activityDetail.notApplicable}
                      </div>
                      <div>{customization.activityDetail.approvedDate}</div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={3}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12}>
                    <div className={classes.activityStatusContainer}>
                      <div className="title">
                        {activityDetail?.status === activitiesStatus.REJECTED
                          ? moment(activityDetail?.timestamp)
                              .utc()
                              .tz(this.props.timezone)
                              .format('DD/MM/YYYY - hh:mm a')
                          : customization.activityDetail.notApplicable}
                      </div>
                      <div>{customization.activityDetail.rejectedDate}</div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  renderActivityIndicator = () => {
    const { classes, activityDetail } = this.props;
    return (
      <Grid item xs={12} md={12} lg={6}>
        <Paper className={classes.tabsPaper} variant="outlined" elevation={0}>
          <div className={classes.headingOneStyle}>
            <Grid container>
              <Grid item xs={12} md={12} lg={1}>
                <UserIcon />
              </Grid>
              <Grid item xs={12} md={12} lg={10}>
                <p className={classes.activityIndicatorCaption}>
                  {customization.activityDetail.activityIndicator}
                </p>
              </Grid>
            </Grid>
            <div className={classes.tabContentStyle}>
              <Grid container className={classes.gridContentStyle}>
                <Grid item xs={12} md={6} lg={6}>
                  {customization.activityDetail.status}{' '}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.status ? (
                      <b>{activityDetail.user?.status}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  {customization.activityDetail.userType}{' '}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.user_type ? (
                      <b>{activityDetail.user?.user_type}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
              </Grid>
              <Grid container className={classes.gridContentStyle}>
                <Grid item xs={12} md={6} lg={6}>
                  {customization.activityDetail.uniqueReferenceNo}{' '}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.user_number ? (
                      <b>{activityDetail.user?.user_number}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  {customization.activityDetail.firstName}{' '}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.first_name ? (
                      <b>{activityDetail.user?.first_name}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
              </Grid>
              <Grid container className={classes.gridContentStyle}>
                <Grid item xs={12} md={6} lg={6}>
                  {customization.activityDetail.middleName}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.middle_name ? (
                      <b>{activityDetail.user?.middle_name}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  {customization.activityDetail.lastName}{' '}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.last_name ? (
                      <b>{activityDetail.user?.last_name}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
              </Grid>
              <Grid container className={classes.gridContentStyle}>
                <Grid item xs={12} md={6} lg={6}>
                  {customization.activityDetail.countryCode}{' '}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.country_code ? (
                      <b>{activityDetail.user?.country_code}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  {customization.activityDetail.mobileNumber}{' '}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.mobile ? (
                      <b>{RenderMobileNumber(activityDetail.user?.mobile)}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
              </Grid>
              <Grid container className={classes.gridContentStyle}>
                <Grid item xs={12} md={6} lg={12}>
                  {customization.activityDetail.defaultLocation}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.default_location ? (
                      <b>{activityDetail.user?.default_location}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
              </Grid>
            </div>
          </div>
        </Paper>
      </Grid>
    );
  };

  renderEditUser = () => {
    const { classes, activityDetail } = this.props;
    return (
      <Grid item xs={12} md={12} lg={6}>
        <Paper className={classes.tabsPaper} variant="outlined" elevation={0}>
          <div className={classes.headingOneStyle}>
            <Grid container>
              <Grid item xs={12} md={12} lg={1}>
                <UserEditIcon />
              </Grid>
              <Grid item xs={12} md={12} lg={10}>
                <p className={classes.activityIndicatorCaption}>
                  {customization.activityDetail.editUser}
                </p>
              </Grid>
            </Grid>
            <div className={classes.tabContentStyle}>
              <Grid container className={classes.gridContentStyle}>
                <Grid item xs={12} md={6} lg={6}>
                  {customization.activityDetail.status}{' '}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.status ? (
                      <b>{activityDetail.user?.status}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  {customization.activityDetail.userType}{' '}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.user_type ? (
                      <b>{activityDetail.user?.user_type}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
              </Grid>
              <Grid container className={classes.gridContentStyle}>
                <Grid item xs={12} md={6} lg={6}>
                  {customization.activityDetail.uniqueReferenceNo}{' '}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.user_number ? (
                      <b>{activityDetail.user?.user_number}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  {customization.activityDetail.firstName}{' '}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.first_name ? (
                      <b>{activityDetail.user?.first_name}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
              </Grid>
              <Grid container className={classes.gridContentStyle}>
                <Grid item xs={12} md={6} lg={6}>
                  {customization.activityDetail.middleName}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.middle_name ? (
                      <b>{activityDetail.user?.middle_name}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  {customization.activityDetail.lastName}{' '}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.last_name ? (
                      <b>{activityDetail.user?.last_name}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
              </Grid>
              <Grid container className={classes.gridContentStyle}>
                <Grid item xs={12} md={6} lg={6}>
                  {customization.activityDetail.countryCode}{' '}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.country_code ? (
                      <b>{activityDetail.user?.country_code}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  {customization.activityDetail.mobileNumber}{' '}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.mobile ? (
                      <b>{RenderMobileNumber(activityDetail.user?.mobile)}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
              </Grid>
              <Grid container className={classes.gridContentStyle}>
                <Grid item xs={12} md={6} lg={12}>
                  {customization.activityDetail.defaultLocation}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.user?.default_location ? (
                      <b>{activityDetail.user?.default_location}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
              </Grid>
            </div>
          </div>
        </Paper>
      </Grid>
    );
  };

  renderCardDetails = () => {
    const { classes, activityDetail } = this.props;
    return (
      <Grid item xs={12} md={12} lg={6}>
        <Paper className={classes.tabsPaper} variant="outlined" elevation={0}>
          <div className={classes.headingOneStyle}>
            <Grid container>
              <Grid item xs={12} md={12} lg={1}>
                <CardIcon />
              </Grid>
              <Grid item xs={12} md={12} lg={10}>
                <p className={classes.activityIndicatorCaption}>
                  {customization.activityDetail.cardDetails}
                </p>
              </Grid>
            </Grid>
            <div className={classes.tabContentStyle}>
              <Grid container className={classes.gridContentStyle}>
                <Grid item xs={12} md={6} lg={6}>
                  {customization.activityDetail.cardType}{' '}
                  <span className={classes.contentValueStyle}>
                    {activityDetail.card_application ? (
                      <b>{activityDetail.card_application.type}</b>
                    ) : (
                      <i>{customization.activityDetail.notPresent}</i>
                    )}
                  </span>
                </Grid>
              </Grid>
            </div>
          </div>
        </Paper>
      </Grid>
    );
  };

  locationDetails = () => {
    const { classes } = this.props;
    return (
      <Grid item xs={12} md={12} lg={6}>
        <Paper className={classes.tabsPaper} variant="outlined" elevation={0}>
          <div className={classes.headingOneStyle}>
            <Grid container>
              <Grid item xs={12} md={12} lg={1}>
                <LocationIcon />
              </Grid>
              <Grid item xs={12} md={12} lg={10}>
                <p className={classes.activityIndicatorCaption}>
                  {customization.activityDetail.locationDetails}
                </p>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
    );
  };

  onChangeInput = (eventValue, inputIdentifier) => {
    const updateState = this.changeInputWithValidation(
      eventValue,
      this.state.rejectForm,
      inputIdentifier,
    );
    this.setState({
      rejectForm: updateState.form,
      isFormValid: updateState.valid,
    });
  };

  handleBackBtn = () => {
    this.props.activitiesAction
      .getAllActivities(this.props.token, 1, 25, activitiesStatus.OPEN, '')
      .then(() => {
        if (this.props.errorList.statusCode) {
          this.setState({
            showSnackBar: true,
            snacBarSeverity: 'error',
            snackBarMessage: `Get activity list error: ${this.props.errorList.statusCode}`,
            notFound: true,
          });
        }

        this.props.history.push('/admin/dashboard/user-activities');
      });
  };

  handleRejectBtn = () => {
    this.setState({
      showRejectDialog: true,
    });
  };

  handleApproveBtn = () => {
    this.setState({
      showApproveDialog: true,
    });
  };

  handleCloseDialog = () => {
    this.setState({ showRejectDialog: false });
  };

  handleCloseApproveDialog = () => {
    this.setState({ showApproveDialog: false });
  };

  approveConfirmationDialog = () => {
    const { classes } = this.props;

    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        onClose={this.handleCloseApproveDialog}
        open={this.state.showApproveDialog}
        classes={{
          paper: classes.paperConfirmationMessageStyle,
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={this.handleCloseApproveDialog}
        ></DialogTitle>
        <DialogContent>
          <Box p={2}>
            <div className={classes.exclamationIconContainterStyle}>
              <img
                src={AlertGreenRoundedIcon}
                alt="alertGreenRoundedIcon"
                loading="lazy"
                className={classes.alertIconStyle}
              />
            </div>
            <div className={classes.exclamationIconContainterStyle}>
              <Typography className={classes.approveMsgStyle}>
                <b>{customization.activityDetail.approveConfirmationMessage}</b>
              </Typography>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <Button
                autoFocus
                type="submit"
                variant="contained"
                className={classes.approveProceedStyle}
                onClick={this.approveActivity}
                disabled={this.props.loading === apiRequestStatus.PENDING}
              >
                {customization.activityDetail.approveConfirmBtn}
              </Button>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Button
                className={classes.closeButton}
                onClick={this.handleCloseApproveDialog}
                variant="outlined"
              >
                {customization.activityDetail.cancelBtn}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  rejectDialog = () => {
    const { classes } = this.props;
    const { rejectForm, isFormValid } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        onClose={this.handleCloseDialog}
        open={this.state.showRejectDialog}
        classes={{
          paper: classes.paperRejectMessageStyle,
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={this.handleCloseDialog} />
        <DialogContent>
          <Box p={3}>
            <div className={classes.exclamationIconContainterStyle}>
              <ErrorIcon fontSize="inherit" className={classes.exclamationIcon} />
            </div>
            <div className={classes.exclamationIconContainterStyle}>
              <Typography className={classes.rejectMsgStyle}>
                <b>{customization.activityDetail.rejectMessage}</b>
              </Typography>
            </div>
            <div className={classes.rejectTextContainerStyle}>
              <FormTextInput
                field="rejectReason"
                placeholder="Reason for rejection"
                touched={rejectForm.rejectReason.touched}
                valid={rejectForm.rejectReason.valid}
                value={rejectForm.rejectReason.value}
                validFormat={rejectForm.rejectReason.isValidFormat}
                onChangeInput={this.onChangeInput}
                length={500}
                validationMessage={customization.requiredError}
                type="text"
                multiline={true}
                rows={13}
              />
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <Button
                autoFocus
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.submit}
                disabled={!isFormValid || this.props.loading === apiRequestStatus.PENDING}
                onClick={this.rejectActivity}
              >
                {customization.activityDetail.confirmBtn}
              </Button>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Button
                className={classes.closeButton}
                onClick={this.handleCloseDialog}
                variant="outlined"
              >
                {customization.activityDetail.cancelBtn}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  renderActivityCellHeader = (cellName) => {
    const { classes } = this.props;
    const typeDef = [
      {
        value: activitiesCell.ACTIVITY_INITIATOR,
        label: (
          <p className={classes.activityIndicatorCaption}>
            {activitiesCell.ACTIVITY_INITIATOR}
          </p>
        ),
        icon: <UserIcon />,
      },
      {
        value: activitiesCell.LOCATION_DETAILS,
        label: (
          <p className={classes.activityIndicatorCaption}>
            {activitiesCell.LOCATION_DETAILS}
          </p>
        ),
        icon: <LocationIcon />,
      },
      {
        value: activitiesCell.CHANGE,
        label: (
          <p className={classes.activityIndicatorCaption}>{activitiesCell.CHANGE}</p>
        ),
        icon: <UserEditIcon />,
      },
      {
        value: activitiesCell.USERTYPE_DELEGATION,
        label: (
          <p className={classes.activityIndicatorCaption}>
            {activitiesCell.USERTYPE_DELEGATION}
          </p>
        ),
        icon: <CardIcon />,
      },
      {
        value: activitiesCell.CHILD_DELEGATION,
        label: (
          <p className={classes.activityIndicatorCaption}>
            {activitiesCell.CHILD_DELEGATION}
          </p>
        ),
        icon: <DelegateIcon />,
      },
      {
        value: activitiesCell.PARENT_DELEGATION,
        label: (
          <p className={classes.activityIndicatorCaption}>
            {activitiesCell.PARENT_DELEGATION}
          </p>
        ),
        icon: <DelegateIcon />,
      },
      {
        value: activitiesCell.CARD_HOLDER,
        label: (
          <p className={classes.activityIndicatorCaption}>{activitiesCell.CARD_HOLDER}</p>
        ),
        icon: <UserIcon />,
      },
      {
        value: activitiesCell.CARD_DETAILS,
        label: (
          <p className={classes.activityIndicatorCaption}>
            {activitiesCell.CARD_DETAILS}
          </p>
        ),
        icon: <CardIcon />,
      },
      {
        value: activitiesCell.NEW_USER_DETAIL,
        label: (
          <p className={classes.activityIndicatorCaption}>
            {activitiesCell.NEW_USER_DETAIL}
          </p>
        ),
        icon: <UserEditIcon />,
      },
      {
        value: activitiesCell.USER_DETAIL,
        label: (
          <p className={classes.activityIndicatorCaption}>{activitiesCell.USER_DETAIL}</p>
        ),
        icon: <UserEditIcon />,
      },
      {
        value: activitiesCell.DEACTIVATE_USER_DETAIL,
        label: (
          <p className={classes.activityIndicatorCaption}>
            {activitiesCell.DEACTIVATE_USER_DETAIL}
          </p>
        ),
        icon: <UserEditIcon />,
      },
      {
        value: activitiesCell.TRANSACTION_DETAILS,
        label: (
          <p className={classes.activityIndicatorCaption}>
            {activitiesCell.TRANSACTION_DETAILS}
          </p>
        ),
        icon: <DisputeIcon />,
      },
      {
        value: activitiesCell.BANK_ACCOUNT_DETAILS,
        label: (
          <p className={classes.activityIndicatorCaption}>
            {activitiesCell.BANK_ACCOUNT_DETAILS}
          </p>
        ),
        icon: <BankIcon />,
      },
      {
        value: activitiesCell.SOURCE_ACCOUNT,
        label: (
          <p className={classes.activityIndicatorCaption}>
            {activitiesCell.SOURCE_ACCOUNT}
          </p>
        ),
        icon: <TransferFundIcon className="success" />,
      },
      {
        value: activitiesCell.DESTINATION_ACCOUNT,
        label: (
          <p className={classes.activityIndicatorCaption}>
            {activitiesCell.DESTINATION_ACCOUNT}
          </p>
        ),
        icon: <TransferFundIcon className="success" />,
      },
      {
        value: activitiesCell.TRANSFER_DETAILS,
        label: (
          <p className={classes.activityIndicatorCaption}>
            {activitiesCell.TRANSFER_DETAILS}
          </p>
        ),
        icon: <DisputeIcon className="success" />,
      },
      {
        value: activitiesCell.NEW_SPLIT,
        label: (
          <p className={classes.activityIndicatorCaption}>{activitiesCell.NEW_SPLIT}</p>
        ),
        icon: <CardIcon />,
      },
    ];

    if (cellName) {
      const typeToDisplay = typeDef.find((x) => x.value === cellName);

      if (typeToDisplay) {
        return (
          <Grid container>
            <Grid item xs={12} md={12} lg={1}>
              {typeToDisplay.icon}
            </Grid>
            <Grid item xs={12} md={12} lg={10}>
              {typeToDisplay.label}
            </Grid>
          </Grid>
        );
      } else {
        return <div></div>;
      }
    } else {
      return <div></div>;
    }
  };

  mappingCell = (separateCells) => {
    return separateCells.map((cells) => {
      return cells.map((cell) => {
        if (cell) {
          cell.containerFields = [];
          const splitNumber = cell?.name === activitiesCell.CHANGE ? 3 : 2;

          let itemFields = [];
          cell?.fields?.forEach((field, index) => {
            itemFields.push(field);
            if (itemFields?.length === splitNumber) {
              cell.containerFields.push(itemFields);
              itemFields = [];
            }
            if (index === cell?.fields?.length - 1 && itemFields.length > 0) {
              cell.containerFields.push(itemFields);
            }
          });
        }

        return cell;
      });
    });
  };

  validateToRenderStringOnly = (value) => {
    if (typeof value === 'string') {
      return <b>{value}</b>;
    } else {
      return <div></div>;
    }
  };

  renderCellContent(containerFields) {
    const { classes } = this.props;
    return (
      <div className={classes.tabContentStyle}>
        {containerFields?.map((containerField, containerFieldIndex) => {
          let className = null;
          if (
            (containerField[0]?.displayName === activitiesCell.PARENT_DELEGATION &&
              containerField[1]?.displayName === activitiesCell.CHILD_DELEGATION) ||
            (containerField[0]?.displayName === activitiesCell.CHILD_DELEGATION &&
              containerField[1]?.displayName === activitiesCell.PARENT_DELEGATION)
          ) {
            if (
              containerField[0]?.value?.length > 0 &&
              containerField[1]?.value?.length > 0
            ) {
              className = classes.gridContentStyle;
            }
          } else {
            className = classes.gridContentStyle;
          }

          return (
            <Grid container className={className} key={containerFieldIndex}>
              {containerField?.map((field, fieldIndex) => {
                let result;
                switch (field.displayName) {
                  case activitiesCell.PARENT_DELEGATION:
                    if (field.value) {
                      result = <ParentDelegationMatrix field={field} key={fieldIndex} />;
                    }
                    return result;
                  case activitiesCell.USERTYPE_DELEGATION:
                    result = <UserTypeDelegationMatrix field={field} key={fieldIndex} />;
                    break;
                  case activitiesCell.ALLOCATED_ACCOUNT:
                    result =
                      field.value.length !== 0 ? (
                        <ParentDelegationMatrix field={field} key={fieldIndex} />
                      ) : (
                        <Grid item xs={12} md={6} lg={12} key={fieldIndex}>
                          <Grid container className={classes.contentValueStyle}>
                            <Grid item xs={6}>
                              {`${field.displayName}: ` || 'property: '}
                            </Grid>
                            <Grid item xs={6}>
                              <i>{customization.activityDetail.notPresent}</i>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    break;
                  case activitiesCell.UPLOADED_TAX_INVOICES:
                    result =
                      field.value.length !== 0 ? (
                        <TaxInvoicesList field={field} key={fieldIndex} />
                      ) : (
                        <Grid item xs={12} md={6} lg={12} key={fieldIndex}>
                          <Grid container className={classes.contentValueStyle}>
                            <Grid item xs={6}>
                              {`${field.displayName}: ` || 'property: '}
                            </Grid>
                            <Grid item xs={6}>
                              <i>{customization.activityDetail.notPresent}</i>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    break;
                  case activitiesCell.CHILD_DELEGATION:
                    result = (
                      <ChildDelegationMatrix
                        matrix={field}
                        location={this.findLocationCell(this.props.activityDetail.cells)}
                        definition={activitiesCell.CHILD_DELEGATION}
                        key={fieldIndex}
                      />
                    );
                    break;
                  case activitiesDetailLabel.OLDVALUE:
                  case activitiesDetailLabel.NEWVALUE:
                    result = (
                      <Grid item xs={12} md={6} lg={12} key={fieldIndex}>
                        <Grid container spacing={2} className={classes.contentValueStyle}>
                          <Grid item xs={6}>
                            <b>{`${field.displayName}: ` || 'property: '}</b>
                          </Grid>
                          <Grid item xs={6}>
                            <b>
                              {field.value !== null && field.value !== false ? (
                                field?.value?.toString()
                              ) : field.value === null ? (
                                <i>{customization.activityDetail.notPresent}</i>
                              ) : field.value === false ? (
                                <i>false</i>
                              ) : (
                                <i>{customization.activityDetail.notPresent}</i>
                              )}
                            </b>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                    break;
                  case activitiesCell.CHILD_DELEGATION_CHANGE:
                    result = (
                      <ChildDelegationEditUser
                        matrix={field}
                        definition={activitiesCell.CHILD_DELEGATION_CHANGE}
                        key={fieldIndex}
                      />
                    );
                    break;
                  case activitiesDetailLabel.ATTRIBUTEFIELD:
                    result = (
                      <Grid item xs={12} md={6} lg={12} key={fieldIndex}>
                        <Grid
                          container
                          spacing={2}
                          className={`${classes.contentValueStyle} row-spacing`}
                        >
                          <Grid item xs={6}>
                            {`${field.displayName}: ` || 'property: '}
                          </Grid>
                          <Grid item xs={6}>
                            {field.value ? (
                              field.value !== null ? (
                                field?.value?.toString().replaceAll('_', ' ')
                              ) : (
                                <i>{customization.activityDetail.notPresent}</i>
                              )
                            ) : (
                              <i>{customization.activityDetail.notPresent}</i>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                    break;
                  case activitiesCell.BANK_ACCOUNT_DETAILS:
                    result = (
                      <Grid item xs={12} md={6} lg={12} key={fieldIndex}>
                        <Grid container className={classes.contentValueStyle}>
                          <Grid item xs={6}>
                            {`${field.displayName}: ` || 'property: '}
                          </Grid>
                          <Grid item xs={6}>
                            {field.value ? (
                              field?.value.toString()
                            ) : (
                              <i>{customization.activityDetail.notPresent}</i>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                    break;
                  default:
                    result = (
                      <Grid item xs={12} md={6} lg={12} key={fieldIndex}>
                        <Grid container className={classes.contentValueStyle}>
                          <Grid item xs={6}>
                            {`${field.displayName}: ` || 'property: '}
                          </Grid>
                          <Grid item xs={6}>
                            {field.value ? (
                              this.validateToRenderStringOnly(field.value)
                            ) : (
                              <i>{customization.activityDetail.notPresent}</i>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                    break;
                }
                return result;
              })}
            </Grid>
          );
        })}
      </div>
    );
  }

  renderCells(cells) {
    const { classes } = this.props;

    if (cells?.length > 0) {
      const separatedCells = this.separateCellBySize(cells, 2);
      const mappedCells = this.mappingCell(separatedCells);

      return mappedCells?.map((cells, index) => {
        const components = cells?.map((cell, cellIndex) => {
          if (cell) {
            return (
              <Grid item xs={12} md={12} lg={6} key={cellIndex}>
                <Paper className={classes.tabsPaper} variant="outlined" elevation={0}>
                  <div className={classes.headingOneStyle}>
                    {this.renderActivityCellHeader(cell?.name)}
                    {this.renderCellContent(cell?.containerFields || [])}
                  </div>
                </Paper>
              </Grid>
            );
          }

          return null;
        });

        return (
          <Grid
            container
            spacing={2}
            className={classes.activityTabsContainer}
            key={index}
          >
            {components}
          </Grid>
        );
      });
    }

    return <div></div>;
  }

  separateCellBySize(cells, chunkSize) {
    return [].concat.apply(
      [],
      cells.map(function (elem, i) {
        return i % chunkSize ? [] : [cells.slice(i, i + chunkSize)];
      }),
    );
  }

  handleCloseErrorMessage = () => {
    this.setState({
      isError: false,
      message: '',
    });
  };

  render() {
    const {
      showSnackBar,
      snackBarMessage,
      snackBarSeverity,
      disableApproveBtn,
      disableRejectBtn,
    } = this.state;
    const { classes, loading } = this.props;
    const { activityDetail, errorMessage, isError } = this.state;

    return (
      <>
        {this.rejectDialog()}
        {this.approveConfirmationDialog()}
        <ErrorMessageDialog
          bindHandleClose={this.handleCloseErrorMessage}
          openSuccess={isError}
          errorData={errorMessage}
        />
        <LoadingBackdrop status={loading} />
        <CustomizedSnackBar
          showBar={showSnackBar}
          severity={snackBarSeverity}
          message={snackBarMessage}
          handleClose={this.handleSnackBar}
        />
        <div className={classes.divStyle}>
          <Grid container spacing={2} className={classes.buttonGrid}>
            <Grid item xs={12} md={4} lg={2}>
              <Button
                variant="outlined"
                className={classes.backBtnStyle}
                onClick={this.handleBackBtn}
              >
                {customization.activityDetail.backBtn}
              </Button>
            </Grid>
            <Grid item lg={6} className={classes.desktopOffset} />
            <Grid item xs={12} md={4} lg={2}>
              <Button
                variant="contained"
                className={classes.rejectBtnStyle}
                onClick={this.handleRejectBtn}
                disabled={disableRejectBtn}
              >
                {customization.activityDetail.rejectBtn}
              </Button>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Button
                variant="contained"
                className={classes.approveBtnStyle}
                onClick={this.handleApproveBtn}
                disabled={disableApproveBtn}
              >
                {customization.activityDetail.approveBtn}
              </Button>
            </Grid>
          </Grid>

          {this.renderTopTabs()}

          {this.renderCells(activityDetail?.cells || [])}
        </div>
      </>
    );
  }
}

ActivityDetailPage.propTypes = {
  classes: PropTypes.object,
  token: PropTypes.string,
  activitiesAction: PropTypes.object,
  error: PropTypes.object,
  errorList: PropTypes.object,
  activityDetail: PropTypes.object,
  loading: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  timezone: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
    activityDetail: state.activities.activityDetail,
    loading: state.activities.loading,
    errorList: state.activities.errorList,
    error: state.activities.error,
    notificationError: state.user.error,
    timezone: state.auth.timezone,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activitiesAction: bindActionCreators(activitiesActions, dispatch),
    userAction: bindActionCreators(userActions, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withStyles(ActivityDetailPageStyle),
  withConnect,
)(ActivityDetailPage);
