import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import AddPersonIcon from '@material-ui/icons/PersonAddRounded';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userLocationType, userOperationTag, apiRequestStatus } from '@data/constants';
import {
  getCompanyList as getCompanyListService,
  getUserTypeList as getUserTypeListService,
  getCountryCodeList as getCountryCodeListService,
  getTimeZoneList as getTimeZoneListService,
} from '@services/api/userApi';
import { customization } from '@customization/default';
import SuccessMessageDialog from '@components/successMessageDialog';
import ErrorMessageDialog from '@components/errorMessageDialog';
import SecondBlock from '@components/formUserSecondBlock';
import CardApplication from '@components/formUserCardApplication';
import LoadingBackdrop from '@components/loadingBackdrop';
import { postUserData } from '@services/api/userApi';
import FormRootUser from '@components/formRootUser';
import { addUserDialogStyle } from './style';
function AddUserDialog(props) {
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [firstAndLastName, setFirstAndLastName] = useState({});
  const [selectedUserType, setUserType] = useState({});
  const [errorMessage, setErrorMessage] = useState({ isError: false, message: '' });
  const [isInviteJoin, setInviteJoin] = useState(false);
  const [rootIsFormValid, setRootIsFormValid] = useState(false);
  const [selectedDefaultLocation, setDefaultLocation] = useState({});
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [formDataFirstBlock, setFormDataFirstBlock] = useState({});
  const [formDataSecondBlock, setFormDataSecondBlock] = useState({});
  const [formCardApplication, setFormCardApplication] = useState({});
  const [countryCodeId, setCountryCodeId] = useState(0);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [responseCompaniesList, setResponseCompaniesList] = useState([]);
  const [responseUserTypesList, setResponseUserTypesList] = useState([]);
  const [responseCountriesList, setResponseCountriesList] = useState([]);
  const [responseTimeZonesList, setResponseTimeZonesList] = useState([]);
  const [addUserLoading, setAddUserLoading] = useState(apiRequestStatus.IDLE);
  const [isDisableSaveButton, setIsDisableSaveButton] = useState(false);
  const { classes } = props;

  const handleClickOpen = async () => {
    setInviteJoin(false);
    setUserType({});
    setAddUserLoading(apiRequestStatus.PENDING);
    try {
      const [responseCompanies, responseUserTypes, responseCountries, responseTimeZones] =
        await Promise.all([
          getCompanyListService(props.token),
          getUserTypeListService(props.token),
          getCountryCodeListService(props.token),
          getTimeZoneListService(props.token),
        ]);
      await setResponseCompaniesList(responseCompanies);
      await setResponseCountriesList(responseCountries);
      await setResponseUserTypesList(responseUserTypes);
      await setResponseTimeZonesList(responseTimeZones);
      setAddUserLoading(apiRequestStatus.RESOLVED);
    } catch (error) {
      await handleErrorRetrieveData(customization.forgotPassword.internalServerError);
      setAddUserLoading(apiRequestStatus.REJECTED);
    }
    setOpen(true);
  };

  const getDataFromFirstBlock = (values) => {
    const countryCodeIdVal = values.country_id;
    setFirstAndLastName({
      firstName: values.first_name,
      lastName: values.last_name,
    });
    setFormDataFirstBlock({
      values,
    });
    setCountryCodeId({
      countryCodeIdVal,
    });
  };

  const getSecondBlockValues = (inviteJoin, userLocations, secondBlockCheck) => {
    // to determine to open card application form
    setInviteJoin(inviteJoin);

    setSubmitTriggered(true);
    const tempDataSecondBlock = {};
    tempDataSecondBlock.userLocations = userLocations;
    tempDataSecondBlock.secondBlockCheck = secondBlockCheck;
    setFormDataSecondBlock(tempDataSecondBlock);
  };

  const getCardFormValues = (cardFormValues, inviteJoin) => {
    setInviteJoin(inviteJoin);
    setSubmitTriggered(true);
    setFormCardApplication(cardFormValues);
  };

  // mapping all field in form into request body
  useEffect(() => {
    const tempLocations = [];
    const tempClients = [];
    let reqBody = {};
    if (formDataFirstBlock.values && formDataSecondBlock.secondBlockCheck) {
      if (formDataSecondBlock.userLocations) {
        formDataSecondBlock.userLocations.forEach((data) => {
          const resultObj = {};
          resultObj.id = data.id;
          resultObj.is_active = data.active;
          if (data.type.toUpperCase() === userLocationType.LOCATION) {
            tempLocations.push(resultObj);
          } else {
            tempClients.push(resultObj);
          }
        });
      }

      const firstForm = formDataFirstBlock.values;
      reqBody = {
        // first block form
        user_number: firstForm.user_number,
        first_name: firstForm.first_name,
        middle_name: firstForm.middle_name,
        last_name: firstForm.last_name,
        email: firstForm.email,
        mobile_number: firstForm.mobile_number,
        user_type_id: firstForm.user_type_id,
        company_id: firstForm.company_id || null,
        country_id: firstForm.country_id || null,
        default_location_id: firstForm.default_location_id || null,
        default_timezone: firstForm.default_timezone,
        // 2nd block form
        location_delegation: tempLocations,
        user_delegation: tempClients,
        is_overall_administrator:
          formDataSecondBlock.secondBlockCheck.overallAdministrator,
        // card application form
        address_1: formCardApplication.addressLine1?.value || '',
        address_2: formCardApplication.addressLine2?.value || '',
        suburb: formCardApplication.suburbTown?.value || '',
        postcode: formCardApplication.postCode?.value.toString() || '',
        state_id: formCardApplication.state?.value || null,
        date_of_birth: formCardApplication.dateOfBirth
          ? moment(formCardApplication.dateOfBirth.value).format('YYYY-MM-DD')
          : '',
        card_type_id: formCardApplication.cardType?.value || null,
        security_answer: formCardApplication.securityAnswer?.value || '',
        security_question_id: formCardApplication.securityQuestion?.value || null,
        pds_reference: formCardApplication.pdsAcceptanceReference?.value || '',
      };

      // eslint-disable-next-line no-inner-declarations
      async function addUserSubmit() {
        try {
          delete firstForm.id;
          const response = await postUserData(props.token, reqBody);
          if (response.status === 200) {
            await undoFilledForm();
            setOpen(false);
            setOpenSuccess(true);
            setFirstAndLastName({
              firstName: firstForm.first_name,
              lastName: firstForm.last_name,
            });
          } else {
            throw new Error(response.data.message);
          }
        } catch (error) {
          await undoFilledForm();
          setErrorMessage({
            isError: true,
            message: error?.response?.data?.message || error?.message,
          });
        }
      }

      if (!isInviteJoin && submitTriggered) {
        addUserSubmit();
      }

      if (isInviteJoin) {
        setIsDisableSaveButton(false);
      }
    }
  }, [
    formDataSecondBlock,
    formDataFirstBlock,
    formCardApplication,
    isInviteJoin,
    submitTriggered,
    props,
  ]);

  const undoFilledForm = () => {
    setSubmitTriggered(false);
    setInviteJoin(false);
    setFormCardApplication({});
    setFormDataSecondBlock({});
    setFormDataFirstBlock({});
    setRootIsFormValid(false);
  };

  const handleErrorRetrieveData = (errorMessage) => {
    setErrorMessage({
      isError: true,
      message: errorMessage,
    });
  };

  const handleCloseErrorMessage = () => {
    setErrorMessage({
      isError: false,
      message: '',
    });
    setIsDisableSaveButton(false);
  };

  const handleDisableButtonSave = () => {
    setIsDisableSaveButton(true);
  };

  const handleClose = () => {
    setInviteJoin(false);
    setOpen(false);
    setOpenSuccess(false);
    setDefaultLocation({});
    setFirstAndLastName({});
    setFormDataFirstBlock({});
    setIsDisableSaveButton(false);
  };

  const handleExitDialog = () => {
    if (isInviteJoin) {
      setShowCancelConfirmation(true);
    } else {
      handleClose();
    }
  };

  const getSelectedUserType = (val) => {
    setUserType(val);
  };

  const getRootIsFormValid = (val) => {
    setRootIsFormValid(val);
  };

  const getDefaultLocation = (val) => {
    setDefaultLocation(val);
  };

  const getSelectedCompanyId = (val) => {
    setSelectedCompanyId(val);
  };

  const successMessage = () => {
    const fullName = `${firstAndLastName.firstName} ${firstAndLastName.lastName}`;
    if (props.cardTypes.length > 0) {
      if (selectedUserType?.user_type_access_rights?.allow_mobile_access) {
        return `${fullName} ${customization.successMessageDialog.addUserMessageWithCardType}`;
      }
    }

    return `${fullName} ${customization.successMessageDialog.addUserAdministratorReviewMessage}`;
  };

  return (
    <div>
      <LoadingBackdrop status={addUserLoading} />
      <SuccessMessageDialog
        openSuccess={openSuccess}
        bindHandleClose={handleClose}
        className={classes.roundedButton}
        message={successMessage()}
      />
      <ErrorMessageDialog
        bindHandleClose={handleCloseErrorMessage}
        openSuccess={errorMessage.isError}
        errorData={errorMessage.message}
      />
      <Button
        className={classes.button}
        color="primary"
        disableElevation
        onClick={handleClickOpen}
        startIcon={<AddPersonIcon />}
        size="large"
        variant="contained"
      >
        {customization.userAddEditDialog.addNewUser}
      </Button>
      <Dialog fullWidth maxWidth="lg" open={open} aria-labelledby="form-dialog-title">
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h6" className={classes.addUserDialogStyle}>
                {isInviteJoin ? (
                  <b>
                    {customization.userAddEditDialog.cardApplicationTitle} (
                    {firstAndLastName.firstName} {firstAndLastName.lastName})
                  </b>
                ) : (
                  customization.userAddEditDialog.addTitle
                )}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleExitDialog}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
        <DialogContent>
          {!isInviteJoin ? (
            <>
              <FormRootUser
                operationTag={userOperationTag.ADD_USER}
                bindErrorRetrieve={handleErrorRetrieveData}
                bindSubmit={getDataFromFirstBlock}
                bindClose={handleClose}
                setUserType={getSelectedUserType}
                setIsFormValid={getRootIsFormValid}
                setDefaultLocation={getDefaultLocation}
                setCompanyId={getSelectedCompanyId}
                responseCompanies={responseCompaniesList}
                responseUserTypes={responseUserTypesList}
                responseCountries={responseCountriesList}
                responseTimeZones={responseTimeZonesList}
              />{' '}
              <hr></hr>
              <SecondBlock
                selectedUserType={selectedUserType}
                bindClose={handleClose}
                rootIsFormValid={rootIsFormValid}
                defaultLocation={selectedDefaultLocation}
                submitCreate={getSecondBlockValues}
                selectedCompanyId={selectedCompanyId}
                bindHandleDisableButtonSave={handleDisableButtonSave}
                isDisableSaveButton={isDisableSaveButton}
              ></SecondBlock>{' '}
            </>
          ) : (
            <CardApplication
              selectedUserType={selectedUserType}
              bindClose={handleClose}
              submitCreate={getCardFormValues}
              showCancelConfirmation={showCancelConfirmation}
              setCancelConfirmation={handleExitDialog}
              countryCodeId={countryCodeId}
              bindHandleDisableButtonSave={handleDisableButtonSave}
              isDisableSaveButton={isDisableSaveButton}
            ></CardApplication>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

AddUserDialog.propTypes = {
  classes: PropTypes.object,
  postUserData: PropTypes.func,
  token: PropTypes.string,
  cardTypes: PropTypes.array,
  userAction: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
    cardTypes: state.user.cardTypes,
  };
};

const withConnect = connect(mapStateToProps);
export default compose(withStyles(addUserDialogStyle), withConnect)(AddUserDialog);
