export const addUserDialogStyle = (theme) => ({
  button: {
    fontSize: '1rem',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  containerFormSection: {
    paddingBottom: 15,
  },
  roundedButton: {
    borderRadius: '8px',
  },
});
