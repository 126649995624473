import { useState } from 'react';
import ForgotPasswordLayout from '@components/layout/loginLayout';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ForgotPasswordButton from '@components/login/loginButton';
import { customization } from '@customization/default';
import { adminClearState } from '@redux';
import { adminForgotPassword } from '@services/api/auth';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { apiRequestStatus } from '@data/constants';
import history from '@utils/history';
import { forgotPasswordPageStyle } from './style';

function ForgotPasswordPage(props) {
  const { classes, adminClearState, adminRequestError } = props;

  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [errorEmailValidation, setErrorEmailValidation] = useState(null);

  const ACCOUNT_FULLY_LOCKED = /locked/i.test(adminRequestError?.message);

  const emailValidation = (value) => {
    const regex =
      /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/g;
    return regex.test(value);
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      handleCheckEmail(event);
    }
  };

  const handleCheckEmail = async (event) => {
    event.preventDefault();
    if (!emailValidation(email) || !email) {
      setErrorEmailValidation(customization.login.emailValidationErrorMessage);
      await adminClearState();
      return;
    }

    setStatus(apiRequestStatus.PENDING);

    try {
      const response = await adminForgotPassword({ email });
      if (response.status === 200) {
        setErrorEmailValidation(false);
        setStatus(apiRequestStatus.RESOLVED);
        await adminClearState();
      }
    } catch (error) {
      const forgotPasswordLocked = /locked/i.test(error?.response?.data?.message);
      setErrorEmailValidation(customization.forgotPassword.internalServerError);

      if (forgotPasswordLocked) {
        setErrorEmailValidation(customization.login.invalidCredentials.lockedAccount);
      }

      await adminClearState();
      setStatus(apiRequestStatus.REJECTED);
    }
  };

  return (
    <ForgotPasswordLayout>
      <Box className={classes.titleContainer}>
        <IconButton
          title="Return to Login"
          aria-label="Return to Login"
          onClick={() => history.push('/login')}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" component="div" className={classes.header}>
          {customization.forgotPassword.introduction}
          <Typography variant="subtitle1" className={classes.subtitle}>
            {customization.forgotPassword.introductionDescription}
          </Typography>
        </Typography>
      </Box>

      <Box className={classes.formContainer}>
        <form className={classes.form}>
          <TextField
            variant="outlined"
            label={customization.login.emailLabel}
            name="email"
            required
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorEmailValidation(null);
            }}
            onKeyPress={handleKeyDown}
            value={email}
            error={!!errorEmailValidation}
            disabled={ACCOUNT_FULLY_LOCKED}
            helperText={
              errorEmailValidation ||
              (status === apiRequestStatus.RESOLVED &&
                customization.forgotPassword.successResponseMessage)
            }
          ></TextField>

          <ForgotPasswordButton
            disabled={
              !email || ACCOUNT_FULLY_LOCKED || status === apiRequestStatus.PENDING
            }
            type="submit"
            onClick={handleCheckEmail}
          >
            {customization.forgotPassword.buttonSendEmailLink}
          </ForgotPasswordButton>
        </form>
      </Box>
    </ForgotPasswordLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    adminRequestError: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminClearState: bindActionCreators(adminClearState, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withStyles(forgotPasswordPageStyle),
  withConnect,
)(ForgotPasswordPage);
