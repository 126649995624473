import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  loginTitle: {
    color: (props) =>
      props.color === 'error' ? theme.palette.error.main : theme.palette.grey[600],
    fontSize: '1rem',
    position: 'relative',
    overflow: 'auto',

    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '0.875rem !important',
    },
  },
}));
