const CardTypes = {
  GET_CARD_LIST_REQUEST: 'GET_CARD_LIST_REQUEST',
  GET_CARD_LIST_SUCCESS: 'GET_CARD_LIST_SUCCESS',
  GET_CARD_LIST_ERROR: 'GET_CARD_LIST_ERROR',

  GET_LINKED_CARD_REQUEST: 'GET_LINKED_CARD_REQUEST',
  GET_LINKED_CARD_SUCCESS: 'GET_LINKED_CARD_SUCCESS',
  GET_LINKED_CARD_ERROR: 'GET_LINKED_CARD_ERROR',

  GET_CARD_DETAIL_REQUEST: 'GET_CARD_DETAIL_REQUEST',
  GET_CARD_DETAIL_SUCCESS: 'GET_CARD_DETAIL_SUCCESS',
  GET_CARD_DETAIL_ERROR: 'GET_CARD_DETAIL_ERROR',

  CLEAR_ERROR_STATE: 'CLEAR_ERROR_STATE',
  CLEAR_STATE: 'CLEAR_STATE',
  CLEAR_LINKED_CARD_TRIGGERED: 'CLEAR_LINKED_CARD_TRIGGERED',
};

export default CardTypes;
