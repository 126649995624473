export const formCardApplicationStyle = (theme) => ({
  tableContainer: {
    width: '40%',
    borderRadius: '3px',
  },
  createButton: {
    float: 'right',
    width: '100%',
  },
  cancelButton: {
    backgroundColor: '#F7F7F7',
    float: 'left',
    width: '100%',
  },
  edit: {
    height: '24px',
    backgroundColor: '#F7F7F7',
    width: '70%',
    color: '#0000008A',
  },
  closeButton: {
    width: '11%',
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#F7F7F7',
  },
  add: {
    margin: theme.spacing(3, 0, 2),
    height: '24px',
    width: '70%',
  },
  boxStyle: {
    borderBottom: '2px solid #F2F2F2',
    textAlign: 'center',
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: '220px',
    width: '100%',
  },
  successIcon: {
    color: 'green',
    fontSize: '120px',
  },
  successMessage: {
    color: 'green',
  },
  tableCellPadding: {
    padding: '12px 30px 12px 12px !important',
  },
  tableHeaderCellPadding: {
    padding: '1px 30px 12px 12px',
  },
  tableTitleCellStyle: {
    padding: '12px 12px 12px 12px',
    border: '0px',
  },
  boxSectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  inputSelectStyle: {
    fontSize: 14,
  },
  formControlLabel: {
    marginLeft: '10px',
  },
  topSectionContainerStyle: {
    minHeight: '253px',
  },
  bottomSectionContainerStyle: {
    minHeight: '350px',
  },
  tableBorder: {
    border: '1px solid #cfcfcf',
  },
  menuitem: {
    direction: 'rtl',
  },
  menuitemhidden: {
    display: 'none',
  },
  placeholder: {
    color: '#aaa',
  },
  separator: {
    width: '96%',
  },
  topMessageContainer: {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20% !important',
    paddingRight: '10% !important',
    border: '1px solid #3F85CA',
    backgroundColor: '#EEF5FB',
  },
  topMessageCaption: {
    fontSize: '14px',
    color: '#5A99D3',
  },
  confirmProceedStyle: {
    color: 'white',
    width: '85%',
    float: 'right',
    margin: theme.spacing(3, 0, 2),
  },
  paperConfirmationMessageStyle: {
    border: '2px solid #EE794F',
  },
  confirmationMsgContainerStyle: {
    textAlign: 'center',
  },
  fontColorMsgDialogStyle: {
    color: '#EE794F',
  },
  cancelDialogButton: {
    width: '85%',
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#F7F7F7',
  },
  alertIconStyle: {
    maxHeight: '100px',
    marginBottom: '24px',
  },
  formLabel: {
    fontSize: '11px',
    fontWeight: 'bold',
    color: '#555555',
  },
});

export const checkboxStyle = (theme) => ({
  root: {
    color: theme.palette.secondary.main,
    width: '22px',
    height: '22px',
    borderRadius: '3px',
    '&$checked': {
      color: 'white',
      backgroundColor: theme.palette.secondary.main,
    },
  },
  checked: {},
});

export const bootStrapInput = (theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
});

export const dialogContentStyle = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
});

export const dialogActionStyle = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
});
