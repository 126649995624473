import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';

import FirstLoginPage from '@containers/firstLoginPage';
import { apiRequestStatus } from '@data/constants';
import { linkTransformation } from '@utils/transformation';
import { adminVerifyTokenSetNewPassword } from '@redux/auth/authActions';
import history from '@utils/history';

function NewUserQnAPage(props) {
  const { adminVerifyTokenSetNewPassword, adminRequestStatus, match } = props;

  const [emailAddress, setEmailAddress] = useState('');

  useEffect(() => {
    const sendGenerateKey = async () => {
      try {
        const { generateKey } = match.params;
        const transformedLink = linkTransformation(generateKey);
        setEmailAddress(transformedLink.email);
        await adminVerifyTokenSetNewPassword(transformedLink);
      } catch (error) {
        return history.push('/login');
      }
    };

    sendGenerateKey();
  }, [adminVerifyTokenSetNewPassword, match.params]);

  return (
    <>
      {adminRequestStatus !== apiRequestStatus.PENDING ? (
        <FirstLoginPage userEmail={emailAddress} />
      ) : (
        <LinearProgress />
      )}
    </>
  );
}

NewUserQnAPage.propTypes = {
  match: PropTypes.object,
  adminRequestStatus: PropTypes.string,
  adminVerifyTokenSetNewPassword: PropTypes.func,
  adminRequestError: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    adminRequestError: state.auth.error,
    adminRequestStatus: state.auth.loading,
    token: state.auth.inMemoryToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminVerifyTokenSetNewPassword: (params) =>
      dispatch(adminVerifyTokenSetNewPassword(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUserQnAPage);
