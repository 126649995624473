const TransferFundsTypes = {
  TRANSFER_FUND_REQUEST: 'TRANSFER_FUND_REQUEST',
  TRANSFER_FUND_SUCCESS: 'TRANSFER_FUND_SUCCESS',
  TRANSFER_FUND_ERROR: 'TRANSFER_FUND_ERROR',

  RESET_ERROR_STATE: 'RESET_ERROR_STATE',
  RESET_STATE: 'RESET_STATE',
};

export default TransferFundsTypes;
