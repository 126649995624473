import { apiRequestStatus } from '@data/constants';
import locationTypes from './locationTypes';

const initialState = {
  loading: '',
  locationList: [],
  error: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET LOCATION==============================================================================
    case locationTypes.GET_LOCATION_LIST_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case locationTypes.GET_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        locationList: action.payload,
        error: {},
      };
    case locationTypes.GET_LOCATION_LIST_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    case locationTypes.CLEAR_ERROR_STATE:
      return {
        ...state,
        error: {},
      };
    case locationTypes.CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
};

export default userReducer;
