export const forgotPasswordPageStyle = (theme) => ({
  header: {
    fontWeight: 800,
    color: theme.palette.grey[900],

    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      flex: 0.45,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },

    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '1.75rem !important',
    },

    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '3rem',
    },
  },
  subtitle: {
    marginTop: '0.5em',
    fontSize: '1rem',
    lineHeight: 1.5,

    [theme.breakpoints.down('sm')]: {
      marginTop: '0.15em',
    },

    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '0.875rem !important',
    },

    [theme.breakpoints.between('sm', 'lg')]: {
      marginTop: '0.25em',
      fontSize: '1.25rem',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',

    '& > :not(:last-child)': {
      margin: '0.5em 0',
    },
  },
  helperTextError: {
    color: theme.palette.error.main,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'baseline',

    '& .MuiIconButton-root': {
      color: theme.palette.grey[900],
    },
  },
});
