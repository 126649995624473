import React from 'react';
import ReactDOM from 'react-dom';

import { ConnectedRouter } from 'connected-react-router';
import { Provider as StateProvider } from 'react-redux';
import { Provider as MonitorProvider } from '@rollbar/react';

import configureStore from '@redux/configureStore';
import history from '@utils/history';
import App from './App';

const store = configureStore(window.__PRELOADED_STATE__);

const rollbarConfig = {
  environment: process.env.RAZZLE_APM_ENVIRONMENT || '',
  accessToken: process.env.RAZZLE_APM_CLIENT_ACCESS_TOKEN || '',
};

module.hot
  ? ReactDOM.render(
      <MonitorProvider config={rollbarConfig}>
        <StateProvider store={store}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </StateProvider>
      </MonitorProvider>,
      document.getElementById('root'),
    )
  : ReactDOM.hydrate(
      <MonitorProvider config={rollbarConfig}>
        <StateProvider store={store}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </StateProvider>
      </MonitorProvider>,
      document.getElementById('root'),
      () => {
        const jssStyles = document.getElementById('jss-ssr');
        if (jssStyles && jssStyles.parentNode)
          jssStyles.parentNode.removeChild(jssStyles);
      },
    );
