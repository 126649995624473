import { createTheme, responsiveFontSizes } from '@material-ui/core';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2400,
    },
  },
  palette: {
    primary: {
      light: '#F8C3D7',
      main: '#EB4E88',
      dark: '#89103D',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#BDE5FF',
      main: '#0090EB',
      dark: '#015F9A',
      contrastText: '#FFFFFF',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    button: {
      textTransform: 'none',
    },
    fontSize: 14,
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: 'white',
      },
    },
    MuiTablePagination: {
      caption: {
        '&:last-of-type': {
          display: 'none',
        },
      },
      selectRoot: {
        marginLeft: 8,
        marginRight: 8,
      },
    },
  },
});

export default responsiveFontSizes(theme);
