import React from 'react';
import _ from 'lodash';
import {
  FormControl,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
  withStyles,
  Button,
  InputBase,
} from '@material-ui/core';
import FormTextInput from '@components/formTextInput';
import PropTypes from 'prop-types';
import BaseComponent from '@containers/BaseComponent';
import { compose } from 'redux';
import { connect } from 'react-redux';
import TimezoneSelect from 'react-timezone-select';
import { getCompanyList as getCompanyListService } from '@services/api/userApi';
import { apiRequestStatus } from '@data/constants';
import { customization } from '@customization/default';
import {
  addEditLocationTransformation,
  transformLocationGroup,
  transformEditLocation,
} from '@utils/transformation';
import { getLocationGroupList, getTimezone } from '@services/api/locationApi';
import { formRootUserStyle, bootStrapInput } from './style';

const BootStrapInput = withStyles(bootStrapInput)((props) => <InputBase {...props} />);
class FormRootLocation extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      location: {
        id: {
          validation: {
            required: false,
          },
          value: '',
          valid: true,
          isValidFormat: true,
          touched: false,
        },
        status: {
          validation: {
            required: true,
          },
          value: customization.userRootForm.statusActive,
          valid: true,
          isValidFormat: true,
          touched: false,
        },
        company: {
          validation: {
            required: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        code: {
          validation: {
            required: true,
            escapedString: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        locationDescription: {
          validation: {
            required: true,
            escapedString: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        locationTimezone: {
          validation: {
            required: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
      },
      isFormValid: false,
      userMasterData: {
        companies: [],
        timezones: [],
      },
      componentProperties: [],
      serverErrorMessage: null,
      submitErrorMessage: null,
      confirmationStatus: false,
      openConfirmationStatus: false,
      loadingStatusLocationGroup: apiRequestStatus.IDLE,
      loadingStatusPopulateData: apiRequestStatus.IDLE,
    };
  }

  fetchUserMasterData = async () => {
    this.setState({
      loadingStatusPopulateData: apiRequestStatus.PENDING,
    });
    try {
      const [responseCompanies, responseTimezone] = await Promise.all([
        getCompanyListService(this.props.token),
        getTimezone(this.props.token),
      ]);
      this.setState(
        (state) => ({
          ...state,
          userMasterData: {
            companies: responseCompanies?.data || [],
            timezones: responseTimezone?.data || [],
          },
          loadingStatusPopulateData: apiRequestStatus.RESOLVED,
        }),
        async () => {
          if (this.state.userMasterData.companies.length === 1) {
            this.setState(
              (state) => ({
                ...state,
                location: {
                  ...state.location,
                  company: {
                    validation: {
                      required: false,
                    },
                    value: state.userMasterData.companies[0].company_name,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                  },
                },
              }),
              async () => {
                if (this.props.operationTag === 'addLocation') {
                  this.setState({
                    loadingStatusLocationGroup: apiRequestStatus.PENDING,
                  });
                  try {
                    const selectedCompany = this.state.userMasterData.companies.filter(
                      (data) => data.company_name === this.state.location.company.value,
                    )[0];

                    const response = await getLocationGroupList(
                      selectedCompany?.id,
                      this.props.token,
                    );

                    const transformedLocationGroup = transformLocationGroup(
                      response?.data,
                    );
                    const updatedLocationState = this.state.location;
                    this.state.location = {
                      ...updatedLocationState,
                      ...transformedLocationGroup.locationState,
                    };
                    this.state.componentProperties =
                      transformedLocationGroup.componentPropertiesData;
                    this.setState({
                      location: this.state.location,
                      componentProperties: this.state.componentProperties,
                      loadingStatusLocationGroup: apiRequestStatus.RESOLVED,
                    });
                  } catch (error) {
                    const FAILED_RETRIEVE_LOCATION_GROUP =
                      /Error in retrieving Location-Group data/i.test(
                        error?.response?.data?.message,
                      );
                    if (FAILED_RETRIEVE_LOCATION_GROUP) {
                      this.setState({
                        loadingStatusLocationGroup: apiRequestStatus.REJECTED,
                      });
                      return;
                    }
                    this.setState({
                      loadingStatusLocationGroup: apiRequestStatus.REJECTED,
                    });
                    this.props.bindErrorRetrieve(
                      customization.forgotPassword.internalServerError,
                    );
                  }
                }
              },
            );
          }
          if (this.props.locationData?.id && this.props.operationTag === 'editLocation') {
            this.setState({
              loadingStatusPopulateData: apiRequestStatus.PENDING,
              loadingStatusLocationGroup: apiRequestStatus.PENDING,
            });
            try {
              const selectedCompany = this.state.userMasterData.companies.filter(
                (data) => data.id === this.props.locationData.company_fk,
              )[0];

              const response = await getLocationGroupList(
                selectedCompany?.id,
                this.props.token,
              );
              const populatedValue = transformEditLocation(
                response?.data,
                this.props.locationData,
                this.state.location,
                selectedCompany.company_name,
              );
              this.setState({
                location: populatedValue.populatedLocationState,
                componentProperties: populatedValue.populatedComponentProperties,
                isFormValid: this.checkValidityRequired(
                  populatedValue.populatedLocationState,
                ),
                loadingStatusPopulateData: apiRequestStatus.RESOLVED,
                loadingStatusLocationGroup: apiRequestStatus.RESOLVED,
              });
            } catch (error) {
              this.props.bindErrorRetrieve(
                customization.forgotPassword.internalServerError,
              );
            }
          }
        },
      );
    } catch (error) {
      this.setState({
        loadingStatusPopulateData: apiRequestStatus.REJECTED,
        loadingStatusLocationGroup: apiRequestStatus.REJECTED,
      });
      this.props.bindErrorRetrieve(customization.forgotPassword.internalServerError);
    }
  };

  handleSaveAndClose = () => {
    const transformedLocation = addEditLocationTransformation(
      this.state.location,
      this.state.userMasterData,
      this.props.operationTag,
    );
    this.props.bindSubmit(transformedLocation, this.props.token);
  };

  handleCancel = () => {
    this.props.bindClose();
  };

  handleOpenConfirmationStatus = () => {
    this.setState({
      openConfirmationStatus: true,
    });
  };

  onChangeInput = (eventValue, inputIdentifier) => {
    const updateState = this.changeInputWithValidation(
      eventValue,
      this.state.location,
      inputIdentifier,
    );

    this.setState(
      {
        location: updateState.form,
        isFormValid: updateState.valid,
      },
      async () => {
        if (inputIdentifier === 'company') {
          try {
            this.setState({
              loadingStatusLocationGroup: apiRequestStatus.PENDING,
            });

            const selectedCompany = this.state.userMasterData.companies.filter(
              (data) => data.company_name === eventValue,
            )[0];

            const response = await getLocationGroupList(
              selectedCompany?.id,
              this.props.token,
            );

            const transformedLocationGroup = transformLocationGroup(response?.data);
            let updatedLocationState = this.state.location;
            if (this.state.componentProperties.length !== 0) {
              const updatedComponentProperties = [];
              for (const element of this.state.componentProperties) {
                updatedComponentProperties.push(element.locationProperties);
              }
              updatedLocationState = Object.keys(updatedLocationState)
                .filter((key) => !updatedComponentProperties.includes(key))
                .reduce((obj, key) => {
                  obj[key] = updatedLocationState[key];
                  return obj;
                }, {});
            }
            this.state.location = {
              ...updatedLocationState,
              ...transformedLocationGroup.locationState,
            };
            this.state.componentProperties =
              transformedLocationGroup.componentPropertiesData;
            this.setState({
              location: this.state.location,
              componentProperties: this.state.componentProperties,
              loadingStatusLocationGroup: apiRequestStatus.RESOLVED,
            });
          } catch (error) {
            const FAILED_RETRIEVE_LOCATION_GROUP =
              /Error in retrieving Location-Group data/i.test(
                error?.response?.data?.message,
              );
            if (FAILED_RETRIEVE_LOCATION_GROUP) {
              this.setState({
                loadingStatusLocationGroup: apiRequestStatus.REJECTED,
              });
              return;
            }
            this.setState({
              loadingStatusLocationGroup: apiRequestStatus.REJECTED,
            });
            this.props.bindErrorRetrieve(
              customization.forgotPassword.internalServerError,
            );
          }
        }
      },
    );
  };

  componentDidMount() {
    this.fetchUserMasterData();
  }

  render() {
    const {
      location: { status, company, code, locationDescription, locationTimezone },
      userMasterData,
      isFormValid,
      componentProperties,
      loadingStatusLocationGroup,
      loadingStatusPopulateData,
    } = this.state;
    const { classes, operationTag } = this.props;
    return (
      <div>
        <form>
          <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={1}>
            <Grid
              id="section-1"
              container
              spacing={2}
              className={classes.containerFormSection}
            >
              {loadingStatusPopulateData !== apiRequestStatus.PENDING ? (
                <Grid id="select-status" item xs={12} sm={12} md={2}>
                  <FormControl className={classes.formControl}>
                    <div>
                      <label className={classes.formLabel}>
                        {customization.userRootForm.statusLabel}
                      </label>
                      <span>{customization.userRootForm.requiredMark}</span>
                    </div>
                    <Select
                      id="status"
                      value={status.value}
                      onChange={(e) => {
                        this.onChangeInput(e.target.value, 'status');
                      }}
                      className={classes.formSelect}
                      input={<BootStrapInput />}
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Inactive">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={2} sm={2} md={2}>
                  <CircularProgress color="primary" />
                </Grid>
              )}
              {loadingStatusPopulateData !== apiRequestStatus.PENDING ? (
                <Grid id="text-field-code" item xs={12} sm={12} md={3}>
                  <FormControl className={classes.formControlInput}>
                    <div>
                      <label className={classes.formLabel}>
                        {customization.addEditLocation.labelCode}
                      </label>
                      {code.validation.required && (
                        <span>{customization.userRootForm.requiredMark}</span>
                      )}
                    </div>
                    <FormTextInput
                      field="code"
                      isDisabled={operationTag === 'editLocation'}
                      touched={code.touched}
                      valid={code.valid}
                      value={code.value}
                      validFormat={code.isValidFormat}
                      onChangeInput={this.onChangeInput}
                      validationMessage={customization.requiredError}
                      errorMode={customization.userRootForm.invalidString}
                      placeholder={customization.addEditLocation.labelCode}
                      type="text"
                    />
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs sm md>
                  <CircularProgress color="primary" />
                </Grid>
              )}
              {loadingStatusPopulateData !== apiRequestStatus.PENDING ? (
                <Grid id="text-field-unique-description" item xs sm md>
                  <FormControl className={classes.formControlInput}>
                    <div>
                      <label className={classes.formLabel}>
                        {customization.addEditLocation.labelDescription}
                      </label>
                      {locationDescription.validation.required && (
                        <span>{customization.userRootForm.requiredMark}</span>
                      )}
                    </div>
                    <FormTextInput
                      field="locationDescription"
                      touched={locationDescription.touched}
                      valid={locationDescription.valid}
                      value={locationDescription.value}
                      validFormat={locationDescription.isValidFormat}
                      onChangeInput={this.onChangeInput}
                      validationMessage={customization.requiredError}
                      errorMode={customization.userRootForm.invalidString}
                      placeholder={customization.addEditLocation.labelDescription}
                      type="text"
                    />
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs sm md>
                  <CircularProgress color="primary" />
                </Grid>
              )}
            </Grid>
            <Grid
              id="section-2"
              container
              spacing={2}
              className={classes.containerFormSection}
            >
              {(userMasterData.companies.length > 1 ||
                operationTag === 'editLocation') && (
                <>
                  {loadingStatusPopulateData !== apiRequestStatus.PENDING ? (
                    <Grid id="select-company" item xs={12} sm={12} md={6} lg={6}>
                      <FormControl className={classes.formControl}>
                        <div>
                          <label className={classes.formLabel}>
                            {customization.userRootForm.companyLabel}
                          </label>
                          <span>{customization.userRootForm.requiredMark}</span>
                        </div>
                        <Select
                          id="company"
                          value={company.value}
                          disabled={operationTag === 'editLocation'}
                          onChange={(e) => {
                            this.onChangeInput(e.target.value, 'company');
                          }}
                          className={classes.formSelect}
                          input={<BootStrapInput />}
                        >
                          {!_.isEmpty(userMasterData.companies) &&
                            userMasterData.companies.map((company) => (
                              <MenuItem key={company.id} value={company.company_name}>
                                {company.company_name}
                              </MenuItem>
                            ))}
                        </Select>
                        <span
                          style={{
                            color: '#d32f2f',
                            display:
                              (company.touched && !company.valid) ||
                              (company.touched && !company.isValidFormat)
                                ? 'block'
                                : 'none',
                          }}
                        >
                          {!company.valid && customization.requiredError}
                        </span>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs sm md>
                      <CircularProgress color="primary" />
                    </Grid>
                  )}
                </>
              )}
              {loadingStatusPopulateData !== apiRequestStatus.PENDING ? (
                <Grid
                  id="select-timezone"
                  item
                  xs
                  sm={12}
                  md={userMasterData.companies.length > 1 ? 6 : 12}
                  lg={userMasterData.companies.length > 1 ? 6 : 12}
                >
                  <FormControl className={classes.formControl}>
                    <div>
                      <label className={classes.formLabel}>
                        {customization.userRootForm.defaultTimeZoneLabel}
                      </label>
                      {locationTimezone.validation.required && (
                        <span>{customization.userRootForm.requiredMark}</span>
                      )}
                    </div>
                    <TimezoneSelect
                      id="locationTimezone"
                      value={locationTimezone.value}
                      onChange={(e) => {
                        this.onChangeInput(e.value, 'locationTimezone');
                      }}
                    />
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={2} sm={2} md={2}>
                  <CircularProgress color="primary" />
                </Grid>
              )}
            </Grid>
            <Grid
              id="section-3"
              container
              spacing={2}
              className={classes.containerFormSection}
            >
              {loadingStatusLocationGroup !== apiRequestStatus.PENDING ? (
                !_.isEmpty(componentProperties) &&
                componentProperties.map((locationGroup) => (
                  <Grid key={locationGroup.id} item xs={6} sm={6} md={6}>
                    <FormControl className={classes.formControl}>
                      <div>
                        <label className={classes.formLabel}>
                          {locationGroup.displayName}
                        </label>
                        {locationGroup.validation.required && (
                          <span>{customization.userRootForm.requiredMark}</span>
                        )}
                      </div>
                      <FormTextInput
                        field={locationGroup.locationProperties}
                        isDisabled={operationTag === 'editLocation'}
                        touched={
                          this.state.location[locationGroup.locationProperties].touched
                        }
                        valid={
                          this.state.location[locationGroup.locationProperties].valid
                        }
                        value={
                          this.state.location[locationGroup.locationProperties].value
                        }
                        onChangeInput={this.onChangeInput}
                        validFormat={
                          this.state.location[locationGroup.locationProperties]
                            .isValidFormat
                        }
                        validationMessage={customization.requiredError}
                        errorMode={customization.userRootForm.invalidString}
                        placeholder={locationGroup.displayName}
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                ))
              ) : (
                <CircularProgress className={classes.loadingStatus} color="primary" />
              )}
            </Grid>
            <Grid
              id="section-4"
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.containerFormSection}
              spacing={1}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                className={classes.formButtonSaveAndClose}
              >
                <Button
                  disabled={
                    !isFormValid || loadingStatusPopulateData === apiRequestStatus.PENDING
                  }
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={this.handleSaveAndClose}
                >
                  {customization.userRootForm.saveAndCloseButton}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={2} className={classes.formButtonCancel}>
                <Button type="button" variant="outlined" onClick={this.handleCancel}>
                  {customization.userRootForm.cancelButton}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}
FormRootLocation.propTypes = {
  bindSubmit: PropTypes.func,
  bindClose: PropTypes.func,
  classes: PropTypes.object,
  locationData: PropTypes.object,
  bindErrorRetrieve: PropTypes.func,
  operationTag: PropTypes.string,
  bindOpenConfirmationStatus: PropTypes.func,
  confirmStatus: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
    userMasterData: state.user.userMasterData,
    errorResponse: state.user.error,
  };
};

const withConnect = connect(mapStateToProps);
export default compose(withStyles(formRootUserStyle), withConnect)(FormRootLocation);
