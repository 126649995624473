import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';

import { bootStrapInput, customInputFieldStyle } from './style';

const BootStrapInput = withStyles(bootStrapInput)((props) => <InputBase {...props} />);

function CustomizedInputSelect(props) {
  const { classes, name, label, options, value, ...restProps } = props;

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select value={value} displayEmpty input={<BootStrapInput />} {...restProps}>
        <MenuItem value="">
          <span className={classes.placeholder}>{label}</span>
        </MenuItem>
        {!_.isEmpty(options) &&
          options.map((data) => (
            <MenuItem
              key={data.id}
              value={data.value ? data.value : data.question}
              data-question-id={data.id}
              className={classes.itemStyle}
            >
              {data.question}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

CustomizedInputSelect.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string,
  value: PropTypes.string,
  useAsFilter: PropTypes.bool,
};

export default compose(withStyles(customInputFieldStyle))(CustomizedInputSelect);
