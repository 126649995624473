import { apiRequestStatus } from '@data/constants';
import userTypeTypes from './userTypeTypes';

const initialState = {
  loading: '',
  userTypeList: [],
  userType: {},
  error: {},
  notificationTypes: [],
};

const userTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    // USER TYPE LIST ==============================================================================
    case userTypeTypes.GET_USERTYPELIST_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypeTypes.GET_USERTYPELIST_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        userTypeList: action.payload,
        error: {},
      };
    case userTypeTypes.GET_USERTYPELIST_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        userTypeList: [],
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    // USER TYPE DETAIL ==============================================================================
    case userTypeTypes.GET_USERTYPE_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypeTypes.GET_USERTYPE_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        userType: action.payload,
        error: {},
      };
    case userTypeTypes.GET_USERTYPE_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        userType: {},
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    // NOTIFICATION TYPES ==============================================================================
    case userTypeTypes.GET_NOTIFICATIONTYPE_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypeTypes.GET_NOTIFICATIONTYPE_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        notificationTypes: action.payload,
        error: {},
      };
    case userTypeTypes.GET_NOTIFICATIONTYPE_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        notificationTypes: [],
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    // INSERT USER TYPE ==============================================================================
    case userTypeTypes.INSERT_USERTYPE_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypeTypes.INSERT_USERTYPE_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        userType: action.payload,
        error: {},
      };
    case userTypeTypes.INSERT_USERTYPE_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        userType: {},
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    // UPDATE USER TYPE ==============================================================================
    case userTypeTypes.UPDATE_USERTYPE_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypeTypes.UPDATE_USERTYPE_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        userType: action.payload,
        error: {},
      };
    case userTypeTypes.UPDATE_USERTYPE_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        userType: {},
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    case 'CLEAR_STATE':
      return initialState;

    default:
      return state;
  }
};

export default userTypeReducer;
