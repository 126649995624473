import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

function CustomizedNumberFormat({ inputRef, onChange, name, ...other }) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(value) => {
        onChange({ target: { name, value: value.value } });
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
      allowNegative={false}
    />
  );
}

CustomizedNumberFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomizedNumberFormat;
