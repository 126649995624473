import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    '& .ant-empty-img-1': {
      fill: '#aeb8c2',
    },
    '& .ant-empty-img-2': {
      fill: '#f5f5f7',
    },
    '& .ant-empty-img-3': {
      fill: '#dce0e6',
    },
    '& .ant-empty-img-4': {
      fill: '#fff',
    },
    '& .ant-empty-img-5': {
      fillOpacity: '0.8',
      fill: '#f5f5f5',
    },
  },
  label: {
    marginTop: theme.spacing(1),
    textAlign: 'center',

    '& .MuiTypography-h4': {
      fontWeight: 600,

      [theme.breakpoints.down('xs')]: {
        fontSize: '1.25rem',
      },
    },

    '& .MuiTypography-body1': {
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.875rem',
      },
    },
  },
}));
