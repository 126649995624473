import { apiRequestStatus } from '@data/constants';
import userTypes from './userTypes';

const initialState = {
  loading: '',
  userList: {},
  userMasterData: {},
  credentialQuestion: {},
  unlockPinMessage: {},
  notification: {},
  error: {},
  total: 0,
  transactionList: [],
  transactionAuditList: [],
  locationList: [],
  cardTypes: [],
  companyList: [],
  locationGroup: {},
  securityQuestions: [],
  stateList: [],
  userLocationDelegationList: [],
  timeZoneList: [],
  linkedUserTriggered: false,
  linkedUserList: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET USER ====================================================================================
    case userTypes.GET_USER_LIST_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        userList: action.payload,
        linkedUserTriggered: false,
        error: {},
      };
    case userTypes.GET_USER_LIST_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // GET USER BY DETAILS =========================================================================
    case userTypes.GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        error: {},
      };
    case userTypes.GET_USER_DETAILS_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // GET LINKED USER DATA ========================================================================
    case userTypes.GET_LINKED_USER_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.GET_LINKED_USER_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        linkedUserList: action.payload,
        linkedUserTriggered: true,
        error: {},
      };
    case userTypes.GET_LINKED_USER_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // RESET LINKED USER STATE =====================================================================
    case userTypes.CLEAR_LINKED_USER_TRIGGERED:
      return {
        ...state,
        linkedUserTriggered: false,
      };

    // GET USER MASTER DATA ========================================================================
    case userTypes.GET_USER_MASTER_DATA_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.GET_USER_MASTER_DATA_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        userMasterData: action.payload,
        error: {},
      };
    case userTypes.GET_USER_MASTER_DATA_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          message: action?.error?.message,
          statusCode: action?.error?.response?.status,
        },
      };

    // POST USER DATA ==============================================================================
    case userTypes.POST_USER_DATA_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.POST_USER_DATA_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // GET USER ACCESS CREDENTIAL ==================================================================
    case userTypes.GET_USER_ACCESS_CREDENTIAL_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.GET_USER_ACCESS_CREDENTIAL_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        credentialQuestion: action.payload,
        error: {},
      };
    case userTypes.GET_USER_ACCESS_CREDENTIAL_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };
    case userTypes.RESET_USER_ACCESS_CREDENTIAL:
      return {
        ...state,
        credentialQuestion: {},
      };

    // SEND USER UNLOCK PIN ========================================================================
    case userTypes.SEND_USER_UNLOCK_PIN_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.SEND_USER_UNLOCK_PIN_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        unlockPinMessage: action.payload,
        error: {},
      };
    case userTypes.SEND_USER_UNLOCK_PIN_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // GET TRANSACTION =============================================================================
    case userTypes.GET_TRANSACTION_LIST_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        total: 0,
        transactionList: [],
        error: {},
      };
    case userTypes.GET_TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        total: action.count,
        transactionList: action.result,
        error: {},
      };
    case userTypes.GET_TRANSACTION_LIST_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // GET TRANSACTION AUDIT =======================================================================
    case userTypes.GET_TRANSACTION_AUDIT_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        transactionAuditList: [],
        error: {},
      };
    case userTypes.GET_TRANSACTION_AUDIT_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        transactionAuditList: action.payload,
        error: {},
      };
    case userTypes.GET_TRANSACTION_AUDIT_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // GET COMPANY =================================================================================
    case userTypes.GET_COMPANY_LIST_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        companyList: [],
        error: {},
      };
    case userTypes.GET_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        companyList: action.payload,
        error: {},
      };
    case userTypes.GET_COMPANY_LIST_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // GET LOCATION GROUP ==========================================================================
    case userTypes.GET_LOCATION_GROUP_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        locationGroup: {},
        error: {},
      };
    case userTypes.GET_LOCATION_GROUP_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        locationGroup: action.payload,
        error: {},
      };
    case userTypes.GET_LOCATION_GROUP_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // PUT LOCATION GROUP ==========================================================================
    case userTypes.PUT_LOCATION_GROUP_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.PUT_LOCATION_GROUP_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        error: {},
      };
    case userTypes.PUT_LOCATION_GROUP_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // POST LOCATION GROUP =========================================================================
    case userTypes.POST_LOCATION_GROUP_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.POST_LOCATION_GROUP_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        error: {},
      };
    case userTypes.POST_LOCATION_GROUP_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // GET NOTIFICATIONS ===========================================================================
    case userTypes.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        notification: action.payload,
        error: {},
      };
    case userTypes.GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // GET LOCATION LIST ===========================================================================
    case userTypes.GET_LOCATION_LIST_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.GET_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        locationList: action.payload,
        error: {},
      };
    case userTypes.GET_LOCATION_LIST_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // GET CARD TYPE LIST ==========================================================================
    case userTypes.GET_CARDTYPE_BY_USERTYPEID_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.GET_CARDTYPE_BY_USERTYPEID_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        cardTypes: action.payload,
        error: {},
      };
    case userTypes.GET_CARDTYPE_BY_USERTYPEID_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        userType: {},
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // GET SECURITY QUESTION LIST ==================================================================
    case userTypes.GET_SECURITY_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.GET_SECURITY_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        securityQuestions: action.payload,
        error: {},
      };
    case userTypes.GET_SECURITY_QUESTIONS_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // GET STATE BY COUNTRY ========================================================================
    case userTypes.GET_STATE_BY_COUNTRY_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.GET_STATE_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        stateList: action.payload,
        error: {},
      };
    case userTypes.GET_STATE_BY_COUNTRY_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // GET USER LOCATION DELEGATION LIST ===========================================================
    case userTypes.GET_USER_LOCATION_DELEGATION_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.GET_USER_LOCATION_DELEGATION_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        userLocationDelegationList: action.payload,
        error: {},
      };
    case userTypes.GET_USER_LOCATION_DELEGATION_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // GET TIME ZONE LIST ==========================================================================
    case userTypes.GET_TIME_ZONE_LIST_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.GET_TIME_ZONE_LIST_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        timeZoneList: action.payload,
        error: {},
      };
    case userTypes.GET_TIME_ZONE_LIST_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // VERIFY ADMIN PASSWORD =======================================================================
    case userTypes.VERIFY_ADMIN_PASSWORD_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.VERIFY_ADMIN_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        error: {},
      };
    case userTypes.VERIFY_ADMIN_PASSWORD_ERROR:
      return {
        ...state,
        // Set to success to hide snackbar on global level
        loading: apiRequestStatus.RESOLVED,
        error: {},
      };

    // GET USER LOCATION ===========================================================================
    case userTypes.GET_USER_LOCATION_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.GET_USER_LOCATION_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        error: {},
      };
    case userTypes.GET_USER_LOCATION_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // RESEND SUBSCRIPTION TOKEN ===================================================================
    case userTypes.RESEND_SUBSCRIPTION_TOKEN_REQUEST:
      return {
        ...state,
        loading: apiRequestStatus.PENDING,
        error: {},
      };
    case userTypes.RESEND_SUBSCRIPTION_TOKEN_SUCCESS:
      return {
        ...state,
        loading: apiRequestStatus.RESOLVED,
        error: {},
      };
    case userTypes.RESEND_SUBSCRIPTION_TOKEN_ERROR:
      return {
        ...state,
        loading: apiRequestStatus.REJECTED,
        error: {
          ...action?.error?.response?.data,
          statusCode: action?.error?.response?.status,
        },
      };

    // RESET STATES ================================================================================
    case userTypes.CLEAR_ERROR_STATE:
      return {
        ...state,
        error: {},
      };
    case 'CLEAR_STATE':
      return initialState;

    default:
      return state;
  }
};

export default userReducer;
