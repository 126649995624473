import { IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPageRounded';
import LastPageIcon from '@material-ui/icons/LastPageRounded';
import PreviousPageIcon from '@material-ui/icons/NavigateBeforeRounded';
import NextPageIcon from '@material-ui/icons/NavigateNextRounded';
import PropTypes from 'prop-types';
import { useStyles } from './style';

function TablePaginationActions({ count, onPageChange, page, rowsPerPage }) {
  const classes = useStyles();

  const theme = useTheme();

  const handleFirstPage = (event) => {
    onPageChange(event, 0);
  };

  const handlePreviousPage = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextPage = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPage = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPage}
        disabled={page === 0}
        aria-label="Navigate to First Page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handlePreviousPage}
        disabled={page === 0}
        aria-label="Navigate to Previous Page"
      >
        {theme.direction === 'rtl' ? <NextPageIcon /> : <PreviousPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleNextPage}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Navigate to Next Page"
      >
        {theme.direction === 'rtl' ? <PreviousPageIcon /> : <NextPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleLastPage}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Navigate to Last Page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default TablePaginationActions;
