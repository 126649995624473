import React from 'react';
import BaseComponent from '@containers/BaseComponent';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CustomizedSnackBar from '@components/customizedSnackBar';
import InputBase from '@material-ui/core/InputBase';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@components/dialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormTextInput from '@components/formTextInput';
import FormDatePicker from '@components/formDatePicker';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import AlertOrangeRoundedIcon from '@assets/icons/Alert-orange-rounded.png';
import * as userActions from '@redux/user/userActions';
import { customization } from '@customization/default';
import {
  bootStrapInput,
  formCardApplicationStyle,
  dialogContentStyle,
  dialogActionStyle,
} from './style';

const BootStrapInput = withStyles(bootStrapInput)((props) => <InputBase {...props} />);
const DialogContent = withStyles(dialogContentStyle)(MuiDialogContent);
const DialogActions = withStyles(dialogActionStyle)(MuiDialogActions);

class FormUserCardApplication extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      cardApplicationForm: {
        addressLine1: {
          validation: {
            required: true,
            escapedString: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        addressLine2: {
          validation: {
            required: false,
            escapedString: true,
          },
          value: '',
          valid: true,
          isValidFormat: true,
          touched: false,
        },
        suburbTown: {
          validation: {
            required: true,
            escapedString: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        state: {
          validation: {
            required: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        postCode: {
          validation: {
            required: true,
            escapedString: true,
          },
          value: null,
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        dateOfBirth: {
          validation: {
            required: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        cardType: {
          validation: {
            required: true,
          },
          value: '0',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        securityQuestion: {
          validation: {
            required: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        securityAnswer: {
          validation: {
            required: true,
            escapedString: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        pdsAcceptanceReference: {
          validation: {
            required: false,
            escapedString: true,
          },
          value: '',
          valid: true,
          isValidFormat: true,
          touched: false,
        },
      },
      isFormValid: false,
      requestParam: {},
      showCancelDialog: false,
      cardTypesList: [],
    };
  }

  componentDidMount() {
    if (this.state.cardTypesList.length < 1) {
      this.setState({
        cardTypesList: this.props.cardTypes,
      });
    }
    this.getSecurityQuestionsList();
    this.getStateListByCountry();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showCancelConfirmation !== this.props.showCancelConfirmation) {
      if (this.props.showCancelConfirmation) {
        this.setState({
          showCancelDialog: true,
        });
      }
    }
  }

  handleSnackBar = () => {
    this.setState({ showSnackBar: false });
  };

  onChangeInput = (eventValue, inputIdentifier) => {
    const updateState = this.changeInputWithValidation(
      eventValue,
      this.state.cardApplicationForm,
      inputIdentifier,
    );
    this.setState({
      cardApplicationForm: updateState.form,
      isFormValid: updateState.valid,
    });
  };

  getSecurityQuestionsList = () => {
    this.props.userAction.getSecurityQuestionsList(this.props.token).then(() => {
      if (this.props.error.statusCode) {
        this.setState({
          showSnackBar: true,
          snackBarSeverity: 'error',
          snackBarMessage: `Get security question list error: ${this.props.error.statusCode}`,
          notFound: true,
        });
      }
    });
  };

  getStateListByCountry = () => {
    const { countryCodeId, token } = this.props;
    this.props.userAction
      .getStateByCountry(countryCodeId.countryCodeIdVal, token)
      .then(() => {
        if (this.props.error.statusCode) {
          this.setState({
            showSnackBar: true,
            snackBarSeverity: 'error',
            snackBarMessage: `Get state list error: ${this.props.error.statusCode}`,
            notFound: true,
          });
        }
      });
  };

  handleCloseCancelDialog = () => {
    this.props.setCancelConfirmation(false);
    this.setState({
      showCancelDialog: false,
    });
  };

  onShowConfirmation = () => {
    this.setState({
      showCancelDialog: true,
    });
  };

  handleToCloseAllDialog = async () => {
    const { bindClose } = this.props;
    bindClose();
  };

  cancelConfirmationDialog = () => {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          fullWidth
          maxWidth="xs"
          onClose={this.handleCloseCancelDialog}
          open={this.state.showCancelDialog}
          classes={{
            paper: classes.paperConfirmationMessageStyle,
          }}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.handleCloseCancelDialog}
          ></DialogTitle>
          <DialogContent>
            <Box p={2}>
              <div className={classes.confirmationMsgContainerStyle}>
                <img
                  src={AlertOrangeRoundedIcon}
                  alt="alertOrangeRoundedIcon"
                  loading="lazy"
                  className={classes.alertIconStyle}
                />
              </div>
              <div className={classes.confirmationMsgContainerStyle}>
                <Typography className={classes.fontColorMsgDialogStyle}>
                  <b>{customization.formCardApplication.cancelConfirmation}</b>
                </Typography>
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={6}>
                <Button
                  autoFocus
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className={classes.confirmProceedStyle}
                  onClick={this.handleToCloseAllDialog}
                >
                  {customization.formCardApplication.yesBtn}
                </Button>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <Button
                  className={classes.cancelDialogButton}
                  onClick={this.handleCloseCancelDialog}
                  variant="outlined"
                >
                  {customization.formCardApplication.cancelBtn}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  createSubmit = async () => {
    const { submitCreate } = this.props;

    await this.props.bindHandleDisableButtonSave();
    await submitCreate(this.state.cardApplicationForm, false);
  };

  render() {
    const { classes, securityQuestions, stateList } = this.props;
    const {
      showSnackBar,
      snackBarSeverity,
      snackBarMessage,
      cardApplicationForm,
      isFormValid,
      cardTypesList,
    } = this.state;

    return (
      <div>
        <CustomizedSnackBar
          showBar={showSnackBar}
          severity={snackBarSeverity}
          message={snackBarMessage}
          handleClose={this.handleSnackBar}
        ></CustomizedSnackBar>
        {this.cancelConfirmationDialog()}
        <Box p={3}>
          <div>
            <Paper className={classes.topMessageContainer} elevation={0}>
              <b className={classes.topMessageCaption}>
                {customization.formCardApplication.topMessage}
              </b>
            </Paper>
          </div>
        </Box>
        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <div>
                <b>{customization.formCardApplication.addressLine1Label}</b>
              </div>
              <FormTextInput
                field="addressLine1"
                value={cardApplicationForm.addressLine1.value}
                touched={cardApplicationForm.addressLine1.touched}
                valid={cardApplicationForm.addressLine1.valid}
                validFormat={cardApplicationForm.addressLine1.isValidFormat}
                onChangeInput={this.onChangeInput}
                length={100}
                validationMessage={customization.requiredError}
                errorMode={customization.userRootForm.invalidString}
                type="text"
                placeholder={customization.formCardApplication.addressLine1PlaceHolder}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div>
                <b>{customization.formCardApplication.addressLine2Label}</b>
              </div>
              <FormTextInput
                field="addressLine2"
                value={cardApplicationForm.addressLine2.value}
                touched={cardApplicationForm.addressLine2.touched}
                valid={cardApplicationForm.addressLine2.valid}
                validFormat={cardApplicationForm.addressLine2.isValidFormat}
                errorMode={customization.userRootForm.invalidString}
                onChangeInput={this.onChangeInput}
                length={100}
                type="text"
                placeholder={customization.formCardApplication.addressLine2PlaceHolder}
              />
            </Grid>
          </Grid>
        </Box>
        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <div>
                <b>{customization.formCardApplication.suburbtownLabel}</b>
              </div>
              <FormTextInput
                field="suburbTown"
                value={cardApplicationForm.suburbTown.value}
                touched={cardApplicationForm.suburbTown.touched}
                valid={cardApplicationForm.suburbTown.valid}
                validFormat={cardApplicationForm.suburbTown.isValidFormat}
                onChangeInput={this.onChangeInput}
                length={100}
                validationMessage={customization.requiredError}
                errorMode={customization.userRootForm.invalidString}
                type="text"
                placeholder={customization.formCardApplication.suburbtownPlaceHolder}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <div>
                <b>{customization.formCardApplication.stateLabel}</b>
              </div>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  id="state"
                  value={cardApplicationForm.state.value}
                  displayEmpty
                  onChange={(e) => {
                    this.onChangeInput(e.target.value, 'state');
                  }}
                  color="secondary"
                  input={<BootStrapInput />}
                >
                  <MenuItem disabled value="" key="0">
                    <span className={classes.placeholder}>
                      {customization.formCardApplication.statePlaceHolder}
                    </span>
                  </MenuItem>
                  {!_.isEmpty(stateList) &&
                    stateList.map((data, index) => (
                      <MenuItem value={data.id} key={index}>
                        {data.internal_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <div>
                <b>{customization.formCardApplication.postCodeLabel}</b>
              </div>
              <FormTextInput
                field="postCode"
                value={cardApplicationForm.postCode.value}
                touched={cardApplicationForm.postCode.touched}
                valid={cardApplicationForm.postCode.valid}
                validFormat={cardApplicationForm.postCode.isValidFormat}
                onChangeInput={this.onChangeInput}
                type="number"
                placeholder={customization.formCardApplication.postCodePlaceHolder}
              />
            </Grid>
          </Grid>
        </Box>
        <hr className={classes.separator}></hr>
        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={6} lg={6}>
              <div>
                <b>{customization.formCardApplication.dateOfBirthLabel}</b>
              </div>
              <FormDatePicker
                field="dateOfBirth"
                touched={cardApplicationForm.dateOfBirth.touched}
                valid={cardApplicationForm.dateOfBirth.valid}
                value={cardApplicationForm.dateOfBirth.value}
                validFormat={cardApplicationForm.dateOfBirth.isValidFormat}
                onChangeInput={this.onChangeInput}
                length={100}
                validationMessage={customization.requiredError}
                type="text"
                placeholder={customization.formCardApplication.dateOfBirthPlaceHolder}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <FormControl className={classes.formControl}>
                <div>
                  <label className={classes.formLabel}>
                    <b>{customization.formCardApplication.cardTypeLabel}</b>
                  </label>
                </div>
                <Select
                  id="cardType"
                  value={cardApplicationForm.cardType.value}
                  displayEmpty
                  onChange={(e) => {
                    this.onChangeInput(e.target.value, 'cardType');
                  }}
                  input={<BootStrapInput />}
                >
                  <MenuItem disabled value="0" key="0">
                    <span className={classes.placeholder}>
                      {customization.formCardApplication.cardTypePlaceholder}
                    </span>
                  </MenuItem>
                  {!_.isEmpty(cardTypesList) &&
                    cardTypesList.map((element) => (
                      <MenuItem key={element.card_types.id} value={element.card_types.id}>
                        <b>{element.card_types.internal_name}</b>
                      </MenuItem>
                    ))}
                </Select>
                <span
                  style={{
                    color: '#d32f2f',
                    display:
                      cardApplicationForm.cardType.touched &&
                      !cardApplicationForm.cardType.valid
                        ? 'block'
                        : 'none',
                  }}
                >
                  {!cardApplicationForm.cardType.valid && customization.requiredError}
                </span>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={6} lg={6}>
              <div>
                <b>{customization.formCardApplication.securityQuestionLabel}</b>
              </div>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  id="securityQuestion"
                  value={cardApplicationForm.securityQuestion.value}
                  displayEmpty
                  onChange={(e) => {
                    this.onChangeInput(e.target.value, 'securityQuestion');
                  }}
                  color="secondary"
                  input={<BootStrapInput />}
                >
                  <MenuItem disabled value="" key="0">
                    <span className={classes.placeholder}>
                      {customization.formCardApplication.securityQuestionPlaceHolder}
                    </span>
                  </MenuItem>
                  {!_.isEmpty(securityQuestions) &&
                    securityQuestions.map((data, index) => (
                      <MenuItem value={data.id} key={index}>
                        {data.question}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <div>
                <b>{customization.formCardApplication.securityAnswerLabel}</b>
              </div>
              <FormTextInput
                field="securityAnswer"
                touched={cardApplicationForm.securityAnswer.touched}
                valid={cardApplicationForm.securityAnswer.valid}
                value={cardApplicationForm.securityAnswer.value}
                validFormat={cardApplicationForm.securityAnswer.isValidFormat}
                onChangeInput={this.onChangeInput}
                length={100}
                validationMessage={customization.requiredError}
                errorMode={customization.userRootForm.invalidString}
                type="text"
                placeholder={customization.formCardApplication.securityAnswerPlaceHolder}
              />
            </Grid>
          </Grid>
        </Box>
        <Box p={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <div>
                <b>{customization.formCardApplication.pdsReferenceLabel}</b>
              </div>
              <FormTextInput
                field="pdsAcceptanceReference"
                touched={cardApplicationForm.pdsAcceptanceReference.touched}
                valid={cardApplicationForm.pdsAcceptanceReference.valid}
                value={cardApplicationForm.pdsAcceptanceReference.value}
                validFormat={cardApplicationForm.pdsAcceptanceReference.isValidFormat}
                onChangeInput={this.onChangeInput}
                length={100}
                validationMessage={customization.requiredError}
                errorMode={customization.userRootForm.invalidString}
                type="text"
                placeholder={customization.formCardApplication.pdsReferencePlaceHolder}
              />
            </Grid>
          </Grid>
        </Box>
        <Box p={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9} lg={9}></Grid>
            <Grid item xs={12} md={2} lg={2}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.createButton}
                disabled={!isFormValid || this.props.isDisableSaveButton}
                onClick={this.createSubmit}
              >
                {customization.formCardApplication.createUserBtn}
              </Button>
            </Grid>
            <Grid item xs={12} md={1} lg={1}>
              <Button
                className={classes.cancelButton}
                variant="outlined"
                onClick={this.onShowConfirmation}
              >
                {customization.formCardApplication.cancelBtn}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
}

FormUserCardApplication.propTypes = {
  classes: PropTypes.object,
  successMessageDialog: PropTypes.bool,
  selectedUserType: PropTypes.object,
  bindClose: PropTypes.func,
  submitCreate: PropTypes.func,
  showCancelConfirmation: PropTypes.bool,
  setCancelConfirmation: PropTypes.func,
  countryCodeId: PropTypes.number,
  bindHandleDisableButtonSave: PropTypes.func,
  isDisableSaveButton: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
    cardTypes: state.user.cardTypes,
    securityQuestions: state.user.securityQuestions,
    stateList: state.user.stateList,
    error: state.user.error,
    loading: state.user.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userAction: bindActionCreators(userActions, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withStyles(formCardApplicationStyle),
  withConnect,
)(FormUserCardApplication);
