export const loginMultiFactorAuthenticationStyle = (theme) => ({
  header: {
    fontWeight: 800,
    color: theme.palette.grey[900],

    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      flex: 0.45,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },

    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '1.75rem !important',
    },

    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '3rem',
    },
  },
  subtitle: {
    marginTop: '0.5em',
    fontSzie: '1rem',
    lineHeight: 1.5,

    [theme.breakpoints.down('sm')]: {
      marginTop: '0.15em',
    },

    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '0.875rem !important',
    },

    [theme.breakpoints.between('sm', 'lg')]: {
      marginTop: '0.25em',
      fontSize: '1.25rem',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.up('lg')]: {
      flex: 1,
    },
  },
  otp: {
    flex: 1,

    '& input:focus-visible': {
      outlineColor: '#EB4E88',
    },
  },
  otpContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    height: '48px',

    [theme.breakpoints.between('sm', 'lg')]: {
      height: '90px',
    },

    [theme.breakpoints.up('lg')]: {
      height: '56px',
    },

    [theme.breakpoints.up('xl')]: {
      height: '72px',
    },
  },
  otpInput: {
    height: '100% !important',
    width: '100% !important',
    margin: '0 0.25rem',
    fontSize: '2rem',
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.3)',
    color: '#212121',

    [theme.breakpoints.up('sm')]: {
      margin: '0 0.5rem',
    },
  },
  resendCode: {
    marginTop: '2em',
    width: 'fit-content',
    fontWeight: 600,
    fontSize: '1rem',
    alignSelf: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',

    '& > :not(:last-child)': {
      margin: '0.5em 0',
    },
  },
});
