export const formInputFieldStyle = (theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    userSelect: 'none',
  },
  adornedStart: {
    '& .MuiInputAdornment-positionStart': {
      border: '1px solid',
      height: 41,
      maxHeight: 'unset',
      paddingLeft: 14,
      paddingRight: 14,
      borderColor: 'rgba(0, 0, 0, 0.15)',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.white,
      marginRight: 0,
    },
  },
  inputAdornedStart: {
    left: -2,
    borderTopLeftRadius: '0px !important',
    borderBottomLeftRadius: '0px !important',
    borderLeft: 'none !important',
    paddingLeft: '0px !important',
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },

    '&[type=number]': {
      MozAppearance: 'textfield',
    },
  },
  textBoldInput: {
    fontWeight: 'bold',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
});
