import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { customCheckboxStyle, labelStyle } from './style';
const CustomCheckbox = withStyles(customCheckboxStyle)((props) => (
  <Checkbox
    color="default"
    checkedIcon={<CheckBoxIcon />}
    uncheckedicon={<CheckBoxOutlineBlankIcon />}
    {...props}
  />
));

function FormCheckBox(props) {
  const {
    valid,
    onChangeInput,
    validationMessage,
    value,
    field,
    label,
    touched,
    classes,
  } = props;
  return (
    <div>
      <FormControlLabel
        control={
          <CustomCheckbox
            id={field}
            name={field}
            checked={value}
            onChange={(e) => onChangeInput(e.target.checked, field)}
          />
        }
        classes={{ label: classes.formControlLabel }}
        label={label}
      />
      <span
        style={{
          color: '#d32f2f',
          display: touched && !valid ? 'block' : 'none',
        }}
      >
        {validationMessage}
      </span>
    </div>
  );
}

FormCheckBox.propTypes = {
  valid: PropTypes.bool,
  onChangeInput: PropTypes.func,
  validationMessage: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  field: PropTypes.string,
  touched: PropTypes.bool,
  classes: PropTypes.object,
};
export default compose(withStyles(labelStyle))(FormCheckBox);
