import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import LocationIcon from '@material-ui/icons/LocationCityOutlined';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { DataGrid } from '@mui/x-data-grid';

import BaseComponent from '@containers/BaseComponent';
import ErrorMessageDialog from '@components/errorMessageDialog';
import DialogTitle from '@components/dialogTitle';
import FormTextInput from '@components/formTextInput';
import EmptyRowsOverlay from '@components/emptyRowsOverlay';
import DataGridPagination from '@components/dataGridPagination';
import {
  getCompanyList,
  getLocationGroup,
  putLocationGroup,
  postLocationGroup,
} from '@redux/user/userActions';
import { errorLocationGroupContain, errorLocationGroupMessage } from '@data/constants';
import { customization } from '@customization/default';
import {
  transformAddLocationGroup,
  transformEditLocationGroup,
} from '@utils/transformation';
import { userLocationStyle, dialogContentStyle, dialogActionStyle } from './style';

const DialogContent = withStyles(dialogContentStyle)(MuiDialogContent);
const DialogActions = withStyles(dialogActionStyle)(MuiDialogActions);

class UserLocationsTab extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      showLocationDialog: false,
      page: 0,
      rowsPerPage: 25,
      totalPages: 0,
      resetForm: {
        id: null,
        internal_name: {
          validation: {
            required: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        company: {
          validation: {
            required: false,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
      },
      userLocationForm: {
        id: null,
        internal_name: {
          validation: {
            required: true,
            escapedString: true,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
        company: {
          validation: {
            required: false,
          },
          value: '',
          valid: false,
          isValidFormat: true,
          touched: false,
        },
      },
      locationData: { rows: [], total: 0 },
      isFormValid: false,
      isAdd: false,
      errorMessage: {
        isError: false,
        message: '',
      },
    };
  }

  getAllLocations = async () => {
    const { rowsPerPage, page } = this.state;

    const [locationGroups, companies] = await Promise.all([
      this.props.getLocationGroup(this.props.token, 1, page + 1, rowsPerPage),
      this.props.getCompanyList(this.props.token),
    ]);

    if (this.props.errorResponse.statusCode) {
      this.setState({
        isError: true,
        message: this.props.errorResponse.message,
      });
    } else {
      this.transformDataLocation(locationGroups.data, companies.data);
    }
  };

  transformDataLocation = (locations, company) => {
    const locationData = locations?.items.length ? locations?.items : null;

    if (!locationData) {
      return locations;
    }

    for (const location of locationData) {
      const selectedCompany = company.find(
        (element) => element?.id === location?.company_fk,
      );

      location.company = selectedCompany?.company_name || null;
    }

    this.setState(
      {
        locationData: { rows: locationData, total: locations.totalItems },
      },
      () => {
        if (locations.currentPage >= locations.totalPages) {
          this.setState({
            page: locations.totalPages - 1,
          });
        }
      },
    );
  };

  handleCloseErrorMessage = () => {
    this.setState({
      isError: false,
      message: '',
    });
  };

  componentDidMount() {
    this.getAllLocations();
  }

  componentDidUpdate(prevProps) {
    const { successMessageDialog } = this.props;
    if (
      prevProps.successMessageDialog !== successMessageDialog &&
      !successMessageDialog
    ) {
      this.setState({
        showLocationDialog: false,
      });
    }
  }

  initLocationForm = () => {
    this.setState({
      isFormValid: false,
    });
  };

  setEditLocations = (val) => {
    const { userLocationForm, locationData } = this.state;
    const filteredRow = locationData.rows
      .filter((row) => row.id === val)
      .map((filteredRow) => {
        return filteredRow;
      });

    userLocationForm.id = filteredRow[0].id;
    userLocationForm.internal_name = {
      value: filteredRow[0].internal_name,
      valid: true,
      isValidFormat: true,
      touched: true,
      validation: {
        required: true,
        escapedString: true,
      },
    };
    userLocationForm.company = {
      value: filteredRow[0].company_fk,
      valid: true,
      isValidFormat: true,
      touched: true,
      validation: {
        required: false,
      },
    };

    this.setState({
      userLocationForm,
      isFormValid: true,
      isAdd: false,
    });
  };

  setAddLocations = () => {
    const { userLocationForm } = this.state;
    userLocationForm.id = null;
    userLocationForm.internal_name.value = '';
    userLocationForm.internal_name.valid = false;
    userLocationForm.company.value = '';
    userLocationForm.company.valid = false;
    this.setState({ userLocationForm, isAdd: true });
  };

  onSaveUserLocations = async () => {
    const { userLocationForm, isAdd } = this.state;
    const { onSaveHandled, onErrorHandled, putLocationGroup, postLocationGroup, token } =
      this.props;
    let response;
    if (isAdd) {
      const transformData = transformAddLocationGroup(userLocationForm);
      response = await postLocationGroup(transformData, token);
    } else {
      const transformData = transformEditLocationGroup(userLocationForm);
      response = await putLocationGroup(transformData, token);
    }
    if (response.status === 200) {
      onSaveHandled();
      this.getAllLocations();
    } else {
      const message = this.handleGetErrorMessage(response.response.data.message);
      onErrorHandled(message);
    }
  };

  handleGetErrorMessage(responseMessage) {
    let message = '';
    if (responseMessage.includes(errorLocationGroupContain.INTERNALNAMEUNIQUE)) {
      message = errorLocationGroupMessage.INTERNALNAMEUNIQUE;
    } else if (responseMessage.includes(errorLocationGroupContain.MAXIMUM)) {
      message = errorLocationGroupMessage.MAXIMUM;
    } else {
      message = responseMessage;
    }
    return message;
  }

  onChangeInput = (eventValue, inputIdentifier) => {
    const updateState = this.changeInputWithValidation(
      eventValue,
      this.state.userLocationForm,
      inputIdentifier,
    );

    this.setState({
      userLocationForm: updateState.form,
      isFormValid: updateState.valid,
    });
  };

  handleCloseDialog = () => {
    const { resetForm } = this.state;
    this.setState({
      showLocationDialog: false,
      userLocationForm: resetForm,
      isFormValid: false,
    });
  };

  handleOpenEditDialog = async (id) => {
    await this.initLocationForm();
    await this.setEditLocations(id);
    this.setState({ showLocationDialog: true });
  };

  handleOpenAddDialog = async () => {
    await this.initLocationForm();
    await this.setAddLocations();
    this.setState({ showLocationDialog: true });
  };

  renderDialog = () => {
    const { classes, companyList } = this.props;
    const { userLocationForm, isFormValid, isAdd } = this.state;
    return (
      <div>
        <Dialog fullWidth maxWidth="sm" open={this.state.showLocationDialog}>
          <DialogTitle onClose={this.handleCloseDialog}>
            {isAdd ? (
              <b>{customization.userSettingsPage.locationAddDialogTitle}</b>
            ) : (
              <b>
                {`${customization.userSettingsPage.locationDialogTitle} -
                ${userLocationForm.internal_name.value}`}
              </b>
            )}
          </DialogTitle>
          <DialogContent>
            <Box p={3} pb={2} pt={0}>
              <b className={classes.formLabelStyle}>
                {customization.userSettingsPage.locationTableHeaderFirst}
              </b>
              <FormTextInput
                field="internal_name"
                touched={userLocationForm.internal_name.touched}
                valid={userLocationForm.internal_name.valid}
                value={userLocationForm.internal_name.value}
                validFormat={userLocationForm.internal_name.isValidFormat}
                errorMode={customization.userRootForm.invalidString}
                onChangeInput={this.onChangeInput}
                length={100}
                validationMessage={customization.requiredError}
                type="text"
              />
            </Box>
            <Box p={3} pt={0} pb={0}>
              <b className={classes.formLabelStyle}>
                {customization.userSettingsPage.locationTableHeaderThird}
              </b>
              <FormControl variant="outlined" fullWidth>
                <Select
                  labelId="companySelect"
                  id="company"
                  value={userLocationForm.company.value}
                  onChange={(e) => {
                    this.onChangeInput(e.target.value, 'company');
                  }}
                  label={customization.userSettingsPage.locationTableHeaderThird}
                  color="secondary"
                >
                  {companyList.map((data, index) => (
                    <MenuItem value={data.id} key={index}>
                      {data.company_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={() => {
                this.onSaveUserLocations();
              }}
              disabled={!isFormValid}
            >
              {customization.userSettingsPage.saveChangesButtonLabel}
            </Button>
            <Button
              className={classes.closeButton}
              onClick={this.handleCloseDialog}
              variant="outlined"
            >
              {customization.userSettingsPage.cancelButtonLabel}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  render() {
    const { page, rowsPerPage, errorMessage, locationData } = this.state;
    const { classes } = this.props;
    const columns = [
      {
        cellClassName: 'transform-capitalize',
        field: 'internal_name',
        flex: 1,
        minWidth: 218.4,
        sortable: false,
        headerName: 'Internal Name',
      },
      {
        cellClassName: 'transform-capitalize',
        field: 'company',
        flex: 1,
        minWidth: 218.4,
        sortable: false,
        headerName: 'Company',
      },
      {
        align: 'center',
        field: 'action',
        headerAlign: 'center',
        headerName: customization.userViews.headers.action,
        sortable: false,
        width: 150,
        renderCell: (params) => {
          return (
            <>
              <Button
                aria-controls="more-options"
                aria-haspopup="true"
                className={`${classes.actionButton} more`}
                disableElevation
                onClick={() => this.handleOpenEditDialog(params.id)}
                variant="contained"
              >
                <EditIcon htmlColor="#FFFFFF" fontSize="medium" />
              </Button>
            </>
          );
        },
      },
    ];
    return (
      <div className={classes.divContainerStyle}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item xs={12} lg={4} xl={6} className={classes.alignEnd}>
            <Button
              className={classes.buttonAddDialog}
              color="primary"
              disableElevation
              onClick={() => this.handleOpenAddDialog()}
              startIcon={<LocationIcon />}
              size="large"
              variant="contained"
            >
              {customization.userSettingsPage.addButtonLabel}
            </Button>
          </Grid>
        </Grid>
        <DataGrid
          autoHeight
          disableColumnMenu
          disableExtendRowFullWidth
          disableSelectionOnClick
          className={classes.table}
          columns={columns}
          components={{
            NoRowsOverlay: EmptyRowsOverlay,
            Pagination: DataGridPagination,
          }}
          componentsProps={{
            noRowsOverlay: {
              label: customization.userViews.emptyRows,
            },
            pagination: {
              count: locationData.total,
              onPageChange: (_event, newPage) => {
                this.setState({ page: newPage });
              },
              onRowsPerPageChange: (event) => {
                this.setState({ rowsPerPage: event.target.value });
              },
              page: locationData.rows.length > 0 ? page : 0,
              rowsPerPage,
            },
          }}
          maxColumns={9}
          pagination
          paginationMode="server"
          rows={locationData.rows}
          rowHeight={80}
        />

        <ErrorMessageDialog
          bindHandleClose={this.handleCloseErrorMessage}
          openSuccess={errorMessage.isError}
          errorData={errorMessage.message}
        />

        {this.renderDialog()}
      </div>
    );
  }
}

UserLocationsTab.propTypes = {
  classes: PropTypes.object,
  onSaveHandled: PropTypes.func,
  onErrorHandled: PropTypes.func,
  successMessageDialog: PropTypes.bool,
  companyList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  locationGroup: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  errorResponse: PropTypes.object,
  getCompanyList: PropTypes.func,
  getLocationGroup: PropTypes.func,
  token: PropTypes.string,
  userMasterData: PropTypes.object,
  putLocationGroup: PropTypes.func,
  postLocationGroup: PropTypes.func,
  loading: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    companyList: state.user.companyList,
    locationGroup: state.user.locationGroup,
    errorResponse: state.user.error,
    token: state.auth.inMemoryToken.token,
    loading: state.user.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyList: (token) => dispatch(getCompanyList(token)),
    getLocationGroup: (token, companyId, page, size) =>
      dispatch(getLocationGroup(token, companyId, page, size)),
    putLocationGroup: (body, token) => dispatch(putLocationGroup(body, token)),
    postLocationGroup: (body, token) => dispatch(postLocationGroup(body, token)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withStyles(userLocationStyle), withConnect)(UserLocationsTab);
