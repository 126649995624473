import React from 'react';
import { Box, Grid, Paper, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ReactComponent as Logo } from '@assets/logo.svg';
import { useStyles } from './style';

function LoginLayout({ children }) {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return isMobile ? (
    <Box className={classes.mobile}>
      <Paper className={classes.paper}>
        <Logo className={classes.logo} />
        {children}
      </Paper>
    </Box>
  ) : (
    <Grid container className={classes.desktop}>
      <Grid item lg={8} className={classes.background}>
        <Logo className={classes.logo} />
      </Grid>
      <Grid item lg={4} className={classes.paperContainer}>
        <Paper className={classes.paper}>{children}</Paper>
      </Grid>
    </Grid>
  );
}

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
