import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { compose } from 'redux';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { formDatePickerStyle } from './style';

function FormDatePicker(props) {
  const {
    valid,
    onChangeInput,
    validationMessage,
    value,
    field,
    isDisabled,
    touched,
    validFormat = true,
    classes,
    boldMode = false,
    placeholder,
  } = props;
  return (
    <div>
      <form className={classes.container} noValidate>
        <TextField
          id={field}
          type="date"
          value={value}
          defaultValue={moment().utc().format('DD/MM/YYYY')}
          className={classes.textField}
          InputLabelProps={{
            shrink: false,
          }}
          inputProps={{
            className: boldMode ? classes.textBoldInput : classes.input,
          }}
          disabled={!!isDisabled}
          placeholder={placeholder}
          onChange={(e) => onChangeInput(e.target.value, field)}
          autoComplete="off"
          variant="outlined"
        />
      </form>
      <span
        style={{
          color: '#d32f2f',
          display: (touched && !valid) || (touched && !validFormat) ? 'block' : 'none',
        }}
      >
        {!valid && validationMessage}
      </span>
    </div>
  );
}

FormDatePicker.propTypes = {
  valid: PropTypes.bool,
  onChangeInput: PropTypes.func,
  validationMessage: PropTypes.string,
  value: PropTypes.any,
  field: PropTypes.string,
  isDisabled: PropTypes.bool,
  classes: PropTypes.object,
  touched: PropTypes.bool,
  validFormat: PropTypes.bool,
  boldMode: PropTypes.bool,
  placeholder: PropTypes.string,
};
export default compose(withStyles(formDatePickerStyle))(FormDatePicker);
