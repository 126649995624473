import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  useMediaQuery,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import AssignmentIndIcon from '@material-ui/icons/AssignmentIndRounded';
import AttachMoneyIcon from '@material-ui/icons/AttachMoneyRounded';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CreditCardIcon from '@material-ui/icons/CreditCardRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import HistoryRoundedIcon from '@material-ui/icons/HistoryRounded';
import LockOutlineIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlineIcon from '@material-ui/icons/LockOpenOutlined';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { DataGrid } from '@mui/x-data-grid';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { unlockVerbal } from '@services/api/userApi';
import AddUserDialog from '@components/addUserDialog';
import CustomizedSnackbar from '@components/customizedSnackBar';
import DashboardLayout from '@components/layout/dashboardLayout';
import DataGridPagination from '@components/dataGridPagination';
import EditUserDialog from '@components/editUserDialog';
import EmptyRowsOverlay from '@components/emptyRowsOverlay';
import SuccessMessageDialog from '@components/successMessageDialog';
import ErrorMessageDialog from '@components/errorMessageDialog';
import AuditHistoryDialog from '@components/auditHistoryDialog';
import FundTransferDialog from '@components/fundTransferDialog';
import {
  apiRequestStatus,
  cardStatus,
  transactionTypeFrom,
  userSearchType,
  userStatusFilter,
  userAccessStatus,
  auditHistoryOperation,
  registrationStatus,
} from '@data/constants';
import { getCompanyList as getCompanyListService } from '@services/api/userApi';
import { customization } from '@customization/default';
import TransactionViewList from '@containers/transactionListView';
import * as cardActions from '@redux/card/cardActions';
import * as userActions from '@redux/user/userActions';
import { useStyles, dialogContentStyle, confirmationDialogContentStyle } from './style';

const ConfirmationDialogContent = withStyles(confirmationDialogContentStyle)(
  MuiDialogContent,
);

const DialogContent = withStyles(dialogContentStyle)(MuiDialogContent);

function UserListPage(props) {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const userStatus = [
    {
      status: [cardStatus.ACTIVE],
      icon: <Chip label="Active" className={classes.chipActive} />,
    },
    {
      status: [
        cardStatus.CLOSED,
        cardStatus.FRAUD_DETECTED,
        cardStatus.INACTIVE,
        cardStatus.INACTIVE_LOST_STOLEN,
      ],
      icon: <Chip label="Inactive" className={classes.chipInactive} />,
    },
    {
      status: [cardStatus.EXCEEDED_INCORRECT_PIN, cardStatus.BLOCKED],
      icon: <Chip label="Locked" className={classes.chipLocked} />,
    },
    {
      status: [cardStatus.PENDING],
      icon: <Chip label="Pending" className={classes.chipPending} />,
    },
  ];

  const {
    cardAction,
    credentialQuestion,
    error,
    history,
    requestState,
    token,
    userAction,
    linkedUserList,
    linkedUserTriggered,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [editUserDialog, setEditUserDialog] = useState(false);
  const [fundTransferDialog, setFundTransferDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [messageDialog, setMessageDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState(false);
  const [searchFilter, setSearchFilter] = useState(userSearchType[0].value);
  const [searchHolder, setSearchHolder] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [snackbar, setSnackbar] = useState({ show: false, message: null });
  const [statusFilter, setStatusFilter] = useState('');
  const [transactionUserDialog, setTransactionUserDialog] = useState(false);
  const [unlockCode, setUnlockCode] = useState(false);
  const [unlockDialog, setUnlockDialog] = useState(false);
  const [users, setUsers] = useState({ rows: [], total: 0 });
  const [userQuestion, setUserQuestion] = useState(null);
  const [auditHistoryDialog, setAuditHistoryDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [successDialog, setSuccessDialog] = useState({
    message: '',
    isSuccess: false,
  });
  const [isCompanySelected, setIsCompanySelected] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [errorDialog, setErrorDialog] = useState({
    message: '',
    isError: false,
  });

  const getUsers = useCallback(() => {
    userAction
      .getUserList(
        token,
        page + 1,
        rowsPerPage,
        searchFilter,
        statusFilter,
        searchText,
        selectedCompanyId,
      )
      .then((response) => {
        if (response.data) {
          const { items, totalItems, totalPages, currentPage } = response.data;
          setUsers({ rows: items, total: totalItems });
          setCurrentPage(currentPage);

          if (currentPage >= totalPages) {
            setPage(totalPages - 1);
          }
        } else {
          if (response.message.includes('403')) {
            setUsers({ rows: [], total: 0 });
            setSnackbar({ show: false, message: null });
          } else {
            setSnackbar({ show: true, message: response.message });
          }
        }

        setLoading(false);
      });
  }, [
    page,
    rowsPerPage,
    searchFilter,
    searchText,
    statusFilter,
    token,
    userAction,
    selectedCompanyId,
  ]);

  const getUserQuestion = async (id, name) => {
    setSelectedUser({ id, name });
    await userAction.getUserCredentialQuestion(token, id);
  };

  const getCompany = async () => {
    const responseCompanies = await getCompanyListService(props.token);
    setCompanies(responseCompanies.data);
  };

  useEffect(() => {
    if (error.statusCode) {
      setSnackbar({
        show: true,
        message: `Error ${error.statusCode}: ${error.message}`,
      });
    }
  }, [error]);

  const handleOpenAuditHistory = () => {
    setAuditHistoryDialog(true);
    setAnchorEl(null);
  };

  const handleCloseAuditHistory = () => {
    setAuditHistoryDialog(false);
    setSelectedUser(null);
  };

  const handleCloseFundTransfer = () => {
    setFundTransferDialog(false);
    setSelectedUser(null);
  };

  const handleCloseConfirmation = () => {
    userAction.resetCredentialQuestion();
    setUnlockCode(false);
    setUnlockDialog(false);
    setSelectedUser(null);
  };

  const handleIssuedVerbally = async (params) => {
    await unlockVerbal(params, token)
      .then((response) => {
        if (response?.status === 200) {
          handleCloseConfirmation();
          setSuccessDialog({ message: response?.data.message, isSuccess: true });
        } else {
          handleCloseConfirmation();
          setErrorDialog({ message: response?.message, isError: true });
        }
      })
      .catch((error) => {
        handleCloseConfirmation();
        setErrorDialog({
          message: error?.data.message || error?.message,
          isError: true,
        });
      });
  };

  const handleSearch = () => {
    setSearch(true);
    setPage(0);
    setSearchText(searchHolder);
  };

  const handleSearchFilter = (event) => {
    let selectedSearchType = event.target.value;
    setSearch(false);
    setSearchFilter(selectedSearchType);
    setSearchHolder('');
    setSearchText('');
    setIsCompanySelected(selectedSearchType === 'company' ? true : false);
    setSelectedCompanyId(selectedSearchType === 'company' ? selectedCompanyId : 0);
  };

  const handleCompanyFilter = (event) => {
    setSelectedCompanyId(event.target.value);
  };

  const handleSearchFieldChange = (event) => {
    const searchString = event.target.value;

    if (searchString.length === 0) {
      setSearch(false);
      setSearchText('');
    }

    setSearchHolder(searchString);
  };

  const handleStatusFilter = (_event, newStatus) => {
    if (newStatus === null) {
      setStatusFilter('');
    } else {
      setStatusFilter(newStatus);
    }
  };

  const handleTransferFunds = () => {
    setAnchorEl(null);
    setFundTransferDialog(true);
  };

  const handleResendSubscription = async () => {
    setAnchorEl(null);
    setLoading(true);

    const { id, deviceAccess } = selectedUser;
    const { web, mobile } = deviceAccess;

    if (web || mobile) {
      try {
        const response = await userAction.resendSubscriptionToken(token, id);

        if (response.status === 200) {
          setSuccessDialog({
            message: response?.data?.message,
            isSuccess: true,
          });
        } else {
          throw new Error(response?.data.message);
        }
      } catch (error) {
        setSnackbar({
          show: true,
          message: error?.response?.data?.message || error.message,
        });
      }
    } else {
      setErrorDialog({
        message: 'Unable to resend subscription to user with no web or mobile access',
        isError: true,
      });
    }

    setLoading(false);
  };

  const handleUnlock = (question) => {
    if (!unlockCode && !question?.isAdmin) {
      setUnlockCode(true);
    } else {
      const request = {
        user_id: parseInt(selectedUser.id, 10),
        unlock_pin: question.unlock_pin,
      };

      userAction.postUserUnlockPin(token, request).then(() => {
        if (error.statusCode) {
          setSnackbar({
            show: true,
            message: `Error ${error.statusCode}: ${error.message}`,
          });
        }

        setMessageDialog(true);
        setUnlockCode(false);
        setUnlockDialog(false);
      });
    }
  };

  const handleUserDetails = () => {
    setEditUserDialog(true);
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setSuccessDialog({ message: '', isSuccess: false });
    setErrorDialog({ message: '', isError: false });
  };

  const handleViewCards = () => {
    cardAction.getLinkedCard(token, 1, 20, 'user_id', '', selectedUser.id).then(() => {
      if (error.statusCode) {
        setSnackbar({
          show: true,
          message: `Get linked card list error: ${error.statusCode}`,
        });
      } else {
        history.push('/admin/dashboard/user-card-status');
      }
    });

    setAnchorEl(null);
  };

  const handleViewTransactions = () => {
    setTransactionUserDialog(true);
    setAnchorEl(null);
  };

  const keyPress = (event) => {
    if (event.keyCode === 13) {
      setSearch(true);
      setPage(0);
      setSearchText(searchHolder);
    }
  };

  const mappingLinkedUser = () => {
    const tempUsers = users;
    if (linkedUserList.items.length > 0) {
      linkedUserList.items.forEach(() => {
        if (users.rows.length < linkedUserList.totalItems) {
          tempUsers.rows = linkedUserList.items;
        }
      });
    }
    setUsers({ rows: tempUsers.rows, total: linkedUserList.totalItems });
    setPage(linkedUserList.currentPage - 1);
    setLoading(false);
    userAction.resetLinkedUserState();
  };

  useEffect(() => {
    setLoading(true);
    if (error.statusCode) {
      setPage(0);
    }

    if (Object.keys(credentialQuestion).length !== 0) {
      setUserQuestion(credentialQuestion);

      if (!credentialQuestion?.question) {
        if (credentialQuestion?.isAdmin) {
          handleUnlock(credentialQuestion);
        } else {
          setUnlockCode(true);
          setUnlockDialog(true);
        }
      } else {
        setUnlockDialog(true);
      }
    }

    if (linkedUserTriggered) {
      mappingLinkedUser();
    } else {
      getCompany();
      getUsers();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentialQuestion, getUsers]);

  const convertUserStatus = (accessStatus) => {
    switch (accessStatus) {
      case userAccessStatus.ACTIVE:
        return cardStatus.ACTIVE;
      case userAccessStatus.FULL_LOCK:
        return cardStatus.BLOCKED;
      case userAccessStatus.TEMP_LOCK:
        return cardStatus.BLOCKED;
      case userAccessStatus.CANCELLED:
        return cardStatus.INACTIVE;
      case userAccessStatus.DEACTIVATED:
        return cardStatus.INACTIVE;
      default:
        return cardStatus.PENDING;
    }
  };

  const columns = [
    {
      align: 'center',
      field: 'access_status',
      headerAlign: 'center',
      headerName: customization.userViews.headers.userStatus,
      sortable: false,
      renderCell: (params) => {
        const accessStatus = params.value;
        const userRegistration = params.row.registration_status;

        if (
          (userRegistration === registrationStatus.PROVISIONED ||
            userRegistration === registrationStatus.ACTIVATED) &&
          accessStatus === null
        ) {
          return _.head(
            userStatus.filter((element) =>
              element.status.includes(convertUserStatus(userAccessStatus.NOT_AVAILABLE)),
            ),
          ).icon;
        } else {
          return _.head(
            userStatus.filter((element) =>
              element.status.includes(convertUserStatus(accessStatus)),
            ),
          ).icon;
        }
      },
    },
    {
      align: 'center',
      field: 'card',
      headerAlign: 'center',
      headerClassName: 'text-center',
      headerName: customization.userViews.headers.cardStatus,
      sortable: false,
      renderCell: (params) => {
        const card = params.value;

        if (card) {
          return _.head(
            userStatus
              .filter((element) => element.status.includes(card.status))
              .reverse(),
          ).icon;
        } else {
          return null;
        }
      },
    },
    {
      field: 'id',
      flex: 0.5,
      minWidth: 109.2,
      headerName: customization.userViews.headers.id,
      renderCell: (params) => {
        const id = params.value;

        return id.includes('user_review_') ? '' : id.replace('user_', '');
      },
    },
    {
      cellClassName: 'transform-capitalize',
      field: 'name',
      flex: 1,
      minWidth: 218.4,
      headerName: customization.userViews.headers.name,
      valueFormatter: (params) => params.value.toLocaleLowerCase(),
      valueGetter: (params) => `${params.row.first_name} ${params.row.last_name}`,
    },
    {
      cellClassName: 'multi-line transform-capitalize',
      field: 'user_type',
      flex: 0.625,
      minWidth: 136.5,
      headerName: customization.userViews.headers.userType,
      valueFormatter: (params) => params.value.toLocaleLowerCase(),
      valueGetter: (params) => `${params.value.display_name}`,
    },
    {
      cellClassName: 'multi-line',
      field: 'location',
      flex: 1,
      minWidth: 218.4,
      headerName: customization.userViews.headers.location,
      valueFormatter: (params) => (params.value ? params.value.description : null),
      valueGetter: (params) => (params.value ? params.value.description : null),
    },
    {
      align: 'right',
      field: 'available_balance',
      flex: 0.625,
      headerAlign: 'right',
      headerClassName: 'text-right',
      headerName: customization.userViews.headers.availableBalance,
      minWidth: 136.5,
      valueFormatter: (params) => {
        const { value, symbol } = params.row.available_balance;
        return `${symbol}${value}`;
      },
      valueGetter: (params) => {
        const removedCommas = params.value.value.replace(/,/g, '');
        const removedPeriods = removedCommas.replace(/\./g, '');
        return parseInt(removedPeriods) / 100;
      },
    },
    {
      align: 'right',
      field: 'action',
      headerAlign: 'center',
      headerName: customization.userViews.headers.action,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const location = params.getValue(params.id, 'location');
        const accessStatus = params.getValue(params.id, 'access_status');
        const accountId = params.row.account_id;
        const userType = params.row.user_type;
        const hasCompleteSignUp = params.row.has_complete_sign_up;
        const userRegistration = params.row.registration_status;
        const card = params.getValue(params.id, 'card');

        const {
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
        } = params.row;
        const legalName = `${firstName} ${middleName ?? ''} ${lastName}`
          .replace(/\s+/g, ' ')
          .trim();

        let userStatus;

        if (
          (userRegistration === registrationStatus.PROVISIONED ||
            userRegistration === registrationStatus.ACTIVATED) &&
          (accessStatus === null || !hasCompleteSignUp)
        ) {
          userStatus = convertUserStatus(userAccessStatus.NOT_AVAILABLE);
        } else {
          userStatus = convertUserStatus(accessStatus);
        }

        const userDetails = {
          id: params.id.replace(/[a-z_]+/, ''),
          name: legalName,
          location,
          status: userStatus,
          accountId,
          availableBalance: {
            ...params.row.available_balance,
          },
          card,
          deviceAccess: {
            web: userType?.allow_web_access,
            mobile: userType?.allow_mobile_access,
          },
          inReview: params.id.includes('user_review'),
        };

        return (
          <>
            {userStatus !== cardStatus.INACTIVE && userStatus !== cardStatus.PENDING && (
              <>
                {userStatus === cardStatus.ACTIVE ? (
                  <Button
                    className={`${classes.actionButton} lock`}
                    disableElevation
                    variant="contained"
                    onClick={() => {
                      setSelectedUser(userDetails);
                    }}
                  >
                    <LockOutlineIcon htmlColor="#FFFFFF" fontSize="medium" />
                  </Button>
                ) : (
                  <Button
                    className={`${classes.actionButton} unlock`}
                    disableElevation
                    variant="contained"
                    onClick={() => {
                      getUserQuestion(
                        params.id.replace(/[a-z_]+/, ''),
                        params.getValue(params.id, 'name'),
                      );
                    }}
                  >
                    <LockOpenOutlineIcon htmlColor="#ff9800" fontSize="medium" />
                  </Button>
                )}
              </>
            )}

            <Button
              aria-controls="more-options"
              aria-haspopup="true"
              className={`${classes.actionButton} more`}
              disableElevation
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setSelectedUser(userDetails);
              }}
              variant="contained"
            >
              <MoreVertIcon htmlColor="#FFFFFF" fontSize="medium" />
            </Button>
          </>
        );
      },
    },
  ];

  const moreOptionsItems = [
    {
      label: customization.userViews.menuItems.userDetails,
      icon: <AssignmentIndIcon />,
      showCondition: true,
      onClick: handleUserDetails,
    },
    {
      label: customization.userViews.menuItems.auditHistory,
      icon: <HistoryRoundedIcon />,
      showCondition: true,
      onClick: handleOpenAuditHistory,
    },
    {
      label: customization.userViews.menuItems.resendSubscription,
      icon: <EmailRoundedIcon />,
      showCondition: selectedUser?.status === 'PENDING',
      onClick: handleResendSubscription,
    },
    {
      label: customization.userViews.menuItems.viewTransactions,
      icon: <AttachMoneyIcon />,
      showCondition: selectedUser?.accountId,
      onClick: handleViewTransactions,
    },
    {
      label: customization.userViews.menuItems.viewCards,
      icon: <CreditCardIcon />,
      showCondition: selectedUser?.card,
      onClick: handleViewCards,
    },
    {
      label: customization.userViews.menuItems.transferFunds,
      icon: <GetAppRoundedIcon />,
      showCondition: selectedUser?.accountId,
      onClick: handleTransferFunds,
    },
  ];

  const pageInformation = () => {
    if (!users?.rows.length) {
      return null;
    }

    const fromPage = (currentPage - 1) * rowsPerPage + 1;

    const toPage = (page + 1) * rowsPerPage;

    const totalPage = users?.total;

    const lastPage =
      currentPage === Math.max(0, Math.ceil(users?.total / rowsPerPage))
        ? totalPage
        : toPage;

    return `Showing ${fromPage}-${lastPage} of ${totalPage} results`;
  };

  return (
    <DashboardLayout>
      <Tabs
        value={statusFilter}
        onChange={handleStatusFilter}
        className={classes.statusTabs}
        scrollButtons="auto"
      >
        {userStatusFilter.map((status) => (
          <Tab key={status.label} label={status.label} value={status.value} />
        ))}
        {!isMobile && (
          <Tab
            className={classes.tabInformationPage}
            key="pageInformation"
            label={pageInformation()}
            disabled
          />
        )}
      </Tabs>
      <Grid
        className={classes.gridContainer}
        container
        justifyContent="flex-start"
        spacing={2}
      >
        <Grid item xs={12} lg={4} xl={3}>
          <FormControl className={classes.searchFilter} variant="outlined">
            <InputLabel color="secondary" id="search-filter-label">
              {customization.userViews.filterPlaceholder}
            </InputLabel>
            <Select
              id="search-filter"
              color="secondary"
              label={customization.userViews.filterPlaceholder}
              labelId="search-filter-label"
              value={searchFilter}
              onChange={handleSearchFilter}
            >
              {userSearchType.map((searchType) => (
                <MenuItem key={searchType.value} value={searchType.value}>
                  {searchType.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          {!isCompanySelected ? (
            <TextField
              className={classes.searchText}
              color="secondary"
              InputProps={{
                endAdornment: (
                  <Button
                    color="secondary"
                    disableElevation
                    onClick={handleSearch}
                    variant="contained"
                  >
                    {customization.userViews.searchLabel}
                  </Button>
                ),
                onKeyDown: keyPress,
              }}
              onChange={handleSearchFieldChange}
              placeholder={`${customization.userViews.searchPlaceholder} ${
                userSearchType.filter(
                  (searchType) => searchType.value === searchFilter,
                )[0].label
              }`}
              type="search"
              value={searchHolder}
              variant="outlined"
            />
          ) : (
            <FormControl className={classes.searchFilter} variant="outlined">
              <InputLabel color="secondary" id="search-by-company-filter-label">
                {customization.userViews.secondaryFilterPlaceholder}
              </InputLabel>
              <Select
                id="search-by-company-filter"
                color="secondary"
                label={customization.userViews.secondaryFilterPlaceholder}
                labelId="search-by-company-filter-label"
                defaultValue={0}
                onChange={handleCompanyFilter}
              >
                <MenuItem value="0">Select company</MenuItem>
                {!_.isEmpty(companies) &&
                  companies.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.company_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} lg={4} xl={6} className={classes.alignEnd}>
          <AddUserDialog />
        </Grid>
      </Grid>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableExtendRowFullWidth
        disableSelectionOnClick
        className={classes.table}
        columns={columns}
        components={{
          NoRowsOverlay: EmptyRowsOverlay,
          Pagination: DataGridPagination,
        }}
        componentsProps={{
          noRowsOverlay: {
            label: customization.userViews.emptyRows,
            search,
          },
          pagination: {
            count: users.total,
            onPageChange: (_event, newPage) => {
              setPage(newPage);
            },
            onRowsPerPageChange: (event) => {
              setRowsPerPage(event.target.value);
            },
            page: users.rows.length > 0 ? page : 0,
            rowsPerPage,
          },
        }}
        loading={loading}
        maxColumns={9}
        pagination
        paginationMode="server"
        rows={users.rows}
        rowHeight={80}
      />
      <Menu
        anchorEl={anchorEl}
        className={classes.moreMenu}
        id="more-options"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {moreOptionsItems.map((item, index) => {
          if (item.showCondition) {
            return (
              <MenuItem key={index} onClick={item.onClick}>
                {item.icon}
                {item.label}
              </MenuItem>
            );
          }

          return null;
        })}
      </Menu>
      <CustomizedSnackbar
        message={snackbar.message}
        showBar={snackbar.show}
        severity="error"
        handleClose={() => setSnackbar({ show: false, message: null })}
      />
      {userQuestion !== null ? (
        <Dialog
          classes={{
            paper: classes.paperUnlockConfirmationStyle,
          }}
          open={unlockDialog}
          onClose={handleCloseConfirmation}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleCloseConfirmation} />
          <ConfirmationDialogContent>
            <Box p={1}>
              <div className={classes.unlockIconContainterStyle}>
                <LockOpenRoundedIcon
                  fontSize="inherit"
                  className={classes.unlockIconStyle}
                ></LockOpenRoundedIcon>
              </div>
              <div className={classes.lockUnlockMsgContainterStyle}>
                {unlockCode ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography className={classes.unlockCodeCaptionDialogStyle}>
                          {customization.userViews.unlockCaption}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography className={classes.unlockCodeCaptionDialogStyle}>
                          <b>
                            {userQuestion.unlock_pin[0]} &nbsp;{' '}
                            {userQuestion.unlock_pin[1]} &nbsp;{' '}
                            {userQuestion.unlock_pin[2]} &nbsp;{' '}
                            {userQuestion.unlock_pin[3]}
                          </b>
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={5} md={5} lg={5}>
                        <Typography className={classes.unlockCaptionDialogStyle}>
                          {customization.userViews.securityQuestion}
                        </Typography>
                      </Grid>
                      <Grid item xs={7} md={7} lg={7}>
                        <Typography className={classes.unlockAnswerDialogStyle}>
                          <b>{userQuestion.question}</b>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={5} md={5} lg={5}>
                        <Typography className={classes.unlockCaptionDialogStyle}>
                          {customization.userViews.answer}
                        </Typography>
                      </Grid>
                      <Grid item xs={7} md={7} lg={7}>
                        <Typography className={classes.unlockAnswerDialogStyle}>
                          <b>{userQuestion.answer}</b>
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6} lg={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      className={classes.confirmStyle}
                      onClick={() => handleUnlock(userQuestion)}
                      disabled={requestState === apiRequestStatus.PENDING}
                    >
                      {unlockCode
                        ? customization.userViews.sendSmsBtn
                        : customization.userViews.confirmBtn}
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <Button
                      type="submit"
                      variant="outlined"
                      className={classes.cancelStyle}
                      onClick={() =>
                        unlockCode
                          ? handleIssuedVerbally({ user_id: selectedUser.id })
                          : handleCloseConfirmation()
                      }
                    >
                      <b>
                        {unlockCode
                          ? customization.userViews.issuedVerballyBtn
                          : customization.userViews.cancelBtn}
                      </b>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </ConfirmationDialogContent>
        </Dialog>
      ) : null}
      {selectedUser !== null ? (
        <Dialog
          fullWidth
          maxWidth="xs"
          onClose={() => {
            userAction.resetCredentialQuestion();
            setMessageDialog(false);
            setSelectedUser(null);
          }}
          open={messageDialog}
          classes={{
            paper: classes.paperSuccessMessageStyle,
          }}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => {
              setMessageDialog(false);
              setSelectedUser(null);
            }}
          />
          <DialogContent>
            <Box p={3}>
              <div className={classes.successIconContainterStyle}>
                <CheckIcon fontSize="inherit" className={classes.successIcon} />
              </div>
              <div className={classes.successMsgContainterStyle}>
                <Typography className={classes.successMessage}>
                  <b>
                    {selectedUser.name} {customization.userViews.unlockSuccessMessage}
                  </b>
                </Typography>
              </div>
            </Box>
          </DialogContent>
        </Dialog>
      ) : null}

      <SuccessMessageDialog
        message={successDialog.message}
        openSuccess={successDialog.isSuccess}
        bindHandleClose={handleCloseDialog}
        className={classes.roundedButton}
      />

      <ErrorMessageDialog
        bindHandleClose={handleCloseDialog}
        openSuccess={errorDialog.isError}
        errorData={errorDialog.message}
      />

      <AuditHistoryDialog
        openAuditHistoryDialog={auditHistoryDialog}
        bindCloseAuditHistory={handleCloseAuditHistory}
        auditData={selectedUser}
        operationTag={auditHistoryOperation.USER}
      />

      <EditUserDialog
        bindCloseEditDialog={() => {
          setSelectedUser(null);
          setEditUserDialog(false);
          getUsers();
        }}
        openEditDialog={editUserDialog}
        userData={selectedUser}
      />

      <FundTransferDialog
        handleClose={handleCloseFundTransfer}
        open={fundTransferDialog}
        user={selectedUser}
      />

      <TransactionViewList
        open={transactionUserDialog}
        handleClose={() => {
          setSelectedUser(null);
          setTransactionUserDialog(false);
        }}
        data={selectedUser}
        typeData={transactionTypeFrom.USER}
      />
    </DashboardLayout>
  );
}

UserListPage.propTypes = {
  cardAction: PropTypes.object,
  credentialQuestion: PropTypes.object,
  error: PropTypes.object,
  history: PropTypes.object,
  linkedUserList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  linkedUserTriggered: PropTypes.bool,
  requestState: PropTypes.string,
  token: PropTypes.string,
  unlockPinMessage: PropTypes.object,
  userAction: PropTypes.object,
  userList: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    credentialQuestion: state.user.credentialQuestion,
    error: state.user.error,
    linkedUserList: state.user.linkedUserList,
    linkedUserTriggered: state.user.linkedUserTriggered,
    requestState: state.user.loading,
    token: state.auth.inMemoryToken.token,
    unlockPinMessage: state.user.unlockPinMessage,
    userList: state.user.userList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cardAction: bindActionCreators(cardActions, dispatch),
    userAction: bindActionCreators(userActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListPage);
