import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  InputAdornment,
  IconButton,
  Link,
  Grid,
  Box,
  Typography,
  TextField,
} from '@material-ui/core';
import queryString from 'query-string';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import FormHelperText from '@material-ui/core/FormHelperText';
import LoginLayout from '@components/layout/loginLayout';
import LoginButton from '@components/login/loginButton';
import { apiRequestStatus } from '@data/constants';
import { LoginTitle } from '@components/login/loginTitle';
import LayoutRoute from '@containers/layout/layoutRoute';
import LoginMultiFactorAuthenticationPage from '@containers/loginMultiFactorAuthenticationPage';
import ProtectedLayout from '@containers/layout/protectedLayout';
import { adminSetNewPasswordSendEmail } from '@services/api/auth';
import { customization } from '@customization/default';
import history from '@utils/history';
import { adminLogin, adminClearState, adminUnlockAccountRequest } from '@redux';
import BaseComponent from '@containers/BaseComponent';
// eslint-disable-next-line import/extensions
import apv from './../../appversion.json';
import { loginPageStyle } from './style';

class LoginPage extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      errorEmailValidation: false,
      unexpectedError: null,
      isExpired: false,
      forgotPassword: {
        status: apiRequestStatus.IDLE,
        isError: false,
        data: {},
      },
    };
  }

  componentDidMount() {
    this.backListener = history.listen((location) => {
      if (history.action === 'POP') {
        this.props.adminClearState();
      }
    });

    let query = queryString.parse(this.props.location.search);
    const expired = query.expired === 'true';
    this.setState({ isExpired: expired });
  }

  componentWillUnmount() {
    this.backListener();
  }

  componentDidUpdate() {
    if (this.state.email === '' && this.state.errorEmailValidation) {
      this.setState({
        errorEmailValidation: false,
      });
    }
  }

  handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  emailValidation = (value) => {
    const regex =
      /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/g;
    return regex.test(value);
  };

  handleEmailInput = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      errorEmailValidation: false,
    });
  };

  onEmailBlur = () => {
    if (this.state.email !== '') {
      this.setState({
        errorEmailValidation: !this.emailValidation(this.state.email),
      });
    }
  };

  handleLogin = async (event) => {
    event.preventDefault();
    const params = {
      username: this.state.email,
      password: this.state.password,
    };

    try {
      this.setState({
        unexpectedError: null,
        forgotPassword: {
          isError: false,
          data: {},
        },
      });
      const response = await this.props.adminLogin(this.props.token, params);
      if (!response?.data) return;
      history.push('/login/mfa-authentication');
    } catch (error) {
      this.setState({
        unexpectedError: error.message,
      });
    }
  };

  handleForgotPassword = () => {
    history.push(`/forgot-password`);
  };

  handleUnlockAccount = async (event) => {
    event.preventDefault();
    this.props.adminClearState();
    history.push(`/unlockAccount/${this.state.email}`);
  };

  handleSetNewUser = async (event) => {
    event.preventDefault();

    if (!this.emailValidation(this.state.email) || !this.state.email) {
      this.setState({
        email: '',
        password: '',
        forgotPassword: {
          isError: true,
        },
        unexpectedError: customization.login.newUserEmailValidationError,
      });
      await this.props.adminClearState();
      return;
    }

    this.setState({
      forgotPassword: {
        status: apiRequestStatus.PENDING,
      },
    });

    try {
      const response = await adminSetNewPasswordSendEmail({ email: this.state.email });
      if (response.status === 200) {
        this.setState((state) => ({
          email: '',
          password: '',
          forgotPassword: {
            isError: false,
            status: apiRequestStatus.RESOLVED,
            data: { ...state.forgotPassword.data, ...response.data },
          },
          unexpectedError: null,
        }));
        await this.props.adminClearState();
      }
    } catch (error) {
      this.setState({
        email: '',
        password: '',
        forgotPassword: {
          isError: false,
        },
        unexpectedError: customization.forgotPassword.internalServerError,
      });
      await this.props.adminClearState();
    }
  };

  handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      this.handleLogin(event);
    }
  };

  handleBackBtn = (event) => {
    event.preventDefault();
    this.props.adminClearState();
  };

  render() {
    const { classes, adminRequestError, adminRequestStatus, match } = this.props;
    const {
      email,
      password,
      showPassword,
      errorEmailValidation,
      unexpectedError,
      forgotPassword,
      isExpired,
    } = this.state;
    const REMAINING_ATTEMPT_LIMIT = 3;
    const ACCOUNT_FULLY_LOCKED = /locked/i.test(adminRequestError?.message);
    const LINK_EXPIRE_INVALID = /You are not authorized/i.test(
      adminRequestError?.message,
    );
    const LINK_SET_NEW_USER_EXPIRE = /Your set new password link has expired/i.test(
      adminRequestError?.message,
    );
    const LINK_SET_NEW_USER_SENT =
      /A set new password link has been sent to you via email/i.test(
        forgotPassword?.data?.message,
      );
    const EMAIL_SET_NEW_USER_INVALID = /set new user email validation error/i.test(
      unexpectedError,
    );

    return (
      <Switch>
        <Route exact path={match.path}>
          <LoginLayout>
            <Typography variant="h4" component="div" className={classes.header}>
              {customization.login.introduction}
              <Typography variant="subtitle1" className={classes.subtitle}>
                {customization.login.introductionDescription}
              </Typography>
            </Typography>

            {adminRequestStatus !== apiRequestStatus.PENDING && (
              <>
                {adminRequestError?.message &&
                !ACCOUNT_FULLY_LOCKED &&
                !LINK_SET_NEW_USER_EXPIRE &&
                !isExpired ? (
                  <LoginTitle color="error">
                    {customization.login.invalidCredentials.baseErrorMessage}
                    {adminRequestError?.remainingAttempt < REMAINING_ATTEMPT_LIMIT &&
                      customization.login.invalidCredentials.remainingAttemptFailed(
                        adminRequestError?.remainingAttempt,
                      )}
                  </LoginTitle>
                ) : ACCOUNT_FULLY_LOCKED ? (
                  <LoginTitle color="error">
                    {customization.login.invalidCredentials.lockedAccount}
                  </LoginTitle>
                ) : isExpired || LINK_EXPIRE_INVALID ? (
                  <LoginTitle color="error">
                    {customization.newPassword.expireInvalidLink}
                  </LoginTitle>
                ) : LINK_SET_NEW_USER_EXPIRE ? (
                  <LoginTitle color="error">
                    {customization.login.newUserExpireLink}
                  </LoginTitle>
                ) : null}
              </>
            )}

            {forgotPassword.status !== apiRequestStatus.PENDING && (
              <>
                {forgotPassword?.data?.message && !forgotPassword.isError && (
                  <LoginTitle color="error">
                    {LINK_SET_NEW_USER_SENT
                      ? customization.login.newUserSuccessMessage
                      : customization.forgotPassword.successResponseMessage}
                  </LoginTitle>
                )}
              </>
            )}

            {forgotPassword.isError && !EMAIL_SET_NEW_USER_INVALID && (
              <LoginTitle color="error">
                {customization.forgotPassword.errorEmailValidation}
              </LoginTitle>
            )}

            {unexpectedError && EMAIL_SET_NEW_USER_INVALID && (
              <LoginTitle color="error">
                {customization.login.newUserErrorEmailValidation}
              </LoginTitle>
            )}

            {unexpectedError && !EMAIL_SET_NEW_USER_INVALID && (
              <LoginTitle color="error">{unexpectedError}</LoginTitle>
            )}

            <Box className={classes.formContainer}>
              <form className={classes.form}>
                <TextField
                  variant="outlined"
                  label={customization.login.emailLabel}
                  name="email"
                  onChange={(e) => this.handleEmailInput(e)}
                  onBlur={() => this.onEmailBlur()}
                  onKeyPress={this.handleKeyDown}
                  value={email}
                  error={errorEmailValidation}
                  disabled={ACCOUNT_FULLY_LOCKED}
                >
                  <FormHelperText
                    id="email-error-text"
                    className={classes.helperTextError}
                  >
                    {errorEmailValidation &&
                      customization.login.emailValidationErrorMessage}
                  </FormHelperText>
                </TextField>

                <TextField
                  variant="outlined"
                  label={customization.login.passwordLabel}
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={this.handleInputValueChange}
                  onKeyPress={this.handleKeyDown}
                  value={password}
                  error={errorEmailValidation}
                  disabled={ACCOUNT_FULLY_LOCKED}
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {ACCOUNT_FULLY_LOCKED && (
                  <Grid item xs={12} lg={12}>
                    <LoginButton onClick={this.handleBackBtn}>
                      {customization.back}
                    </LoginButton>
                  </Grid>
                )}
                <LoginButton
                  disabled={
                    !(email && password && !errorEmailValidation) ||
                    ACCOUNT_FULLY_LOCKED ||
                    adminRequestStatus === apiRequestStatus.PENDING
                  }
                  type="submit"
                  onClick={this.handleLogin}
                >
                  {customization.login.loginButtonLabel}
                </LoginButton>

                <Grid container>
                  <Grid item xs sm md className={classes.forgotPassword}>
                    {forgotPassword.status !== apiRequestStatus.PENDING &&
                    !ACCOUNT_FULLY_LOCKED &&
                    !LINK_SET_NEW_USER_EXPIRE &&
                    !EMAIL_SET_NEW_USER_INVALID ? (
                      <Link onClick={this.handleForgotPassword}>
                        {customization.login.forgotPasswordLabel}
                      </Link>
                    ) : ACCOUNT_FULLY_LOCKED &&
                      !LINK_SET_NEW_USER_EXPIRE &&
                      !EMAIL_SET_NEW_USER_INVALID ? (
                      <Link component="button" onClick={this.handleUnlockAccount}>
                        {customization.login.unlockAccountLabel}
                      </Link>
                    ) : (LINK_SET_NEW_USER_EXPIRE && !ACCOUNT_FULLY_LOCKED) ||
                      (EMAIL_SET_NEW_USER_INVALID &&
                        !LINK_SET_NEW_USER_EXPIRE &&
                        !ACCOUNT_FULLY_LOCKED) ? (
                      <Link onClick={this.handleSetNewUser}>
                        {customization.login.setNewUserLabel}
                      </Link>
                    ) : (
                      <span>{customization.loading}</span>
                    )}
                  </Grid>
                </Grid>
                <span>{apv.build.date}</span>
              </form>
            </Box>
          </LoginLayout>
        </Route>
        <ProtectedLayout>
          <LayoutRoute
            component={LoginMultiFactorAuthenticationPage}
            path="/login/mfa-authentication"
          />
        </ProtectedLayout>
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminRequestError: state.auth.error,
    adminRequestStatus: state.auth.loading,
    adminUnlockRequestMessage: state.auth.unlockRequestMessage,
    token: state.auth.inMemoryToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminLogin: (token, params) => dispatch(adminLogin(token, params)),
    adminUnlockAccountRequest: (token, params) =>
      dispatch(adminUnlockAccountRequest(token, params)),
    adminClearState: () => dispatch(adminClearState()),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withStyles(loginPageStyle), withConnect)(LoginPage);
