/* eslint-disable no-useless-constructor */
import { Component } from 'react';
import { valuesValidation } from '@utils/transformation';
import checkValidity from '@utils/checkValidity';
import { updateState } from '@redux/utility';
// Define any function that can be implemented to all class
class BaseComponent extends Component {
  constructor(props) {
    super(props);
  }

  handleInputValueChange = (event) => {
    const id = event.target.id ? event.target.id : event.target.name;
    this.setState({ [id]: event.target.value });
  };

  handleInputWithIntegerValue = (event) => {
    const id = event.target.id ? event.target.id : event.target.name;
    let value = event.target.value;
    value = parseInt(event.target.value, 10);
    this.setState({ [id]: value }, () => {
      console.log(`state ${JSON.stringify(this.state)}`);
    });
  };

  mappedUserToFormRootState = (newUserData, existingUserData) => {
    let updatedForm = {};
    for (const keyExistingUserData in existingUserData) {
      for (const keyNewUserData in newUserData) {
        if (keyExistingUserData === keyNewUserData) {
          const transformedData = valuesValidation(
            newUserData[keyNewUserData],
            existingUserData,
            keyExistingUserData,
          );
          updatedForm = updateState(updatedForm, {
            [keyExistingUserData]: transformedData,
          });
        }
      }
    }

    let formIsValid = true;
    let validState = false;
    let validFormatState = false;
    for (const inputIdentifier in updatedForm) {
      validState =
        typeof updatedForm[inputIdentifier].valid === 'undefined'
          ? true
          : updatedForm[inputIdentifier].valid;
      validFormatState =
        typeof updatedForm[inputIdentifier].isValidFormat === 'undefined'
          ? true
          : updatedForm[inputIdentifier].isValidFormat;
      formIsValid = validState && validFormatState && formIsValid;
    }
    return {
      user: updatedForm,
      formIsValid,
    };
  };

  changeInputWithValidation = (eventValue, formObject, inputIdentifier) => {
    let isNotEmpty = false;
    let isValidFormat = false;
    if (
      !formObject[inputIdentifier].validation.isNumeric &&
      formObject[inputIdentifier].validation.required
    ) {
      if (typeof eventValue === 'string') {
        isNotEmpty = eventValue.trim() !== '';
      } else if (typeof eventValue === 'boolean') {
        isNotEmpty = eventValue === true;
      } else if (typeof eventValue === 'number') {
        isNotEmpty = eventValue !== null;
      }
    } else {
      isNotEmpty = true;
    }

    isValidFormat = checkValidity(eventValue, formObject[inputIdentifier].validation);

    const updatedFormElement = updateState(formObject[inputIdentifier], {
      value: eventValue,
      valid: isNotEmpty,
      isValidFormat: isValidFormat,
      touched: true,
    });
    const updatedOrderForm = updateState(formObject, {
      [inputIdentifier]: updatedFormElement,
    });

    let formIsValid = true;
    let validState = false;
    let validFormatState = false;
    for (const inputIdentifier in updatedOrderForm) {
      if (updatedOrderForm[inputIdentifier] !== null) {
        validState =
          typeof updatedOrderForm[inputIdentifier].valid === 'undefined'
            ? true
            : updatedOrderForm[inputIdentifier].valid;
        validFormatState =
          typeof updatedOrderForm[inputIdentifier].isValidFormat === 'undefined'
            ? true
            : updatedOrderForm[inputIdentifier].isValidFormat;
      } else {
        validState = true;
        validFormatState = true;
      }
      formIsValid = validState && validFormatState && formIsValid;
    }

    return {
      form: updatedOrderForm,
      valid: formIsValid,
    };
  };

  checkValidityRequired = (user) => {
    for (const key in user) {
      if (user[key].validation.required && user[key].value?.trim?.() === '') {
        return false;
      }
    }
    return true;
  };
}

export default BaseComponent;
