import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { customizedSnackBarStyle } from './style';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CustomizedSnackBar(props) {
  const { severity, message, showBar, classes, handleClose } = props;

  return (
    <div className={classes.root}>
      <Snackbar open={showBar} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

CustomizedSnackBar.propTypes = {
  showBar: PropTypes.bool,
  severity: PropTypes.string,
  message: PropTypes.string,
  classes: PropTypes.object,
  handleClose: PropTypes.func,
};

export default compose(withStyles(customizedSnackBarStyle))(CustomizedSnackBar);
