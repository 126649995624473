import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import activitiesReducer from './activities/activitiesReducer';
import authReducer from './auth/authReducer';
import bankReducer from './bank/bankReducer';
import cardReducer from './card/cardReducer';
import locationReducer from './locations/locationReducer';
import merchantReducer from './merchantCategoryRestriction/merchantReducer';
import transactionsReducer from './transactions/transactionReducer';
import transferFundsReducer from './transferFunds/transferFundsReducer';
import userReducer from './user/userReducer';
import userTypeReducer from './settings/userTypeReducer';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    // other reducer, put in here
    activities: activitiesReducer,
    auth: authReducer,
    bank: bankReducer,
    card: cardReducer,
    locations: locationReducer,
    merchantCategory: merchantReducer,
    transactions: transactionsReducer,
    transferFunds: transferFundsReducer,
    user: userReducer,
    userType: userTypeReducer,
  });

export default rootReducer;
