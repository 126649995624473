import { makeStyles } from '@material-ui/core/styles';
import background from '@assets/background.svg';

export const useStyles = makeStyles((theme) => ({
  desktop: {
    height: '100vh',

    [theme.breakpoints.up('xxl')]: {
      '& .MuiGrid-grid-lg-8': {
        flexBasis: '66.666667%',
        maxWidth: '66.666667%',
      },

      '& .MuiGrid-grid-lg-4': {
        flexBasis: '33.333333%',
        maxWidth: '33.333333%',
      },
    },
  },
  mobile: {
    backgroundColor: '#EBF3FA',
    height: '100vh',
    padding: theme.spacing(1.5),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  logo: {
    width: '28%',
    height: '36px',
    position: 'relative',
    top: '1em',
    left: '1em',

    [theme.breakpoints.down('md')]: {
      position: 'unset',
      top: 'unset',
      left: 'unset',
      alignSelf: 'center',
      width: '100%',
    },

    [theme.breakpoints.down('sm')]: {
      height: '48px',
    },

    [theme.breakpoints.between('sm', 'lg')]: {
      height: '72px',
    },
  },
  background: {
    backgroundColor: '#EBF3FA',
    backgroundImage: `url(${background})`,
    backgroundSize: '90%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    minHeight: '20vh',
  },
  paperContainer: {
    backgroundColor: '#EBF3FA',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderTopLeftRadius: '25px',
    borderBottomLeftRadius: '25px',
    boxShadow: 'none',
    padding: theme.spacing(6),

    [theme.breakpoints.down('md')]: {
      borderRadius: '25px',
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5),
    },

    [theme.breakpoints.between('sm', 'lg')]: {
      padding: theme.spacing(3),
    },
  },
}));
