import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputBase,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import clsx from 'clsx';
import { every, isEmpty, round, snakeCase } from 'lodash';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CustomizedSnackbar from '@components/customizedSnackBar';
import CustomizedNumberFormat from '@components/customizedNumberFormat';
import DialogTitle from '@components/dialogTitle';
import FormTextInput from '@components/formTextInput';
import { customization } from '@customization/default';
import { apiRequestStatus, fundTransferOptions } from '@data/constants';
import * as userActions from '@redux/user/userActions';
import * as bankActions from '@redux/bank/bankActions';
import checkValidity from '@utils/checkValidity';

import VerificationDialog from './verify';
import { useStyles, bootstrapStyles } from './style';

const BootstrapInput = withStyles(bootstrapStyles)((props) => <InputBase {...props} />);

function FundTransferDialog({
  bankAccountList,
  bankAction,
  bankApiError,
  bankApiStatus,
  handleClose,
  location,
  open,
  token,
  user,
  userAction,
}) {
  const classes = useStyles();

  const { getBankAccountList } = bankAction;
  const { getUserDetails, getUserLocations } = userAction;

  const initialFormState = {
    accountId: {
      value: '',
      valid: false,
      required: true,
      touched: false,
    },
    transactionDescription: {
      value: '',
      valid: false,
      required: true,
      touched: false,
      error: null,
    },
    administratorNotes: {
      value: '',
      valid: true,
      required: false,
      touched: false,
      error: null,
    },
    amount: {
      value: '',
      valid: false,
      required: true,
      touched: false,
      error: null,
    },
  };

  const initialSearchState = {
    firstNameLocationCode: {
      value: '',
      valid: true,
      touched: false,
      error: null,
    },
    lastNameLocationDesc: {
      value: '',
      valid: true,
      touched: false,
      error: null,
    },
    mobileNumber: {
      value: '',
      valid: true,
      touched: false,
      error: null,
    },
  };

  const [account, setAccount] = useState({});
  const [accountList, setAccountList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [destinationAccount, setDestinationAccount] = useState({});
  const [form, setForm] = useState(initialFormState);
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [originAccount, setOriginAccount] = useState({});
  const [search, setSearch] = useState(initialSearchState);
  const [snackbar, setSnackbar] = useState({ show: false, message: null });
  const [transferType, setTransferType] = useState(fundTransferOptions[0].value);
  const [verifyDialog, setVerifyDialog] = useState(false);

  useEffect(() => {
    if (open) {
      if (user || location) {
        getBankAccountList(token);

        const account = {};

        if (user?.accountId) {
          account.id = user?.accountId;
          account.name = user?.name;
          account.currency = user?.availableBalance.symbol;
          account.balance = user?.availableBalance.value;

          getUserDetails(token, user?.id).then(({ data }) => {
            if (data) {
              const { user_number, mobile_prefix, mobile_number } = data;

              account.reference = user_number;
              account.mobile = mobile_number ? `${mobile_prefix}${mobile_number}` : '';
            } else {
              account.reference = '';
              account.mobile = '';
            }

            setAccount(account);
          });
        }

        if (location?.account) {
          account.id = location?.account?.account.id;
          account.description = location?.description;
          account.code = location?.code;
          account.currency = location?.account?.account?.available_balance.symbol;
          account.balance = location?.account?.account?.available_balance.value;

          setAccount(account);
        }
      }
    }
  }, [getBankAccountList, getUserDetails, location, open, token, user]);

  useEffect(() => {
    if (bankApiStatus === apiRequestStatus.RESOLVED) {
      setBankList(bankAccountList);
    } else if (
      bankApiStatus === apiRequestStatus.REJECTED &&
      Object.keys(bankApiError).length > 0
    ) {
      setSnackbar({ show: true, message: bankApiError.message });
    }
  }, [bankAccountList, bankApiError, bankApiStatus]);

  useEffect(() => {
    if (
      isEmpty(
        search.firstNameLocationCode.value ||
          search.lastNameLocationDesc.value ||
          search.mobileNumber.value,
      )
    ) {
      setNotFound(false);
      setAccountList([]);
    }
  }, [search, setAccountList]);

  const columns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'accountId',
      hide: true,
    },
    {
      align: 'center',
      field: 'type',
      headerAlign: 'center',
      headerClassName: 'text-center',
      headerName: customization.fundTransfer.headers.type,
    },
    {
      flex: 0.3334,
      minWidth: 250.667,
      field: 'codeMobileNumber',
      headerName: customization.fundTransfer.headers.codeMobileNumber,
    },
    {
      flex: 0.3334,
      minWidth: 250.667,
      cellClassName: 'multi-line',
      field: 'descriptionFullName',
      headerName: customization.fundTransfer.headers.descriptionFullName,
    },
    {
      flex: 0.3334,
      minWidth: 250.667,
      align: 'right',
      field: 'availableBalance',
      headerAlign: 'right',
      headerClassName: 'text-right',
      headerName: customization.fundTransfer.headers.balance,
      valueFormatter: (params) => {
        const { balance, symbol } = params.row.availableBalance;
        return `${symbol}${balance}`;
      },
      valueGetter: (params) => {
        const { balance } = params.value;
        const removedCommas = balance.replace(/,/g, '');
        const removedPeriods = removedCommas.replace(/\./g, '');
        return parseInt(removedPeriods) / 100;
      },
    },
    {
      headerAlign: 'center',
      headerName: customization.fundTransfer.headers.source,
      align: 'center',
      field: 'source',
      sortable: false,
      width: 125,
      renderCell: (params) => {
        const id = params.row.id.substring(
          params.row.id.indexOf('_') + 1,
          params.row.id.length,
        );

        const selectedAccount = {
          id: params.row.accountId,
          currency: params.row.availableBalance.symbol,
          balance: params.row.availableBalance.balance,
          ...(params.row.type === 'Client'
            ? {
                name: params.row.descriptionFullName,
                mobile: params.row.codeMobileNumber,
              }
            : {
                description: params.row.descriptionFullName,
                code: params.row.codeMobileNumber,
              }),
        };

        return (
          <Radio
            className={classes.radioBlack}
            checked={params.row.accountId === account.id}
            onChange={() => {
              handleSource(id, selectedAccount);
            }}
            disabled
            value={params.row.accountId}
            name="source"
            inputProps={{ 'aria-label': 'Source' }}
          />
        );
      },
    },
    {
      cellClassName: 'border-right',
      headerAlign: 'center',
      headerName: customization.fundTransfer.headers.destination,
      align: 'center',
      field: 'destination',
      sortable: false,
      width: 125,
      renderCell: (params) => {
        const accountId = params.row.accountId;
        const selectedAccount = {
          ...(params.row.type === 'Client'
            ? {
                name: params.row.descriptionFullName,
                mobile: params.row.codeMobileNumber,
              }
            : {
                description: params.row.descriptionFullName,
                code: params.row.codeMobileNumber,
              }),
        };

        return (
          <Radio
            className={classes.radioBlack}
            checked={params.row.accountId === form.accountId.value}
            disabled={params.row.accountId === account.id}
            onChange={() => {
              handleDestination(accountId, selectedAccount);
            }}
            value={params.row.accountId}
            name="destination"
            inputProps={{ 'aria-label': 'Destination' }}
          />
        );
      },
    },
  ];

  const formatNumber = (symbol, value) => {
    let balance = String(value).replaceAll(',', '');
    balance = parseFloat(balance).toFixed(2);

    const formattedBalance = String(balance).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${symbol}${formattedBalance}`;
  };

  const handleTransfer = () => {
    if (isValid(form)) {
      setVerifyDialog(true);
    } else {
      let errorFields = {};

      for (const [key, value] of Object.entries(form)) {
        if (!value.valid) {
          errorFields = { ...errorFields, [key]: { ...value, touched: true } };
        }
      }

      setForm({ ...form, ...errorFields });
    }
  };

  const handleSearch = () => {
    setForm({ ...form, accountId: initialFormState.accountId });

    if (isValid(search)) {
      const fields = {};

      for (const [key, value] of Object.entries(search)) {
        if (!isEmpty(value.value)) {
          fields[snakeCase(key)] = value.value;
        }
      }

      if (!isEmpty(fields)) {
        setLoading(true);
        getUserLocations(token, fields)
          .then(({ data }) => {
            const { userList, locationList } = data;

            const initialSourceRow = {
              id: user ? `client_${user?.id}` : `location_${location?.id}`,
              accountId: account.id,
              type: user ? 'Client' : 'Location',
              codeMobileNumber: user ? account.mobile : account.code,
              descriptionFullName: user ? account.name : account.description,
              availableBalance: {
                symbol: account.currency,
                balance: account.balance,
              },
            };

            if (!isEmpty(userList) || !isEmpty(locationList)) {
              const accounts = [];

              userList.forEach((user) => {
                const balance = user.user_account_map?.account?.available_balance;
                const middleName = user?.middle_name ?? '';
                const row = {
                  id: `client_${user.id}`,
                  accountId: user.user_account_map?.account?.id,
                  type: user.type,
                  codeMobileNumber: user.mobile_number ?? '',
                  descriptionFullName:
                    `${user.first_name} ${middleName} ${user.last_name}`.trim(),
                  availableBalance: {
                    symbol: user.company?.Currency?.symbol ?? '$',
                    balance: balance.slice(0, -2) + '.' + balance.slice(-2),
                  },
                };

                accounts.push(row);
              });

              locationList.forEach((location) => {
                const balance =
                  location?.location_account_map?.account?.available_balance;

                const row = {
                  id: `location_${location.id}`,
                  accountId: location?.location_account_map?.account?.id,
                  type: location.type,
                  codeMobileNumber: location.code,
                  descriptionFullName: location.description,
                  availableBalance: {
                    symbol: location.Company?.Currency?.symbol ?? '$',
                    balance: balance.slice(0, -2) + '.' + balance.slice(-2),
                  },
                };

                accounts.push(row);
              });

              setAccountList([initialSourceRow, ...accounts]);
            } else {
              setNotFound(true);
              setAccountList([]);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setAccountList([]);
      }
    } else {
      let errorFields = {};

      for (const [key, value] of Object.entries(search)) {
        if (!value.valid) {
          errorFields = { ...errorFields, [key]: { ...value, touched: true } };
        }
      }

      setSearch({ ...search, ...errorFields });
    }
  };

  const handleTransferType = (event) => {
    if (!isEmpty(originAccount)) setAccount(originAccount);
    setTransferType(event.target.value);
    setSearch(initialSearchState);
    setForm({ ...form, accountId: initialFormState.accountId });
  };

  const handleUserInput = (value, field) => {
    let valid = true;
    let error = null;

    if (form[field].required) {
      if (isEmpty(value)) valid = false;
    }

    switch (field) {
      case 'accountId':
        setAccount({ ...account });
        setDestinationAccount({
          bankAccount: bankList.filter((bank) => bank.id.toString() === value)[0],
          userLocation: null,
        });
        break;
      case 'transactionDescription':
      case 'administratorNotes':
        if (field === 'transactionDescription' && value.length > 50) {
          valid = false;
          error = customization.maximumCharacterError(50);
        }

        if (field === 'administratorNotes' && value.length > 100) {
          valid = false;
          error = customization.maximumCharacterError(100);
        }

        if (!checkValidity(value, { escapedString: true })) {
          valid = false;
          error = customization.enterValidStringError;
        }

        break;
      case 'amount': {
        valid = false;

        const amount = parseFloat(value);
        const balance = parseFloat(account.balance.replaceAll(',', ''));

        if (amount > balance)
          error = customization.insufficientBalanceError(
            account.currency,
            round(amount - balance, 2).toFixed(2),
          );
        else if (amount === 0 || isEmpty(value))
          error = customization.validFieldError('Amount');
        else valid = true;
        break;
      }
      default:
        break;
    }

    setForm({
      ...form,
      [field]: { ...form[field], touched: false, value, valid, error },
    });
  };

  const handleSearchInput = (value, field) => {
    let valid = true;
    let error = null;

    if (value.length > 75) {
      valid = false;
      error = customization.maximumCharacterError(75);
    }

    if (!checkValidity(value, { escapedString: true })) {
      valid = false;
      error = customization.enterValidStringError;
    }

    setSearch({
      ...search,
      [field]: { ...search[field], touched: false, value, valid, error },
    });
  };

  const handleSource = (id, selectedAccount) => {
    if (isEmpty(originAccount)) setOriginAccount(account);

    if (selectedAccount.name) {
      getUserDetails(token, id).then(({ data }) => {
        const additionalData = {};

        if (data) {
          const { user_number, mobile_prefix, mobile_number } = data;

          additionalData.reference = user_number;

          if (!selectedAccount.mobile) {
            additionalData.mobile = mobile_number
              ? `${mobile_prefix}${mobile_number}`
              : '';
          }
        } else {
          additionalData.reference = '';
          additionalData.mobile = '';
        }

        setAccount({ ...selectedAccount, ...additionalData });
      });
    } else {
      setAccount(selectedAccount);
    }
  };

  const handleDestination = (accountId, selectedAccount) => {
    setForm({
      ...form,
      accountId: {
        ...form.accountId,
        touched: false,
        valid: true,
        error: null,
        value: accountId,
      },
    });
    setAccount({ ...account });
    setDestinationAccount({ userLocation: { ...selectedAccount }, bankAccount: null });
  };

  const isValid = (state) => {
    const validFields = [];

    for (const keys of Object.values(state)) {
      if (keys.valid) validFields.push(true);
      else validFields.push(false);
    }

    return every(validFields);
  };

  const clearState = () => {
    handleClose();
    setTransferType(fundTransferOptions[0].value);
    setSearch(initialSearchState);
    setForm(initialFormState);
    setOriginAccount({});
  };

  const renderTitle = () => {
    let title = customization.fundTransfer.title;
    const { name, reference, description, code } = account;

    return name
      ? title.concat(` – ${name}`, reference && ` (${reference})`)
      : title.concat(` – ${code} – ${description}`);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={clearState}
        aria-labelledby="fund-transfer-dialog-title"
        transitionDuration={{ enter: 250, exit: 0 }}
      >
        <DialogTitle
          id="fund-transfer-dialog-title"
          onClose={clearState}
          className={classes.dialogTitle}
        >
          <b>{renderTitle()}</b>
        </DialogTitle>
        <DialogContent>
          <Grid
            alignItems="flex-start"
            className={classes.root}
            container
            direction="row"
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item xs={12} lg={2} className={classes.radioContainer}>
              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  aria-label="fund-transfer-type"
                  name="transfer-type"
                  value={transferType}
                  onChange={handleTransferType}
                  className={classes.radioGroup}
                >
                  {fundTransferOptions.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      value={option.value}
                      control={<Radio className={classes.radioBlack} />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            {transferType === fundTransferOptions[0].value ? (
              <>
                <Grid
                  alignItems="flex-start"
                  container
                  item
                  lg={8}
                  direction="row"
                  spacing={2}
                  className={`${classes.selfAlignCenter} ${classes.searchFieldContainer}`}
                >
                  {Object.keys(initialSearchState).map((field) => (
                    <Grid item xs={12} lg={4} key={field}>
                      <label className={classes.formLabel}>
                        <b>{customization.fundTransfer[field]}</b>
                      </label>
                      <FormTextInput
                        field={field}
                        placeholder={customization.fundTransfer[field]}
                        type={field === 'mobileNumber' ? 'number' : 'text'}
                        length={75}
                        onChangeInput={handleSearchInput}
                        touched={search[field].touched}
                        valid={search[field].valid}
                        value={search[field].value}
                        validationMessage={search[field].error}
                        onKeyDown={(event) => event.keyCode === 13 && handleSearch()}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={2}
                  className={`${classes.selfAlignCenter} ${classes.searchButtonContainer}`}
                >
                  <Button
                    autoFocus
                    disableElevation
                    onClick={handleSearch}
                    variant="outlined"
                    fullWidth
                    className={classes.buttonDefault}
                    disabled={isEmpty(
                      search.firstNameLocationCode.value ||
                        search.lastNameLocationDesc.value ||
                        search.mobileNumber.value,
                    )}
                  >
                    {customization.fundTransfer.searchButtonLabel}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {!isEmpty(accountList) ? (
                    <Box className={classes.tableContainer}>
                      <DataGrid
                        disableColumnMenu
                        disableExtendRowFullWidth
                        disableSelectionOnClick
                        hideFooter
                        className={classes.table}
                        columns={columns}
                        loading={loading}
                        maxColumns={6}
                        rows={accountList}
                        rowHeight={80}
                      />
                      <span
                        className={clsx(
                          classes.fieldTextError,
                          form.accountId.touched && !form.accountId.valid
                            ? classes.fieldTextVisible
                            : classes.fieldTextHidden,
                        )}
                      >
                        {!form.accountId.valid &&
                          customization.validSelectError('Destination User/Location')}
                      </span>
                    </Box>
                  ) : (
                    <Box className={classes.emptyPlaceholder}>
                      {notFound && (
                        <div>
                          <b>{customization.fundTransfer.notFoundPlaceholder}</b>
                        </div>
                      )}
                      {customization.fundTransfer.emptyPlaceholder}
                    </Box>
                  )}
                </Grid>
              </>
            ) : (
              <Grid item xs={12} lg={10} className={classes.selfAlignCenter}>
                <FormControl className={classes.searchControl}>
                  <label className={classes.formLabel}>
                    <b>
                      {customization.fundTransfer.destinationSelectLabel}
                      {customization.fundTransfer.requiredMark}
                    </b>
                  </label>
                  <Select
                    id="destination-bank"
                    value={form.accountId.value}
                    input={<BootstrapInput />}
                    displayEmpty
                    onChange={(event) =>
                      handleUserInput(event.target.value.toString(), 'accountId')
                    }
                    className={clsx(isEmpty(form.accountId.value) && classes.placeholder)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      classes: { paper: classes.menuPaper },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem key={0} value={''} disabled>
                      {customization.fundTransfer.destinationInputLabel.placeholder}
                    </MenuItem>
                    {bankList.length > 0 &&
                      bankList.map((bank, index) => (
                        <MenuItem key={index + 1} value={bank.id}>
                          {customization.fundTransfer.destinationInputLabel.bsb}:&nbsp;
                          {bank.bsb}&nbsp;&nbsp;
                          {customization.fundTransfer.destinationInputLabel.account}
                          :&nbsp;
                          {bank.account_number}&nbsp;&nbsp;
                          {customization.fundTransfer.destinationInputLabel.accountName}
                          :&nbsp;
                          {bank.account_name}
                        </MenuItem>
                      ))}
                  </Select>
                  <span
                    className={clsx(
                      classes.fieldTextError,
                      form.accountId.touched && !form.accountId.valid
                        ? classes.fieldTextVisible
                        : classes.fieldTextHidden,
                    )}
                  >
                    {!form.accountId.valid &&
                      customization.validSelectError('Destination Bank Account')}
                  </span>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} lg={12}>
              <FormControl className={classes.formControl}>
                <label className={classes.formLabel}>
                  <b>
                    {customization.fundTransfer.transactionDescriptionInputLabel}
                    {customization.fundTransfer.requiredMark}
                  </b>
                </label>
                <FormTextInput
                  field="transactionDescription"
                  placeholder={
                    customization.fundTransfer.transactionDescriptionPlaceholderInputLabel
                  }
                  type="text"
                  length={50}
                  onChangeInput={handleUserInput}
                  touched={form.transactionDescription.touched}
                  valid={form.transactionDescription.valid}
                  value={form.transactionDescription.value}
                  validationMessage={
                    form.transactionDescription.error ??
                    customization.validFieldError('Transation Description')
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={5}>
              <FormControl className={classes.formControl}>
                <label className={classes.formLabel}>
                  <b>{customization.fundTransfer.administratorNotesInputLabel}</b>
                </label>
                <FormTextInput
                  field="administratorNotes"
                  multiline
                  placeholder={customization.fundTransfer.administratorNotesInputLabel}
                  rows={3}
                  type="text"
                  length={100}
                  onChangeInput={handleUserInput}
                  touched={form.administratorNotes.touched}
                  valid={form.administratorNotes.valid}
                  value={form.administratorNotes.value}
                  validationMessage={
                    form.administratorNotes.error ??
                    customization.validFieldError('Administrator Notes')
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={3}>
              <FormControl className={classes.formControl}>
                <label className={classes.formLabel}>
                  <b>
                    {customization.fundTransfer.amountInputLabel}
                    {customization.fundTransfer.requiredMark}
                  </b>
                  <span className={classes.balance}>
                    <b>
                      {customization.fundTransfer.availableTextLabel}
                      {formatNumber(account?.currency, account?.balance)}
                    </b>
                  </span>
                </label>
                <FormTextInput
                  field="amount"
                  onChangeInput={handleUserInput}
                  type="text"
                  touched={form.amount.touched}
                  valid={form.amount.valid}
                  value={form.amount.value}
                  validationMessage={
                    form.amount.error ?? customization.validFieldError('Amount')
                  }
                  startAdornment={
                    account?.currency && (
                      <InputAdornment position="start">{account.currency}</InputAdornment>
                    )
                  }
                  inputComponent={CustomizedNumberFormat}
                  inputMode="numeric"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={2} className={classes.buttonContainer}>
              <Button
                autoFocus
                color="secondary"
                disableElevation
                onClick={handleTransfer}
                variant="contained"
                fullWidth
              >
                {customization.fundTransfer.transferButtonLabel}
              </Button>
            </Grid>
            <Grid item xs={12} lg={2} className={classes.buttonContainer}>
              <Button
                autoFocus
                disableElevation
                onClick={clearState}
                variant="outlined"
                fullWidth
                className={classes.buttonDefault}
              >
                {customization.fundTransfer.cancelButtonLabel}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <VerificationDialog
        data={{ ...account, ...form, ...destinationAccount }}
        open={verifyDialog}
        handleClose={() => setVerifyDialog(false)}
        handleSuccess={() => {
          setVerifyDialog(false);
          clearState();
        }}
        transferType={transferType}
      />

      <CustomizedSnackbar
        message={snackbar.message}
        showBar={snackbar.show}
        severity="error"
        handleClose={() => setSnackbar({ show: false, message: null })}
      />
    </>
  );
}

FundTransferDialog.propTypes = {
  bankAccountList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  bankAction: PropTypes.object,
  bankApiError: PropTypes.object,
  bankApiStatus: PropTypes.string,
  handleClose: PropTypes.func,
  location: PropTypes.object,
  open: PropTypes.bool,
  token: PropTypes.string,
  user: PropTypes.object,
  userAction: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    bankAccountList: state.bank.bankAccountList,
    bankApiError: state.bank.error,
    bankApiStatus: state.bank.loading,
    token: state.auth.inMemoryToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bankAction: bindActionCreators(bankActions, dispatch),
    userAction: bindActionCreators(userActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FundTransferDialog);
