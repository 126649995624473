import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import { useStyles } from './style';

function LoginButton(props) {
  const { children, ...otherProps } = props;
  const classes = useStyles(props);

  return (
    <Button
      variant="contained"
      color="primary"
      disableElevation
      className={classes.root}
      {...otherProps}
    >
      {children}
    </Button>
  );
}

LoginButton.propTypes = {
  children: PropTypes.node,
};

export default LoginButton;
