export const userLocationStyle = (theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  closeButton: {
    width: '11%',
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#F7F7F7',
  },
  boxStyle: {
    borderBottom: '2px solid #F2F2F2',
    textAlign: 'center',
  },
  successIcon: {
    color: 'green',
    fontSize: '120px',
  },
  successMessage: {
    color: 'green',
  },
  formLabelStyle: {
    color: '#0000008A',
  },
  actionButton: {
    margin: theme.spacing(0, 1),
    minWidth: 38,
    height: 38,
    width: 38,
    borderRadius: 999,

    '&.lock': {
      backgroundColor: '#ff9800',

      '&:hover': {
        backgroundColor: '#D46B45',
      },
    },

    '&.unlock': {
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ff9800',

      '&:hover': {
        backgroundColor: 'rgba(255,152,0,0.15)',
      },
    },

    '&.more': {
      backgroundColor: '#B4075B',

      '&:hover': {
        backgroundColor: '#8C0446',
      },
    },
  },
  table: {
    marginTop: theme.spacing(2),
    border: 'unset',

    '& .MuiDataGrid-cell': {
      '&:focus': {
        outline: 'unset',

        '&-within': {
          outline: 'unset',
        },
      },
    },

    '& .MuiDataGrid-withBorder': {
      borderRight: 'unset',
    },

    '& .MuiDataGrid-columnsContainer': {
      borderBottom: 'unset',
    },

    '& .MuiDataGrid-columnHeaderWrapper': {
      backgroundColor: '#EBF3FA',
      borderRadius: 5,
    },

    '& .MuiDataGrid-columnHeader': {
      color: theme.palette.grey[900],
      fontSize: '1.125rem',

      [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
      },

      '&:focus': {
        outline: 'unset',

        '&-within': {
          outline: 'unset',
        },
      },

      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 700,
        lineHeight: '3rem',
        overflow: 'hidden',
        whiteSpace: 'normal',
      },
    },

    '& .MuiDataGrid-row': {
      backgroundColor: theme.palette.common.white,
      borderRadius: 5,
      fontSize: '1rem',

      [theme.breakpoints.down('lg')]: {
        fontSize: '0.875rem',
      },

      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },

    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },

    '& .MuiDataGrid-overlay': {
      zIndex: 999,

      '& .MuiCircularProgress-root': {
        width: '56px !important',
        height: '56px !important',
        color: theme.palette.primary.main,
      },
    },

    '& .justify-center': {
      justifyContent: 'center',
    },

    '& .multi-line': {
      alignItems: 'center',
      display: 'flex',
      lineHeight: '1.43 !important',
      overflow: 'hidden',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },

    '& .text-center': {
      textAlign: 'center',
    },

    '& .text-right': {
      textAlign: 'right',
    },

    '& .transform-capitalize': {
      textTransform: 'capitalize',
    },
  },
  alignEnd: {
    textAlign: 'end',
  },
  buttonAddDialog: {
    fontSize: '1rem',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  divContainerStyle: {
    width: '50%',
  },
});

export const dialogContentStyle = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
});

export const dialogActionStyle = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
});
