import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 800,
    color: theme.palette.grey[900],
  },
  subtitle: {
    marginTop: '0.5em',
    fontSzie: '1rem',
    lineHeight: 1.5,
  },
  errorValidation: {
    color: theme.palette.error.main,
  },
  focused: {
    color: `${theme.palette.text.secondary} !important`,
  },
  forgotPassword: {
    margin: 'auto 0',
    textAlign: 'right',

    '& a': {
      color: theme.palette.secondary.main,
      cursor: 'pointer',
      fontSize: '1rem',
      fontWeight: 600,
      textDecoration: 'underline',

      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',

    '& .MuiFormControl-root': {
      marginTop: theme.spacing(2),

      '& .MuiSelect-root': {
        borderRadius: '5px !important',
      },
    },
  },
  inputPasswordLabel: {
    fontSize: '1.125rem',
    fontWeight: 700,
    userSelect: 'none',
  },
  inputPasswordRoot: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    userSelect: 'none',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: '1rem',
    width: 'auto',
    padding: '4px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
  linearStyle: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#7ECE6D',
    },
  },
  passwordStrengthContainer: {
    marginTop: '8px',
    paddingBottom: '21px',
    borderBottom: '1px solid #E7E9EC',
  },
  labelContainer: {
    flex: 1,
    display: 'flex',
  },
  strongStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    color: '#388E3C',
    fontWeight: 600,
  },
  weakStyle: {
    color: '#D32F2F',
    fontWeight: 600,
  },
  strengthLow: {
    backgroundColor: '#CCCCCC',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#D32F2F',
    },
  },
  strengthMedium: {
    backgroundColor: '#CCCCCC',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#FDD835',
    },
  },
  strengthHigh: {
    backgroundColor: '#CCCCCC',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#43A047',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'baseline',

    '& .MuiIconButton-root': {
      color: theme.palette.grey[900],
    },
  },
}));
