const BankTypes = {
  GET_BANK_LIST_REQUEST: 'GET_BANK_LIST_REQUEST',
  GET_BANK_LIST_SUCCESS: 'GET_BANK_LIST_SUCCESS',
  GET_BANK_LIST_ERROR: 'GET_BANK_LIST_ERROR',

  GET_BANK_ACCOUNT_REQUEST: 'GET_BANK_ACCOUNT_REQUEST',
  GET_BANK_ACCOUNT_SUCCESS: 'GET_BANK_ACCOUNT_SUCCESS',
  GET_BANK_ACCOUNT_ERROR: 'GET_BANK_ACCOUNT_ERROR',

  CREATE_BANK_ACCOUNT_REQUEST: 'CREATE_BANK_ACCOUNT_REQUEST',
  CREATE_BANK_ACCOUNT_SUCCESS: 'CREATE_BANK_ACCOUNT_SUCCESS',
  CREATE_BANK_ACCOUNT_ERROR: 'CREATE_BANK_ACCOUNT_ERROR',

  UPDATE_BANK_ACCOUNT_REQUEST: 'UPDATE_BANK_ACCOUNT_REQUEST',
  UPDATE_BANK_ACCOUNT_SUCCESS: 'UPDATE_BANK_ACCOUNT_SUCCESS',
  UPDATE_BANK_ACCOUNT_ERROR: 'UPDATE_BANK_ACCOUNT_ERROR',

  RESET_ERROR_STATE: 'RESET_ERROR_STATE',
  RESET_STATE: 'RESET_STATE',
};

export default BankTypes;
