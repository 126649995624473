import { TablePagination } from '@material-ui/core';
import PropTypes from 'prop-types';
import TablePaginationActions from './tablePaginationActions';

function DataGridPagination(props) {
  return (
    <TablePagination
      component="div"
      rowsPerPageOptions={[25, 50, 100]}
      SelectProps={{ inputProps: { 'aria-label': 'Rows per page' } }}
      ActionsComponent={TablePaginationActions}
      {...props}
    />
  );
}

DataGridPagination.propTypes = {
  count: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
};

export default DataGridPagination;
