export const customInputFieldStyle = (theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    userSelect: 'none',
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
});

export const customLabelFieldStyle = (theme) => ({
  root: {
    fontSize: 18,
    fontWeight: 700,
    '&$focused': {
      color: theme.palette.text.secondary,
    },
    userSelect: 'none',
  },
  focused: {},
});
