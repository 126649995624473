import { Button, Dialog, DialogContent, FormControl, Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AlertOrangeRoundedIcon from '@assets/icons/Alert-orange-rounded.png';
import CustomizedSnackbar from '@components/customizedSnackBar';
import DialogTitle from '@components/dialogTitle';
import FormTextInput from '@components/formTextInput';
import SuccessMessageDialog from '@components/successMessageDialog';
import { customization } from '@customization/default';
import { fundTransferOptions } from '@data/constants';
import * as bankActions from '@redux/bank/bankActions';
import * as userActions from '@redux/user/userActions';
import * as transferFundsActions from '@redux/transferFunds/transferFundsActions';

import { useStyles } from './style';

function VerificationDialog({
  bankAction,
  data,
  handleClose,
  handleSuccess,
  open,
  token,
  transferFundsAction,
  transferType,
  userAction,
}) {
  const classes = useStyles();

  const { bankResetState } = bankAction;

  const { transferFunds } = transferFundsAction;

  const { verifyAdminPassword } = userAction;

  const initialFormState = {
    password: {
      value: '',
      valid: false,
      required: true,
      touched: false,
      error: null,
    },
  };

  const [form, setForm] = useState(initialFormState);
  const [snackbar, setSnackbar] = useState({ show: false, message: null });
  const [success, setSuccess] = useState(false);

  const handleUserInput = (value, field) => {
    let valid = true;
    let error = null;

    if (form[field].required) {
      if (isEmpty(value)) valid = false;
    }

    setForm({
      ...form,
      [field]: { ...form[field], touched: false, value, valid, error },
    });
  };

  const handleProceed = () => {
    if (form.password.valid) {
      verifyAdminPassword(token, form.password.value)
        .then((response) => {
          if (response.status === 200) {
            let payload = {};

            if (transferType === fundTransferOptions[0].value) {
              payload = {
                from_account_id: parseInt(data?.id),
                to_account_id: parseInt(data?.accountId.value),
                transaction_description: data?.transactionDescription.value,
                administrator_notes: !isEmpty(data?.administratorNotes.value)
                  ? data?.administratorNotes.value
                  : null,
                amount: data.amount.value,
              };
            } else if (transferType === fundTransferOptions[1].value) {
              payload = {
                user_location_account_id: data?.id,
                bank_account_fk: parseInt(data?.accountId.value),
                transaction_description: data?.transactionDescription.value,
                administrator_notes: !isEmpty(data?.administratorNotes.value)
                  ? data?.administratorNotes.value
                  : null,
                amount: data?.amount.value,
              };
            }

            transferFunds(token, transferType, payload)
              .then((response) => {
                if (response.status === 200) {
                  setSuccess(true);
                  setForm(initialFormState);
                  handleSuccess();
                } else {
                  setSnackbar({ show: true, message: customization.systemError });
                }
              })
              .catch((error) => {
                setSnackbar({ show: true, message: error });
              })
              .finally(() => {
                bankResetState();
              });
          } else {
            setForm({
              ...form,
              password: {
                ...form.password,
                touched: true,
                valid: false,
                error: customization.fundTransfer.confirmationDialog.invalidPassword,
              },
            });
          }
        })
        .catch(() => {
          setForm({
            ...form,
            password: {
              ...form.password,
              touched: true,
              valid: false,
              error: customization.fundTransfer.confirmationDialog.invalidPassword,
            },
          });
        });
    } else {
      setForm({ ...form, password: { ...form.password, touched: true } });
    }
  };

  const renderDescription = () => {
    if (data.userLocation) {
      if (data.userLocation?.name) {
        return (
          <p>
            {customization.fundTransfer.confirmationDialog.mobile(
              data.userLocation?.mobile,
            )}
            <br />
            {customization.fundTransfer.confirmationDialog.name(data.userLocation?.name)}
          </p>
        );
      }

      return (
        <p>
          {customization.fundTransfer.confirmationDialog.code(data.userLocation?.code)}
          <br />
          {customization.fundTransfer.confirmationDialog.description(
            data.userLocation?.description,
          )}
        </p>
      );
    }
    return (
      <p>
        {customization.fundTransfer.confirmationDialog.bankAccountNumber(
          data.bankAccount?.account_number,
        )}
        <br />
        {customization.fundTransfer.confirmationDialog.bankAccountName(
          data.bankAccount?.account_name,
        )}
      </p>
    );
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        PaperProps={{ className: classes.dialog }}
        transitionDuration={{ enter: 250, exit: 0 }}
      >
        <DialogTitle id="verification-dialog-title" onClose={handleClose} />
        <DialogContent>
          <Grid container spacing={2} direction="row" alignContent="center">
            <Grid item xs={12} className={classes.alignCenter}>
              <img
                src={AlertOrangeRoundedIcon}
                alt="AlertOrangeRoundedIcon"
                loading="lazy"
                className={classes.errorIcon}
              />
            </Grid>
            <Grid item xs={12} className={`${classes.alignCenter} ${classes.dialogText}`}>
              <p>
                {customization.fundTransfer.confirmationDialog.title(
                  data.currency,
                  data.amount.value,
                  data.name ?? `${data.code} - ${data.description}`,
                )}
              </p>
              {renderDescription()}
              <p>{customization.fundTransfer.confirmationDialog.alert}</p>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={`${classes.formControl} ${classes.password}`}>
                <label className={classes.formLabel}>
                  <b>{customization.fundTransfer.passwordInputLabel}</b>
                </label>
                <FormTextInput
                  field="password"
                  placeholder={customization.fundTransfer.passwordInputLabel}
                  type="password"
                  onChangeInput={handleUserInput}
                  touched={form.password.touched}
                  valid={form.password.valid}
                  value={form.password.value}
                  validationMessage={
                    form.password.error
                      ? form.password.error
                      : customization.validPasswordError
                  }
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) handleProceed();
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} direction="row" justifyContent="space-between">
                <Grid item xs={6}>
                  <Button
                    autoFocus
                    color="secondary"
                    disableElevation
                    variant="contained"
                    fullWidth
                    onClick={handleProceed}
                  >
                    {customization.fundTransfer.proceedButtonLabel}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    autoFocus
                    disableElevation
                    variant="outlined"
                    onClick={() => {
                      setForm(initialFormState);
                      handleClose();
                    }}
                    fullWidth
                  >
                    {customization.fundTransfer.cancelButtonLabel}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <SuccessMessageDialog
        openSuccess={success}
        bindHandleClose={() => setSuccess(false)}
        message={customization.fundTransfer.confirmationDialog.success}
      />

      <CustomizedSnackbar
        message={snackbar.message}
        showBar={snackbar.show}
        severity="error"
        handleClose={() => setSnackbar({ show: false, message: null })}
      />
    </>
  );
}

VerificationDialog.propTypes = {
  bankAction: PropTypes.object,
  data: PropTypes.object,
  handleClose: PropTypes.func,
  handleSuccess: PropTypes.func,
  open: PropTypes.bool,
  transferFundAction: PropTypes.object,
  transferType: PropTypes.string,
  userAction: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bankAction: bindActionCreators(bankActions, dispatch),
    transferFundsAction: bindActionCreators(transferFundsActions, dispatch),
    userAction: bindActionCreators(userActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerificationDialog);
