const LocationTypes = {
  GET_LOCATION_LIST_REQUEST: 'GET_LOCATION_LIST_REQUEST',
  GET_LOCATION_LIST_SUCCESS: 'GET_LOCATION_LIST_SUCCESS',
  GET_LOCATION_LIST_ERROR: 'GET_LOCATION_LIST_ERROR',

  CLEAR_ERROR_STATE: 'CLEAR_ERROR_STATE',
  CLEAR_STATE: 'CLEAR_STATE',
};

export default LocationTypes;
