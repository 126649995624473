export const formCurrencyInputStyle = (theme) => ({
  root: {
    height: '40px',
  },
  input: {
    fontWeight: 'inherit',
  },
  textBoldInput: {
    fontWeight: 'bold',
  },
});
