import { Link, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/ExitToAppRounded';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomizedSnackBar from '@components/customizedSnackBar';
import history from '@utils/history';
import * as authActions from '@redux/auth/authActions';
import { useStyles } from './style';

function Logout(props) {
  const classes = useStyles();

  const { authAction, isListItem, token } = props;

  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleLogout = (event) => {
    event.preventDefault();
    try {
      authAction.adminLogout(token);
      history.push('/login');
    } catch (error) {
      setSnackbar(true);
      setSnackbarMessage(error);
    }
  };

  return (
    <>
      {snackbar ? (
        <CustomizedSnackBar
          classes={classes.snackbar}
          showBar={snackbar}
          severity="error"
          message={snackbarMessage}
          handleClose={() => setSnackbar(false)}
        />
      ) : null}

      {!isEmpty(token) ? (
        isListItem ? (
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        ) : (
          <Link component="button" onClick={handleLogout}>
            Log Out
          </Link>
        )
      ) : null}
    </>
  );
}

Logout.propTypes = {
  authActions: PropTypes.object,
  isListItem: PropTypes.bool,
  token: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authAction: bindActionCreators(authActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
