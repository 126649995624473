export const dialogErrorStyle = (theme) => ({
  errorIcon: {
    color: 'red',
    fontSize: '100px',
  },
  errorMessage: {
    color: 'red',
  },
  errorIconContainerStyle: {
    textAlign: 'center',
    paddingBottom: '30px',
  },
  errorMsgContainerStyle: {
    textAlign: 'center',
    paddingBottom: '20px',
  },
  paperErrorMessageStyle: {
    border: '2px solid red',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});
