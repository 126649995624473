import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  root: {
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, #EBF3FA 100%)',
    display: 'flex',
    minHeight: '100vh',
  },
  tableContainer: {
    borderRadius: 25,
    padding: theme.spacing(2),
  },
  active: {
    backgroundColor: '#EB4E88',
  },
  appBar: {
    width: `calc(100% - 52px)`,
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
    paddingLeft: '24px',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth - 22}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',

    '& .MuiPaper-root': {
      backgroundColor: '#233862',
      color: '#FFFFFF',
      borderTopRightRadius: 25,
      borderBottomRightRadius: 25,
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
      padding: theme.spacing(0, 1),

      '& .MuiDivider-root': {
        backgroundColor: '#FFFFFF',
        width: 'calc(100% - 24px)',
        margin: '0 auto',
      },

      '& .MuiList-root': {
        '& .MuiListItem-root': {
          borderRadius: 5,
          margin: theme.spacing(1, 0),

          '&:hover': {
            backgroundColor: '#0090EB',
          },

          '& .MuiListItemIcon-root': {
            color: '#FFFFFF',
          },

          '& .MuiListItemText-root': {
            '& .MuiListItemText-primary': {
              fontWeight: 500,
            },
          },
        },
      },
    },
  },
  topList: {
    flex: 1,
    '& .MuiListItemText-root': {
      color: '#fff',
    },
  },
  bottomList: {
    '& .MuiListItemText-root': {
      color: '#fff',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'transparent',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  header: {
    '& .MuiTypography-h5': {
      flex: 1,
      fontWeight: 700,
      color: '#212121',
      textAlign: 'center',
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,

    '& .MuiTypography-subtitle1': {
      fontWeight: 500,
    },

    '& .MuiTypography-root': {
      '& .MuiIconButton-root': {
        color: '#FFFFFF',
      },
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
