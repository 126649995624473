import React from 'react';
import { withStyles } from '@material-ui/styles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import LoginLayout from '@components/layout/loginLayout';
import { LoginTitle } from '@components/login/loginTitle';
import { apiRequestStatus } from '@data/constants';
import LoginButton from '@components/login/loginButton';
import { adminResendAuthenticationCode } from '@services/api/auth';
import { customization } from '@customization/default';
import history from '@utils/history';
import { adminMultiFactorAuthentication, adminClearErrorState } from '@redux';
import BaseComponent from '@containers/BaseComponent';
import OtpInput from 'react-otp-input';
import { loginMultiFactorAuthenticationStyle } from './style';

class LoginMultiFactorAuthenticationPage extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      authCode: '',
      unexpectedError: null,
      resendCode: {
        status: apiRequestStatus.IDLE,
        error: {},
        data: {},
        continueClicked: false,
        resendCodeClicked: false,
      },
      loading: false,
    };
  }

  handleAuthenticationCodeInput = (otp) => {
    if (
      this.state.unexpectedError ||
      this.state.resendCode?.error ||
      this.props.adminMultiFactorAuthenticationRequestError?.message
    ) {
      this.setState({
        unexpectedError: null,
        resendCode: { ...this.state.resendCode, error: {} },
      });

      this.props.adminClearErrorState();
    }

    this.setState({
      authCode: otp,
    });
  };

  handleContinue = async (event) => {
    event.preventDefault();
    const params = {
      authentication_code: this.state.authCode,
    };

    this.setState({
      resendCodeClicked: false,
      continueClicked: true,
      loading: true,
    });
    try {
      const response = await this.props.adminMultiFactorAuthentication(
        this.props.token,
        params,
      );

      if (response.message) {
        this.setState({
          unexpectedError: response.message,
        });
      }

      if (!response?.data) return;
      history.push('/admin/dashboard/view-users');
    } catch (error) {
      this.setState({
        unexpectedError: error.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleResendCode = async (event) => {
    event.preventDefault();
    this.setState({
      resendCode: {
        status: apiRequestStatus.PENDING,
      },
      resendCodeClicked: true,
      continueClicked: false,
    });

    try {
      const response = await adminResendAuthenticationCode(this.props.token);
      if (response.status === 200) {
        this.setState((state) => ({
          resendCode: {
            status: apiRequestStatus.RESOLVED,
            data: { ...state.resendCode.data, ...response.data },
          },
        }));
      }
    } catch (error) {
      this.setState((state) => ({
        resendCode: {
          status: apiRequestStatus.REJECTED,
          error:
            { ...state.resendCode.error, ...error?.response?.data } || error?.message,
        },
      }));
    }
  };

  render() {
    const {
      classes,
      adminMultiFactorAuthenticationRequestError,
      adminMultiFactorAuthenticationRequestStatus,
    } = this.props;

    const {
      authCode,
      unexpectedError,
      resendCode,
      resendCodeClicked,
      continueClicked,
      loading,
    } = this.state;
    const REMAINING_ATTEMPT_LIMIT = 2;
    const ACCOUNT_LOCKED = /locked/i.test(
      adminMultiFactorAuthenticationRequestError?.message,
    );
    const EXPIRED_AUTHENTICATION_CODE = /expired/i.test(
      adminMultiFactorAuthenticationRequestError?.message,
    );
    const RESEND_CODE_SUCCESSFULLY = /successful/i.test(resendCode?.data?.message);

    const renderMessage = () => {
      if (
        adminMultiFactorAuthenticationRequestStatus !== apiRequestStatus.PENDING &&
        continueClicked
      ) {
        if (adminMultiFactorAuthenticationRequestError?.remainingAttempt) {
          return (
            <LoginTitle color="error">
              {
                customization.loginMultiFactorAuthenticationPage
                  .authenticationCodeFailureMessage
              }
              {adminMultiFactorAuthenticationRequestError?.remainingAttempt >=
              REMAINING_ATTEMPT_LIMIT
                ? `You have ${adminMultiFactorAuthenticationRequestError?.remainingAttempt} attempt(s) remaining.`
                : customization.loginMultiFactorAuthenticationPage
                    .authenticationCodeFailureMessageLastAttempt}
              {ACCOUNT_LOCKED
                ? customization.loginMultiFactorAuthenticationPage.lockedAccount
                : ''}
            </LoginTitle>
          );
        }
      } else if (resendCode.status !== apiRequestStatus.PENDING && resendCodeClicked) {
        return RESEND_CODE_SUCCESSFULLY ? (
          <LoginTitle color="error">
            {
              customization.loginMultiFactorAuthenticationPage
                .resendAuthenticationCodeSuccess
            }
          </LoginTitle>
        ) : (
          <LoginTitle color="error">
            {
              customization.loginMultiFactorAuthenticationPage
                .resendAuthenticationCodeFailed
            }
          </LoginTitle>
        );
      } else if (
        !!adminMultiFactorAuthenticationRequestError?.message ||
        !!unexpectedError ||
        !!resendCode?.error?.message
      ) {
        if (EXPIRED_AUTHENTICATION_CODE) {
          return (
            <LoginTitle color="error">
              {customization.loginMultiFactorAuthenticationPage.expiredAuthenticationCode}
            </LoginTitle>
          );
        }

        if (unexpectedError) {
          return <LoginTitle color="error">{customization.unexpectedError}</LoginTitle>;
        }
      }
    };

    return (
      <LoginLayout>
        <Typography variant="h4" component="div" className={classes.header}>
          {customization.loginMultiFactorAuthenticationPage.introduction}
          <Typography variant="subtitle1" className={classes.subtitle}>
            {customization.loginMultiFactorAuthenticationPage.introductionDescription}
          </Typography>
        </Typography>

        {renderMessage()}

        <Box className={classes.formContainer}>
          <form className={classes.form}>
            <OtpInput
              className={classes.otp}
              value={authCode}
              onChange={this.handleAuthenticationCodeInput}
              numInputs={6}
              isDisabled={ACCOUNT_LOCKED}
              inputType="tel"
              containerStyle={classes.otpContainer}
              inputStyle={classes.otpInput}
              renderInput={(props) => <input {...props} />}
            />

            <LoginButton
              disabled={!authCode || ACCOUNT_LOCKED || loading}
              onClick={this.handleContinue}
              type="submit"
            >
              {adminMultiFactorAuthenticationRequestStatus === apiRequestStatus.PENDING
                ? customization.loading
                : customization.verify}
            </LoginButton>

            <LoginButton
              variant="text"
              disabled={ACCOUNT_LOCKED || resendCode.status === apiRequestStatus.PENDING}
              onClick={this.handleResendCode}
              className={classes.resendCode}
            >
              {resendCode.status === apiRequestStatus.PENDING
                ? customization.loading
                : customization.resendCode}
            </LoginButton>
          </form>
        </Box>
      </LoginLayout>
    );
  }
}

LoginMultiFactorAuthenticationPage.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    adminMultiFactorAuthenticationRequestError: state.auth.error,
    adminMultiFactorAuthenticationRequestStatus: state.auth.loading,
    token: state.auth.inMemoryToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminMultiFactorAuthentication: (token, params) =>
      dispatch(adminMultiFactorAuthentication(token, params)),
    adminClearErrorState: () => dispatch(adminClearErrorState()),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withStyles(loginMultiFactorAuthenticationStyle),
  withConnect,
)(LoginMultiFactorAuthenticationPage);
