import { getLocationList as getLocationListService } from '@services/api/locationApi';
import locationTypes from './locationTypes';

export const getLocationList = (token, page, size, search, order, sort, active) => {
  return async (dispatch) => {
    dispatch({ type: locationTypes.GET_LOCATION_LIST_REQUEST });
    try {
      const response = await getLocationListService(
        token,
        page,
        size,
        search,
        order,
        sort,
        active,
      );
      dispatch({ type: locationTypes.GET_LOCATION_LIST_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: locationTypes.GET_LOCATION_LIST_ERROR, error });
      return error;
    }
  };
};
