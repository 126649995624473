import { get } from './baseApi';

const getAuditHistoryList = async (operationTag, id, token) => {
  return get(`/admin/${operationTag}/${id}/audit`, token);
};

const getAuditHistoryDetail = async (operationTag, id, token) => {
  return get(`/admin/${operationTag}/${id}/audit-detail`, token);
};

export { getAuditHistoryList, getAuditHistoryDetail };
