import React from 'react';
import { withStyles } from '@material-ui/styles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import CardImage from '@assets/icons/CardHeroCardNew.png';
import Typography from '@material-ui/core/Typography';
import { customization } from '@customization/default';
import { cardStatus as cardStatusType } from '@data/constants';
import { cardStyle } from './style';

const CardHeroCard = (props) => {
  const {
    classes,
    cardData: { cardStatus },
  } = props;

  const cardStatusMap = () => {
    switch (cardStatus) {
      case cardStatusType.ACTIVE:
        return (
          <div className={classes.cardStatusActiveBlockedClosedContainer}>
            <Typography className={classes.cardStatus}>
              {customization.cardDetails.cardStatusDisplay.ACTIVE}
            </Typography>
          </div>
        );
      case cardStatusType.BLOCKED:
      case cardStatusType.LOCKED:
        return (
          <div className={classes.cardStatusActiveBlockedClosedContainer}>
            <Typography className={classes.cardStatus}>
              {customization.cardDetails.cardStatusDisplay.BlOCKED}
            </Typography>
          </div>
        );
      case cardStatusType.INACTIVE:
        return (
          <div className={classes.cardStatusActiveBlockedClosedContainer}>
            <Typography className={classes.cardStatus}>
              {customization.cardDetails.cardStatusDisplay.INACTIVE}
            </Typography>
          </div>
        );
      case cardStatusType.FRAUD_DETECTED:
        return (
          <div className={classes.cardStatusExceededFraudContainer}>
            <Typography className={classes.cardStatus}>
              {customization.cardDetails.cardStatusDisplay.FRAUD_DETECTED}
            </Typography>
          </div>
        );
      case cardStatusType.EXCEEDED_INCORRECT_PIN:
        return (
          <div className={classes.cardStatusExceededFraudContainer}>
            <Typography className={classes.cardStatus}>
              {customization.cardDetails.cardStatusDisplay.EXCEEDED_INCORRECT_PIN}
            </Typography>
          </div>
        );
      default:
        return (
          <div className={classes.cardStatusActiveBlockedClosedContainer}>
            <Typography className={classes.cardStatus}>N/A</Typography>
          </div>
        );
    }
  };

  return (
    <div className={classes.cardImageContainer}>
      <img
        className={classes.cardImage}
        src={CardImage}
        alt="card_image"
        loading="lazy"
      />
      {cardStatusMap()}
    </div>
  );
};

CardHeroCard.propTypes = {
  classes: PropTypes.object,
  cardData: PropTypes.object,
  expired_date: PropTypes.string,
  card_number: PropTypes.string,
};

export default compose(withStyles(cardStyle))(CardHeroCard);
