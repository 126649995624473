import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SecondBlock from '@components/formUserSecondBlock';
import { userLocationType, apiRequestStatus, userOperationTag } from '@data/constants';
import { customization } from '@customization/default';
import LoadingBackdrop from '@components/loadingBackdrop';
import SuccessMessageDialog from '@components/successMessageDialog';
import ErrorMessageDialog from '@components/errorMessageDialog';
import {
  getCompanyList as getCompanyListService,
  getUserTypeList as getUserTypeListService,
  getCountryCodeList as getCountryCodeListService,
  getTimeZoneList as getTimeZoneListService,
} from '@services/api/userApi';
import FormRootUser from '@components/formRootUser';
import {
  getUserById as getUserByIdService,
  patchUserData as patchUserDataService,
} from '@services/api/userApi';
import { editUserDialogStyle } from './style';
function EditUserDialog(props) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [newUserData, setNewUserData] = useState({});
  const [errorMessage, setErrorMessage] = useState({ isError: false, message: '' });
  const [selectedUserType, setUserType] = useState({});
  const [rootIsFormValid, setRootIsFormValid] = useState(false);
  const [selectedDefaultLocation, setDefaultLocation] = useState({});
  const [formDataFirstBlock, setFormDataFirstBlock] = useState({});
  const [formDataSecondBlock, setFormDataSecondBlock] = useState({});
  const [isSubmitEdit, setSubmitEdit] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [editUserLoading, setEditUserLoading] = useState(apiRequestStatus.IDLE);
  const { classes, userData, token, bindCloseEditDialog } = props;
  const [responseCompaniesList, setResponseCompaniesList] = useState([]);
  const [responseUserTypesList, setResponseUserTypesList] = useState([]);
  const [responseCountriesList, setResponseCountriesList] = useState([]);
  const [responseTimeZonesList, setResponseTimeZonesList] = useState([]);
  const [isDisableSaveButton, setIsDisableSaveButton] = useState(false);
  const [byPassReview, setByPassReview] = useState(false);

  useEffect(() => {
    const handleClickOpen = async () => {
      try {
        if (props.openEditDialog) {
          setEditUserLoading(apiRequestStatus.PENDING);
          const { id, inReview } = userData;

          try {
            const [
              responseCompanies,
              responseUserTypes,
              responseCountries,
              responseTimeZones,
              responseNewUserData,
            ] = await Promise.all([
              getCompanyListService(props.token),
              getUserTypeListService(props.token),
              getCountryCodeListService(props.token),
              getTimeZoneListService(props.token),
              getUserByIdService(token, id, inReview),
            ]);
            setResponseCompaniesList(responseCompanies);
            setResponseCountriesList(responseCountries);
            setResponseUserTypesList(responseUserTypes);
            setResponseTimeZonesList(responseTimeZones);
            setNewUserData({ ...responseNewUserData.data });
          } catch (error) {
            handleErrorRetrieveData(
              error?.response?.status + ': ' + error?.response?.data?.message,
            );
          }
          setEditUserLoading(apiRequestStatus.RESOLVED);
          setShowEditDialog(true);
        }
      } catch (error) {
        setEditUserLoading(apiRequestStatus.REJECTED);
        setErrorMessage({
          isError: true,
          message: error.message,
        });
      }
    };
    handleClickOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openEditDialog]);

  useEffect(() => {
    if (Object.keys(newUserData).length !== 0) {
      setEditUserLoading(apiRequestStatus.RESOLVED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserData]);

  const handleErrorRetrieveData = (errorMessage) => {
    setErrorMessage({
      isError: true,
      message: errorMessage,
    });
  };

  const handleCloseErrorMessage = () => {
    setEditUserLoading(apiRequestStatus.IDLE);
    setErrorMessage({
      isError: false,
      message: '',
    });
    handleClose();
  };

  const handleDisableButtonSave = () => {
    setIsDisableSaveButton(true);
  };

  const handleClose = () => {
    setEditUserLoading(apiRequestStatus.IDLE);
    setShowEditDialog(false);
    bindCloseEditDialog();
    setOpenSuccess(false);
    setDefaultLocation({});
    setFormDataFirstBlock({});
    setRootIsFormValid(false);
    setUserType({});
    setIsDisableSaveButton(false);
  };

  const successMessage = () => {
    return byPassReview
      ? customization.userAddEditDialog.editUpdatedSuccessMessage
      : customization.userAddEditDialog.editSubmittedSuccessMessage;
  };

  // mapping all field in form into request body
  useEffect(() => {
    const tempLocations = [];
    const tempClients = [];
    let reqBody = {};

    if (userData) {
      const { id } = userData;

      if (formDataFirstBlock.values && formDataSecondBlock.secondBlockCheck) {
        if (formDataSecondBlock.userLocations) {
          formDataSecondBlock.userLocations.forEach((data) => {
            const resultObj = {};
            resultObj.id = data.id;
            resultObj.is_active = data.active;
            if (data.type.toUpperCase() === userLocationType.LOCATION) {
              tempLocations.push(resultObj);
            } else {
              tempClients.push(resultObj);
            }
          });
        }

        const firstForm = formDataFirstBlock.values.body;
        reqBody = {
          // first block form
          status: firstForm.status,
          user_number: firstForm.user_number,
          first_name: firstForm.first_name,
          middle_name: firstForm.middle_name,
          last_name: firstForm.last_name,
          email: firstForm.email,
          mobile_number: firstForm.mobile_number,
          user_type_id: firstForm.user_type_id,
          company_id: firstForm.company_id,
          country_id: firstForm.country_id,
          default_location_id: firstForm.default_location_id,
          default_timezone: firstForm.default_timezone,

          // second block form
          location_delegation: tempLocations,
          user_delegation: tempClients,
          is_overall_administrator:
            formDataSecondBlock.secondBlockCheck.overallAdministrator,
        };

        // eslint-disable-next-line no-inner-declarations
        async function editUserSubmit() {
          setSubmitEdit(false);
          try {
            const response = await patchUserDataService(
              id,
              reqBody,
              props.token,
              userData.inReview,
            );
            if (response.status === 200) {
              undoFilledForm();
              setOpenSuccess(true);
              const isByPassed = response.data.message.includes(
                'has been updated successfully',
              );
              setByPassReview(isByPassed);
            } else {
              throw new Error(response.response.data.message);
            }
          } catch (error) {
            setErrorMessage({
              isError: true,
              message: error?.response?.data?.message || error?.message,
            });
          }
        }

        if (isSubmitEdit) {
          editUserSubmit();
          setIsDisableSaveButton(false);
        }
      }
    }
  }, [
    formDataSecondBlock,
    formDataFirstBlock,
    props,
    bindCloseEditDialog,
    isSubmitEdit,
    userData,
  ]);

  const undoFilledForm = () => {
    setFormDataSecondBlock({});
    setFormDataFirstBlock({});
    setRootIsFormValid(false);
  };

  const getSelectedUserType = (val) => {
    setUserType(val);
  };

  const getRootIsFormValid = (val) => {
    setRootIsFormValid(val);
  };

  const getDefaultLocation = (val) => {
    setDefaultLocation(val);
  };

  const getDataFromFirstBlock = (values) => {
    setFormDataFirstBlock({
      values,
    });
  };

  const getSecondBlockValues = (_, userLocations, secondBlockCheck) => {
    const tempDataSecondBlock = {};
    tempDataSecondBlock.userLocations = userLocations;
    tempDataSecondBlock.secondBlockCheck = secondBlockCheck;
    setFormDataSecondBlock(tempDataSecondBlock);
    setSubmitEdit(true);
  };

  const getSelectedCompanyId = (val) => {
    setSelectedCompanyId(val);
  };

  return (
    <div>
      <LoadingBackdrop status={editUserLoading} />
      <SuccessMessageDialog
        openSuccess={openSuccess}
        bindHandleClose={handleClose}
        message={successMessage()}
        className={classes.roundedButton}
      />
      <ErrorMessageDialog
        bindHandleClose={handleCloseErrorMessage}
        openSuccess={errorMessage.isError}
        errorData={errorMessage.message}
      />
      {!errorMessage.isError && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={showEditDialog}
          aria-labelledby="form-dialog-title"
        >
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Typography variant="h6" className={classes.addUserDialogStyle}>
                  {customization.userAddEditDialog.editTitle}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
          <DialogContent>
            <FormRootUser
              operationTag={userOperationTag.EDIT_USER}
              userData={newUserData}
              bindErrorRetrieve={handleErrorRetrieveData}
              bindSubmit={getDataFromFirstBlock}
              bindClose={handleClose}
              setUserType={getSelectedUserType}
              setIsFormValid={getRootIsFormValid}
              setDefaultLocation={getDefaultLocation}
              setCompanyId={getSelectedCompanyId}
              responseCompanies={responseCompaniesList}
              responseUserTypes={responseUserTypesList}
              responseCountries={responseCountriesList}
              responseTimeZones={responseTimeZonesList}
            />
            {selectedUserType.id && (
              <SecondBlock
                userData={newUserData}
                operationTag={userOperationTag.EDIT_USER}
                selectedUserType={selectedUserType}
                bindClose={handleClose}
                rootIsFormValid={rootIsFormValid}
                defaultLocation={selectedDefaultLocation}
                submitCreate={getSecondBlockValues}
                selectedCompanyId={selectedCompanyId}
                bindHandleDisableButtonSave={handleDisableButtonSave}
                isDisableSaveButton={isDisableSaveButton}
              ></SecondBlock>
            )}
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

EditUserDialog.propTypes = {
  classes: PropTypes.object,
  postUserData: PropTypes.func,
  userData: PropTypes.object,
  token: PropTypes.string,
  openEditDialog: PropTypes.bool,
  bindCloseEditDialog: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.inMemoryToken.token,
  };
};

const withConnect = connect(mapStateToProps);
export default compose(withStyles(editUserDialogStyle), withConnect)(EditUserDialog);
