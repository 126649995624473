import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    '& button': {
      color: 'black',
    },
  },
  alignCenter: {
    textAlign: 'center',
  },
  balance: {
    float: 'right',
    color: theme.palette.primary.main,
  },
  buttonDefault: {
    backgroundColor: '#F7F7F7',

    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },
  buttonContainer: {
    [theme.breakpoints.up('lg')]: {
      paddingTop: `${theme.spacing(4)}px !important`,
      paddingBottom: '0px !important',

      '& .MuiButton-root': {
        lineHeight: '2.075',
      },
    },
  },
  dialog: {
    border: '1px solid #EE6F3F',
    paddingBottom: theme.spacing(4),
  },
  dialogText: {
    color: '#EE6F3F',
    fontWeight: 600,
    fontSize: '1rem',
  },
  errorIcon: {
    maxHeight: 96,
  },
  emptyPlaceholder: {
    textAlign: 'center',
    color: '#1976D2',
    backgroundColor: '#E3F2FD',
    border: '1px solid #1976D2',
    borderRadius: 5,

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4),
      margin: theme.spacing(2, 8),
    },
  },
  fieldTextError: {
    color: '#d32f2f',
  },
  fieldTextVisible: {
    display: 'block',
  },
  fieldTextHidden: {
    display: 'none',
  },
  formControl: {
    width: '100%',

    '& div .MuiInputBase-multiline': {
      padding: 0,
    },
  },
  formLabel: {
    position: 'relative',
    color: '#555555',
  },
  menuPaper: {
    maxHeight: 260,
  },
  password: {
    marginBottom: theme.spacing(3),
  },
  placeholder: {
    color: 'rgba(0, 0, 0, 0.35)',
  },
  radioBlack: {
    '&.MuiRadio-colorSecondary.Mui-checked': {
      color: theme.palette.common.black,
    },
  },
  radioContainer: {
    [theme.breakpoints.up('lg')]: {
      borderRight: '1px solid',
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
  },
  radioGroup: {
    '& .MuiFormControlLabel-root': {
      marginRight: 0,

      '& .MuiFormControlLabel-label': {
        fontWeight: 700,
        fontSize: '0.813rem',
        color: '#555555',
      },
    },

    [theme.breakpoints.between('sm', 'md')]: {
      flexDirection: 'row',

      '& .MuiFormControlLabel-root': {
        flex: 1,
      },
    },
  },
  root: {
    marginBottom: theme.spacing(3),
  },
  searchButtonContainer: {
    [theme.breakpoints.up('lg')]: {
      paddingTop: `${theme.spacing(2.55)}px !important`,
      paddingBottom: '0px !important',

      '& .MuiButton-root': {
        lineHeight: '2.075',
      },
    },
  },
  searchFieldContainer: {
    paddingRight: `3px !important`,
  },
  searchControl: {
    width: '100%',

    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  selfAlignCenter: {
    alignSelf: 'center',
  },
  tableContainer: {
    height: 300,
  },
  table: {
    border: 'unset',
    marginTop: theme.spacing(2),

    '& .MuiDataGrid-cell': {
      borderBottom: 'unset',
      lineHeight: '64px !important',
      maxHeight: '64px !important',
      minHeight: '64px !important',

      '&:focus': {
        outline: 'unset',

        '&-within': {
          outline: 'unset',
        },
      },
    },

    '& .MuiDataGrid-columnsContainer': {
      borderBottom: 'unset',
    },

    '& .MuiDataGrid-columnHeader': {
      color: '#555555',
      fontSize: '0.875rem',

      [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
      },

      '&:focus': {
        outline: 'unset',

        '&-within': {
          outline: 'unset',
        },
      },

      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 600,
        lineHeight: '1.43rem',
        overflow: 'hidden',
        whiteSpace: 'normal',
      },
    },

    '& .MuiDataGrid-row': {
      WebkitBoxShadow: 'inset 0px 0px 0px 1px #2E7D32',
      MozBoxShadow: 'inset 0px 0px 0px 1px #2E7D32',
      backgroundColor: theme.palette.common.white,
      borderRadius: 5,
      boxShadow: 'inset 0px 0px 0px 1px #2E7D32',
      fontSize: '1rem',
      margin: theme.spacing(2, 0),
      maxHeight: '64px !important',
      minHeight: '64px !important',

      [theme.breakpoints.down('lg')]: {
        fontSize: '0.875rem',
      },

      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },

    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },

    '& .MuiDataGrid-overlay': {
      zIndex: 999,

      '& .MuiCircularProgress-root': {
        width: '56px !important',
        height: '56px !important',
        color: theme.palette.secondary.main,
      },
    },

    '& .border-right': {
      borderRight: '1px solid #2E7D32',
      borderRadius: 5,
    },

    '& .justify-center': {
      justifyContent: 'center',
    },

    '& .multi-line': {
      alignItems: 'center',
      display: 'flex',
      lineHeight: '1.43 !important',
      overflow: 'hidden',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },

    '& .text-center': {
      textAlign: 'center',
    },

    '& .text-right': {
      textAlign: 'right',
    },

    '& .transform-capitalize': {
      textTransform: 'capitalize',
    },
  },
}));

export const bootstrapStyles = (theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
});
