import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import GreenPlusIcon from '@assets/icons/Green-plus.png';
import RedCrossIcon from '@assets/icons/Red-cross.png';
import { withStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid';
import { capitalize } from 'lodash';
import { ActivityDetailPageStyle } from './style';

const renderIcon = (classes, value) => {
  if (value === 'add') {
    return (
      <img
        src={GreenPlusIcon}
        alt="GreenPlusIcon"
        loading="lazy"
        className={classes.iconEditDelegationStyle}
      />
    );
  } else {
    return (
      <img
        src={RedCrossIcon}
        alt="RedCrossIcon"
        loading="lazy"
        className={classes.iconEditDelegationStyle}
      />
    );
  }
};

function ChildDelegationEditUser({ classes, matrix, definition }) {
  return (
    <div className={classes.activityTypeGrid}>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableExtendRowFullWidth
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        columns={[
          {
            field: 'description',
            flex: 0.57,
            renderHeader: () => <b>Full Name/Location Description</b>,
            minWidth: 200,
          },
          {
            field: 'status',
            renderHeader: () => <b>Status</b>,
            flex: 0.2,
          },
          {
            field: 'activity_type',
            renderHeader: () => <b>Acitvity</b>,
            flex: 0.2,
            renderCell: (params) => {
              const displayName = params.row.displayName;
              return (
                <div>
                  {renderIcon(classes, displayName)}
                  <span
                    className={
                      displayName === 'add'
                        ? classes.activityTypeLabelAdd
                        : classes.activityTypeLabelElse
                    }
                  >
                    {capitalize(displayName)}
                  </span>
                </div>
              );
            },
          },
          {
            field: 'user_type',
            renderHeader: () => <b>Type</b>,
            flex: 0.23,
          },
        ]}
        hideFooter
        rows={matrix.value
          .map((parentItem, parentIndex) =>
            parentItem.value.map((caption, captionIndex) => ({
              id: `id-${parentIndex}-${captionIndex}`,
              displayName: parentItem.displayName,
              description: caption.description,
              status: caption?.is_active === 'active' ? 'Active' : 'Inactive',
              user_type: caption.type,
            })),
          )
          .reduce((acc, current) => [...acc, ...current], [])}
      />
    </div>
  );
}

ChildDelegationEditUser.propTypes = {
  classes: PropTypes.object,
  matrix: PropTypes.object,
  location: PropTypes.object,
  definition: PropTypes.string,
};

export default compose(withStyles(ActivityDetailPageStyle))(ChildDelegationEditUser);
