import {
  adminSignInWithAuthenticationCode,
  adminSignIn,
  adminSignOut,
  adminRefreshToken as adminRefreshTokenService,
  adminResetPassword as adminResetPasswordService,
  adminVerifyToken as adminVerifyTokenService,
  adminUnlockRequest,
  adminUnlockConfirm,
  adminVerifyTokenSetNewPassword as adminVerifyTokenSetNewPasswordService,
  adminSetNewPassword as adminSetNewPasswordService,
} from '@services/api/auth';
import authTypes from './authTypes';

export const adminLogout = (token) => {
  return async (dispatch) => {
    dispatch({ type: authTypes.LOGOUT_REQUEST });
    try {
      const response = await adminSignOut(token);
      dispatch({ type: authTypes.LOGOUT_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: authTypes.LOGOUT_ERROR, error });
      return error;
    }
  };
};

export const adminLogin = (token, params) => {
  return async (dispatch) => {
    dispatch({ type: authTypes.LOGIN_REQUEST });
    try {
      const response = await adminSignIn(token, params);
      dispatch({ type: authTypes.LOGIN_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: authTypes.LOGIN_ERROR, error });
      return error;
    }
  };
};

export const adminRefreshToken = (token) => {
  return async (dispatch) => {
    dispatch({ type: authTypes.REFRESH_TOKEN_REQUEST });
    try {
      const response = await adminRefreshTokenService(token);
      dispatch({ type: authTypes.REFRESH_TOKEN_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: authTypes.REFRESH_TOKEN_ERROR, error });
      if (error.response.status === 403) {
        dispatch({ type: 'CLEAR_STATE' });
      }
      return error;
    }
  };
};

export const adminMultiFactorAuthentication = (token, params) => {
  return async (dispatch) => {
    dispatch({ type: authTypes.MULTI_FACTOR_AUTHENTICATION_REQUEST });
    try {
      const response = await adminSignInWithAuthenticationCode(token, params);
      dispatch({
        type: authTypes.MULTI_FACTOR_AUTHENTICATION_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({
        type: authTypes.MULTI_FACTOR_AUTHENTICATION_ERROR,
        error,
      });
      return error;
    }
  };
};

export const adminResetToken = (token, params) => {
  return async (dispatch) => {
    dispatch({ type: authTypes.NEW_PASSWORD_REQUEST });
    try {
      const response = await adminResetPasswordService(token, params);
      dispatch({
        type: authTypes.NEW_PASSWORD_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({
        type: authTypes.NEW_PASSWORD_ERROR,
        error,
      });
      return error;
    }
  };
};

export const adminVerifyToken = (params) => {
  return async (dispatch) => {
    dispatch({ type: authTypes.VERIFY_TOKEN_REQUEST });
    try {
      const response = await adminVerifyTokenService(params);
      dispatch({
        type: authTypes.VERIFY_TOKEN_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({
        type: authTypes.VERIFY_TOKEN_ERROR,
        error,
      });
      return error;
    }
  };
};

export const adminUnlockAccountRequest = (params) => {
  return async (dispatch) => {
    dispatch({ type: authTypes.UNLOCK_ACCOUNT_REQ_REQUEST });
    try {
      const response = await adminUnlockRequest(params);
      dispatch({
        type: authTypes.UNLOCK_ACCOUNT_REQ_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({
        type: authTypes.UNLOCK_ACCOUNT_REQ_ERROR,
        error,
      });
      return error;
    }
  };
};

export const adminUnlockAccountConfirm = (params) => {
  return async (dispatch) => {
    dispatch({ type: authTypes.UNLOCK_ACCOUNT_CONFIRM_REQUEST });
    try {
      const response = await adminUnlockConfirm(params);
      dispatch({
        type: authTypes.UNLOCK_ACCOUNT_CONFIRM_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({
        type: authTypes.UNLOCK_ACCOUNT_CONFIRM_ERROR,
        error,
      });
      return error;
    }
  };
};

export const adminVerifyTokenSetNewPassword = (params) => {
  return async (dispatch) => {
    dispatch({ type: authTypes.VERIFY_TOKEN_REQUEST });
    try {
      const response = await adminVerifyTokenSetNewPasswordService(params);
      dispatch({
        type: authTypes.VERIFY_TOKEN_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({
        type: authTypes.VERIFY_TOKEN_ERROR,
        error,
      });
      throw new Error(error);
    }
  };
};

export const adminSetNewPassword = (token, params) => {
  return async (dispatch) => {
    dispatch({ type: authTypes.NEW_PASSWORD_REQUEST });
    try {
      const response = await adminSetNewPasswordService(token, params);
      dispatch({
        type: authTypes.NEW_PASSWORD_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({
        type: authTypes.NEW_PASSWORD_ERROR,
        error,
      });
      return error;
    }
  };
};

export const adminClearState = () => {
  return async (dispatch) => {
    dispatch({ type: 'CLEAR_STATE' });
  };
};

export const adminClearErrorState = () => {
  return async (dispatch) => {
    dispatch({ type: authTypes.CLEAR_ERROR_STATE });
  };
};
