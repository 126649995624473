import { get, put, patch } from './baseApi';

const getCardList = async (
  token,
  page = 0,
  size = 25,
  searchby,
  cardStatus,
  searchText,
) => {
  let url = `/admin/cards?page=${page}&size=${size}`;
  if (searchby !== '' && searchText !== '') {
    url = url + `&search_by=${searchby}&keyword=${searchText}`;
  }
  if (cardStatus !== '') {
    url = url + `&card_status=${cardStatus}`;
  }
  return get(url, token);
};

const getCardDetailById = async (token, cardId) => {
  return get(`/admin/card/${cardId}`, token);
};

const putCardBlock = async (token, params) => {
  return put('/admin/card-maintenance/block', params, token);
};

const putCardUnblock = async (token, params) => {
  return put('/admin/card-maintenance/unblock', params, token);
};

const putCardCancel = async (token, params) => {
  return put('/admin/card-maintenance/cancel', params, token);
};

const putCardLostStolen = async (token, params) => {
  return put('/admin/card-maintenance/lost-stolen', params, token);
};

const putCardReplace = async (token, params) => {
  return put('/admin/card-maintenance/replace-damage', params, token);
};

const patchAccountLimit = async (token, params) => {
  return patch('/admin/card-limit', params, token);
};

export {
  getCardList,
  getCardDetailById,
  putCardBlock,
  putCardUnblock,
  putCardCancel,
  putCardLostStolen,
  putCardReplace,
  patchAccountLimit,
};
