import axios from 'axios';
import { config } from './index';

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: config.api_server,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.message === 'Network Error') {
      throw new Error('Internal server error');
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
