import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '1rem',
    fontWeight: 600,
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));
