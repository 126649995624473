import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  appbar: {
    WebkitBoxShadow: '0px 2px 6px 0px rgba(0,0,0,0.2)',
    MozBoxShadow: '0px 2px 6px 0px rgba(0,0,0,0.2)',
    boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.2)',
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: 64,

    [theme.breakpoints.down('sm')]: {
      height: 56,
    },
  },
  brand: {
    marginRight: theme.spacing(2),

    '& .banner': {
      height: 36,
    },
  },
  drawer: {
    width: '80vw',

    [theme.breakpoints.between('sm', 'lg')]: {
      width: '40vw',
    },

    '& .MuiPaper-root': {
      color: '#FFFFFF',
      width: 'inherit',
      borderTopRightRadius: 25,
      borderBottomRightRadius: 25,
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
      padding: theme.spacing(0, 1),

      '& div[role="presentation"]': {
        height: 'inherit',
        display: 'flex',
        flexDirection: 'column',

        '& .MuiList-root': {
          '& a,.MuiButtonBase-root': {
            borderRadius: 5,
            margin: theme.spacing(1, 0),

            '& .MuiListItemIcon-root svg': {
              fontSize: '2.25em',
            },

            '& .MuiListItemText-root span': {
              fontSize: '1.1250em',
              fontWeight: 600,
              color: theme.palette.grey[600],
            },

            '&.active': {
              backgroundColor: 'rgba(225,4,84,0.1)',

              '& .MuiListItemIcon-root svg': {
                color: '#E10454',
              },
              '& .MuiListItemText-root span': {
                color: '#E10454',
              },
            },

            '&:hover': {
              '& .MuiListItemIcon-root svg': {
                color: theme.palette.grey[600],
              },
              '& .MuiListItemText-root span': {
                color: theme.palette.grey[600],
              },
            },
          },
        },
      },
    },
  },
  flex: {
    flex: 1,
  },
  mobileTitle: {
    alignSelf: 'center',
    color: theme.palette.common.white,
    flexWrap: 'wrap',
    fontSize: '1.5em',
    fontWeight: 600,
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25em',
    },
  },
  mobileNotifications: {
    alignSelf: 'center',
    textAlign: 'center',
    '& a': {
      color: theme.palette.common.white,

      '&.active': {
        color: theme.palette.common.white,
      },
    },
  },
  navigation: {
    display: 'flex',
    height: '100%',
    margin: 0,
    listStyleType: 'none',
    padding: 0,

    '& > li': {
      alignItems: 'center',
      borderBottom: '3px solid transparent',
      display: 'flex',
      flexDirection: 'row',
      margin: theme.spacing(0, 0.5),

      '&.active': {
        borderBottom: `3px solid ${theme.palette.primary.main}`,

        '& a': {
          color: theme.palette.primary.main,
        },
      },

      '& a,button': {
        color: theme.palette.grey[600],
        borderRadius: 5,
        fontSize: '1.1250em',
        fontWeight: 600,
        marginTop: theme.spacing(1),
        padding: theme.spacing(0.75, 1),

        '&:hover': {
          backgroundColor: 'rgba(225,4,84,0.075)',
          color: theme.palette.primary.main,
          textDecoration: 'none',
        },
      },

      '&.rounded': {
        '& a': {
          borderRadius: 999,
          padding: theme.spacing(1),
        },
      },
    },
  },
  notificationBadge: {
    '& .MuiBadge-badge': {
      top: theme.spacing(1),
      right: theme.spacing(0.25),
    },
  },
  notificationIcon: {
    fontSize: '2.250em',
  },
  root: {
    width: 'unset',
  },
  sidebar: {
    display: 'flex',
  },
  sidebarBrand: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  sidebarIcons: {
    color: theme.palette.common.white,
    padding: '0',
    '& svg': {
      fontSize: '1.25em',
    },
  },
  mainList: {
    flex: 1,
    padding: 0,
  },
  toolbar: {
    backgroundColor: '#233862',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    display: 'flex',
    paddingLeft: 24,
    paddingRight: 24,
  },
  tooltip: {
    maxWidth: 500,
    height: 30,
    fontSize: '0.875em',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(1),
  },
  tooltipArrow: {
    fontSize: 20,
    color: '#a7002b',
  },
}));
