const checkValidity = (value, rules) => {
  if (!rules) {
    return true;
  }

  if (rules.escapedString) {
    const pattern =
      /^[a-z\d\-_\s.'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ]+$/i;
    if (value && !pattern.test(value)) {
      return false;
    }
  }

  if (rules.minNumber && value < rules.minNumber) {
    return false;
  }

  if (rules.maxNumber && value > rules.maxNumber) {
    return false;
  }

  if (rules.minLengthString && value.length < rules.minLengthString) {
    return false;
  }

  if (rules.maxLengthString && value.length > rules.maxLengthString) {
    return false;
  }

  if (rules.isEmail) {
    // eslint-disable-next-line max-len
    // eslint-disable-next-line no-useless-escape
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value && !pattern.test(value)) {
      return false;
    }
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;

    if (value && !pattern.test(value)) {
      return false;
    }
  }

  if (rules.isPhoneNumber) {
    const pattern =
      /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})|([8|9]\d{7})$/;
    if (!value || !pattern.test(value)) {
      return false;
    }
  }

  return true;
};

export default checkValidity;
